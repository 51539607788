<script lang="ts" setup>
  import { computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import IconBackstage from '@backstage/ui-lib/src/icons/IconBackstage.vue';
  import { useRepo } from 'pinia-orm';

  import { Action, TrainingRequest } from '@orm-lib';
  import {
    UiButton,
    UiLogo,
    UiMenuLink,
    UiRouterLink,
    useMedia,
  } from '@ui-lib';
  import { useApp, useHome } from '@/composables';
  import { useAppStore } from '@/stores';

  const { isAdmin, isCoach, isLearner, member, user } = useApp();
  const { trainings, getLearnerTrainingAction } = useHome();
  const router = useRouter();
  const store = useAppStore();

  interface IProps {
    collapsed: boolean;
    variant?: 'fixed' | 'pink' | 'gradient';
  }
  interface IEmits {
    (e: 'toggleSidebar'): void;
  }
  const props = defineProps<IProps>();

  const emit = defineEmits<IEmits>();

  const lg = useMedia('lg');
  const route = useRoute();

  const showGroupManagementLink = computed(() => {
    return isLearner.value && member.value?.owned_group?.id;
  });

  const formattedTrainings = computed(() => {
    return trainings.value
      .map((training) => getLearnerTrainingAction(training, true))
      .filter((training) => !!training?.key);
  });

  const showTrainingLink = computed(() => {
    return (
      isCoach.value ||
      member.value?.learner_group ||
      formattedTrainings.value?.length
    );
  });

  const actionCount = computed(() => {
    if (isCoach.value) {
      return null;
    }

    return useRepo(Action).withAll().get().length;
  });

  const groupActionCount = computed(() => {
    const actionCount = useRepo(Action)
      .withAllRecursive()
      .get()
      .filter(
        (action) =>
          action.reason === 'GroupWithoutGoal' ||
          action.reason === 'GroupLearnerWithoutGoal' ||
          action.reason === 'GoalWithoutReport'
      ).length;
    const reviewCount = useRepo(TrainingRequest)
      .withAllRecursive()
      .where('status', 'PendingGroupOwnerReview')
      .get().length;

    return actionCount + reviewCount;
  });

  const administrationTaskCount = computed(() => {
    const adminTaskCount = useRepo(Action)
      .withAllRecursive()
      .get()
      .filter(
        (action) =>
          action.reason === 'EmployerWithoutGroup' ||
          action.reason === 'EmployerPastDue' ||
          action.reason === 'GroupWithoutLearner' ||
          action.reason === 'GroupWithoutOwner'
      ).length;

    const adminRequestCount = useRepo(TrainingRequest)
      .withAllRecursive()
      .where('status', 'PendingEmployerAdminReview')
      .get().length;

    return adminTaskCount + adminRequestCount;
  });

  const sidebarClass = computed(() => {
    const baseClass =
      'fixed top-0 left-0 z-40 border-r-[1px] border-gray-200 border-solid h-screen w-[276px] overflow-y-auto overflow-x-visible bg-white transition-transform ease-in-out duration-300 ';

    if (props.collapsed) {
      return baseClass + ' -translate-x-[194px]';
    }

    return baseClass;
  });
</script>

<template>
  <div
    id="drawer-navigation"
    :class="sidebarClass"
    tabindex="-1"
    aria-labelledby="drawer-navigation-label"
  >
    <div
      :class="`z-[41] flex h-full flex-col gap-y-1 overflow-y-auto overflow-x-visible py-7 pl-8 ${
        collapsed ? ' pr-[26px]' : ' pr-6'
      }`"
    >
      <div
        :class="`border-b-[1px] border-solid border-[#EFEDF3] ${
          !collapsed
            ? ' -mr-6 -ml-8 pl-[42px] pr-6'
            : ' -mr-[26px] -ml-8 self-end pl-7 pr-[30px]'
        }`"
      >
        <UiRouterLink
          :class="`mb-5 flex w-full flex-row items-center self-stretch hover:no-underline`"
          aria-label="Backstage Home"
          :to="{ name: 'Home' }"
        >
          <div class="flex flex-row items-center gap-x-3">
            <svg
              :class="`block ${collapsed ? 'ml-auto' : ''}`"
              width="24"
              height="26"
              viewBox="0 0 24 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.9501 11.3737V0H8.50908C3.2738 0 0 2.98448 0 7.16805C0 9.91241 1.47258 12.0256 4.14007 13.1348V26H14.7687C19.8533 26 24 22.8535 24 18.1205C23.9956 14.9924 22.3627 12.6767 18.9501 11.3737ZM4.02856 7.16805C4.02856 4.68221 5.88147 3.56855 8.85027 3.56855H14.8861V10.8846H8.85027C5.88147 10.8846 4.02856 9.53456 4.02856 7.16805ZM14.395 22.3939H8.21072V13.8669H14.395C17.8955 13.8669 19.9338 15.3032 19.9338 18.1205C19.9338 20.9377 17.8955 22.3939 14.395 22.3939Z"
                fill="#5E5CFF"
              />
            </svg>
            <h1
              v-if="!collapsed"
              class="font-avenir text-header-primary mt-[2px] text-[22px] font-normal leading-[22px] tracking-[0.07rem]"
            >
              BACKSTAGE
            </h1>
          </div>
        </UiRouterLink>
      </div>
      <UiMenuLink
        :collapsed="collapsed"
        :to="{ name: 'Home' }"
        text="Dashboard"
        icon="Dashboard"
        :task-count="actionCount"
      />
      <UiMenuLink
        v-if="isLearner && isAdmin"
        :collapsed="collapsed"
        :to="{ name: 'Administration' }"
        text="Administration"
        icon="Administration"
        :is-active="route.path.includes('administration')"
        :task-count="administrationTaskCount"
      />
      <UiMenuLink
        v-if="isLearner && isAdmin"
        :collapsed="collapsed"
        :to="{ name: 'Billing' }"
        text="Billing"
        icon="Billing"
      />
      <UiMenuLink
        v-if="isCoach && isAdmin"
        :collapsed="collapsed"
        :to="{ name: 'CoachList' }"
        text="Users"
        icon="Group"
      />
      <UiMenuLink
        v-if="showGroupManagementLink"
        :collapsed="collapsed"
        :text="member.owned_group?.name"
        icon="Group"
        :to="{
          name: 'GroupManagement',
          params: {
            groupId: member.owned_group.id,
          },
        }"
        :task-count="groupActionCount"
      />
      <UiMenuLink
        v-if="isCoach"
        :collapsed="collapsed"
        :to="{ name: 'RequestList' }"
        text="Requests"
        icon="Reporting"
      />
      <UiMenuLink
        v-if="showTrainingLink"
        :collapsed="collapsed"
        :to="{ name: 'TrainingList' }"
        icon="Training"
        text="Training"
      />
      <UiMenuLink
        v-if="isLearner && (isAdmin || showGroupManagementLink)"
        :collapsed="collapsed"
        :to="{ name: 'Reporting' }"
        icon="Reporting"
        text="Reporting"
      />
      <UiButton
        :class="`transition-margin mt-auto h-[48px] !rounded-[10px] !py-2 duration-300 ease-in-out hover:!bg-[#F6F8FC] focus:!outline-0 focus:!ring-0 active:!bg-[#F6F8FC] active:!bg-opacity-10 active:!outline-0 active:!ring-0 [&>span]:flex [&>span]:items-center [&>*]:!text-gray-500 ${
          collapsed
            ? ' -mr-2 w-[48px] self-end'
            : ' w-full !px-4 [&>span]:!flex [&>span]:!justify-start'
        }`"
        variant="link"
        @click="emit('toggle-sidebar')"
      >
        <svg
          :class="`mr-4 stroke-[#5C6582] ${collapsed && 'ml-auto rotate-180'}`"
          width="9"
          height="15"
          viewBox="0 0 9 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 1L2 7.5L8 14" stroke-width="2" stroke-linecap="round" />
        </svg>
        <span
          v-if="!collapsed"
          class="block text-center font-normal leading-none text-gray-500"
        >
          Collapse
        </span>
      </UiButton>
    </div>
  </div>
</template>

<style scoped>
  .menu-link-update {
    @apply hover:bg-brand-light active:bg-brand-active text-brand-primary border-brand-primary relative mx-4 mt-16 block w-[unset] self-start rounded-md border-[1px] border-solid hover:!no-underline;
  }

  .menu-link-active {
    @apply text-brand-primary bg-brand-light relative rounded-md font-semibold;
  }

  .menu-link-active::before {
    content: '';
    @apply bg-brand-primary absolute -left-[32px] top-0 h-full w-[12px] rounded-full;
  }

  .menu-link-active.collapsed::before {
    @apply -left-[22px];
  }
</style>

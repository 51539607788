<script lang="ts" setup>
  import { computed, ref, shallowReactive } from 'vue';
  import AgoraRTC, {
    ILocalAudioTrack,
    ILocalVideoTrack,
  } from 'agora-rtc-sdk-ng';

  import { UiButton, UiFormGroup, UiModal } from '@ui-lib';
  import { useApp } from '@/composables';

  interface IScreen {
    display_id: string;
    name: string;
    id: string;
    thumbnail: any;
    thumbnailUrl: string;
  }

  const emit = defineEmits<{
    (e: 'close', arg: boolean): void;
    (e: 'present', args: any): void;
  }>();

  const { isDesktop } = useApp();

  const media = shallowReactive({
    audioTrack: null,
    stream: null,
    videoTrack: null as
      | ILocalVideoTrack
      | [ILocalVideoTrack, ILocalAudioTrack]
      | null,
  });
  const selectedScreen = ref<null | IScreen>(null);
  const screens = ref<IScreen[]>([]);
  const showModal = ref(false);

  const desktops = computed(() => {
    let result = [];

    if (screens.value !== null) {
      for (const screen of screens.value) {
        if (screen.display_id !== '' && screen.display_id !== null) {
          result.push(screen);
        }
      }
    }

    return result;
  });

  const windows = computed(() => {
    let result = [];

    if (screens.value !== null) {
      for (const screen of screens.value) {
        if (screen.display_id === '' || screen.display_id === null) {
          result.push(screen);
        }
      }
    }
    return result;
  });

  function close() {
    showModal.value = false;
    selectedScreen.value = null;
    screens.value = [];
    emit('close', true);
  }

  function getScreenThumbnail(screen: IScreen) {
    return screen.thumbnailUrl;
  }

  async function onPresentScreen(browserStream?: object) {
    const stream =
      browserStream ||
      (await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          mandatory: {
            width: 1080,
            height: 1920,
            frameRate: { ideal: 5, max: 5 },
            chromeMediaSource: 'desktop',
            chromeMediaSourceId: selectedScreen.value?.id,
          },
        } as MediaTrackConstraints,
      }));
    const track = stream.getVideoTracks()[0];

    media.videoTrack = AgoraRTC.createCustomVideoTrack({
      mediaStreamTrack: track,
      optimizationMode: 'detail',
    });

    emit('present', {
      audioTrack: media.audioTrack,
      stream: media.stream,
      videoTrack: media.videoTrack,
    });

    close();
  }

  function onSelectScreen(screen: IScreen) {
    selectedScreen.value = screen;
  }

  async function show() {
    if (showModal.value) {
      return;
    }

    if (isDesktop.value) {
      screens.value = await window.electronDesktopCapturer.getSources();
      showModal.value = true;
    } else {
      const browserSelectedStream =
        await navigator.mediaDevices.getDisplayMedia();
      await onPresentScreen(browserSelectedStream);
    }
  }

  defineExpose({ show });
</script>

<template>
  <UiModal v-if="showModal" @close="close">
    <div class="modal-container">
      <div class="flex">
        <!-- List of Screens -->
        <div class="picker-list">
          <div class="page-section picker-list-section">Desktops</div>
          <div v-if="desktops.length === 0" class="picker-list-empty">
            There are no desktops
          </div>
          <UiButton
            v-for="desktop in desktops"
            :key="`desktop${desktop.id}`"
            class="picker-button"
            @click="onSelectScreen(desktop)"
          >
            <div class="min-w-[72px] max-w-[72px] text-center">
              <img
                :src="getScreenThumbnail(desktop)"
                class="picker-list-thumbnail"
              />
            </div>
            <div class="picker-list-title" style="padding-left: 10px">
              {{ desktop.name }}
            </div>
          </UiButton>
          <div class="page-section picker-list-section">Windows</div>
          <div v-if="windows.length === 0" class="picker-list-empty">
            There are no windows
          </div>
          <UiButton
            v-for="window in windows"
            :key="`window${window.id}`"
            class="picker-button"
            @click="onSelectScreen(window)"
          >
            <div class="min-w-[72px] max-w-[72px] text-center">
              <img
                :src="getScreenThumbnail(window)"
                class="picker-list-thumbnail"
              />
            </div>
            <div class="picker-list-title" style="padding-left: 10px">
              {{ window.name }}
            </div>
          </UiButton>
        </div>
        <!-- Selected Screen Detail -->
        <div class="picker-detail">
          <div v-if="selectedScreen === null" class="flex flex-col">
            <div class="form-title">Select a Screen</div>
            <div class="form-subtitle">
              Use the menu on the left to select a screen to present.
            </div>
          </div>
          <div v-if="selectedScreen !== null" class="flex flex-col">
            <div class="form-title">Present Screen</div>
            <div class="form-subtitle">
              Press present to share this screen now.
            </div>
            <UiFormGroup class="form-group-text !mb-4">
              <div class="form-group-title">title</div>
              <div
                class="text-ellipses text-body-primary whitespace-wrap overflow-hidden text-sm"
              >
                {{ selectedScreen.name }}
              </div>
            </UiFormGroup>
            <UiFormGroup class="form-group-text !mb-4">
              <div class="form-group-title">type</div>
              <span
                v-if="selectedScreen.display_id"
                class="text-ellipses text-body-primary overflow-hidden whitespace-nowrap text-sm"
              >
                Desktop
              </span>
              <span
                v-if="!selectedScreen.display_id"
                class="text-ellipses text-body-primary overflow-hidden whitespace-nowrap text-sm"
              >
                Window
              </span>
            </UiFormGroup>
            <UiFormGroup
              v-if="selectedScreen.thumbnailUrl"
              class="form-group-text !mb-4"
            >
              <div class="form-group-title">thumbnail</div>
              <img
                class="block h-auto max-h-[202px] w-auto max-w-full rounded-[4px] object-contain"
                :src="getScreenThumbnail(selectedScreen)"
              />
            </UiFormGroup>
            <UiFormGroup class="form-group-submit">
              <UiButton
                class="text mr-2 self-start !rounded-[5px]"
                type="button"
                @click="() => onPresentScreen(null)"
              >
                Present
              </UiButton>
            </UiFormGroup>
          </div>
        </div>
      </div>
    </div>
  </UiModal>
</template>

import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Attr, Num, Str } from 'pinia-orm/dist/decorators';

export type TBlogPostType =
  | 'cms.Root'
  | 'cms.BlogInterview'
  | 'cms.BlogArticle'
  | 'cms.BlogRelease';

export interface IPostMeta {
  first_published_at: string;
  slug: string;
  type: TBlogPostType;
}

export interface IPostAuthor {
  first_name: string;
  last_name: string;
  avatar: string;
}

export interface IPostThumbnail {
  url: string;
  width: number;
  height: number;
  alt: string;
  caption: string;
}

export interface IPostBodyItem {
  type: string;
  value: string;
  id: string;
}

export interface IPostHero {
  url: string;
  width: number;
  height: number;
  alt: string;
  caption: string;
}

export interface IBlogVideoChapter {
  id: number;
  minutes: number;
  seconds: number;
  name: string;
  meta: {
    type: string;
  };
}

export default class BlogPost extends Model {
  static entity = 'blog-post';

  @Num(null) declare id: number;
  @Attr(null) declare meta: IPostMeta;
  @Str('') declare title: string;
  @Attr(null) declare author: IPostAuthor;
  @Str('') declare subtitle: string;
  @Str('') declare published: string;
  @Attr(null) declare thumbnail: IPostThumbnail;
  @Attr(null) declare body: IPostBodyItem[];
  @Attr(null) declare hero: IPostHero;
  @Attr(null) declare chapters: IBlogVideoChapter[];
  @Str(null) declare description: string;
  @Str(null) declare video_url: string;

  static api() {
    return {
      async list() {
        const { data } = await axios({
          method: 'GET',
          url: 'pages/?type=cms.BlogBase&fields=author,published',
          baseURL: import.meta.env.VITE_CMS_BASE_URL,
        });
        const repo = useRepo(BlogPost);
        repo.flush();
        repo.save(data.items);
      },
      async postDetail(slug: string) {
        const { data: basicData } = await axios({
          method: 'GET',
          url: `pages/?slug=${slug}`,
          baseURL: import.meta.env.VITE_CMS_BASE_URL,
        });

        const { data } = await axios({
          method: 'GET',
          url: `pages/${basicData.items?.[0]?.id}`,
          baseURL: import.meta.env.VITE_CMS_BASE_URL,
        });

        const repo = useRepo(BlogPost);
        repo.save(data);
      },
    };
  }

  static piniaOptions = {
    persist: true,
  };
}

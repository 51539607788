<template>
  <div>
    <template v-if="label || (labelProgress && labelPosition === 'outside')">
      <div class="mb-1 flex justify-between">
        <span class="text-base font-medium" :class="outsideLabelClasses">
          {{ label }}
        </span>
        <span
          v-if="labelProgress && labelPosition === 'outside'"
          class="text-sm font-medium"
          :class="outsideLabelClasses"
        >
          {{ progress }}%
        </span>
      </div>
    </template>
    <div
      class="w-full rounded-full bg-gray-200 dark:bg-gray-700"
      :class="outerClasses"
    >
      <div
        class="rounded-full p-0.5 text-center font-medium text-blue-100"
        :class="innerClasses"
        :style="{ width: progress + '%' }"
      >
        <template v-if="labelProgress && labelPosition === 'inside'">
          {{ progress }}%
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import type { PropType } from 'vue';
  import { toRefs } from 'vue';

  import { useProgressClasses } from './composables/useProgressClasses';
  import type {
    ProgressLabelPosition,
    ProgressSize,
    ProgressVariant,
  } from './types';

  const props = defineProps({
    color: {
      type: String as PropType<ProgressVariant>,
      default: 'default',
    },
    label: {
      type: String,
      default: '',
    },
    labelPosition: {
      type: String as PropType<ProgressLabelPosition>,
      default: 'none',
    },
    labelProgress: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    size: {
      type: String as PropType<ProgressSize>,
      default: 'md',
    },
  });

  const { innerClasses, outerClasses, outsideLabelClasses } =
    useProgressClasses(toRefs(props));
</script>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import {
    AngleRight,
    Sparkle,
} from "@/components/icons";

const router = useRouter();

interface IProps {
    icon?: 'angle-right' | 'sparkle' | null;
    text?: string;
    to?: object | null;
}

const props = withDefaults(defineProps<IProps>(), {
    icon: null,
    text: 'Button',
    to: null
});

interface IEmits {
    (e: 'click'): void;
}

const emit = defineEmits<IEmits>();

const buttonClass = computed(() => {
    let result = 'box-border group font-[400] rounded-[8px] focus-visible:outline-golden focus-visible:outline-dashed focus-visible:outline-offset-[2px] transition duration-[200ms] bg-primary text-white hover:bg-hoverPrimary h-[44px] text-[14px]'

    // padding
    if (props.icon === null) {
        result = result + ' px-[16px]'
    } else {
        result = result + ' pl-[16px] pr-[8px]'
    }

    return result
})

const iconClass = computed(() => {
    return 'stroke-white w-[24px] h-[24px]'
})

function handleClick() {
    if (props.to !== null) {
        router.push(props.to)
    } else {
        emit('click')
    }
}
</script>

<template>
    <button :class="buttonClass" @click="handleClick">
        <div class="flex flex-row items-center gap-[2px]">
            {{ props.text }}
            <AngleRight v-if="props.icon === 'angle-right'" :class="iconClass" />
            <Sparkle v-if="props.icon === 'sparkle'" :class="iconClass" />
        </div>
    </button>
</template>
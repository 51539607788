<script lang="ts" setup>
import { computed, reactive, ref, watch } from "vue";

import type { GroupCreatePayload, UseCase } from '@/components/public/types';
import { GroupCreatePayloadCreate } from '@/components/public/types';
import { EmailListRow, FormBase, TextRow } from './'


type Nullable<T> = T | null;

defineOptions({ version: 1 })

interface IProps {
    currentStep?: number,
    stepCount?: number,
    useCase: UseCase,
    workEmail: Nullable<string>,
}

const props = withDefaults(defineProps<IProps>(), {
    currentStep: 1,
    stepCount: 1,
});

interface IEmits {
    (e: 'back'): void;
    (e: 'submit', value:GroupCreatePayload): void;
}

const emit = defineEmits<IEmits>();

const errorMessage = ref<string | null>(null)

type useCaseLabel = {
    formSubtitle: string,
    formTitle: string,
    groupLearnersSubtitle: string,
    groupLearnersTitle: string,
    groupNameTitle: string,
    groupOwnersSubtitle: string | null,
    groupOwnersTitle: string | null,
}

const labels: Record<string, useCaseLabel> = {
    manager: {
        formTitle: 'Your Team',
        formSubtitle: 'Let us know a little bit about your team.',
        groupNameTitle: 'Team Name',
        groupLearnersTitle: 'Team Members',
        groupLearnersSubtitle: 'Enter the email address of each team member you want to invite to receive training.',
        groupOwnersTitle: null,
        groupOwnersSubtitle: null,
    },
    leadership: {
        formTitle: 'Your First Team',
        formSubtitle: "Let's set up the first team in your department.",
        groupNameTitle: 'Team Name',
        groupLearnersTitle: 'Team Members',
        groupLearnersSubtitle: 'Enter the email address of each team member you want to invite to receive training.',
        groupOwnersTitle: 'Team Manager',
        groupOwnersSubtitle: "Enter the email address of the person who will manage training for this team. If that person is you, leave this field blank."
    },
    learning: {
        formTitle: 'Your First Group',
        formSubtitle: "Let's set up your first training group.",
        groupNameTitle: 'Group Name',
        groupLearnersTitle: 'Group Members',
        groupLearnersSubtitle: 'Enter the email address of each employee you want to invite to receive training.',
        groupOwnersTitle: null,
        groupOwnersSubtitle: null,
    }
}

const data = reactive(GroupCreatePayloadCreate())

const groupName = ref<typeof TextRow>()
const groupOwners = ref<typeof EmailListRow>()
const groupLearners = ref<typeof EmailListRow>()
const inputs = [
    groupName,
    groupOwners,
    groupLearners
]

const formSubtitle = computed(() => {
    if (props.useCase === null || props.useCase === 'individual')
        return null

    return labels[props.useCase].formSubtitle
})

const formTitle = computed(() => {
    if (props.useCase === null || props.useCase === 'individual')
        return null

    return labels[props.useCase].formTitle
})

const groupLearnersSelfEmail = computed(() => {
    if (props.useCase === 'manager')
        return props.workEmail

    return null
})

const groupLearnersSubtitle = computed(() => {
    if (props.useCase === null || props.useCase === 'individual')
        return null

    return labels[props.useCase].groupLearnersSubtitle
})

const groupLearnersTitle = computed(() => {
    if (props.useCase === null || props.useCase === 'individual')
        return null

    return labels[props.useCase].groupLearnersTitle
})

const groupNameTitle = computed(() => {
    if (props.useCase === null || props.useCase === 'individual')
        return null

    return labels[props.useCase].groupNameTitle
})

const groupOwnersTitle = computed(() => {
    if (props.useCase === null || props.useCase === 'individual')
        return null

    return labels[props.useCase].groupOwnersTitle
})

const groupOwnersSubtitle = computed(() => {
    if (props.useCase === null || props.useCase === 'individual')
        return null

    return labels[props.useCase].groupOwnersSubtitle
})

const showGroupOwners = computed(() => {
    if (props.useCase === 'leadership' || props.useCase === 'individual')
        return true

    return false
})

function handleBack() {
    emit('back')
}

function handleGroupNameChange(inputValue: Nullable<string>) {
    data.group_name = inputValue
}

function handleGroupLearnersChange(emails: Array<string>) {
    data.group_learners = emails
}

function handleGroupOwnersChange(emails: Array<string>) {
    data.group_owners = emails
}

async function handleSubmit() {
    let errorCount = 0;

    inputs.forEach((input) => {
        if (input.value !== undefined) {
            let error = input.value.validate()
            if (error !== null) {
                errorCount += 1;
            }
        }
    });

    if (errorCount !== 0)
        return

    emit('submit', data)
}

watch(() => props.useCase,() => {
    inputs.forEach((input) => {
        input.value?.resetValidation();
    })
});
</script>

<template>
    <FormBase
        @back=handleBack
        @submit=handleSubmit
        :current-step=currentStep
        :error-message=errorMessage
        mode=sign-up
        :title=formTitle
        :step-count=stepCount
        :subtitle=formSubtitle
    >
        <TextRow id="group-create-group-name" ref="groupName" :required="true" :title=groupNameTitle type="text" @change=handleGroupNameChange @submit=handleSubmit />
        <EmailListRow
            v-show="showGroupOwners"
            id="group-create-group-owners"
            ref="groupOwners"
            :required="false"
            :subtitle=groupOwnersSubtitle
            :title=groupOwnersTitle
            @change=handleGroupOwnersChange
        />
        <EmailListRow
            id="group-create-group-learners"
            ref="groupLearners"
            :required="true"
            :self-email=groupLearnersSelfEmail
            :subtitle=groupLearnersSubtitle
            :title=groupLearnersTitle
            @change=handleGroupLearnersChange
        />
    </FormBase>
</template>
<script lang="ts" setup>
  import { ref, watch } from 'vue';

  interface IProps {
    disabled?: boolean;
    id: string | number;
    label?: string;
    modelValue: boolean | number | string;
  }
  const props = withDefaults(defineProps<IProps>(), {
    disabled: false,
  });

  const localValue = ref(props.modelValue);

  const emit = defineEmits<{
    (e: 'input', value: boolean): void;
  }>();

  watch(
    () => props.modelValue,
    (newVal) => {
      localValue.value = newVal;
    }
  );
</script>

<template>
  <div class="checkbox flex w-full items-start pt-4">
    <input
      :id="id"
      v-model="localValue"
      :disabled="disabled"
      type="checkbox"
      class="focus:ring-brand-primary text-brand-primary mb-8 mr-1 h-4 w-4 rounded border-gray-300 bg-gray-100 focus:ring-2"
      @update:model-value="(value) => emit('input', value)"
    />
    <label
      for="id"
      class="text-body-primary w- ml-2 flex w-full text-xs sm:text-sm"
    >
      <template v-if="!!label">
        {{ label }}
      </template>
      <slot v-else></slot>
    </label>
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue';

  import { UiRouterLink } from '@ui-lib';
  import AppPanel from '@/components/AppPanel.vue';
  import PanelListHeading from '@/components/PanelListHeading.vue';
  import RatingRange from '@/components/RatingRange.vue';

  interface IProps {
    trainingCount?: number;
    resultCount?: number;
    from?: string;
    to?: string;
    variant: 'employer' | 'cohort';
  }
  const props = defineProps<IProps>();

  const title = computed(() => {
    return 'All Skills';
  });

  const subtitle = computed(() => {
    if (props.variant === 'employer') {
      return 'See the impact of training on your organization.';
    }

    return 'See the impact of training on this cohort.';
  });

  const link = computed(() => {
    if (props.variant === 'employer') {
      return { name: 'EmployerEfficacy' };
    }

    return { name: 'CohortEfficacy' };
  });
</script>

<template>
  <AppPanel title="Measurement" :subtitle="subtitle">
    <PanelListHeading heading="Improvement" />
    <RatingRange
      :title="title"
      :result-count="resultCount"
      :training-count="trainingCount"
      :from="from"
      :to="to"
    />
    <UiRouterLink :to="link" class="cta-link">View Details</UiRouterLink>
  </AppPanel>
</template>

<style scoped>
  .detail-link {
    font-size: 13px;
    margin-bottom: 20px;
    align-self: flex-start;
  }

  :deep(.form-list-form) {
    display: flex;
    flex-direction: column;
  }
</style>

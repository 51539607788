<script lang="ts" setup>
  import { computed, ref } from 'vue';

  const props = defineProps<{
    from?: string | number;
    to?: string | number;
    resultCount: number;
    trainingCount: number;
    title: string;
  }>();

  const barContainer = ref();

  const barWidth = computed(() => {
    const ratingRange = Number(props.to) - Number(props.from);

    return (ratingRange / 9) * 100;
  });

  const barLeftPosition = computed(() => {
    return ((Number(props.from) - 1) / 9) * 100;
  });

  const barContainerWidth = computed(() => {
    return barContainer.value?.clientWidth;
  });

  const barGradient = computed(() => {
    return `linear-gradient(to right, #BF81D3, #7587FE 50%, #5BBEFA)`;
  });

  const barStyles = computed(() => {
    return `width: ${barWidth.value}%; margin-left:${barLeftPosition.value}%; background-image: ${barGradient.value};`;
  });

  const trainingText = computed(() => {
    const suffix =
      props.trainingCount > 1 || props.trainingCount === 0 ? 's' : '';

    return `${props.trainingCount} training${suffix}`;
  });

  const fromText = computed(() => {
    return translateRating(props.from);
  });

  const toText = computed(() => {
    return translateRating(props.to);
  });

  function translateRating(value: string | undefined) {
    const numericValue = Number(value);

    if (numericValue <= 1) {
      return 'terrible';
    }
    if (numericValue < 3) {
      return 'poor';
    }
    if (numericValue < 5) {
      return 'fair';
    }
    if (numericValue < 7) {
      return 'good';
    }
    if (numericValue < 9) {
      return 'great';
    }
    return 'excellent';
  }
</script>

<template>
  <div class="mb-4 last:mb-0">
    <template v-if="resultCount">
      <div class="mb-3 flex items-center justify-between">
        <h2 class="text-header-primary text-base font-semibold leading-5">
          {{ title }}
        </h2>
        <span v-if="trainingCount" class="text-header-primary text-base">
          {{ trainingText }}
        </span>
      </div>
      <div
        ref="barContainer"
        class="relative mb-4 mt-4 h-6 w-full rounded-sm bg-[#F3F5F8]"
      >
        <div class="rating-tick-marks">
          <span
            v-if="Number(from) >= 2"
            class="text-base font-semibold text-[#BA82D7]"
          >
            1
          </span>
          <span
            v-if="Number(to) <= 9"
            class="ml-auto text-base font-semibold text-[#35B3FF]"
          >
            10
          </span>
        </div>
        <div class="rating-bar" :style="barStyles">
          <span class="text-base font-semibold">{{ from }}</span>
          <span class="to-pseudo relative text-base font-semibold">
            {{ to }}
          </span>
        </div>
      </div>
    </template>
    <div v-else class="no-data-message">
      There aren’t enough completed trainings with collaborator feedback to
      calculate improvement.
    </div>
  </div>
</template>

<style scoped>
  .rating-bar,
  .rating-tick-marks {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .rating-tick-marks {
    padding: 1px 7px;
  }

  .rating-bar {
    padding: 3px 7px;

    border-radius: 2px;
    color: white;
    height: 100%;
    min-width: 50px;
    z-index: 5;
  }

  .rating-tick-marks {
    width: 100%;
    position: absolute;
    color: #adadad;
    z-index: 3;
  }

  .no-data-message {
    font-size: 14px;
    text-align: left;
    @apply text-body-secondary;
  }

  .to-pseudo::after {
    content: '';
    position: absolute;
    right: -18px;
    border-top: solid 12px transparent;
    border-left: solid 12px #5bbefa;
    border-bottom: solid 12px transparent;
  }
</style>

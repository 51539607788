<script lang="ts" setup>
  import { computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { DateTime } from 'luxon';

  import { UiButton } from '@ui-lib';

  interface IBaseTask {
    title: string;
    subtitle?: string;
    coach?: string;
    trainingId: number;
    skillId?: number;
    created?: string | null;
    currentSessionId?: number;
    entity: 'assignment' | 'engagement';
    canReschedule: boolean;
    scheduled?: string | null;
    coachMatchCompleted?: string | null;
    started?: string | null;
  }

  const props = defineProps<IBaseTask>();
  const router = useRouter();

  const formattedRescheduleText = computed(() => {
    if (props.entity === 'assignment') {
      return null;
    }

    return 'Reschedule';
  });

  const ctaTo = computed(() => {
    if (props.entity === 'assignment') {
      return {
        name: 'Assignment',
        params: { assignmentId: props.trainingId, skillId: props.skillId },
      };
    }

    if (!props.scheduled && !props.currentSessionId) {
      return {
        name: 'SessionLearnerSchedule',
        params: { engagementId: props.trainingId },
      };
    }

    return {
      name: 'Session',
      params: { engagementId: props.trainingId },
    };
  });

  const ctaText = computed(() => {
    if (props.entity === 'assignment') {
      return 'Start Assignment';
    }

    if (!props.scheduled && !props.currentSessionId) {
      return 'Schedule Session';
    }

    return 'Join Session';
  });

  const formattedScheduledText = computed(() => {
    return (
      DateTime.fromISO(props.scheduled ?? '').toFormat('MMMM dd, hh:mma') +
      ' - ' +
      DateTime.fromISO(props.scheduled ?? '')
        .plus({ hour: 1 })
        .toFormat('hh:mma')
    );
  });

  const matchDateText = computed(() => {
    return DateTime.fromISO(props.coachMatchCompleted ?? '').toRelative();
  });
</script>

<template>
  <div
    class="flex min-h-[208px] w-full flex-col justify-between rounded-lg border-[1px] border-solid border-[#E8ECF4] bg-white p-4 sm:px-8 sm:py-6"
  >
    <div class="flex flex-col items-start justify-between">
      <h3 class="text-header-primary mb-0.5 text-base font-semibold sm:text-xl">
        {{ title }}
      </h3>
      <span
        v-if="coach"
        class="text-body-secondary line-clamp-2 text-base sm:text-lg"
      >
        Coach: {{ coach }}
      </span>
      <span
        v-else-if="!started"
        class="text-body-secondary line-clamp-2 text-base sm:text-lg"
      >
        Assigned: {{ DateTime.fromISO(created).toRelative() }}
      </span>
      <span
        v-else
        class="text-body-secondary line-clamp-2 text-base sm:text-lg"
      >
        Coach Matching in Progress...
      </span>
      <span
        v-if="!!scheduled"
        class="text-body-secondary line-clamp-2 text-base sm:text-lg"
      >
        {{ formattedScheduledText }}
      </span>
    </div>
    <div
      class="-mx-4 flex items-center justify-between border-t-[1px] border-solid border-[#E8ECF4] px-4 pt-5 sm:-mx-8 sm:px-8"
    >
      <template v-if="entity === 'engagement' && !!scheduled">
        <UiButton
          v-if="formattedRescheduleText && canReschedule"
          class="text-body-secondary"
          variant="link"
          @click="
            router.push({
              name: 'SessionLearnerSchedule',
              params: { engagementId: props.trainingId },
            })
          "
        >
          {{ formattedRescheduleText }}
        </UiButton>
        <div v-else class="text-xs font-medium text-[#A0A4AD] sm:text-base">
          Reschedule Not Available
        </div>
      </template>
      <UiButton
        v-if="!!coach"
        variant="app-home"
        class="ml-auto"
        @click="router.push(ctaTo)"
      >
        {{ ctaText }}
      </UiButton>
      <UiButton
        v-else-if="!started"
        variant="app-home"
        class="ml-auto"
        @click="
          router.push({
            name: 'Assignment',
            params: {
              assignmentId: trainingId,
              skillId: skillId,
            },
          })
        "
      >
        Start Training
      </UiButton>
      <div
        v-else
        class="text-brand-primary border-brand-primary ml-auto rounded-lg border-[2px] border-solid py-[6px] px-[14px] font-medium"
      >
        Completes {{ matchDateText }}
      </div>
    </div>
  </div>
</template>

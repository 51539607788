import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import Skill from './skill';

export default class SkillFocus extends Model {
  static entity = 'skill-focus';

  @Num(null) declare id: number;
  @Str('') declare name: string;
  @BelongsTo(() => Skill, 'focus_id') declare skill: Skill;

  static api() {
    return {
      async list() {
        const { data } = await axios.get('/skill/focus');

        const repo = useRepo(SkillFocus);
        repo.save(data);
      },
    };
  }
}

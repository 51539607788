<script lang="ts" setup>
import { computed, reactive, ref } from "vue";

import { RowBase, TextInput, TextRow } from "@/components/public/forms";
import { Eye, Lash } from "@/components/icons";

type Nullable<T> = T | null;

interface IProps {
    disabled?: boolean
    id: string,
    required?: boolean,
    subtitle?: string | null,
    title: string | null,
    type?: 'text' | 'password'
}

const props = withDefaults(defineProps<IProps>(), {
    disabled: false,
    required: false,
    subtitle: null,
    type: 'text'
});

interface IEmits {
    (e: 'blur', value:Nullable<string>): void;
    (e: 'change', value:Nullable<string>): void;
    (e: 'submit'): void;
}

const emit = defineEmits<IEmits>();

const textInput = ref<typeof TextRow | null>(null)

const data = reactive({
    inputValue: null as Nullable<string>,
})

const state = reactive({
    blurred: false,
    showPassword: false
})

const error = computed(() => {
    if (state.blurred === false) {
        return null
    }

    if (props.required === true) {
        if (data.inputValue === null || data.inputValue === '') {
            return `${ props.title } is required`
        }
    }

    return null
})

const showEyeIcon = computed(() => {
    return textInputType.value === 'text'
})

const showLashIcon = computed(() => {
    return textInputType.value === 'password'
})

const showPasswordToggle = computed(() => {
    if (props.type === 'password')
        return true;

    return false;
})

const textInputType = computed(() => {
    if (props.type === 'password' && state.showPassword === false)
        return 'password'

    return 'text'
})

function handleBlur(inputValue: Nullable<string>) {
    data.inputValue = inputValue;
    emit('blur', data.inputValue);
}

function handleChange(inputValue: Nullable<string>) {
    data.inputValue = inputValue;
    emit('change', data.inputValue);
}

function handleSubmit() {
    emit('submit')
}

function handleToggleClick() {
    if (state.showPassword === false)
        state.showPassword = true;
    else
        state.showPassword = false;
}

function resetValidation() {
    state.blurred = false
}

function setValue(inputValue:Nullable<string>) {
    if (textInput.value !== null) {
        textInput.value.setValue(inputValue)
    }
}

function validate() {
    state.blurred = true
    return error.value
}

defineExpose({ resetValidation, setValue, validate });
</script>

<template>
    <RowBase :error=error :title=title :subtitle=subtitle>
        <div class="flex flex-row gap-[8px]">
            <TextInput :id=id ref="textInput" :error=error :title=title :type=textInputType :disabled=disabled @blur=handleBlur @change=handleChange @submit=handleSubmit />
            <div>
                <button v-if=showPasswordToggle class="password-toggle" @click=handleToggleClick>
                    <label hidden>Toggle password visibility</label>
                    <Lash v-show=showLashIcon />
                    <Eye v-show=showEyeIcon />
                </button>
            </div>
        </div>
    </RowBase>
</template>

<style scoped>
.password-toggle {
    @apply flex flex-col bg-white border-lightGrey border-[1px] w-[44px] h-[44px] rounded-[8px] justify-center items-center;
}

.password-toggle svg {
    @apply stroke-darkGrey h-[24px] w-[24px]
}

.password-toggle:focus-visible {
    @apply outline-golden outline-dashed outline-offset-[2px] outline-[1px];
}

.password-toggle:hover svg {
    @apply stroke-primary
}
</style>
<script lang="ts" setup>
  import { reactive, ref } from 'vue';
  import { isAxiosError } from 'axios';

  import { TrainingRequest } from '@orm-lib';
  import { UiAlert, UiButton, UiFormTextarea, UiModal } from '@ui-lib';

  const props = defineProps<{
    requestId: number;
    type: 'approve' | 'deny';
  }>();
  const emit = defineEmits<{
    (e: 'close'): void;
  }>();

  const form = reactive({
    comment: '',
  });
  const validationError = reactive({
    comment: '',
  });
  const error = ref();

  function handleClose() {
    emit('close');
  }

  async function declineRequest() {
    await TrainingRequest.api().reviewRequests(
      props.requestId,
      'Denied',
      form.comment
    );
    await TrainingRequest.api().adminList();
  }

  async function approveRequest() {
    await TrainingRequest.api().reviewRequests(
      props.requestId,
      'Approved',
      form.comment
    );
    await TrainingRequest.api().adminList();
  }

  function handleComment(event: InputEvent) {
    form.comment = event.target?.value;
    validationError.comment = '';
  }

  async function handleSubmit() {
    error.value = '';

    if (!form.comment) {
      return (validationError.comment = 'Comment required');
    }

    try {
      if (props.type === 'approve') {
        await approveRequest();
      } else {
        await declineRequest();
      }
      handleClose();
    } catch (e) {
      error.value =
        'There was an error submitting this response. Please try again later.';

      if (isAxiosError(e)) {
        const data = e?.response?.data;
        error.value = Object.values(data)?.[0]?.[0];
      }
    }
  }
</script>

<template>
  <UiModal
    class="[&>div>div>div]:rounded-[20px]"
    :close-on-click-outside="false"
    @close="emit('close')"
  >
    <form
      v-click-outside="handleClose"
      class="flex flex-col p-6"
      @submit.prevent="handleSubmit"
    >
      <h2 class="mb-2 text-lg font-semibold capitalize sm:text-2xl">
        {{ type }} Training Request
      </h2>
      <UiAlert v-if="!!error" class="mb-4 w-full" type="danger">
        {{ error }}
      </UiAlert>
      <span v-if="!validationError.comment" class="form-label mb-1">
        Comments
      </span>
      <span v-else class="form-label mb-1 uppercase !text-red-500">
        {{ validationError.comment }}
      </span>
      <UiFormTextarea
        :class="`mb-8 ${
          validationError.comment && ' [&>div>input]:!border-red-500'
        }`"
        :placeholder="
          type === 'approve'
            ? 'Let the requester know any additional information about this request.'
            : 'Let the request know why you are denying this request.'
        "
        type="text"
        @input="handleComment"
      />
      <div class="mb-4 flex justify-end">
        <UiButton
          variant="app-home"
          class="mr-4"
          color="white"
          type="button"
          @click="handleClose"
        >
          Cancel
        </UiButton>
        <UiButton variant="app-home" type="submit">Confirm</UiButton>
      </div>
    </form>
  </UiModal>
</template>

<style lang="css">
  .form-label {
    @apply text-header-secondary text-base font-medium;
  }
</style>

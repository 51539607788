<script lang="ts" setup>
  import { onMounted } from 'vue';
  import { useRouter } from 'vue-router';

  import { Action } from '@orm-lib';
  import { UiButton, UiForm } from '@ui-lib';
  import type { TBreadcrumb } from '@/types';

  interface IProps {
    title: 'Training Successfully Started!';
    tagline: "Your training has been successfully started. We'll get to work on pairing you with a trainer and you'll receive a notification once we are done.";
  }
  const props = defineProps<{
    anchor: TBreadcrumb;
    tagline: string;
    title: string;
  }>();

  const router = useRouter();

  // Once we reach this step, async refresh the actions list
  onMounted(() => {
    Action.api().list();
  });
</script>

<template>
  <UiForm :anchor="anchor" :title="title" :subtitle="tagline" variant="config">
    <UiButton
      class="mt-4 self-end"
      :to="anchor[1]"
      variant="app-home"
      @click="router.push(anchor[1])"
    >
      {{ anchor[0] }}
    </UiButton>
  </UiForm>
</template>

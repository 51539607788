<template>
  <svg
    width="25"
    height="20"
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.36548"
      y="6.21143"
      width="19.5"
      height="13.0385"
      rx="3.25"
      stroke="white"
      stroke-width="1.5"
    />
    <line x1="12" y1="3" x2="12" y2="6" stroke="white" stroke-width="2" />
    <circle
      cx="12"
      cy="2"
      r="1.25"
      fill="white"
      stroke="white"
      stroke-width="1.5"
    />
    <circle
      cx="6.86542"
      cy="11.5192"
      r="1.26923"
      fill="white"
      stroke="white"
      stroke-width="1.5"
    />
    <circle
      cx="17.3654"
      cy="11.5192"
      r="1.26923"
      fill="white"
      stroke="white"
      stroke-width="1.5"
    />
    <path
      d="M9.69238 14.3462C9.69238 14.3462 10.0962 15.5577 12.1155 15.5577C14.1347 15.5577 14.5385 14.3462 14.5385 14.3462"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.75 12.3076C0.75 11.7304 1.14128 11.2445 1.67308 11.1008V14.3606C1.14128 14.2169 0.75 13.731 0.75 13.1538V12.3076Z"
      stroke="white"
      stroke-width="1.5"
    />
    <path
      d="M22.5576 11.1008C23.0894 11.2445 23.4807 11.7304 23.4807 12.3076V13.1538C23.4807 13.731 23.0894 14.2169 22.5576 14.3606V11.1008Z"
      stroke="white"
      stroke-width="1.5"
    />
  </svg>
</template>

<script lang="ts" setup>
  import { ref, useSlots } from 'vue';

  import { UiForm } from '@ui-lib';
  import FormSuccess from '@/components/FormSuccess.vue';

  interface IProps {
    error?: string | null;
    hideSubmit?: boolean;
    successMessage?: string;
    subtitle?: string;
    title: string;
    variant?: 'empty' | 'primary';
  }
  const props = withDefaults(defineProps<IProps>(), {
    error: '',
    successMessage: '',
    subtitle: '',
    variant: 'primary',
  });
  const emit = defineEmits<{
    (e: 'submit', cb: () => void): void;
  }>();
  const slots = useSlots();

  defineExpose({
    successCallback,
  });

  const successRef = ref();

  function successCallback(message?: string) {
    successRef.value.show(message ?? props.successMessage);
  }
</script>

<template>
  <div class="form-panel">
    <UiForm
      :title="title"
      :subtitle="subtitle"
      :error="error"
      variant="config"
      @submit="emit('submit', successCallback)"
    >
      <template v-if="!!slots.subtitle" #subtitle>
        <slot name="subtitle"></slot>
      </template>
      <FormSuccess ref="successRef" />
      <div v-if="variant !== 'empty'" class="w-full">
        <div class="form-panel-list">
          <slot></slot>
        </div>
        <slot name="submit"></slot>
      </div>
    </UiForm>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { DateTime } from 'luxon';
  import { useRepo } from 'pinia-orm';

  import { type TrainingRequest, Skill } from '@orm-lib';
  import { IconDownChevron, UiButton } from '@ui-lib';

  const props = defineProps<{
    isAdminView: boolean;
    isHistoryView: boolean;
    groupName: string;
    requester: string | null;
    requestDate?: string;
    reviews?: typeof TrainingRequest.reviews;
    status: string;
    items: typeof TrainingRequest.items;
    isDesktop: boolean;
    isRequestLink?: boolean;
  }>();

  const isExpanded = ref(false);

  const emit = defineEmits(['decline', 'approve', 'cancel']);

  const sortedReviews = computed(() => {
    const reviews = props.reviews.slice();
    return reviews.sort(
      (a, b) => DateTime.fromISO(b.created) - DateTime.fromISO(a.created)
    );
  });

  const statusColor = computed(() => {
    switch (props.status) {
      case 'Approved':
        return ' bg-[#5CFFC41A] text-[#00C47D]';
      case 'Denied':
      default:
        return ' bg-[#FB4F571A] text-[#FB4F57]';
    }
  });

  function getRequestFriendlyName(item: typeof TrainingRequest.items) {
    if (item.subscription_tier === 'Focused') {
      const skillName =
        useRepo(Skill).find(item.skill_id)?.name ?? 'Let the Learner Decide';
      return `Focused: ${skillName}`;
    }

    return `${item.subscription_tier}`;
  }

  function toggleExpand() {
    isExpanded.value = !isExpanded.value;
  }

  function getOutcomeStyle(outcome: string) {
    if (outcome === 'Denied') {
      return '  bg-[#FB4F571A] text-[#FB4F57]';
    }

    if (outcome === 'Approved') {
      return ' text-[#00C47D] bg-[#00C47D]';
    }

    return ' bg-[#5E5CFF1A] text-[#5E5CFF]';
  }
</script>

<template>
  <div
    class="mb-4 flex w-full flex-col rounded-[10px] border-[1px] border-solid border-[#E8ECF4] bg-white p-4"
  >
    <button
      class="-my-4 -mx-4 flex cursor-pointer items-center gap-2 p-4"
      @click="toggleExpand"
    >
      <div class="flex flex-col">
        <h4
          class="text-header-primary text-left text-base font-bold sm:text-lg"
        >
          {{ groupName }}
        </h4>
        <span class="text-body-secondary text-left font-medium">
          {{ requestDate }}
        </span>
      </div>
      <div class="ml-auto flex items-center">
        <div
          v-if="isHistoryView"
          :class="`mr-4 flex items-center whitespace-nowrap rounded-lg bg-opacity-10 px-2 py-1 text-xs sm:px-3 sm:text-sm ${statusColor}`"
        >
          {{ status }}
        </div>
        <IconDownChevron
          :class="`ml-3 transition duration-100 ease-in-out ${
            isRequestLink ? '-rotate-90' : isExpanded && 'rotate-180'
          }`"
        />
      </div>
    </button>
    <template v-if="isExpanded">
      <div class="mt-4 border-t-[1px] border-solid border-[#E8ECF4] pt-4">
        <div class="mb-4">
          <div class="breakdown-panel">
            <h3 class="!border-b-0">Upgrades</h3>
            <div>
              <div class="-mx-6 -mb-4 w-[calc(100%+48px)]">
                <table class="table w-full rounded-t-[10px]">
                  <tr
                    class="table-header-group w-full border-y-[1px] border-solid border-[#E8ECF4] bg-[#EFEFFF] bg-opacity-50"
                  >
                    <th class="breakdown-table-header pl-4">Name</th>
                    <th class="breakdown-table-header pl-2 pr-4">Tier</th>
                  </tr>
                  <tr v-for="item in items" :key="item.id">
                    <td class="breakdown-table-cell pl-4 font-bold">
                      {{
                        `${item.group_learner.learner.user.first_name} ${item.group_learner.learner.user.last_name}`
                      }}
                    </td>
                    <td class="breakdown-table-cell pl-2 pr-4">
                      {{ getRequestFriendlyName(item) }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="breakdown-panel !mb-0">
          <h3>Activity</h3>
          <div v-for="review in sortedReviews" :key="review.id" class="pt-4">
            <div class="flex items-start justify-between">
              <div>
                <h4 class="text-header-primary font-semibold">
                  {{
                    `${review?.created_by_learner?.user?.first_name} ${review?.created_by_learner?.user?.last_name}`
                  }}
                </h4>
                <span class="text-header-primary">
                  {{
                    DateTime.fromISO(review.created).toFormat('MMMM d, yyyy')
                  }}
                </span>
              </div>
              <span
                :class="`rounded-lg bg-opacity-10 px-2 py-1 text-sm ${getOutcomeStyle(
                  review.outcome
                )}`"
              >
                {{ review.outcome }}
              </span>
            </div>
            <p class="text-body-secondary mb-4 italic last:mb-0">
              {{ review.comment }}
            </p>
          </div>
        </div>
        <div v-if="!isHistoryView" class="mt-8 flex items-center justify-end">
          <template v-if="isAdminView">
            <UiButton
              variant="app-home"
              color="white"
              class="mr-4 !border-red-500 [&>*]:!text-red-500"
              @click="emit('decline')"
            >
              Decline
            </UiButton>
            <UiButton variant="app-home" @click="emit('approve')">
              Approve Request
            </UiButton>
          </template>
          <template v-else>
            <UiButton
              variant="app-home"
              color="white"
              class="!border-red-500 [&>*]:!text-red-500"
              @click="emit('cancel')"
            >
              Cancel
            </UiButton>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
  .breakdown-panel {
    @apply mb-4 flex w-full flex-col rounded-[10px] border-[1px] border-solid bg-white px-6 py-4;
  }

  .breakdown-panel h3 {
    @apply text-brand-primary mt-1 border-b-[1px] border-solid pb-4 text-lg font-bold leading-[18px];
  }

  .breakdown-table-header {
    @apply text-brand-primary py-3 text-left text-sm font-semibold uppercase;
  }

  .breakdown-table-cell {
    @apply py-4;
  }
</style>

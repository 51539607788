<script lang="ts" setup>
  import AppTaskCell from '@/components/AppTaskCell.vue';
  import type { IBaseTask } from '@/types';

  interface IProps {
    title: string;
    tasks: IBaseTask[];
    emptyState?: {
      title: string;
      body: string;
    };
  }
  const props = defineProps<IProps>();

  function getTaskKey(task: IBaseTask) {
    if (task.key) {
      return task.key;
    }

    return `${task.title}-${task.body}-${task.status ?? 'none'}-${
      task.alert ?? 'none'
    }`;
  }
</script>

<template>
  <div v-if="tasks.length || !!emptyState" class="mb-2">
    <div
      class="text-header-primary mb-2 text-sm font-bold uppercase opacity-70"
    >
      {{ title }}
    </div>
    <div v-if="!!tasks.length" class="flex flex-wrap items-start">
      <AppTaskCell
        v-for="task in tasks"
        :key="getTaskKey(task)"
        :alert="task.alert"
        :body="task.body"
        :status="task.status"
        :title="task.title"
        :to="task.to"
      />
    </div>
    <!-- No Trainings -->
    <div v-else-if="!!emptyState" class="page-cell">
      <div class="page-navigation task-navigation relative">
        <div class="text-header-primary mb-2 font-bold opacity-70">
          {{ emptyState.title }}
        </div>
        <div class="text-header-primary mb-2 text-sm opacity-50">
          {{ emptyState.body }}
        </div>
      </div>
    </div>
  </div>
</template>

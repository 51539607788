<script lang="ts" setup>
  import { ref } from 'vue';
  import Popper from 'vue3-popper';

  import UserRoleTag from '@/components/UserRoleTag.vue';

  interface IUser {
    name?: string;
    email: string;
    roles?: { role: string; type: 'admin' | 'group' }[];
    sent: string;
    actions: any[];
    currentStarted: string;
    currentSubscription?: string;
    currentSubscriptionId?: number;
    upcomingSubscription?: string;
    upcomingCancellableId?: number;
    availableDowngrades?: string[];
    expiration?: string;
    starts?: string;
    pending?: string;
    isAdmin?: boolean;
    id?: number;
    inviteId?: number;
    adminId?: number;
  }

  const props = defineProps<{
    headings: string[];
    users: IUser[];
    headerless?: boolean;
    hideActions?: boolean;
    inline?: boolean;
    isMobile: boolean;
    isLarge?: boolean;
    isExtraLarge?: boolean;
    userId?: number;
    variant: 'admin' | 'group';
    useAdminActions?: boolean;
  }>();
  const emit = defineEmits<{
    (e: 'resend-invite', id: number | undefined): void;
    (e: 'remove-invite', id: number | undefined): void;
    (e: 'cancel-training', id: number | undefined): void;
    (
      e: 'downgrade-subscription',
      id: number | undefined,
      tier: 'Lite' | 'Basic'
    ): void;
    (e: 'remove-admin', id: number | undefined): void;
    (e: 'promote-admin', id: number | undefined): void;
    (e: 'delete-account', id: number | undefined): void;
  }>();

  const isPopperOpen = ref(false);
  const popperRef = ref();

  function handleResendInvite(close: any, user: IUser) {
    close();
    emit('resend-invite', user.inviteId);
  }

  function handleCancel(close: any, user: IUser) {
    close();
    emit('cancel-training', user.upcomingCancellableId);
  }

  function handleDowngrade(
    close: any,
    user: IUser,
    downgrade: 'Lite' | 'Basic'
  ) {
    close();
    emit('downgrade-subscription', user.currentSubscriptionId, downgrade);
  }

  function handleRemoveAdmin(close: any, user: IUser) {
    close();
    emit('remove-admin', user.adminId);
  }

  function handlePromoteAdmin(close: any, user: IUser) {
    close();
    emit('promote-admin', user.id);
  }

  function handleDeleteAccount(close: any, user: IUser) {
    close();
    emit('delete-account', user.id);
  }
</script>

<template>
  <div
    :class="`w-full overflow-hidden bg-white ${
      variant === 'group' &&
      ' rounded-[10px] border-[1px] border-solid border-[#E8ECF4]'
    }`"
  >
    <component
      :is="headerless ? 'div' : 'table'"
      :class="`flex w-full flex-col  ${
        !headerless ? ' sm:table sm:h-[1px]' : ' flex flex-col'
      }`"
    >
      <tr
        v-if="!headerless"
        :class="`hidden w-full  sm:table-header-group ${
          inline ? ' border-t-[1px] border-solid border-[#E8ECF4]' : ''
        }`"
      >
        <th
          v-for="heading in headings"
          :key="heading ?? 'no-text'"
          :class="`text-brand-primary whitespace-nowrap py-3 px-3 text-left text-sm font-semibold uppercase leading-[18px] first:pl-5 last:pr-5 ${
            variant === 'admin' && 'bg-[#EFEFFF] bg-opacity-50'
          }`"
        >
          {{ heading }}
        </th>
      </tr>
      <component
        :is="headerless ? 'div' : 'tr'"
        v-for="user in users"
        :key="user.name || user.email"
        :class="`border-t-solid flex w-full items-center border-t-[1px] border-[#E8ECF4]  sm:h-full ${
          !headerless ? ' px-4 py-3 sm:table-row-group' : ' justify-between '
        }`"
      >
        <component
          :is="headerless ? 'div' : 'td'"
          :class="`shrink px-0 sm:h-full ${
            headerless ? ' max-w-[calc(100%-40px)] py-3' : ' sm:pl-5 sm:pr-3'
          } ${variant === 'admin' ? 'sm:py-3' : 'sm:py-3'}`"
          :style="variant === 'group' ? `vertical-align: top` : ''"
        >
          <div
            class="flex shrink flex-col flex-wrap items-start justify-center"
          >
            <div
              :class="`text-header-primary max-w-full shrink overflow-hidden text-ellipsis whitespace-nowrap text-sm font-semibold leading-[18px] sm:leading-[21px] ${
                variant === 'admin' && !user.name
                  ? ' text-header-primary font-semibold'
                  : ' md:text-base '
              } ${variant === 'group' && isMobile ? ' mb-[1px]' : 'mb-0'}`"
            >
              {{ user.name ?? user.email }}
            </div>
            <div
              v-if="user.name && user.email"
              :class="`text-body-secondary mt-[1px] max-w-full overflow-hidden text-ellipsis whitespace-nowrap  text-sm leading-[18px] `"
            >
              {{ user.email }}
            </div>
            <template v-if="!user.name && user.roles?.length">
              <div v-for="role in user.roles" :key="role.role" class="mt-2">
                <UserRoleTag
                  v-if="role.role"
                  class="max-w-[unset]"
                  :color="role.type === 'admin' ? 'yellow' : 'blue'"
                  :icon="role.type"
                >
                  {{ role.role }}
                </UserRoleTag>
              </div>
            </template>
            <span
              v-if="isMobile && variant === 'group'"
              class="text-body-secondary mt-1 overflow-hidden text-ellipsis text-sm leading-[18px] md:text-base"
            >
              {{ user.currentSubscription }}
            </span>
            <span
              v-if="isMobile && variant === 'group'"
              :class="`mt-1 flex h-[29px] items-center justify-center whitespace-nowrap rounded-lg bg-[#5E5CFF1A] px-2 py-1 text-center text-xs text-[#5E5CFF] sm:px-3 sm:py-[7px] md:text-sm`"
            >
              Started {{ user.currentStarted }}
            </span>
            <div
              v-if="isMobile && user.upcomingSubscription"
              class="relative mt-2.5 -mb-2 pl-7"
            >
              <svg
                class="absolute left-1.5 -top-1"
                width="16"
                height="22"
                viewBox="0 0 22 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.75 1C1.75 0.585786 1.41421 0.25 1 0.25C0.585786 0.25 0.25 0.585786 0.25 1H1.75ZM21.5303 21.5303C21.8232 21.2374 21.8232 20.7626 21.5303 20.4697L16.7574 15.6967C16.4645 15.4038 15.9896 15.4038 15.6967 15.6967C15.4038 15.9896 15.4038 16.4645 15.6967 16.7574L19.9393 21L15.6967 25.2426C15.4038 25.5355 15.4038 26.0104 15.6967 26.3033C15.9896 26.5962 16.4645 26.5962 16.7574 26.3033L21.5303 21.5303ZM0.25 1V15H1.75V1H0.25ZM7 21.75H21V20.25H7V21.75ZM0.25 15C0.25 18.7279 3.27208 21.75 7 21.75V20.25C4.10051 20.25 1.75 17.8995 1.75 15H0.25Z"
                  fill="#5C6582"
                />
              </svg>
              <span
                class="text-body-secondary mt-1 overflow-hidden text-ellipsis text-sm leading-[18px]"
              >
                {{ user.upcomingSubscription }}
              </span>
              <div class="mt-0.5 flex gap-2">
                <span
                  v-if="user.expiration"
                  :class="`flex h-[29px] items-center justify-center whitespace-nowrap rounded-lg bg-[#FB4F571A] px-2 py-1 text-center text-xs text-[#FB4F57] sm:px-3 sm:py-[7px] md:text-sm`"
                >
                  Expires {{ user.expiration }}
                </span>
                <span
                  v-if="user.starts"
                  class="flex h-[29px] items-center justify-center whitespace-nowrap rounded-lg bg-[#5E5CFF1A] px-2 py-1 text-center text-xs text-[#5E5CFF] sm:px-3 sm:py-[7px] md:text-sm"
                >
                  Starts {{ user.starts }}
                </span>
                <span
                  v-if="user.pending"
                  class="flex h-[29px] items-center justify-center whitespace-nowrap rounded-lg bg-[#5E5CFF1A] px-2 py-1 text-center text-xs text-[#5E5CFF] sm:px-3 sm:py-[7px] md:text-sm"
                >
                  Requested {{ user.pending }}
                </span>
              </div>
            </div>
            <div
              v-if="isMobile && user.name"
              class="mt-2 flex justify-start gap-2 self-start"
            >
              <template v-for="role in user.roles" :key="role.role">
                <UserRoleTag
                  v-if="role.role"
                  :color="role.type === 'admin' ? 'yellow' : 'blue'"
                  :icon="role.type"
                >
                  {{ role.role }}
                </UserRoleTag>
              </template>
            </div>
          </div>
        </component>
        <template v-if="variant === 'admin'">
          <td
            :class="`ml-auto flex items-center justify-center py-3 sm:ml-0 sm:table-cell ${
              headerless ? '' : ' sm:pl-3 sm:pr-5'
            }`"
          >
            <div class="flex min-w-0 items-center gap-4">
              <div
                v-if="!useAdminActions && user.name"
                class="hidden min-w-0 flex-wrap items-center gap-4 sm:flex"
              >
                <template v-for="role in user.roles" :key="role.role">
                  <UserRoleTag
                    v-if="role.role"
                    :color="role.type === 'admin' ? 'yellow' : 'blue'"
                    :icon="role.type"
                  >
                    {{ role.role }}
                  </UserRoleTag>
                </template>
              </div>
              <div class="ml-auto flex items-center">
                <Popper
                  offset-distance="4"
                  placement="bottom-end"
                  @open:popper="isPopperOpen = true"
                  @close:popper="isPopperOpen = false"
                >
                  <button
                    ref="editButton"
                    :disabled="user.id === userId"
                    :class="`navigation-button z-10 flex h-[30px] w-[30px] items-center justify-self-end rounded-lg hover:bg-[#E6EBF1] hover:outline-none hover:outline focus:bg-[#E6EBF1] ${
                      user.id === userId
                        ? ' cursor-not-allowed bg-gray-100'
                        : ''
                    }`"
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="29"
                        height="29"
                        rx="5.5"
                        stroke="#E8ECF4"
                      />
                      <circle cx="15" cy="9" r="2" fill="#5C6582" />
                      <circle cx="15" cy="15" r="2" fill="#5C6582" />
                      <circle cx="15" cy="21" r="2" fill="#5C6582" />
                    </svg>
                  </button>
                  <template #content="{ close }">
                    <div ref="popperRef" class="learner-menu rounded-lg">
                      <button
                        v-if="!user.name"
                        class="text-brand-primary flex w-full items-center rounded-t-lg border-b-[1px] border-solid border-[#E8ECF4] bg-white px-4 py-2 text-sm capitalize hover:bg-gray-50"
                        @click="handleResendInvite(close, user)"
                      >
                        <svg
                          class="mr-[8px] -ml-[2px] -mt-[2px]"
                          width="14"
                          height="10"
                          viewBox="0 0 14 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 9V1H13V9H1Z"
                            stroke="#5E5CFF"
                            stroke-width="1.5"
                          />
                          <path
                            d="M1 1.5L7 5L13 1.5"
                            stroke="#5E5CFF"
                            stroke-width="1.5"
                          />
                        </svg>
                        Resend Invite
                      </button>
                      <button
                        v-if="useAdminActions"
                        :class="`text-brand-primary flex w-full items-center border-b-[1px] border-solid border-[#E8ECF4] bg-white px-4 py-2 text-sm capitalize hover:bg-gray-50 ${
                          user.name ? ' rounded-lg' : ' rounded-b-lg'
                        }`"
                        @click="handleRemoveAdmin(close, user)"
                      >
                        <svg
                          class="mr-[10px]"
                          width="10"
                          height="2"
                          viewBox="0 0 10 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 1L10 1"
                            stroke="#5E5CFF"
                            stroke-width="1.6"
                          />
                        </svg>
                        Remove from Admins
                      </button>
                      <button
                        v-if="
                          !!user.name && userId !== user.id && !useAdminActions
                        "
                        :class="`flex w-full items-center bg-white px-4 py-2 text-sm capitalize text-red-500 hover:bg-gray-50 ${
                          user.name ? ' rounded-lg' : ' rounded-b-lg'
                        }`"
                        @click="handleDeleteAccount(close, user)"
                      >
                        <svg
                          class="mr-2"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.19922 2.69999H10.7992M4.79922 8.69999V5.09999M7.19922 8.69999V5.09999M8.39922 11.1H3.59922C2.93648 11.1 2.39922 10.5627 2.39922 9.89999V3.29999C2.39922 2.96862 2.66785 2.69999 2.99922 2.69999H8.99922C9.33059 2.69999 9.59922 2.96862 9.59922 3.29999V9.89999C9.59922 10.5627 9.06196 11.1 8.39922 11.1ZM4.79922 2.69999H7.19922C7.53059 2.69999 7.79922 2.43136 7.79922 2.09999V1.49999C7.79922 1.16862 7.53059 0.899994 7.19922 0.899994H4.79922C4.46785 0.899994 4.19922 1.16862 4.19922 1.49999V2.09999C4.19922 2.43136 4.46785 2.69999 4.79922 2.69999Z"
                            stroke="#FB4F57"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Delete Account
                      </button>
                      <button
                        v-else-if="user.id !== userId && !user.name"
                        :class="`flex w-full items-center bg-white px-4 py-2 text-sm capitalize text-red-500 hover:bg-gray-50 ${
                          user.name ? ' rounded-lg' : ' rounded-b-lg'
                        }`"
                        @click="handleDeleteAccount(close, user)"
                      >
                        <svg
                          class="mr-[11px]"
                          width="9"
                          height="10"
                          viewBox="0 0 9 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.964844 1.46447L8.03591 8.53554"
                            stroke="#FB4F57"
                            stroke-width="1.6"
                          />
                          <path
                            d="M0.964844 8.53554L8.03591 1.46447"
                            stroke="#FB4F57"
                            stroke-width="1.6"
                          />
                        </svg>
                        Remove Invite
                      </button>
                    </div>
                  </template>
                </Popper>
              </div>
            </div>
          </td>
        </template>
        <template v-else>
          <template v-if="!isMobile">
            <td
              class="text-header-primary overflow-hidden px-3 py-2 text-sm sm:py-3 md:text-base"
              style="vertical-align: top"
            >
              <div class="flex flex-col items-start">
                <span class="text-header-primary mb-1 text-sm md:text-base">
                  {{ user.currentSubscription }}
                </span>
                <span
                  v-if="user.currentStarted"
                  class="flex h-[29px] items-center justify-center whitespace-nowrap rounded-lg bg-[#5E5CFF1A] px-2 py-1 text-center text-xs text-[#5E5CFF] sm:px-3 sm:py-[7px] md:text-sm"
                >
                  Started {{ user.currentStarted }}
                </span>
              </div>
            </td>
          </template>
          <template v-if="!isMobile">
            <td
              class="flex flex-col items-start overflow-hidden px-3 py-2 sm:table-cell sm:py-3"
              style="vertical-align: top"
            >
              <div class="flex flex-col items-start">
                <span class="text-header-primary text-sm md:text-base">
                  {{ user.upcomingSubscription }}
                </span>
                <span
                  v-if="user.expiration"
                  class="mt-1 flex h-[29px] items-center justify-center whitespace-nowrap rounded-lg bg-[#FB4F571A] px-2 py-1 text-center text-xs text-[#FB4F57] sm:px-3 sm:py-[7px] md:text-sm"
                >
                  Expires {{ user.expiration }}
                </span>
                <span
                  v-if="user.starts"
                  class="mt-1 flex h-[29px] items-center justify-center whitespace-nowrap rounded-lg bg-[#5E5CFF1A] px-2 py-1 text-center text-xs text-[#5E5CFF] sm:px-3 sm:py-[7px] md:text-sm"
                >
                  Starts {{ user.starts }}
                </span>
                <span
                  v-if="user.pending"
                  class="mt-1 flex h-[29px] items-center justify-center whitespace-nowrap rounded-lg bg-[#5E5CFF1A] px-2 py-1 text-center text-xs text-[#5E5CFF] sm:px-3 sm:py-[7px] md:text-sm"
                >
                  Requested {{ user.pending }}
                </span>
              </div>
            </td>
          </template>
          <component
            :is="headerless ? 'div' : 'td'"
            class="ml-auto flex items-center justify-center py-3 sm:ml-6 sm:table-cell sm:pl-3 sm:pr-5"
          >
            <div class="flex items-center justify-around">
              <div
                v-show="!hideActions"
                class="ml-auto flex items-center lg:ml-4"
              >
                <Popper
                  offset-distance="4"
                  placement="bottom-end"
                  @open:popper="isPopperOpen = true"
                  @close:popper="isPopperOpen = false"
                >
                  <button
                    ref="editButton"
                    :disabled="
                      !user.upcomingCancellableId &&
                      !user.availableDowngrades?.length
                    "
                    :class="`navigation-button z-10 flex h-[30px] w-[30px] items-center justify-self-end rounded-lg hover:bg-[#E6EBF1] hover:outline-none hover:outline focus:bg-[#E6EBF1] ${
                      !user.upcomingCancellableId &&
                      !user.availableDowngrades?.length
                        ? ' cursor-not-allowed bg-gray-100'
                        : ''
                    }`"
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="29"
                        height="29"
                        rx="5.5"
                        stroke="#E8ECF4"
                      />
                      <circle cx="15" cy="9" r="2" fill="#5C6582" />
                      <circle cx="15" cy="15" r="2" fill="#5C6582" />
                      <circle cx="15" cy="21" r="2" fill="#5C6582" />
                    </svg>
                  </button>
                  <template #content="{ close }">
                    <div ref="popperRef" class="learner-menu rounded-lg">
                      <button
                        v-if="!user.name"
                        class="text-brand-primary flex w-full items-center rounded-t-lg border-b-[1px] border-solid border-[#E8ECF4] bg-white px-4 py-2 text-sm capitalize hover:bg-gray-50"
                        @click="handleResendInvite(close, user)"
                      >
                        <svg
                          class="mr-[6px] -ml-[2px] -mt-[2px]"
                          width="14"
                          height="10"
                          viewBox="0 0 14 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 9V1H13V9H1Z"
                            stroke="#5E5CFF"
                            stroke-width="1.5"
                          />
                          <path
                            d="M1 1.5L7 5L13 1.5"
                            stroke="#5E5CFF"
                            stroke-width="1.5"
                          />
                        </svg>
                        Resend Invite
                      </button>
                      <button
                        v-if="!!user.name && user.upcomingCancellableId"
                        :class="`flex w-full items-center bg-white px-4 py-2 text-sm capitalize text-red-500 hover:bg-gray-50 ${
                          user.name ? ' rounded-lg' : ' rounded-b-lg'
                        }`"
                        @click="handleCancel(close, user)"
                      >
                        <svg
                          class="mr-2 mt-[-1px]"
                          width="11"
                          height="10"
                          viewBox="0 0 11 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 9.24219L9.48528 0.756905"
                            stroke="#FB4F57"
                            stroke-width="1.6"
                          />
                          <path
                            d="M9.48438 9.24219L0.999092 0.756907"
                            stroke="#FB4F57"
                            stroke-width="1.6"
                          />
                        </svg>
                        Cancel Focused Training
                      </button>
                      <template
                        v-if="!!user.name && user.availableDowngrades?.length"
                      >
                        <button
                          v-for="downgrade in user.availableDowngrades"
                          :key="downgrade"
                          :class="`flex w-full items-center bg-white px-4 py-2 text-sm capitalize text-red-500 hover:bg-gray-50 ${
                            user.name ? ' rounded-lg' : ' rounded-b-lg'
                          }`"
                          @click="handleDowngrade(close, user, downgrade)"
                        >
                          <svg
                            class="mr-2 mt-[-1px]"
                            width="11"
                            height="10"
                            viewBox="0 0 11 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 9.24219L9.48528 0.756905"
                              stroke="#FB4F57"
                              stroke-width="1.6"
                            />
                            <path
                              d="M9.48438 9.24219L0.999092 0.756907"
                              stroke="#FB4F57"
                              stroke-width="1.6"
                            />
                          </svg>
                          Downgrade Subscription to {{ downgrade }}
                        </button>
                      </template>
                    </div>
                  </template>
                </Popper>
              </div>
            </div>
          </component>
        </template>
      </component>
    </component>
  </div>
</template>

<style scoped>
  .learner-menu {
    box-shadow: 0 4px 15px 5px rgba(0, 0, 0, 0.1);
  }
</style>

<script lang="ts" setup>
import {computed, onBeforeMount, reactive, ref} from "vue";
import { useRouter } from 'vue-router';

import type { AxiosError } from "axios";
import { useRepo } from "pinia-orm";

import { Invite, User } from "@orm-lib";

import { useAppStore } from '@/stores';
import type { AcceptInviteFormPayload } from '@/components/public/types';
import { AcceptInviteFormPayloadCreate } from '@/components/public/types';
import { FormBase, TextRow } from './'


type Nullable<T> = T | null;

interface IProps {
    inviteUuid: string;
}

const props = defineProps<IProps>();

interface IEmits {
    (e: 'submit', value:AcceptInviteFormPayload): void;
}

const emit = defineEmits<IEmits>();

const router = useRouter();

const errorMessage = ref<string | null>(null)

const data = reactive(AcceptInviteFormPayloadCreate())

const store = useAppStore();

const confirmationInput = ref<typeof TextRow | null>(null)
const emailInput = ref<typeof TextRow | null>(null)
const firstNameInput = ref<typeof TextRow | null>(null)
const lastNameInput = ref<typeof TextRow | null>(null)
const passwordInput = ref<typeof TextRow | null>(null)
const inputs = [
    confirmationInput,
    firstNameInput,
    lastNameInput,
    passwordInput
]

const invite = computed(() => {
    return useRepo(Invite)
        .withAllRecursive(2)
        .where('uuid', props.inviteUuid)
        .first();
});

function handleConfirmationInputChange(inputValue: Nullable<string>) {
    if (inputValue !== null)
        data.confirmation = inputValue
}

function handleFirstNameInputChange(inputValue: Nullable<string>) {
    if (inputValue !== null)
        data.first_name = inputValue
}

function handleLastNameInputChange(inputValue: Nullable<string>) {
    if (inputValue !== null)
        data.last_name = inputValue
}

function handlePasswordInputChange(inputValue: Nullable<string>) {
    if (inputValue !== null)
        data.password = inputValue
}

async function handleSubmit() {
    let errorCount = 0;

    inputs.forEach((input) => {
        let error = input.value?.validate()
        if (error !== null) {
            errorCount += 1;
        }
    });

    if (errorCount === 0) {
        if (data.password !== data.confirmation) {
            errorCount = 1
            errorMessage.value = 'Your new password and password confirmation do not match.'
        }
    }

    if (errorCount !== 0)
        return

    try {
        await Invite.api().join(
            props.inviteUuid,
            data.first_name,
            data.last_name,
            data.password
        );

        await store.login({
            username: invite.value?.email ?? '',
            password: data.password,
        });

        errorMessage.value = null
    } catch(e) {
        errorCount = 1
        const axiosError = e as AxiosError
        const errors = axiosError.response?.data as object
        errorMessage.value = Object.values(errors)[0][0]
    }

    if (errorCount === 0) {
         emit('submit', data)
    }
}

onBeforeMount(async () => {
    await Invite.api().detail(props.inviteUuid)
    if (invite.value !== null) {
        emailInput.value?.setValue(invite.value.email)
    }
})
</script>

<template>
    <FormBase
        :error-message=errorMessage
        mode=create-account
        title="Create Account"
        subtitle="Create your account to start using Backstage!"
        @submit=handleSubmit
    >
        <TextRow
            id="create-account-email"
            ref="emailInput"
            :required="false"
            title="Email"
            type="text"
            :disabled=true
            :initial-value=invite?.email
        />
        <TextRow
            id="create-account-first-name"
            ref="firstNameInput"
            :required="true"
            title="First Name"
            type="text"
            @change=handleFirstNameInputChange
            @submit=handleSubmit
        />
        <TextRow
            id="create-account-last-name"
            ref="lastNameInput"
            :required="true"
            title="Last Name"
            type="text"
            @change=handleLastNameInputChange
            @submit=handleSubmit
        />
        <TextRow
            id="create-account-password"
            ref="passwordInput"
            :required="true"
            title="New Password"
            subtitle="Your new password must be at least 8 characters long and cannot contain common words including your first name, last name and email address."
            type="password"
            @change=handlePasswordInputChange
            @submit=handleSubmit
        />
        <TextRow
            id="create-account-confirmation"
            ref="confirmationInput"
            :required="true"
            title="Password Confirmation"
            type="password"
            @change=handleConfirmationInputChange
            @submit=handleSubmit
        />
    </FormBase>
</template>
<script lang="ts" setup>
  import { reactive } from 'vue';
  import { email, helpers, required } from '@vuelidate/validators';

  import {
    UiForm,
    UiFormGroup,
    UiFormInput,
    UiFormNavigation,
    useCookie,
    useValidatedForm,
  } from '@ui-lib';
  import type { IOnboardingSignupRequest } from '@/types';

  interface IProps {
    serviceError: string;
    form: IOnboardingSignupRequest;
  }
  interface IEmits {
    (e: 'register', request: IOnboardingSignupRequest): void;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<IEmits>();
  const { cookie } = useCookie(import.meta.env.VITE_REFERRAL_KEY);

  const localForm = reactive({ ...props.form, referral_code: cookie });

  const validations = {
    email: {
      email: helpers.withMessage('Invalid email address', email),
      required: helpers.withMessage('Email is required', required),
    },
    referral_code: {},
    employer_name: {
      required: helpers.withMessage('Employer name is required', required),
    },
  };
  const { isSubmitting, validate, v$ } = useValidatedForm(
    localForm,
    validations,
    submit
  );

  async function submit() {
    await emit('register', localForm);
  }
</script>

<template>
  <UiForm
    :error="serviceError"
    title="Company Details"
    variant="config"
    @submit="validate"
  >
    <template #header>
      <slot />
    </template>
    <UiFormGroup class="!mb-8">
      <UiFormInput
        v-model="localForm.employer_name"
        class="mb-2 w-full"
        label="Company Name"
        placeholder="Your company's name"
        type="text"
        :errors="v$.employer_name.$errors"
      />
      <UiFormInput
        v-model="localForm.email"
        class="w-full"
        label="Email"
        type="text"
        placeholder="Your email"
        :errors="v$.email.$errors"
      />
    </UiFormGroup>
    <UiFormNavigation
      class="mb-0"
      :loading="isSubmitting"
      submit-text="Submit"
    />
  </UiForm>
</template>

<script lang="ts" setup>
import {computed, reactive, ref} from "vue";

import type { AssessmentQuestion } from "@orm-lib";

import type { AssessmentQuestionPayload } from '@/components/public/types';
import { AssessmentQuestionPayloadCreate } from '@/components/public/types';
import { FormBase, ChoiceRow } from './'


defineOptions({ version: 1 })

interface IProps {
    currentStep?: number,
    stepCount?: number,
    question: AssessmentQuestion
}

const props = withDefaults(defineProps<IProps>(), {
    currentStep: 1,
    stepCount: 1,
});

interface IEmits {
    (e: 'back'): void;
    (e: 'submit', value:AssessmentQuestionPayload): void;
}

const emit = defineEmits<IEmits>();

const errorMessage = ref<string | null>(null)

const data = reactive(AssessmentQuestionPayloadCreate(props.question.id))

const inputType = computed(() => {
    if (props.question.type === 'choice.multiple')
        return 'checkbox'
    return 'radio'
})

function handleBack() {
    emit('back')
}

function  handleChange(inputValues:string[]) {
    data.answer_ids = [] as number[]

    inputValues.forEach((inputValue) => {
        const integerValue = Number(inputValue)
        if (Number.isInteger(integerValue)) {
            data.answer_ids.push(Number(inputValue))
        }
    })
}

async function handleSubmit() {
    if (data.answer_ids.length === 0) {
        errorMessage.value = 'Answer this question to continue.'
    } else {
        errorMessage.value = null;
        emit('submit', data as AssessmentQuestionPayload)
    }
}
</script>

<template>
    <FormBase
        @back=handleBack
        @submit=handleSubmit
        :current-step=currentStep
        :error-message=errorMessage
        mode=sign-up
        title="Your First Goal"
        :step-count=stepCount
        :subtitle="question.title"
    >
        <div class="flex flex-col gap-[4px] md:gap-[8px]">
            <ChoiceRow
                v-for="answer in question.answers"
                v-bind:key=answer.id
                :name="`question-${question.id}`"
                :title=answer.title
                :subtitle=answer.subtitle
                :type=inputType
                :value="answer.id.toString()"
                @change=handleChange
            />
        </div>
    </FormBase>
</template>
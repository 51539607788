<script lang="ts" setup>
  import { ref, watch } from 'vue';

  interface IProps {
    disabled?: boolean;
    id: string | number;
    checked?: boolean;
    label?: string;
    modelValue?: boolean;
    value?: number | string;
  }
  const props = withDefaults(defineProps<IProps>(), {
    disabled: false,
  });

  const localValue = ref(props.modelValue);
  const emit = defineEmits<{
    (e: 'input', value: boolean): void;
  }>();

  watch(
    () => props.modelValue,
    (newVal) => {
      localValue.value = newVal;
    }
  );
</script>

<template>
  <div :class="`radio flex items-start ${disabled ? 'bg-gray-100' : ''}`">
    <input
      :id="id"
      :value="value"
      :checked="localValue || checked"
      :disabled="disabled"
      type="radio"
      class="mt-1 h-4 w-4 self-start rounded-full border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500"
      @update:model-value="(value) => emit('input', value)"
      @input="(value) => emit('input', value)"
    />
    <label for="id" class="text-body-primary ml-2 text-sm">
      <template v-if="!!label">
        {{ label }}
      </template>
      <slot v-else></slot>
    </label>
  </div>
</template>

<style scoped></style>

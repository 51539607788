import axios from 'axios';
import { DateTime } from 'luxon';
import { Model, useRepo } from 'pinia-orm';
import { Attr, Num, Str } from 'pinia-orm/dist/decorators';

import Group from './group';
import type TrainingRequestItem from './training_request_item';
import type TrainingRequestReview from './training_request_review';

export default class TrainingRequest extends Model {
  static entity = 'training-request';

  @Num(null) declare id: number;
  @Num(null) declare group_id: number;
  @Str(null) declare created: string;
  @Attr(null) declare items: TrainingRequestItem[];
  @Attr(null) declare reviews: TrainingRequestReview[];
  @Str(null) declare status: string;

  static api() {
    return {
      async list(groupId: number) {
        const { data } = await axios.get(`/training-request/group/${groupId}/`);

        const repo = useRepo(TrainingRequest);
        repo.flush();
        repo.save(data);
      },
      async adminList() {
        const { data } = await axios.get(`/training-request/employer-admin/`);

        const repo = useRepo(TrainingRequest);
        repo.flush();
        repo.save(data);
      },
      async submitRequest(request: any) {
        const { data } = await axios.post(`/training-request/group/`, request);

        return data;
      },
      async learnerRequest(request: any) {
        const { data } = await axios.post(
          `/training-request/learner/`,
          request
        );

        return data;
      },
      async reviewRequests(
        requestId: number,
        outcome: 'Approved' | 'Denied',
        comment: string | null,
        type = 'EmployerAdmin'
      ) {
        await axios.post('/training-request-review/', {
          training_request_id: requestId,
          comment: comment,
          outcome: outcome,
          type: type,
        });
      },
      async cancelRequest(requestId: number) {
        await axios.delete(`/training-request/${requestId}`);
      },
    };
  }

  get groupName() {
    const foundGroup = useRepo(Group).find(this.group_id);

    return foundGroup?.name ?? 'Unknown Group';
  }

  get requesterName() {
    const learnerData = this.reviews?.[0]?.created_by_learner?.user;
    return `${learnerData?.first_name} ${learnerData?.last_name}`;
  }

  get requestDate() {
    return DateTime.fromISO(this.created).toFormat('MMMM d, yyyy');
  }
}

<script lang="ts" setup>
  import { computed, reactive, ref } from 'vue';

  import { Skill } from '@orm-lib';
  import { UiForm, UiFormNavigation } from '@ui-lib';
  import FormEvaluation from '@/components/FormEvaluation.vue';
  import PanelListHeading from '@/components/PanelListHeading.vue';

  interface IProps {
    confidenceQuestion?: string;
    error?: string;
    maxSteps: number;
    previousConfidenceRating?: number;
    selectedSkill?: string;
    skill: Skill;
    skillConfidenceRating?: number;
    flow?: 'training' | 'collaborator';
    title?: string;
  }
  const props = withDefaults(defineProps<IProps>(), {
    flow: 'training',
    title: 'Collaborator Feedback',
  });
  const emit = defineEmits<{
    (e: 'next', form: any): void;
    (e: 'prev'): void;
  }>();

  const form = reactive({
    rating: props.skillConfidenceRating as number | null,
  });
  const errorMessage = ref('');

  const submitText = computed(() => {
    return props.flow === 'collaborator' ? 'Finish' : 'Next Step';
  });

  const collaboratorInto = computed(() => {
    if (props.previousConfidenceRating) {
      return 'This learner has completed skill training. Please help us understand their growth from your own perspective.';
    }

    return 'This learner is about to begin a new training. Please help us understand their current ability from your own perspective.';
  });

  function selectRating(rating: number) {
    form.rating = rating;
  }

  function next() {
    if (!form.rating) {
      const pronoun = props.flow === 'collaborator' ? "the learner's" : 'your';

      errorMessage.value = `Please rate ${pronoun} skill level at ${
        props.skill?.name ?? props.selectedSkill
      } before proceeding.`;

      return;
    }

    const updatedForm = {
      skill_confidence_rating: form.rating,
    };

    emit('next', updatedForm);
  }
</script>

<template>
  <UiForm
    :submit-text="submitText"
    variant="config"
    :title="title"
    :error="error || errorMessage"
    @submit="next"
    @prev="emit('prev')"
  >
    <template #header>
      <slot />
    </template>
    <p v-if="flow === 'collaborator'" class="collaborator-intro mb-4">
      {{ collaboratorInto }}
    </p>
    <PanelListHeading
      v-if="!previousConfidenceRating && flow === 'collaborator'"
      heading="Feedback"
      class="self-start"
    />
    <FormEvaluation
      v-model="form.rating"
      :question="confidenceQuestion"
      @select="selectRating"
    />
    <template v-if="previousConfidenceRating">
      <PanelListHeading class="self-start" heading="YOUR PREVIOUS RATING" />
      <FormEvaluation
        variant="history"
        :model-value="previousConfidenceRating"
      />
    </template>
    <UiFormNavigation
      class="mb-0 mt-6"
      show-previous
      :prev-text="previousConfidenceRating ? 'Cancel' : 'Previous'"
      :submit-text="submitText"
      @prev="emit('prev')"
    >
      <span class="text-body-secondary">
        Step {{ previousConfidenceRating ? '1' : '2' }}/{{ maxSteps }}
      </span>
    </UiFormNavigation>
  </UiForm>
</template>

<style scoped>
  .collaborator-intro {
    text-align: left;
    font-size: 14px;
    color: #808080;
  }
</style>

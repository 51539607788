import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Attr, Num, Str, Uid } from 'pinia-orm/dist/decorators';

import Coach from './coach';
import type Learner from './learner';

export default class Invite extends Model {
  static entity = 'invite';

  @Num(null) declare id: number;
  @Attr(null) declare coach: Coach;
  @Num(null) declare coach_id: number;
  @Str('') declare created: string;
  @Str('') declare email: string;
  @Attr(null) declare learner: Learner;
  @Num(null) declare learner_id: number;
  @Uid() declare uuid: number;

  static api() {
    return {
      create(
        email: string,
        isAdmin: boolean,
        assignSkill: boolean,
        skillId: number | null
      ) {
        if (!assignSkill) {
          skillId = null;
        }

        const data = {
          email: email,
          is_admin: isAdmin,
          assign_skill: assignSkill,
          skill_id: skillId,
        };
        return axios.post(`/invite`, data);
      },
      async join(
        inviteUuid: string,
        firstName: string,
        lastName: string,
        password: string,
        specialties = null
      ) {
        const data = {
          first_name: firstName,
          last_name: lastName,
          password: password,
          specialties: specialties,
        };

        return axios.put(`/invite/${inviteUuid}`, data);
      },
      async detail(id: string) {
        const { data } = await axios.get(`/invite/${id}`);

        useRepo(Invite).save(data);
      },
      async remove(id: number) {
        await axios.delete(`/invite/${id}`);
        const invite = useRepo(Invite).find(id);
        useRepo(Coach).destroy(invite?.coach_id ?? '');
        await useRepo(Invite).destroy(id);
      },
    };
  }

  static async handleModelMessage(message: {
    payload: Invite;
    method: 'create' | 'update' | 'delete';
  }) {
    if (message.method === 'create') {
      await useRepo(Invite).save(message.payload);
    } else if (message.method === 'update') {
      await useRepo(Invite).save(message.payload);
    } else if (message.method === 'delete') {
      useRepo(Invite).destroy(message.payload.id);
    }
  }
}

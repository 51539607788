<script lang="ts" setup>
  import {
    computed,
    onBeforeUnmount,
    onMounted,
    ref,
    toRefs,
    useAttrs,
    watch,
  } from 'vue';
  import { uuid } from 'vue3-uuid';
  import DatePicker from 'flowbite-datepicker/Datepicker';
  import { DateTime } from 'luxon';

  import { useMedia, useValidationErrorMessage } from '@ui-lib';

  interface VuelidateError {
    $message: string;
  }
  const props = defineProps<{
    errors?: VuelidateError[] | null;
    label?: string;
    modelValue: string | null;
    minDate?: Date;
    hidePlaceholder?: boolean;
  }>();
  const datePickerId = 'datePicker' + uuid.v4();

  const emit = defineEmits<{
    (e: 'focus'): void;
    (e: 'input', date: string): void;
  }>();
  const attrs = useAttrs();
  const datePickerRef = ref();
  const datePickerEl = ref();
  const sm = useMedia('sm');

  const localValue = ref(formatDateTime(props.modelValue, true));
  const { label, errors } = toRefs(props);
  const { errorClass, errorMessage, inputLabel } = useValidationErrorMessage(
    label,
    errors
  );

  const datePickerClass = computed(() => {
    const baseClass = 'relative w-full max-w-sm';

    if (errorMessage.value) {
      return baseClass + errorClass.value;
    }

    return baseClass;
  });

  function formatDateTime(dateTime: string | null, toHuman = false) {
    if (!dateTime) {
      return '';
    }

    if (toHuman) {
      return DateTime.fromFormat(dateTime, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
    }

    return DateTime.fromFormat(dateTime, 'MM/dd/yyyy').toFormat('yyyy-MM-dd');
  }

  onMounted(() => {
    const dateRangePickerEl = document.getElementById(datePickerId);
    datePickerEl.value = new DatePicker(dateRangePickerEl, {
      autohide: true,
      clearBtn: true,
      minDate: props.minDate,
    });

    const clearBtn =
      datePickerEl.value.picker.element.querySelector('.clear-btn');
    clearBtn.innerHTML = 'Cancel';

    dateRangePickerEl?.addEventListener('changeDate', (event) => {
      if (!event.target?.value) {
        datePickerEl.value.setDate(localValue.value);
        return;
      }

      const value = formatDateTime(event.target?.value);
      emit('input', value);
    });
  });

  onBeforeUnmount(() => {
    const datepicker = document.body.querySelector('.datepicker-dropdown');
    datepicker?.remove();
  });

  function handleClick() {
    datePickerEl.value.show();
  }

  function handleFocus() {
    emit('focus');
  }

  watch(
    () => props.modelValue,
    () => {
      localValue.value = formatDateTime(props.modelValue, true);
    }
  );
</script>

<template>
  <div :class="datePickerClass">
    <label
      :for="datePickerId"
      class="text-body-primary mb-1 block self-start text-sm font-medium"
    >
      {{ inputLabel }}
    </label>
    <div class="relative w-full">
      <div
        class="pointer-events-none absolute inset-y-0 left-0 flex w-full items-center pl-3"
      >
        <svg
          aria-hidden="true"
          class="h-5 w-5 text-gray-500 dark:text-gray-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
      <input
        :id="datePickerId"
        ref="datePickerRef"
        datepicker
        readonly
        v-bind="attrs"
        :value="localValue"
        type="text"
        class="block !w-full cursor-text rounded-lg border border-[#e8ecf4] bg-white p-2.5 pl-10 text-base !text-transparent placeholder:text-sm focus:rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        :placeholder="`${hidePlaceholder ? '' : 'Select date'}`"
        @focus="handleFocus"
      />
    </div>
    <span
      v-if="localValue"
      class="absolute top-[17px] left-[41px] text-sm text-gray-900"
      @click="handleClick"
    >
      {{ localValue }}
    </span>
  </div>
</template>

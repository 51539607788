import { Model, useRepo } from 'pinia-orm';
import { Attr, Num, Str } from 'pinia-orm/dist/decorators';

import Skill from './skill';

export interface IFeedbackItemExample {
  created: string;
  end_seconds: number | null;
  id: number;
  meeting_feedback_item_id: number;
  quote: string;
  order: number;
  summary: string;
  start_seconds: number | null;
  title: string;
  type: string;
}

export default class MeetingRecordingFeedbackItem extends Model {
  static entity = 'meeting-recording-feedback-item';

  @Num(null) declare id: number;
  @Str(null) declare created: string;
  @Num(null) declare meeting_feedback_id: number;
  @Attr(null) declare examples: IFeedbackItemExample[];
  @Num(null) declare skill_id: number;
  @Str(null) declare summary: string;
  @Str(null) declare title: string;

  get skill_name() {
    return useRepo(Skill).withAllRecursive().find(this.skill_id)?.name;
  }
}

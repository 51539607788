import type { RouteLocation } from 'vue-router';

export default [
  {
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "app-home" */ '@/views/AppHome.vue'),
    meta: { title: 'Backstage App: Home', tags: [] },
    path: '/',
  },
{
    name: 'DesktopDeprecated',
    component: () =>
      import(/* webpackChunkName: "app-home" */ '@/views/public/DesktopDeprecated.vue'),
    meta: { title: 'Backstage App: This app is no longer supported', tags: [] },
    path: '/message/desktop-deprecated',
  },
  {
    name: 'GroupManagement',
    component: () =>
      import(
        /* webpackChunkName: "group-management" */ '@/views/GroupManagement.vue'
      ),
    meta: { title: 'Backstage App: Group Management', tags: [] },
    path: '/group/:groupId(\\d+)',
    props: ({ params }: { params: { groupId: number } }) => ({
      groupId: Number.parseInt(params.groupId),
    }),
  },
  {
    name: 'GroupGoalCreate',
    component: () =>
      import(
        /* webpackChunkName: "group-goal-create" */ '@/views/GroupGoalCreate.vue'
      ),
    meta: { title: 'Backstage App: Group Management', tags: [] },
    path: '/group/:groupId(\\d+)/goal',
    props: ({ params }: { params: { groupId: number } }) => ({
      groupId: Number.parseInt(params.groupId),
    }),
  },
  {
    name: 'GroupLearnerGoalManage',
    component: () =>
      import(
        /* webpackChunkName: "group-learner-goal-manage" */ '@/views/GroupLearnerGoalManage.vue'
      ),
    meta: { title: 'Backstage App: Group Management', tags: [] },
    path: '/group-learner/:groupLearnerId(\\d+)/goal',
    props: ({ params }: { params: { groupLearnerId: number } }) => ({
      groupLearnerId: Number.parseInt(params.groupLearnerId),
    }),
  },

  {
    name: 'GroupGoalProgress',
    component: () =>
      import(
        /* webpackChunkName: "group-goal-progress" */ '@/views/GroupGoalProgress.vue'
      ),
    meta: { title: 'Backstage App: Group Management', tags: [] },
    path: '/group/:groupId(\\d+)/goal/:groupGoalId(\\d+)/progress',
    props: ({
      params,
    }: {
      params: { groupGoalId: number; groupId: number };
    }) => ({
      groupGoalId: Number.parseInt(params.groupGoalId),
      groupId: Number.parseInt(params.groupId),
    }),
  },
  {
    name: 'RequestTraining',
    component: () =>
      import(
        /* webpackChunkName: "request-training" */ '@/views/RequestTraining.vue'
      ),
    meta: { title: 'Backstage App: Request Training', tags: [] },
    path: '/group/:groupId(\\d+)/training/request',
    props: ({ params }: { params: { groupId: number } }) => ({
      groupId: Number.parseInt(params.groupId),
    }),
  },
  {
    name: 'AppDownload',
    component: () =>
      import(/* webpackChunkName: "app-download" */ '@/views/AppDownload.vue'),
    meta: { title: 'Backstage App: Download App', tags: [] },
    path: '/app/download',
  },
  {
    name: 'CoachList',
    component: () =>
      import(/* webpackChunkName: "coach-list" */ '@/views/CoachList.vue'),
    meta: { title: 'Backstage App: Users', tags: [] },
    path: '/trainer',
  },
  {
    path: '/contact',
    name: 'ContactUs',
    component: () =>
      import(/* webpackChunkName: "contact-us" */ '@/views/ContactUs.vue'),
    meta: { title: 'Backstage App: Contact Us', tags: [] },
    props: ({ params }: { params: { cohortId: string } }) => ({
      cohortId: Number.parseInt(params.cohortId) || 0,
    }),
  },
  {
    path: '/administration',
    name: 'Administration',
    component: () =>
      import(
        /* webpackChunkName: "administration" */ '@/views/Administration.vue'
      ),
    meta: { title: 'Backstage App: Administration', tags: [] },
  },
  {
    path: '/reporting',
    name: 'Reporting',
    component: () =>
      import(/* webpackChunkName: "reporting" */ '@/views/Reporting.vue'),
    meta: { title: 'Backstage App: Reporting', tags: [] },
  },
  {
    path: '/reporting/focused-training',
    name: 'ReportingFocusedTraining',
    component: () =>
      import(
        /* webpackChunkName: "reporting-focused-training" */ '@/views/ReportingFocusedTraining.vue'
      ),
    meta: { title: 'Backstage App: Reporting: Focused Training', tags: [] },
  },
  {
    path: '/reporting/skill-improvement',
    name: 'ReportingSkillImprovement',
    component: () =>
      import(
        /* webpackChunkName: "recording-skill-improvement" */ '@/views/ReportingSkillImprovement.vue'
      ),
    meta: { title: 'Backstage App: Reporting: Skill Improvement', tags: [] },
  },
  {
    path: '/reporting/goal-progress',
    name: 'ReportingGoalProgress',
    component: () =>
      import(
        /* webpackChunkName: "recording-goal-progress" */ '@/views/ReportingGoalProgress.vue'
      ),
    meta: { title: 'Backstage App: Reporting: Goal Progress', tags: [] },
  },
  {
    path: `/administration/:groupId(\\d+)/request/:requestId(\\d+)/review`,
    name: 'AdminRequestReview',
    component: () =>
      import(
        /* webpackChunkName: "admin-request-review" */ '@/views/AdminRequestReview.vue'
      ),
    meta: { title: 'Backstage App: Administration Requests', tags: [] },
    props: ({
      params,
    }: {
      params: { requestId: string; groupId: string };
    }) => ({
      requestId: Number.parseInt(params.requestId) || 0,
      groupId: Number.parseInt(params.groupId) || 0,
    }),
  },
  {
    path: `/group/:groupId(\\d+)/request/:requestId(\\d+)/review`,
    name: 'GroupRequestReview',
    component: () =>
      import(
        /* webpackChunkName: "group-request-review" */ '@/views/GroupRequestReview.vue'
      ),
    meta: { title: 'Backstage App: Administration Requests', tags: [] },
    props: ({
      params,
    }: {
      params: { requestId: string; groupId: string };
    }) => ({
      requestId: Number.parseInt(params.requestId) || 0,
      groupId: Number.parseInt(params.groupId) || 0,
    }),
  },
  {
    path: '/billing',
    name: 'Billing',
    component: () =>
      import(/* webpackChunkName: "billing" */ '@/views/Billing.vue'),
    meta: { title: 'Backstage App: Billing', tags: [] },
  },
  {
    path: '/administration/:groupId(\\d+)',
    name: 'AdministrationGroup',
    component: () =>
      import(
        /* webpackChunkName: "administration-group" */ '@/views/Administration.vue'
      ),
    meta: { title: 'Backstage App: Administration', tags: [] },
    props: ({ params }: { params: { groupId: string } }) => ({
      groupId: Number.parseInt(params.groupId) || 0,
    }),
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () =>
      import(/* webpackChunkName: "feedback" */ '@/views/Feedback.vue'),
    meta: { title: 'Backstage App: Feedback Requests', tags: [] },
  },
  {
    path: '/feedback/:feedbackId(\\d+)/detail',
    name: 'FeedbackDetail',
    component: () =>
      import(
        /* webpackChunkName: "feedback-detail" */ '@/views/FeedbackDetail.vue'
      ),
    meta: { title: 'Backstage App: Feedback Detail', tags: [] },
    props: ({ params }: { params: { feedbackId: string } }) => ({
      feedbackId: Number.parseInt(params.feedbackId) || 0,
    }),
  },
  {
    name: 'DeepLink',
    component: () =>
      import(/* webpackChunkName: "deep-link" */ '@/views/DeepLink.vue'),
    meta: { title: 'Backstage App: Link', tags: [] },
    path: '/deeplink',
  },
  {
    name: 'SignUp',
    component: () =>
      import(
        /* webpackChunkName: "employer-onboarding" */ '@/views/public/SignUp.vue'
      ),
    meta: { title: 'Sign Up for Backstage', tags: [] },
    path: '/sign-up',
  },
  {
    name: 'Engagement',
    component: () =>
      import(/* webpackChunkName: "engagement" */ '@/views/Engagement.vue'),
    meta: {
      title: 'Backstage App: Engagement',
      tags: [],
    },
    path: '/engagement/:engagementId(\\d+)',
    props: ({ params }: { params: { engagementId: string } }) => ({
      engagementId: Number.parseInt(params.engagementId) || 0,
    }),
  },
  {
    name: 'Assignment',
    component: () =>
      import(/* webpackChunkName: "assignment" */ '@/views/Assignment.vue'),
    meta: {
      title: 'Backstage App: Assignment',
      tags: [],
    },
    path: '/assignment/:assignmentId(\\d+)/:skillId(\\d+)?',
    props: ({ params }) => ({
      assignmentId: Number.parseInt(params.assignmentId) || null,
      skillId: Number.parseInt(params.skillId) || null,
    }),
  },
  {
    name: 'EngagementComplete',
    component: () =>
      import(
        /* webpackChunkName: "engagement-complete" */ '@/views/EngagementComplete.vue'
      ),
    meta: {
      title: 'Backstage App: Complete Engagement',
      tags: [],
    },
    path: '/engagement/:engagementId(\\d+)/complete',
    props: ({ params }: { params: { engagementId: string } }) => ({
      engagementId: Number.parseInt(params.engagementId) || 0,
    }),
  },
  {
    name: 'RecordingList',
    component: () =>
      import(
        /* webpackChunkName: "recording-list" */ '@/views/RecordingList.vue'
      ),
    meta: {
      title: 'Backstage App: Recordings',
      tags: [],
    },
    path: '/engagement/:engagementId(\\d+)/recording',
    props: ({ params }: { params: { engagementId: string } }) => ({
      engagementId: Number.parseInt(params.engagementId) || 0,
    }),
  },
  {
    name: 'LearnerRecording',
    component: () =>
      import(
        /* webpackChunkName: "learner-recording" */ '@/views/LearnerRecording.vue'
      ),
    meta: {
      title: 'Backstage App: Recording',
      tags: [],
    },
    path: '/engagement/:engagementId(\\d+)/recording/:recordingId(\\d+)',
    props: ({
      params,
    }: {
      params: { engagementId: string; recordingId: string };
    }) => ({
      engagementId: Number.parseInt(params.engagementId) || 0,
      recordingId: Number.parseInt(params.recordingId) || 0,
    }),
  },
  {
    name: 'BotRecording',
    component: () =>
      import(
        /* webpackChunkName: "bot-recording" */ '@/views/BotRecording.vue'
      ),
    meta: {
      title: 'Backstage App: Recording',
      tags: [],
    },
    path: '/meeting/:meetingRecordingId(\\d+)/recording/:recordingId(\\d+)',
    props: ({
      params,
    }: {
      params: { meetingRecordingId: string; recordingId: string };
    }) => ({
      meetingRecordingId: Number.parseInt(params.meetingRecordingId) || 0,
      recordingId: Number.parseInt(params.recordingId) || 0,
    }),
  },
  {
    name: 'CoachRecording',
    component: () =>
      import(
        /* webpackChunkName: "coach-recording" */ '@/views/CoachRecording.vue'
      ),
    meta: {
      title: 'Backstage App: Recording',
      tags: [],
    },
    path: '/engagement/:engagementId(\\d+)/coach-recording/:recordingId(\\d+)/coach',
    props: ({
      params,
    }: {
      params: { engagementId: string; recordingId: string };
    }) => ({
      engagementId: Number.parseInt(params.engagementId) || 0,
      recordingId: Number.parseInt(params.recordingId) || 0,
    }),
  },
  {
    name: 'Session',
    component: () =>
      import(/* webpackChunkName: "session" */ '@/views/Session.vue'),
    meta: {
      title: 'Backstage App: Session',
      tags: [],
    },
    path: '/engagement/:engagementId(\\d+)/session',
    props: ({ params }: { params: { engagementId: string } }) => ({
      engagementId: Number.parseInt(params.engagementId) || 0,
    }),
  },
  {
    name: 'SessionLearnerSchedule',
    component: () =>
      import(
        /* webpackChunkName: "session-learner-schedule" */ '@/views/SessionLearnerSchedule.vue'
      ),
    meta: {
      title: 'Backstage App: Schedule Session',
      tags: [],
    },
    path: '/engagement/:engagementId(\\d+)/session/schedule/learner',
    props: ({ params }: { params: { engagementId: string } }) => ({
      engagementId: Number.parseInt(params.engagementId) || 0,
    }),
  },
  {
    name: 'SessionSchedule',
    component: () =>
      import(
        /* webpackChunkName: "session-schedule" */ '@/views/SessionSchedule.vue'
      ),
    meta: {
      title: 'Backstage App: Schedule Session',
      tags: [],
    },
    path: '/engagement/:engagementId(\\d+)/session/schedule',
    props: ({ params }: { params: { engagementId: string } }) => ({
      engagementId: Number.parseInt(params.engagementId) || 0,
    }),
  },
  {
    name: 'UploadList',
    component: () =>
      import(/* webpackChunkName: "upload-list" */ '@/views/UploadList.vue'),
    meta: {
      title: 'Backstage App: Uploads',
      tags: [],
    },
    path: '/engagement/:engagementId(\\d+)/upload',
    props: ({ params }: { params: { engagementId: string } }) => ({
      engagementId: Number.parseInt(params.engagementId) || 0,
    }),
  },
  {
    name: 'UploadAdd',
    component: () =>
      import(/* webpackChunkName: "upload-add" */ '@/views/UploadAdd.vue'),
    meta: {
      title: 'Backstage App: Add Upload',
      tags: [],
    },
    path: '/engagement/upload/add',
  },

  {
    name: 'InviteAccept',
    component: () =>
      import(
        /* webpackChunkName: "invite-accept" */ '@/views/public/AcceptInvite.vue'
      ),
    meta: { title: 'Backstage App: Accept Invite', tags: [] },
    path: '/invite/:inviteUuid([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})',
    props: true,
  },
  {
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "app-login" */ '@/views/public/LogIn.vue'),
    meta: { title: 'Log In to Backstage', tags: [] },
    path: '/login',
  },
  {
    name: 'LoginForgot',
    component: () =>
      import(/* webpackChunkName: "login-forgot" */ '@/views/public/ForgotPassword.vue'),
    meta: { title: 'Reset your Backstage Password', tags: [] },
    path: '/login/forgot',
  },
  {
    name: 'LoginReset',
    component: () =>
      import(/* webpackChunkName: "login-reset" */ '@/views/public/ResetPassword.vue'),
    meta: { title: 'Backstage App: Reset Password', tags: [] },
    path: '/login/reset/:userId(\\d+)/:token(\\S+)',
    props: ({ params }: { params: { token: string; userId: string } }) => ({
      token: params.token,
      userId: Number.parseInt(params.userId) || 0,
    }),
  },
  {
    name: 'ProfileManage',
    component: () =>
      import(
        /* webpackChunkName: "profile-manage" */ '@/views/ProfileManage.vue'
      ),
    meta: { title: 'Backstage App: Profile', tags: [] },
    path: '/profile',
  },
  {
    name: 'RequestList',
    component: () =>
      import(/* webpackChunkName: "request-list" */ '@/views/RequestList.vue'),
    meta: { title: 'Backstage App: Requests', tags: [] },
    path: '/request',
  },
  {
    path: '/request/:requestId(\\d+)',
    name: 'Request',
    component: () =>
      import(/* webpackChunkName: "request" */ '@/views/Request.vue'),
    meta: { title: 'Backstage App: Request', tags: [] },
    props: ({ params }: { params: { requestId: string } }) => ({
      requestId: Number.parseInt(params.requestId) || 0,
    }),
  },
  {
    name: 'ReconnectSession',
    component: () =>
      import(
        /* webpackChunkName: "reconnect-session" */ '@/views/ReconnectSession.vue'
      ),
    meta: { title: 'Backstage App: Reconnect Session', tags: [] },
    path: '/session/reconnect',
  },
  {
    name: 'TrainingList',
    component: () =>
      import(
        /* webpackChunkName: "training-list" */ '@/views/TrainingList.vue'
      ),
    meta: { title: 'Backstage App: Trainings', tags: [] },
    path: '/training',
    props: (route: RouteLocation) => ({ initial: route.query.initial }),
  },
  {
    name: 'ScheduleMeeting',
    component: () =>
      import(
        /* webpackChunkName: "schedule-meeting" */ '@/views/ScheduleMeeting.vue'
      ),
    meta: { title: 'Backstage App: Schedule Meeting', tags: [] },
    path: '/schedule',
  },
  {
    path: '/subscription/:subscriptionId(\\S+)',
    name: 'Subscription',
    component: () =>
      import(/* webpackChunkName: "subscription" */ '@/views/Subscription.vue'),
    meta: { title: 'Backstage App: Subscription', tags: [] },
    props: ({ params }: { params: { subscriptionId: string } }) => ({
      subscriptionId: params.subscriptionId,
    }),
  },
  {
    path: '/invite/:inviteId(\\S+)/decline',
    name: 'InviteDecline',
    component: () =>
      import(
        /* webpackChunkName: "invite-decline" */ '@/views/public/DeclineInvite.vue'
      ),
    meta: { title: 'Backstage App: Subscription', tags: [] },
    props: ({ params }: { params: { inviteId: string } }) => ({
      inviteId: params.inviteId,
    }),
  },
  { path: '/:catchAll(.*)', redirect: '/' },
];

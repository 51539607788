<script lang="ts" setup>
  import { useRoute } from 'vue-router';

  import { useAppStore } from '@/stores';

  const route = useRoute();
  const appStore = useAppStore();

  async function loadApp() {
    await appStore.loadModel();

    await appStore.ensureUserSocket();

    if (appStore.isDesktop) {
      window.electron.ipcRenderer.on(
        'update-downloaded',
        async (event: string, message: string) => {
          appStore.hasUpdate = true;
        }
      );

      window.electron.ipcRenderer.on(
        'deep-link-opened',
        async (event: string, message: string) => {
          await appStore.loadDeepLink(message);
        }
      );
    }
  }

  loadApp();
</script>

<template>
  <RouterView :key="route.fullPath" />
</template>

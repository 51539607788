<script lang="ts" setup>
  import {
    computed,
    onBeforeUnmount,
    onMounted,
    ref,
    toRefs,
    useAttrs,
    watch,
  } from 'vue';
  import { uuid } from 'vue3-uuid';
  import DateRangePicker from 'flowbite-datepicker/DateRangePicker';
  import { DateTime } from 'luxon';

  import { useMedia, useValidationErrorMessage } from '@ui-lib';

  interface VuelidateError {
    $message: string;
  }
  const props = defineProps<{
    errors?: VuelidateError[] | null;
    label?: string;
    isSmall?: boolean;
    modelStartValue?: string | null;
    modelEndValue?: string | null;
    startLabel?: string;
    endLabel?: string;
  }>();
  const datePickerId = 'dateRangePicker' + uuid.v4();
  const dateStartPickerId = 'dateRangeStartPicker' + uuid.v4();
  const dateEndPickerId = 'dateRangeEndPicker' + uuid.v4();

  const emit = defineEmits<{
    (e: 'focus'): void;
    (e: 'input-start', date: string): void;
    (e: 'input-end', date: string): void;
  }>();
  const attrs = useAttrs();
  const datePickerEl = ref();
  const sm = useMedia('sm');

  const localStartValue = ref(formatDateTime(props.modelStartValue, true));
  const localEndValue = ref(formatDateTime(props.modelEndValue, true));

  const { label, errors } = toRefs(props);
  const { errorClass, errorMessage, inputLabel } = useValidationErrorMessage(
    label,
    errors
  );

  const datePickerClass = computed(() => {
    const baseClass = 'relative w-full  justify-between';

    if (errorMessage.value) {
      return baseClass + errorClass.value;
    }

    return baseClass;
  });

  function formatDateTime(dateTime: string | null, toHuman = false) {
    if (!dateTime) {
      return '';
    }

    if (toHuman) {
      return DateTime.fromFormat(dateTime, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
    }

    return DateTime.fromFormat(dateTime, 'MM/dd/yyyy').toFormat('yyyy-MM-dd');
  }

  onMounted(() => {
    const dateRangePickerEl = document.getElementById(datePickerId);
    const dateRangeStartPickerEl = document.getElementById(dateStartPickerId);
    const dateRangeEndPickerEl = document.getElementById(dateEndPickerId);

    datePickerEl.value = new DateRangePicker(dateRangePickerEl, {
      autohide: true,
      clearBtn: true,
      allowOneSidedRange: true,
      startView: 8,
    });
    const clearBtn = datePickerEl.value.picker?.element?.querySelector(
      '.clear-btn',
      datePickerEl.value
    );
    if (clearBtn) clearBtn.innerHTML = 'Cancel';

    dateRangeStartPickerEl?.addEventListener('changeDate', (event) => {
      const value = formatDateTime(event.target?.value);
      emit('input-start', value);
    });

    dateRangeEndPickerEl?.addEventListener('changeDate', (event) => {
      const value = formatDateTime(event.target?.value);
      emit('input-end', value);
    });
  });

  onBeforeUnmount(() => {
    const datepicker = document.body.querySelector('.datepicker-dropdown');
    datepicker?.remove();
  });

  function handleClick() {
    datePickerEl.value.show();
  }

  function handleFocus() {
    emit('focus');
  }

  watch(
    () => props.modelStartValue,
    () => {
      localStartValue.value = formatDateTime(props.modelStartValue, true);
    }
  );

  watch(
    () => props.modelEndValue,
    () => {
      localEndValue.value = formatDateTime(props.modelEndValue, true);
    }
  );
</script>

<template>
  <div :id="datePickerId" :class="datePickerClass" daterange-picker>
    <label
      v-if="inputLabel"
      :for="datePickerId"
      class="text-body-secondary mb-1 block self-start text-sm font-medium"
    >
      {{ inputLabel }}
    </label>
    <div class="flex flex-wrap gap-2 sm:flex-nowrap sm:gap-4">
      <div class="relative w-full basis-full">
        <label class="text-body-secondary mb-1 block font-medium">
          {{ startLabel ?? 'Start Date' }}
        </label>
        <div class="relative w-full basis-full">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex w-full items-center pl-3"
          >
            <svg
              class="ml-1.5 -mt-[1px]"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="1.5"
                width="13"
                height="13"
                rx="1.5"
                fill="white"
                stroke="#5E5CFF"
              />
              <line
                x1="4.5"
                y1="1.63917e-08"
                x2="4.5"
                y2="2"
                stroke="#5E5CFF"
              />
              <line
                x1="9.5"
                y1="1.63917e-08"
                x2="9.5"
                y2="2"
                stroke="#5E5CFF"
              />
              <rect x="3" y="7" width="2" height="2" fill="#5E5CFF" />
              <rect x="6" y="7" width="2" height="2" fill="#5E5CFF" />
              <rect x="9" y="7" width="2" height="2" fill="#5E5CFF" />
              <rect x="3" y="10" width="2" height="2" fill="#5E5CFF" />
              <rect x="6" y="10" width="2" height="2" fill="#5E5CFF" />
              <rect x="9" y="10" width="2" height="2" fill="#5E5CFF" />
              <rect x="1" y="2" width="12" height="3" fill="#5E5CFF" />
              <line x1="4.5" y1="1" x2="4.5" y2="3" stroke="white" />
              <line x1="9.5" y1="1" x2="9.5" y2="3" stroke="white" />
            </svg>
          </div>
          <input
            :id="dateStartPickerId"
            name="start"
            readonly
            :value="localStartValue"
            type="text"
            class="range-picker text-body-secondary block !w-full cursor-pointer cursor-text rounded-lg border border-[#e8ecf4] bg-white p-2.5 pl-11 text-sm placeholder:text-sm focus:rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            placeholder="Select a date"
            @focus="handleFocus"
          />
        </div>
      </div>
      <div class="relative w-full basis-full">
        <label class="text-body-secondary mb-1 block font-medium">
          {{ endLabel ?? 'End Date' }}
        </label>
        <div class="relative w-full basis-full">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex w-full items-center pl-3"
          >
            <svg
              class="ml-1.5 -mt-[1px]"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="1.5"
                width="13"
                height="13"
                rx="1.5"
                fill="white"
                stroke="#5E5CFF"
              />
              <line
                x1="4.5"
                y1="1.63917e-08"
                x2="4.5"
                y2="2"
                stroke="#5E5CFF"
              />
              <line
                x1="9.5"
                y1="1.63917e-08"
                x2="9.5"
                y2="2"
                stroke="#5E5CFF"
              />
              <rect x="3" y="7" width="2" height="2" fill="#5E5CFF" />
              <rect x="6" y="7" width="2" height="2" fill="#5E5CFF" />
              <rect x="9" y="7" width="2" height="2" fill="#5E5CFF" />
              <rect x="3" y="10" width="2" height="2" fill="#5E5CFF" />
              <rect x="6" y="10" width="2" height="2" fill="#5E5CFF" />
              <rect x="9" y="10" width="2" height="2" fill="#5E5CFF" />
              <rect x="1" y="2" width="12" height="3" fill="#5E5CFF" />
              <line x1="4.5" y1="1" x2="4.5" y2="3" stroke="white" />
              <line x1="9.5" y1="1" x2="9.5" y2="3" stroke="white" />
            </svg>
          </div>
          <input
            :id="dateEndPickerId"
            name="start"
            readonly
            :value="localEndValue"
            type="text"
            class="range-picker text-body-secondary block !w-full cursor-pointer cursor-text rounded-lg border border-[#e8ecf4] bg-white p-2.5 pl-11 text-sm placeholder:text-sm focus:rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            placeholder="Select a date"
            @focus="handleFocus"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useSlots } from 'vue';

  import { UiButton, UiRouterLink } from '@ui-lib';
  import AppTag from '@/components/AppTag.vue';
  import AppTitleGroup from '@/components/AppTitleGroup.vue';
  import type { TBreadcrumb } from '@/types';

  interface IProps {
    action?: string;
    alert?: string;
    isLink?: boolean;
    showDelete?: boolean;
    subtitle?: string;
    tag?: string;
    title: string;
    to?: TBreadcrumb;
    value?: number | string;
    variant?: 'main' | 'assignment';
  }

  const props = withDefaults(defineProps<IProps>(), {
    variant: 'main',
  });

  const emit = defineEmits<{
    (e: 'delete', id?: number | string): void;
    (e: 'action-click'): void;
  }>();

  const slots = useSlots();
</script>

<template>
  <component
    :is="isLink ? UiRouterLink : 'div'"
    :to="isLink && to[1]"
    :class="`form-panel-list-item z-[2] flex flex-row items-center no-underline hover:!no-underline ${variant} ${
      !title && 'condensed'
    }`"
  >
    <slot name="modal"></slot>
    <AppTitleGroup :title="title" :subtitle="subtitle" class="z-[2] min-w-0">
      <template v-if="slots.subtitle" #subtitle>
        <slot name="subtitle"></slot>
      </template>
    </AppTitleGroup>
    <div class="list-item-right z-[2] ml-auto flex items-center">
      <AppTag
        v-if="alert || tag"
        :variant="alert ? 'alert' : 'info'"
        class="list-item-tag z-[2] opacity-70"
      >
        {{ alert || tag }}
      </AppTag>
      <UiRouterLink
        v-if="action"
        class="cta-link text-brand-primary mr-3 whitespace-nowrap"
        @click="emit('action-click')"
      >
        {{ action }}
      </UiRouterLink>
      <UiRouterLink
        v-if="!!to && !isLink"
        :to="to[1]"
        class="cta-link mr-3 whitespace-nowrap pb-0"
      >
        {{ to[0] }}
      </UiRouterLink>
      <UiButton
        v-if="showDelete"
        type="button"
        class="icon ml-2 h-[40px] min-w-[40px] self-end !rounded-lg !bg-white !p-0"
        variant="icon-delete"
        @click="emit('delete')"
      >
        <img src="/assets/img/icon/delete.png" class="h-[17px] w-4" />
      </UiButton>
    </div>
  </component>
</template>

<style scoped>
  .form-panel-list-item.assignment :deep(.list-title) {
    @apply text-header-primary mb-1 text-sm font-semibold uppercase opacity-30;
  }

  .form-panel-list-item .form-panel-list-item .form-panel-list-item.assignment {
    @apply pt-0;
  }

  .form-panel-list-item:not(.condensed) {
    @apply py-5;
  }

  .form-panel-list-item .form-panel-list-item:not(.condensed) .list-item-right {
    @apply self-end;
  }

  .form-panel-list-item:not(.condensed) + .form-panel-list-item.condensed {
    @apply mt-[-20px] mb-0;
  }
  .form-panel-list-item + .form-panel-list-item.condensed {
    @apply mb-0 pb-[10px];
  }

  .form-panel-list-item + .form-panel-list-item.condensed:last-child {
    @apply pb-[20px];
  }
</style>

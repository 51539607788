import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { HasMany, HasOne, Num, Str } from 'pinia-orm/dist/decorators';

import EmployerAdmin from './employer_admin';
import EmployerBilling from './employer_billing';
import type Learner from './learner';

export default class Employer extends Model {
  static entity = 'employer';

  @Num(null) declare id: number;
  @HasMany(() => EmployerAdmin, 'employer_id')
  declare admins: EmployerAdmin[];
  @Str('') declare billing_status: string;
  @Str('') declare name: string;
  @HasOne(() => EmployerBilling, 'employer_id')
  declare billing: EmployerBilling;
  @Num(null) declare unstarted_assignment_count: number;
  @Str(null) declare email_campaign: string;

  static api() {
    return {
      async detail() {
        const { data } = await axios.get(`/employer`);

        const repo = useRepo(Employer);
        const billingRepo = useRepo(EmployerBilling);
        billingRepo.flush();
        repo.flush();
        repo.save(data);
      },
      async searchAdmin(searchTerm: string) {
        const payload = {
          search_term: searchTerm,
        };
        const { data } = await axios.post(`/employer-admin/search`, payload);
        return data;
      },
      async saveName(name: string) {
        const payload = {
          name,
        };
        const { data } = await axios.post(`/employer`, payload);
        useRepo(Employer).save(data);
        return data;
      },
    };
  }

  isAdmin(learner: Learner) {
    return useRepo(EmployerAdmin)
      .where('employer_id', this.id)
      .where('learner_id', learner.id)
      .first();
  }
}

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { DateTime } from 'luxon';
  import { useRepo } from 'pinia-orm';

  import { Engagement, EngagementRecording, MeetingRecording } from '@orm-lib';
  import { UiButton, UiPillFilter, usePillFilter } from '@ui-lib';
  import BotRecordingCard from '@/components/BotRecordingCard.vue';
  import EmptyCard from '@/components/EmptyCard.vue';
  import HomeTrainingCard from '@/components/HomeTrainingCard.vue';
  import IconBot from '@/components/icons/IconBot.vue';
  import BotSchedulingModal from '@/components/modals/BotSchedulingModal.vue';
  import RecordingThumbnail from '@/components/RecordingThumbnail.vue';
  import { useApp, useHome } from '@/composables';

  const { isLearner, isCoach, member } = useApp();

  const { selectedFilters, resetFilters, toggleFilter } = usePillFilter();

  interface IProps {
    loaded: boolean;
  }
  const props = withDefaults(defineProps<IProps>(), {
    loaded: false,
  });

  const dashboard = ref();

  const newestFirst = ref(true);
  const transitionGroup = ref();
  const animatedVisibleRecordings = ref();
  const schedulingModalVisible = ref();
  const cancelledMeetingId = ref();
  const router = useRouter();

  const { trainings, getLearnerTrainingAction } = useHome();

  const formattedTrainings = computed(() => {
    return trainings.value
      .map((training) => getLearnerTrainingAction(training, true))
      .filter((training) => !!training?.key);
  });

  const workMeetingRecordings = computed(() => {
    return useRepo(MeetingRecording).withAllRecursive().get();
  });

  const engagements = computed(() => {
    return useRepo(Engagement).withAllRecursive().get();
  });

  const filterGroups = computed(() => {
    return (
      unfilteredRecordings.value?.map((recording) => {
        return [recording.label, recording.engagement?.name];
      }) ?? []
    );
  });

  const currentSubscription = computed(() => {
    return member.value?.training_subscriptions?.find((subscription) => {
      return subscription.status === 'Present';
    });
  });

  const trainingRecordings = computed(() => {
    return engagements.value?.reduce(
      (acc: EngagementRecording[], engagement) => {
        engagement.sessions.forEach((session) => {
          session.recordings
            .filter((sessionRecording) => sessionRecording.type === 'archive')
            .forEach((sessionRecording) => {
              acc.push({
                ...sessionRecording,
                engagement,
                label: 'Training Session',
              });
            });
        });

        return acc;
      },
      []
    );
  });

  const upcomingRecordings = computed(() => {
    return workMeetingRecordings.value.filter(
      (meeting) => DateTime.fromISO(meeting.starts_at) > DateTime.now()
    );
  });

  const botRecordings = computed(() => {
    return workMeetingRecordings.value
      .filter(
        (meeting) =>
          !!meeting.recording && meeting.feedback.outcome !== 'Failed'
      )
      .map((recording) => {
        return {
          ...recording,
          label: 'Work Meeting',
        };
      });
  });

  const uploadedRecordings = computed(() => {
    return engagements.value?.reduce(
      (acc: EngagementRecording[], engagement) => {
        engagement.recordings.forEach((recording) => {
          acc.push({ ...recording, engagement, label: 'Work Meeting' });
        });

        return acc;
      },
      []
    );
  });

  const unfilteredRecordings = computed(() => {
    return [
      ...trainingRecordings.value,
      ...botRecordings.value,
      ...uploadedRecordings.value,
    ];
  });

  const visibleRecordings = computed(() => {
    const filteredRecordings =
      selectedFilters.value.length > 0
        ? unfilteredRecordings.value.filter(isFilteredRecording)
        : unfilteredRecordings.value.slice();

    if (newestFirst.value) {
      return filteredRecordings.sort(
        (a, b) => DateTime.fromISO(b.created) - DateTime.fromISO(a.created)
      );
    }

    return filteredRecordings.sort(
      (a, b) => DateTime.fromISO(a.created) - DateTime.fromISO(b.created)
    );
  });

  function isFilteredRecording(recording: EngagementRecording) {
    const filterableOptions = [recording.label, recording.engagement?.name];

    return selectedFilters.value.every((filter) => {
      return filterableOptions.indexOf(filter) > -1;
    });
  }

  function hideSchedulingModal() {
    schedulingModalVisible.value = false;
    cancelledMeetingId.value = null;
  }

  function handleRecordingCancel(id: number) {
    schedulingModalVisible.value = true;
    cancelledMeetingId.value = id;
  }

  watch(
    [() => visibleRecordings.value, () => newestFirst.value],
    ([newRecordingsVal, newOrderVal], [oldRecordingsVal, oldOrderVal]) => {
      if (!oldRecordingsVal.length) {
        animatedVisibleRecordings.value = visibleRecordings.value;
      }

      if (
        oldRecordingsVal.length &&
        (oldRecordingsVal.length !== newRecordingsVal.length ||
          newOrderVal !== oldOrderVal)
      ) {
        if (transitionGroup.value) {
          transitionGroup.value.style.opacity = 0;
        }

        setTimeout(() => {
          transitionGroup.value.style.opacity = 1;

          animatedVisibleRecordings.value = visibleRecordings.value;
        }, 300);
      }
    }
  );

  onMounted(() => {
    animatedVisibleRecordings.value = visibleRecordings.value;
  });
</script>

<template>
  <div ref="dashboard" class="w-full">
    <h1 class="text-header-3 mb-3 self-start font-bold">Training</h1>
    <template v-if="loaded">
      <div :class="`flex flex-col`">
        <div class="mb-8">
          <h2 class="column-heading mb-2">Focused Training</h2>
          <div v-if="formattedTrainings?.length" class="flex flex-wrap gap-4">
            <div class="flex w-full max-w-full flex-wrap gap-4">
              <HomeTrainingCard
                v-for="training in formattedTrainings"
                :key="training.key"
                class="max-w-[470px]"
                :title="training.training?.skill?.name"
                :coach="training.training?.coach?.user?.full_name"
                :current-session-id="training.training?.current_session_id"
                :entity="training.training?.entity"
                :subtitle="training.subtitle"
                :training-id="training.training?.id"
                :skill-id="training.training?.skill?.id"
                :created="training.training?.created"
                :can-reschedule="
                  training.training?.is_learner_reschedule_allowed ?? false
                "
                :started="training.training?.started"
                :scheduled="training.training?.currentSession?.scheduled"
                :coach-match-completed="
                  training.training?.coach_match_completed
                "
              />
            </div>
          </div>
          <EmptyCard
            v-else
            heading="You don't have a focused training assignment."
            message="When one is assigned to you it will appear here."
          />
        </div>
        <div class="mb-8">
          <div
            class="mb-[10px] flex flex-wrap items-center justify-between gap-y-2"
          >
            <h2 class="column-heading mr-2">Meeting Feedback</h2>
            <UiButton
              variant="app-home"
              class="flex items-center"
              @click="router.push({ name: 'ScheduleMeeting' })"
            >
              <IconBot class="-mt-1" />
              <span class="ml-2">Schedule</span>
            </UiButton>
          </div>
          <div
            v-if="upcomingRecordings?.length"
            class="thumbnail-grid grid flex-col gap-y-4 gap-x-3 sm:flex md:grid"
          >
            <BotRecordingCard
              v-for="recording in upcomingRecordings"
              :key="recording.id"
              :starts-at="recording.starts_at"
              @cancel-recording="() => handleRecordingCancel(recording.id)"
            />
          </div>
          <EmptyCard
            v-else-if="currentSubscription?.meeting_recording_available"
            heading="Monthly meeting recording limit has been exceeded."
            :message="`You will be able to record more meetings after ${DateTime.fromISO(
              currentSubscription.meeting_recording_available
            ).toFormat('MMMM dd')}`"
          />
          <EmptyCard
            v-else
            heading="There are no scheduled meeting reviews."
            message='Schedule StageBot to attend a work meeting to receive feedback by pressing the "Schedule" button above.'
          />
        </div>
        <div>
          <div
            class="mb-[10px] flex flex-wrap items-center justify-between gap-y-2"
          >
            <h2 class="column-heading">Recordings</h2>
            <div
              v-if="unfilteredRecordings.length > 1"
              class="flex items-center"
            >
              <button
                :class="`text-sm ${
                  newestFirst
                    ? ' text-brand-primary font-bold'
                    : ' text-body-light'
                }`"
                @click="newestFirst = true"
              >
                Newest
              </button>
              <span class="text-body-light mx-2 text-sm">|</span>
              <button
                :class="`text-sm ${
                  !newestFirst
                    ? ' text-brand-primary font-bold'
                    : ' text-body-light'
                }`"
                @click="newestFirst = false"
              >
                Oldest
              </button>
            </div>
          </div>
          <template v-if="filterGroups.length > 1">
            <UiPillFilter
              class="mb-6"
              :filter-groups="filterGroups"
              :selected-filters="selectedFilters"
              @reset-filters="resetFilters"
              @toggle-filter="toggleFilter"
            />
          </template>
          <div
            ref="transitionGroup"
            :class="`${
              animatedVisibleRecordings?.length && 'thumbnail-grid grid'
            } flex-col gap-y-4 gap-x-3 sm:flex md:grid`"
          >
            <template v-if="animatedVisibleRecordings?.length">
              <TransitionGroup name="slide-fade">
                <RecordingThumbnail
                  v-for="visibleRecording in animatedVisibleRecordings"
                  :key="visibleRecording.id"
                  :recording="visibleRecording"
                  :label="visibleRecording.label"
                />
              </TransitionGroup>
            </template>
            <EmptyCard
              v-else
              heading="You don't have any recordings."
              message="After each scheduled meeting, a recording of the meeting along with StageBot's feedback will appear here."
            />
          </div>
        </div>
      </div>
    </template>
    <BotSchedulingModal
      v-if="schedulingModalVisible"
      :meeting-recording-available="
        currentSubscription?.meeting_recording_available
      "
      :meeting-id="cancelledMeetingId"
      @hide-scheduling-modal="hideSchedulingModal"
    />
  </div>
</template>

<style scoped>
  .thumbnail-grid {
    grid-template-columns: repeat(
      auto-fill,
      minmax(min(320px, calc(100vw - 32px)), 1fr)
    );
    transition: all 0.3s ease;
  }
</style>

<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
  import { throttle } from 'lodash';

  import { IconRightChevron, useMedia } from '@ui-lib';

  const props = defineProps<{
    filterGroups: string[][];
    selectedFilters: string[];
  }>();

  const emit = defineEmits<{
    (e: 'resetFilters'): void;
    (e: 'toggleFilter', option: string): void;
  }>();

  const isOverflownLeft = ref(false);
  const isOverflownRight = ref(false);
  const pillWrapper = ref();
  const throttledFunction = ref();
  const sm = useMedia('sm');

  const availableFilters = computed(() => {
    const primaryOptions: string[] = [];
    const secondaryOptions: string[] = [];

    props.filterGroups.forEach((group) => {
      group.forEach((item, index) => {
        const isPrimaryOption = index === 0;

        if (!item) {
          return;
        }

        if (isPrimaryOption && isAvailableOption(item, primaryOptions)) {
          return primaryOptions.push(item);
        }

        if (!isPrimaryOption && isAvailableOption(item, secondaryOptions)) {
          return secondaryOptions.push(item);
        }
      });
    });

    return [...primaryOptions, ...secondaryOptions];
  });

  const showResetButton = computed(() => {
    return props.selectedFilters.length > 0 && !sm.value;
  });

  const visiblePills = computed(() => {
    return availableFilters.value;
  });

  function isAvailableOption(item: string, currentOptions: string[]) {
    if (!props.selectedFilters.length) {
      return currentOptions.indexOf(item) < 0;
    }

    if (currentOptions.indexOf(item) > -1) {
      return false;
    }

    if (sm.value) {
      return true;
    }

    return isCompatibleWithSelectedOptions(item);
  }

  function isCompatibleWithSelectedOptions(item: string) {
    const associatedFilterGroups = props.filterGroups.filter((group) => {
      return group.indexOf(item) > -1;
    });

    return associatedFilterGroups.some((group) => {
      return props.selectedFilters.every((filter) => {
        return group.indexOf(filter) > -1;
      });
    });
  }

  function isSelected(option: string) {
    return props.selectedFilters?.includes(option);
  }

  function isDisabled(option: string) {
    if (!props.selectedFilters.length) {
      return false;
    }

    if (isSelected(option)) {
      return false;
    }

    return !isCompatibleWithSelectedOptions(option);
  }

  function isScrolledToEnd(
    scrollLeft: number,
    clientWidth: number,
    scrollWidth: number
  ) {
    return Math.abs(scrollLeft - (scrollWidth - clientWidth)) <= 1;
  }

  function handleScreenSize() {
    if (sm.value) {
      isOverflownLeft.value = false;
      isOverflownRight.value = false;
      return;
    }

    if (pillWrapper.value.scrollWidth <= pillWrapper.value.clientWidth) {
      isOverflownLeft.value = false;
      isOverflownRight.value = false;
      return;
    }

    isOverflownLeft.value = pillWrapper.value.scrollLeft !== 0;
    isOverflownRight.value = !isScrolledToEnd(
      pillWrapper.value.scrollLeft,
      pillWrapper.value.clientWidth,
      pillWrapper.value.scrollWidth
    );
  }

  function handlePillClick(option: string) {
    if (isDisabled(option)) {
      return;
    }

    emit('toggleFilter', option);
    scrollTo(pillWrapper.value, 0, 250);
  }

  function handleOverflowButtonClick(direction: 'left' | 'right') {
    let to;
    const scrollLeft = pillWrapper.value.scrollLeft;

    if (direction === 'left') {
      to = scrollLeft - 200 < 0 ? 0 : scrollLeft - 200;
    } else {
      const distanceToEnd =
        pillWrapper.value.scrollWidth - pillWrapper.value.clientWidth;
      to = scrollLeft + 200 > distanceToEnd ? distanceToEnd : scrollLeft + 200;
    }

    scrollTo(pillWrapper.value, to, 250);
  }

  function scrollTo(element: HTMLElement, to: number, duration: number) {
    if (duration <= 0) return;
    const difference = to - element.scrollLeft;
    const perTick = (difference / duration) * 10;

    setTimeout(function () {
      element.scrollLeft = element.scrollLeft + perTick;
      if (element.scrollLeft == to) return;
      scrollTo(element, to, duration - 10);
    }, 10);
  }

  onBeforeUnmount(() => {
    window.removeEventListener('resize', throttledFunction.value);
  });

  onMounted(() => {
    handleScreenSize();
    throttledFunction.value = throttle(handleScreenSize, 200);

    window.addEventListener('resize', throttledFunction.value);
  });

  watch(
    () => props.selectedFilters.length,
    () => {
      setTimeout(() => {
        handleScreenSize();
      }, 350);
    }
  );
</script>

<template>
  <div
    :class="`relative flex flex-nowrap gap-2 overflow-x-hidden ${
      selectedFilters.length > 0 && 'has-clear-button'
    }`"
  >
    <div
      ref="pillWrapper"
      :class="`flex flex-nowrap gap-2 overflow-x-auto transition-all duration-300 ease-in-out sm:flex-wrap  sm:overflow-x-hidden `"
      @scroll.passive="handleScreenSize"
    >
      <button
        v-if="showResetButton"
        class="filter-button order-first rounded-full px-[9px] py-2 xl:p-3"
        @click="emit('resetFilters')"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="1.63664"
            y1="14.3642"
            x2="14.3646"
            y2="1.63626"
            stroke="#5E5CFF"
            stroke-width="2"
          />
          <line
            x1="14.3632"
            y1="14.3638"
            x2="1.63528"
            y2="1.63592"
            stroke="#5E5CFF"
            stroke-width="2"
          />
        </svg>
      </button>
      <div v-if="isOverflownLeft" class="left-overflow-clip" />
      <button
        v-if="isOverflownLeft"
        class="filter-button overflow-button left-0 pl-[11px] pr-[12px]"
        @click="handleOverflowButtonClick('left')"
      >
        <IconRightChevron class="mr-[1px] rotate-180" color="brand-primary" />
      </button>
      <TransitionGroup name="slide-fade">
        <button
          v-for="option in visiblePills"
          :key="option"
          :class="`pill-filter filter-button ${
            isSelected(option) && 'selected'
          } ${isDisabled(option) && 'disabled'}`"
          @click="handlePillClick(option)"
        >
          {{ option }}
        </button>
      </TransitionGroup>
      <button
        v-if="isOverflownRight"
        class="filter-button overflow-button right-0 pl-[13px] pr-[12px]"
        @click="handleOverflowButtonClick('right')"
      >
        <IconRightChevron color="brand-primary" class="-mr-[1px]" />
      </button>
      <div v-if="isOverflownRight" class="right-overflow-clip" />
    </div>
  </div>
</template>

<style scoped>
  .filter-button {
    @apply border-[1px] border-solid border-[#E8ECF4] bg-white hover:bg-gray-100;
  }

  .pill-filter {
    @apply text-body-secondary min-h-[36px] translate-x-0 whitespace-nowrap rounded-[20px] py-1 px-4 text-sm transition-[position,transform,background] duration-300 ease-in-out sm:text-base;
  }

  .has-clear-button .pill-filter.selected:nth-child(3) {
    @apply duration-[350ms];
  }

  .has-clear-button .pill-filter.selected:nth-child(4) {
    @apply duration-[400ms];
  }

  .has-clear-button .pill-filter.selected:nth-child(5) {
    @apply duration-[450ms];
  }

  .has-clear-button .pill-filter.selected:nth-child(6) {
    @apply duration-[500ms];
  }

  .has-clear-button .pill-filter.selected:nth-child(n + 6) {
    @apply duration-[550ms];
  }

  .pill-filter.selected {
    @apply text-brand-primary z-[2] order-first bg-[#E4E3FF] hover:bg-[#d0cfe8] sm:order-none;
  }

  .pill-filter.selected ~ .pill-filter.selected {
    @apply z-[1] -translate-x-12 bg-[#D7D8FF] pl-12 sm:translate-x-0 sm:bg-[#E4E3FF] sm:pl-4;
  }

  .pill-filter.disabled {
    @apply cursor-default bg-gray-100 text-gray-300 hover:bg-gray-100;
  }

  .overflow-button {
    @apply absolute z-[4] flex items-center self-center justify-self-center rounded-full  py-[9px];
  }

  .overflow-button:first-child {
    box-shadow: 8px 0 8px -3px rgba(7, 12, 54, 0.15);
  }

  .overflow-button:last-child {
    box-shadow: -8px 0 8px -3px rgba(7, 12, 54, 0.15);
  }

  .right-overflow-clip,
  .left-overflow-clip {
    content: '';
    @apply bg-page-gray absolute z-[3] h-full w-[20px];
  }

  .left-overflow-clip {
    left: 0;
  }

  .right-overflow-clip {
    right: 0;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.3s ease;
  }

  .slide-fade-leave-active {
    position: absolute;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    opacity: 0;
  }

  *::-webkit-scrollbar {
    height: 0;
    background-color: #f7f7f7;
  }
</style>

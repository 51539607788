<script lang="ts" setup>
  import { useRouter } from 'vue-router';

  import { UiMenuLink } from '@ui-lib';
  import { useApp } from '@/composables';
  import { useAppStore } from '@/stores';

  const { hasUpdate, isDesktop } = useApp();

  const router = useRouter();
  const store = useAppStore();

  async function onLogOut() {
      await store.logout();
      await router.push('/login');
  }

  async function onApplyUpdate() {
    if (isDesktop.value) {
      window.electron.ipcRenderer.sendSync('apply-update', true);
    }
  }
</script>

<template>
  <div class="profile-menu -mx-8 rounded-lg bg-white px-3 py-3">
    <UiMenuLink
      :to="{ name: 'ProfileManage' }"
      text="My Profile"
      is-profile-link
    />
    <UiMenuLink
      v-if="hasUpdate"
      text="Apply Update"
      has-update
      is-profile-link
      @click="onApplyUpdate"
    />
    <UiMenuLink text="Sign Out" is-profile-link @click="onLogOut" />
  </div>
</template>

<style scoped>
  .profile-menu {
    box-shadow: 0 4px 15px 5px rgba(0, 0, 0, 0.1);
  }

  .menu-link-active {
    @apply text-brand-primary relative rounded-md font-semibold;
  }
</style>

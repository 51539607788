import {
  type NavigationGuardNext,
  type RouteLocation,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';

import { useAppStore } from '@/stores';

import routes from './routes';
import { useCookies } from "vue3-cookies";
import { nextTick } from "vue";

import { capturePosthogPageview } from "@ui-lib";

const base = import.meta.env.BASE_URL;

const history =
  import.meta.env.VITE_IS_DESKTOP === 'true'
    ? createWebHashHistory(base)
    : createWebHistory(base);

const router = createRouter({
  history,
  routes,
});

export function ensureApp(
    to: RouteLocation,
    _from: RouteLocation,
    next: NavigationGuardNext
) {
    // Handle always allowed public pages
    const alwaysAllowedPages = [
        'Subscription',
        'DesktopDeprecated',
        'WebrtcReplay'
    ]

    if (alwaysAllowedPages.includes(<string>to.name)) {
        return next();
    }

    const appStore= useAppStore();

    // Handle anon only public pages
    const anonOnlyPages = [
        'SignUp',
        'Login',
        'LoginForgot',
        'InviteDecline',
        'LoginReset',
        'InviteAccept',
    ]
    if (anonOnlyPages.includes(<string>to.name)) {
        if (appStore.isAuthenticated)
            return next({ name: 'Home' })
        return next();
    }

    // Handle auth only pages
    if (appStore.isAuthenticated === false) {
        let query = {};
        if (to.name !== 'Home')
            query = { redirect: to.fullPath };

        return next({ name: 'Login', query });
    }

    if (to.name === 'CohortCreation' && !to.params?.cohortName) {
        return next({ name: 'CohortList' });
    }

    return next();
}

router.beforeEach((to, from, next) => {
    const isDesktop = import.meta.env.VITE_IS_DESKTOP === 'true';

    if (isDesktop === true && to.name !== 'DesktopDeprecated') {
        return next({ name: 'DesktopDeprecated' });
    }

    return next();
})

router.beforeEach((to, from, next) => {
  ensureApp(to, from, next);
});

router.beforeEach((to, _from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.tags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = <string>nearestWithTitle.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[router-tag]')).map((el) => {
    return el.parentNode?.removeChild(el);
  });

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta?.tags
    // @ts-expect-error
    ?.map((tagDef: any) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('router-tag', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag: any) => document.head.appendChild(tag));

  next();
});

router.beforeEach((to) => {
    const emailCampaignKey = import.meta.env.VITE_EMAIL_CAMPAIGN_KEY
    if (emailCampaignKey in to.query && to.query[emailCampaignKey] !== null) {
        const cookies = useCookies()
        cookies.cookies.set(emailCampaignKey, <string>to.query[emailCampaignKey])
    }
})

// Capture all Page Views
router.afterEach((to, from, failure) => {
    if (!failure) {
        nextTick(() => {
            capturePosthogPageview(to.fullPath)
        });
    }
});

export default router;

import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import MeetingRecordingFeedback from './meeting_recording_feedback';
import Recording from './recording';

export default class MeetingRecording extends Model {
  static entity = 'meeting-recording';

  @Num(null) declare id: number;
  @Str(null) declare bot_joined: string;
  @Str(null) declare completed: string;
  @Str(null) declare created: string;
  @BelongsTo(() => MeetingRecordingFeedback, 'feedback_id')
  declare feedback: MeetingRecordingFeedback;
  @Num(null) declare feedback_id: number;
  @Num(null) declare learner_id: number;
  @BelongsTo(() => Recording, 'recording_id')
  declare recording: Recording[];
  @Num(null) declare recording_id: number;
  @Str(null) declare starts_at: string;

  static api() {
    return {
      async list() {
        const { data } = await axios.get('/meeting-recording/');

        const repo = useRepo(MeetingRecording);
        repo.flush();
        repo.save(data);
      },
      async schedule(url: string, dateTime: string, skillId: number) {
        await axios.post(`/meeting-recording/`, {
          meeting_url: url,
          starts_at: dateTime,
          skill_id: skillId,
        });
      },
      async cancel(id: number) {
        await axios.delete(`/meeting-recording/${id}`);
      },
    };
  }
}

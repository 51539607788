import type { Router } from 'vue-router';
import Axios, { type AxiosRequestConfig } from 'axios';

import { useAppStore } from '@/stores';

const setup = (router: Router): void => {
  Axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;

  Axios.interceptors.request.use(function (config: AxiosRequestConfig) {
    const appStore = useAppStore();

    if (appStore.isAuthenticated) {
      config.headers = {
        ...config.headers,
        Authorization: `Token ${appStore.token}`,
      };
    }

    return config;
  });

  Axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (error.response?.status === 401) {
        if (
          error.response.request.responseURL.startsWith(
            import.meta.env.VITE_API_BASE_URL
          )
        ) {
          const appStore = useAppStore();
          await appStore.clearToken();

          if (router.currentRoute.value.name !== 'Login') {
            await router.push('/login');
          }
        }
      }
      return Promise.reject(error);
    }
  );
};

export default setup;

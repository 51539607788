<script lang="ts" setup>
  import { computed, reactive, ref } from 'vue';
  import { useRouter } from 'vue-router';

  import { GroupLearner } from '@orm-lib';
  import {
    UiForm,
    UiFormCheckbox,
    UiFormGroup,
    UiFormNavigation,
  } from '@ui-lib';
  import AppCheckboxGroup from '@/components/AppCheckboxGroup.vue';
  import EmptyCard from '@/components/EmptyCard.vue';
  import type { IOnboardingSignupRequest } from '@/types';

  const router = useRouter();

  interface IProps {
    groupLearners: GroupLearner[];
    groupGoals: GroupLearner[];
    serviceError?: string;
    form: IOnboardingSignupRequest;
    recommendation: any;
  }
  interface IEmits {
    (e: 'prev'): void;
    (e: 'next', request: IOnboardingSignupRequest): void;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<IEmits>();

  const localForm = reactive({ ...props.form });
  const initialChecked = localForm.group_learners.map((learner) => learner.id);
  const initialAllChecked =
    localForm.group_learners?.length === props.groupLearners?.length;
  const localValue = ref(initialChecked);
  const allChecked = ref(initialAllChecked);

  const learnerOptions = computed(() => {
    return props.groupLearners.map((learner) => {
      const hasGoals =
        !!props.groupGoals.length &&
        props.groupGoals.some((groupGoal) =>
          groupGoal.group_learners.find(
            (groupLearner) => groupLearner.group_learner_id === learner.id
          )
        );
      const currentSubscription = learner.learner.training_subscriptions?.find(
        (subscription) => subscription.status === 'Present'
      );
      const tag = !currentSubscription?.available_upgrades?.length
        ? 'No Upgrades Available'
        : hasGoals
        ? ''
        : 'No Goals Set';

      return {
        id: learner.id,
        title: learner.learner.full_name,
        subtitle: learner.learner.email,
        tag: tag,
        tagColor: tag === 'No Goals Set' ? 'yellow' : 'red',
        disabled: !currentSubscription?.available_upgrades?.length,
      };
    });
  });

  const allText = computed(() => {
    if (allChecked.value) {
      return 'Deselect All';
    }

    return 'Select All';
  });

  async function submit() {
    const updatedForm = {
      ...localForm,
      group_learners: localValue.value.map((val) => {
        return {
          id: val,
        };
      }),
    };

    emit('next', updatedForm);
  }

  function handleCheckItem(checked: boolean, value: number) {
    if (checked && localValue.value?.length) {
      localValue.value?.push(value);

      if (localValue.value.length === props.groupLearners.length) {
        allChecked.value = true;
      } else {
        allChecked.value = false;
      }
      return;
    }

    if (checked && !localValue.value?.length) {
      localValue.value = [value];

      if (localValue.value.length === props.groupLearners.length) {
        allChecked.value = true;
      } else {
        allChecked.value = false;
      }
      return;
    }

    localValue.value = localValue.value.filter((val) => value !== val);
    allChecked.value = false;
  }

  function handleAllCheck(checked: boolean) {
    if (checked) {
      localValue.value = props.groupLearners
        .filter((groupLearner) => {
          const currentSubscription =
            groupLearner.learner.training_subscriptions?.find(
              (subscription) => subscription.status === 'Present'
            );
          return currentSubscription?.available_upgrades?.length;
        })
        .map((learner) => learner.id);
      allChecked.value = true;
    } else {
      localValue.value = [];
      allChecked.value = false;
    }
  }
</script>

<template>
  <UiForm
    :error="serviceError"
    variant="app-config"
    class="!border-0 bg-transparent sm:!p-0"
    @submit="submit"
  >
    <div
      class="mx-0 w-full min-w-full rounded-[10px] border-[1px] border-solid border-[#E8ECF4] bg-white p-5 px-4 pt-4 pb-6 md:pt-5 lg:px-6 lg:pb-6"
    >
      <UiFormGroup class="-mt-4 !mb-0">
        <template v-if="learnerOptions.length">
          <div
            class="-mx-4 mb-5 border-b-[1px] border-solid border-[#E8ECF4] px-4 pb-1 lg:-mx-6 lg:px-6"
          >
            <UiFormCheckbox
              id="all"
              v-model="allChecked"
              class="w-full min-w-full pl-4 [&>input]:mb-3 [&>input]:mr-2.5"
              @input="handleAllCheck"
            >
              <div
                class="option-content flex w-full min-w-full items-center justify-around"
              >
                <div
                  class="option-title -mt-1 w-full text-left text-base font-semibold text-[#242431]"
                >
                  {{ allText }}
                </div>
                <div class="text-body-secondary ml-auto whitespace-nowrap">
                  ({{ localValue.length }}/{{ learnerOptions.length }}) Learners
                  selected
                </div>
              </div>
            </UiFormCheckbox>
          </div>
          <AppCheckboxGroup
            v-model="localValue"
            is-multiple
            class="!mb-0"
            variant="bordered"
            :options="learnerOptions"
            @input="handleCheckItem"
          />
        </template>
        <EmptyCard
          v-else
          class="mt-4"
          heading="There are no learners in this group."
          message="You can't request training until you have learners assigned to your group."
        />
      </UiFormGroup>
    </div>

    <UiFormNavigation
      class="mb-0 mt-[40px] lg:mt-[60px] pr-4 lg:pr-6"
      show-previous
      prev-text="Cancel"
      submit-text="Next Step"
      :disabled="!learnerOptions.length || !localValue.length"
      @prev="emit('prev')"
    >
      <slot></slot>
    </UiFormNavigation>

  </UiForm>
</template>

<style scoped>
  :deep(input[disabled]) {
    position: relative;
  }

  :deep(input[disabled]:after) {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    @apply rounded bg-gray-200;
  }
</style>

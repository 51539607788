<script lang="ts" setup>
  import { computed } from 'vue';

  import { UiButton, UiFormGroup } from '@ui-lib';
  import AppPanel from '@/components/AppPanel.vue';

  const props = defineProps<{
    canSchedule?: boolean;
    scheduledDate?: string;
    scheduledTime?: string;
  }>();

  const emit = defineEmits<{
    (e: 'showCalendly'): void;
  }>();

  const subtitle = computed(() => {
    if (!props.canSchedule) {
      return 'No changes can be made to the scheduled session at this time.';
    }

    if (props.scheduledDate) {
      return 'You may cancel this session by clicking the link below.';
    }

    return 'No session has been scheduled for this engagement. Click on the button below to schedule a session.';
  });
</script>

<template>
  <AppPanel title="Upcoming Session" class="relative" :subtitle="subtitle">
    <UiFormGroup class="form-group-input !mb-6 w-full min-w-full">
      <div
        v-if="!scheduledDate"
        class="text-body-secondary m-auto w-full rounded border border-gray-300 p-8 text-center italic"
      >
        You have no session scheduled
      </div>
      <div v-else class="flex flex-col text-sm">
        <span class="text-header-primary mb-1 font-semibold">
          {{ scheduledDate }}
        </span>
        <span class="text-body-secondary">{{ scheduledTime }}</span>
      </div>
    </UiFormGroup>
    <UiFormGroup v-if="canSchedule" class="form-group-submit !mb-0">
      <UiButton
        class="self-start !rounded-[5px]"
        type="button"
        @click="emit('showCalendly')"
      >
        {{ !scheduledDate ? 'Schedule a session' : 'Cancel Session' }}
      </UiButton>
    </UiFormGroup>
  </AppPanel>
</template>

<script setup lang="ts">
  import AppTaskColumn from '@/components/AppTaskColumn.vue';
  import { useApp, useHome } from '@/composables';

  const { isLearner, isCoach } = useApp();

  const { taskColumns } = useHome();

  interface IProps {
    loaded: boolean;
  }
  const props = withDefaults(defineProps<IProps>(), {
    loaded: false,
  });
</script>

<template>
  <div class="page-container-centered">
    <h1 class="font-gradient-2 text-header-4 mb-6 self-start font-bold">
      Optimize Your Team
    </h1>
    <AppTaskColumn
      v-for="col in taskColumns"
      :key="col.title"
      :empty-state="col.emptyState"
      :tasks="col.tasks"
      :title="col.title"
    />
  </div>
</template>

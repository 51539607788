<script lang="ts" setup>
  import { computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useRepo } from 'pinia-orm';

  import { Action, FeatureFlag, TrainingRequest } from '@orm-lib';
  import { UiLogo, UiMenuLink, UiModal } from '@ui-lib';
  import { useApp, useHome } from '@/composables';
  import { useAppStore } from '@/stores';

  const { hasUpdate, isAdmin, isCoach, isDesktop, isLearner, member, user } =
    useApp();

  const router = useRouter();
  const store = useAppStore();

  interface IProps {
    variant?: 'fixed' | 'pink' | 'gradient';
  }
  interface IEmits {
    (e: 'close'): void;
  }
  const props = defineProps<IProps>();

  const emit = defineEmits<IEmits>();
  const route = useRoute();
  const { trainings, getLearnerTrainingAction } = useHome();

  const formattedTrainings = computed(() => {
    return trainings.value
      .map((training) => getLearnerTrainingAction(training, true))
      .filter((training) => !!training?.key);
  });

  const showGroupManagementLink = computed(() => {
    return isLearner.value && member.value?.owned_group?.id;
  });

  const showTrainingLink = computed(() => {
    return (
      isCoach.value ||
      member.value?.learner_group ||
      formattedTrainings.value?.length
    );
  });

  const actionCount = computed(() => {
    if (isCoach.value) {
      return null;
    }

    return useRepo(Action).withAll().get().length;
  });

  const groupActionCount = computed(() => {
    return useRepo(Action)
      .withAllRecursive()
      .get()
      .filter(
        (action) =>
          action.reason === 'GroupWithoutGoal' ||
          action.reason === 'GroupLearnerWithoutGoal' ||
          action.reason === 'GoalWithoutReport'
      ).length;
  });

  const administrationTaskCount = computed(() => {
    const adminTaskCount = useRepo(Action)
      .withAllRecursive()
      .get()
      .filter(
        (action) =>
          action.reason === 'EmployerWithoutGroup' ||
          action.reason === 'EmployerPastDue' ||
          action.reason === 'GroupWithoutLearner' ||
          action.reason === 'GroupWithoutOwner'
      ).length;

    const adminRequestCount = useRepo(TrainingRequest)
      .withAllRecursive()
      .where('status', 'PendingEmployerAdminReview')
      .get().length;

    return adminTaskCount + adminRequestCount;
  });

  async function onLogOut() {
    await router.push('/login');
    await store.logout();
  }

  async function onApplyUpdate() {
    if (isDesktop.value) {
      window.electron.ipcRenderer.sendSync('apply-update', true);
    }
  }

  function handleClose() {
    emit('close');
  }
</script>

<template>
  <UiModal
    v-click-outside="handleClose"
    variant="nav"
    class="overflow-y-hidden [&>div]:overflow-y-hidden"
    @close="handleClose"
  >
    <div
      class="h-[100vh] overflow-y-hidden rounded-lg bg-white px-4 py-3"
      tabindex="-1"
      aria-labelledby="drawer-navigation-label"
    >
      <div class="mb-6">
        <UiLogo
          class="[&>h1]:text-body-primary [&>h1]:text-xl"
          size="md"
          heading-color="black"
          :variant="'primary'"
        />
      </div>
      <UiMenuLink
        :to="{ name: 'Home' }"
        is-mobile
        text="Dashboard"
        icon="Dashboard"
        :task-count="actionCount"
      />
      <UiMenuLink
        v-if="isLearner && isAdmin"
        :to="{ name: 'Administration' }"
        text="Administration"
        icon="Administration"
        :is-active="route.path.includes('administration')"
        :task-count="administrationTaskCount"
        is-mobile
      />
      <UiMenuLink
        v-if="isLearner && isAdmin"
        :to="{ name: 'Billing' }"
        text="Billing"
        icon="Billing"
        is-mobile
      />
      <UiMenuLink
        v-if="isCoach && isAdmin"
        is-mobile
        :to="{ name: 'CoachList' }"
        text="Users"
        icon="Group"
      />
      <UiMenuLink
        v-if="showGroupManagementLink"
        :text="member.owned_group?.name"
        :to="{
          name: 'GroupManagement',
          params: {
            groupId: member.owned_group.id,
          },
        }"
        icon="Group"
        :task-count="groupActionCount"
        is-mobile
      />
      <UiMenuLink
        v-if="isCoach"
        :to="{ name: 'RequestList' }"
        text="Requests"
        icon="Reporting"
        is-mobile
      />
      <UiMenuLink
        v-if="showTrainingLink"
        :to="{ name: 'TrainingList' }"
        text="Training"
        icon="Training"
        is-mobile
      />
      <UiMenuLink
        v-if="isLearner && (isAdmin || showGroupManagementLink)"
        :to="{ name: 'Reporting' }"
        text="Reporting"
        icon="Reporting"
        is-mobile
      />
      <div
        class="mt-4 flex flex-col border-t-[1px] border-solid border-[rgba(92,101,130,0.30)] border-opacity-20 pt-3"
      >
        <UiMenuLink
          :to="{ name: 'ProfileManage' }"
          text="My Profile"
          is-profile-link
          is-mobile
        />
        <UiMenuLink
          v-if="hasUpdate"
          has-update
          text="Apply Update"
          is-mobile
          is-profile-link
          @click="onApplyUpdate"
        />
        <UiMenuLink
          text="Sign Out"
          is-mobile
          is-profile-link
          @click="onLogOut"
        />
      </div>
    </div>
  </UiModal>
</template>

<style scoped>
  .menu-link-update {
    @apply hover:bg-brand-light active:bg-brand-active text-brand-primary border-brand-primary relative mt-16 block w-[unset] self-start rounded-md border-[1px] border-solid hover:!no-underline;
  }

  .menu-link-active {
    @apply bg-brand-light text-brand-primary relative rounded-md font-semibold;
  }

  :deep(.menu-link-active svg path) {
    @apply stroke-brand-primary;
  }
</style>

<script lang="ts" setup>
  import { computed, nextTick, onMounted, reactive, ref } from 'vue';
  import AgoraRTC, {
    ILocalAudioTrack,
    ILocalVideoTrack,
  } from 'agora-rtc-sdk-ng';

  import { UiButton } from '@ui-lib';
  import RecordingFeedback from '@/components/RecordingFeedback.vue';

  const emit = defineEmits<{
    (e: 'stopped'): void;
    (e: 'positionUpdated', arg: unknown): void;
    (e: 'streamCreated', arg: unknown): void;
  }>();

  const video = ref();
  const canvas = ref();
  const canPlay = ref(false);
  const file = ref(null);
  const height = ref(null);
  const isPlaying = ref(false);
  const wasPlaying = ref(false);
  const media = reactive({
    audioTrack: null as ILocalAudioTrack | null,
    stream: null,
    videoTrack: null as ILocalVideoTrack | null,
  });
  const position = ref(0);
  const positionInterval = ref<ReturnType<typeof setTimeout> | null>(null);
  const previousPosition = ref(0);
  const recording = ref(null);
  const started = ref(false);
  const videoStream = ref<MediaStream | string>('');
  const width = ref(null);

  const currentNote = computed(() => {
    if (recording.value === null) {
      return null;
    }

    return recording.value?.scrubNote(position);
  });

  const isReady = computed(() => {
    return recording.value !== null;
  });

  const name = computed(() => {
    if (recording.value === null) {
      return null;
    }

    return recording.value?.name;
  });

  const nextNote = computed(() => {
    return recording.value?.nextNote(position.value);
  });

  const pauseDisabled = computed(() => {
    return canPlay.value === false || isPlaying.value === false;
  });

  const playDisabled = computed(() => {
    return canPlay.value === false || isPlaying.value === true;
  });

  const previousNote = computed(() => {
    return recording.value?.previousNote(position.value - 0.5);
  });

  const reviewStreamContainerClass = computed(() => {
    if (recording.value === null) return '';
    if (recording.value?.type === 'Audio') return 'session-stream-review-audio';
    if (!recording.value?.hasFeedback) return 'session-stream-review-video';

    return 'session-stream-review-video-feedback';
  });

  const showFeedback = computed(() => {
    return recording.value?.hasFeedback;
  });

  const showStop = computed(() => {
    return started.value;
  });

  const heightStyle = computed(() => {
    if (!showFeedback.value) {
      return 'max-height: calc(100vh - 155px)';
    }

    return 'max-height: calc(100vh - 415px)';
  });

  async function attemptStart() {
    if (
      height.value === null ||
      width.value === null ||
      canPlay.value === false
    ) {
      return;
    }

    await applyStream();

    const videoTracks = videoStream.value.getVideoTracks();

    if (videoTracks.length > 0) {
      media.videoTrack = AgoraRTC.createCustomVideoTrack({
        bitrateMax: 1250,
        bitrateMin: 300,
        mediaStreamTrack: videoTracks[0],
        optimizationMode: 'motion',
      });
    }

    const audioTracks = videoStream.value.getAudioTracks();

    if (audioTracks.length > 0) {
      media.audioTrack = AgoraRTC.createCustomAudioTrack({
        encoderConfig: 'music_standard',
        mediaStreamTrack: audioTracks[0],
      });
    }

    emit('streamCreated', media);
  }

  function drawVideo() {
    const ctx = canvas.value.getContext('2d');

    function step() {
      if (video.value && width.value && height.value) {
        ctx.drawImage(video.value, 0, 0, width.value, height.value);
        requestAnimationFrame(step);
      }
    }
    requestAnimationFrame(step);
  }

  async function applyStream() {
    video.value.addEventListener('play', drawVideo);

    const newStream = new MediaStream();
    const localCanvasStream = await canvas.value.captureStream();
    const localVideoStream = await video.value.captureStream();

    newStream.addTrack(localCanvasStream.getVideoTracks()[0]);
    newStream.addTrack(localVideoStream.getAudioTracks()[0]);

    videoStream.value = newStream;
  }

  function getScrubNoteWidthStyle(position: number) {
    const percentage = (position / recording.value?.duration) * 100;
    let offsetPixels = ((50.0 - percentage) / 100.0) * 16;
    return `calc(${Math.round(percentage)}% + ${Math.round(offsetPixels)}px )`;
  }

  async function initialize(newRecording) {
    started.value = false;
    recording.value = newRecording;

    await nextTick(async () => {
      video.value.onloadedmetadata = async (e) => {
        height.value = e.target.videoHeight;
        width.value = e.target.videoWidth;
      };

      video.value.onended = async () => {
        await applyStream();
      };

      video.value.oncanplay = async () => {
        if (canPlay.value === false) {
          canPlay.value = true;
          await attemptStart();
        }
      };

      file.value = await recording.value?.getFile();
    });
  }

  function onScrubStart() {
    wasPlaying.value = isPlaying.value;
    if (isPlaying.value) video.value.pause();
  }

  function onScrubEnd() {
    video.value.currentTime = position.value;
    if (wasPlaying.value === true) video.value.play();
  }

  function onScrubToNextNote() {
    if (nextNote.value === null) return;
    position.value = nextNote.value.note.position;
    video.value.currentTime = position.value;
  }

  function onScrubToPreviousNote() {
    if (previousNote.value === null) return;
    position.value = previousNote.value.note.position;
    video.value.currentTime = position.value;
  }

  async function onStop() {
    video.value.oncanplay = null;
    video.value.onended = null;
    video.value.pause();
    file.value = null;
    video.value.load();

    emit('stopped');

    started.value = false;
    canPlay.value = false;
    position.value = 0;
    media.stream = null;
    media.audioTrack = null;
    media.videoTrack = null;
    recording.value = null;

    video.value.removeEventListener('play', drawVideo);
  }

  async function onTogglePlayPause() {
    if (isPlaying.value === false) video.value.play();
    else video.value.pause();
  }

  async function start() {
    started.value = true;
    video.value.play();
  }

  async function stop() {
    await onStop();
  }

  onMounted(() => {
    positionInterval.value = setInterval(() => {
      if (started.value === true && position.value !== previousPosition.value) {
        emit('positionUpdated', position.value);
        previousPosition.value = position.value;
      }
    }, 250);
  });

  defineExpose({ initialize, start, stop });
</script>

<template>
  <div
    v-if="isReady"
    class="relative flex h-full w-full flex-col"
    style="max-height: calc(100% - 60px)"
  >
    <div class="video-stream-overlay">
      <UiButton
        v-if="showStop"
        type="button"
        class="mb-[5px] h-[46px] w-[48px] !rounded-lg"
        style="margin-left: 5px"
        variant="icon"
        @click="onStop"
      >
        <img src="/assets/img/icon/cancel.png" class="h-4 !w-4" />
      </UiButton>
    </div>
    <div class="video-stream-label">{{ name }}</div>
    <div
      :class="['video-stream-border', reviewStreamContainerClass]"
      :style="heightStyle"
    >
      <video
        ref="video"
        :src="file"
        playsinline
        crossOrigin="anonymous"
        class="video-stream-main mx-auto"
        @timeupdate="position = $event.target.currentTime"
        @play="isPlaying = true"
        @pause="isPlaying = false"
      />
      <canvas
        ref="canvas"
        class="video-stream-main hidden"
        :width="width"
        :height="height"
      ></canvas>
    </div>

    <div style="display: flex; height: 50px; padding-top: 10px; flex-basis: 0">
      <UiButton
        type="button"
        variant="icon"
        :disabled="playDisabled"
        style="margin: 0 5px 0 0"
        class="icon min-h-[40px] min-w-[40px] self-end !rounded-[5px] !p-0"
        @click="onTogglePlayPause"
      >
        <img src="/assets/img/icon/play.png" class="h-3 !w-3" />
      </UiButton>
      <UiButton
        type="button"
        variant="icon"
        :disabled="pauseDisabled"
        style="margin-right: 5px"
        class="icon min-h-[40px] min-w-[40px] self-end !rounded-[5px] !p-0"
        @click="onTogglePlayPause"
      >
        <img src="/assets/img/icon/pause.png" class="h-3 !w-3" />
      </UiButton>
      <div class="relative flex h-[40px] w-full items-center">
        <div
          v-for="recordingNote in recording.notesSorted"
          :key="'scrubNote' + recordingNote.id"
          class="video-scrub-note"
          :style="{
            width: getScrubNoteWidthStyle(recordingNote.note.position),
          }"
        ></div>
        <input
          ref="scrubBar"
          v-model.number="position"
          :max="recording.duration"
          class="scrub-bar w-full"
          min="0"
          step="0.2"
          type="range"
          @mousedown="onScrubStart"
          @mouseup="onScrubEnd"
        />
      </div>

      <UiButton
        v-if="showFeedback"
        type="button"
        :disabled="previousNote === null"
        class="icon min-h-[40px] min-w-[40px] self-end !rounded-[5px] !p-0"
        variant="icon"
        style="margin-left: 5px"
        @click="onScrubToPreviousNote"
      >
        <img src="/assets/img/icon/previous.png" class="h-3 !w-3" />
      </UiButton>

      <UiButton
        v-if="showFeedback"
        type="button"
        :disabled="nextNote === null"
        class="icon min-h-[40px] min-w-[40px] self-end !rounded-[5px] !p-0"
        variant="icon"
        style="margin-left: 5px"
        @click="onScrubToNextNote"
      >
        <img src="/assets/img/icon/next.png" class="h-3 !w-3" />
      </UiButton>
    </div>

    <div
      v-if="showFeedback"
      class="flex h-full min-h-[160px] w-full flex-col pt-[10px]"
    >
      <RecordingFeedback :recording="recording" :current-note="currentNote" />
    </div>
  </div>
</template>

<style scoped>
  .scrub-bar {
    @apply accent-brand-primary;
  }
</style>

<script lang="ts" setup>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import Popper from 'vue3-popper';

  import { UiRouterLink } from '@ui-lib';

  const props = defineProps<{
    name: string;
    id: number;
  }>();
  const emit = defineEmits<{
    (e: 'rename'): void;
    (e: 'delete'): void;
  }>();

  const isPopperOpen = ref(false);
  const popperRef = ref();
  const router = useRouter();

  function handleRemove(close: () => null) {
    close();
    emit('delete');
  }

  function handleManage(close: () => null) {
    close();
    router.push({
      name: 'GroupManagement',
      params: {
        groupId: props.id,
      },
    });
  }

  function handleRename(close: () => null) {
    close();
    emit('rename');
  }
</script>

<template>
  <UiRouterLink
    :to="{ name: 'AdministrationGroup', params: { groupId: id } }"
    class="[&>*>h5]:hover:text-brand-primary mb-2 flex items-center rounded-[10px] border-[1px] border-solid border-[#E8ECF4] bg-white px-6 py-7 hover:bg-gray-50 hover:no-underline"
  >
    <svg
      class="mr-4"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 1000004504">
        <path
          id="Polygon 1"
          d="M3.17949 10.2887L20 0.57735L36.8205 10.2887V29.7113L20 39.4226L3.17949 29.7113V10.2887Z"
          stroke="#E8ECF4"
        />
        <path
          id="Vector 129"
          d="M20 40L3 30V10L20 18.6667V40Z"
          fill="#E8ECF4"
        />
      </g>
    </svg>
    <div class="flex flex-col">
      <h5 class="text-header-primary text-base font-semibold sm:text-lg">
        {{ name }}
      </h5>
    </div>
    <div class="ml-auto h-[30px]">
      <Popper
        offset-distance="4"
        placement="bottom-end"
        @open:popper="isPopperOpen = true"
        @close:popper="isPopperOpen = false"
      >
        <button
          ref="editButton"
          class="navigation-button ml-2 flex h-[30px] w-[30px] items-center justify-self-end rounded-lg hover:bg-[#E6EBF1] hover:outline-none hover:outline focus:bg-[#E6EBF1]"
          @click.prevent
          @hover.prevent
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="29"
              height="29"
              rx="5.5"
              stroke="#E8ECF4"
            />
            <circle cx="15" cy="9" r="2" fill="#5C6582" />
            <circle cx="15" cy="15" r="2" fill="#5C6582" />
            <circle cx="15" cy="21" r="2" fill="#5C6582" />
          </svg>
        </button>
        <template #content="{ close }">
          <div
            ref="popperRef"
            class="learner-menu flex flex-col rounded-lg bg-white"
          >
            <button
              class="flex items-center rounded-t-lg bg-white px-4 py-2 text-sm capitalize hover:bg-gray-50"
              @click.prevent="handleManage(close)"
            >
              <svg
                class="mr-2"
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.75 1C10.75 0.585786 10.4142 0.25 10 0.25L3.25 0.25C2.83579 0.25 2.5 0.585786 2.5 1C2.5 1.41421 2.83579 1.75 3.25 1.75H9.25V7.75C9.25 8.16421 9.58579 8.5 10 8.5C10.4142 8.5 10.75 8.16421 10.75 7.75L10.75 1ZM1.53033 10.5303L10.5303 1.53033L9.46967 0.46967L0.46967 9.46967L1.53033 10.5303Z"
                  fill="#5C6582"
                />
              </svg>
              Manage Training
            </button>
            <button
              class="flex items-center border-t-[1px] border-solid border-[#E8ECF4] bg-white px-4 py-2 text-sm capitalize hover:bg-gray-50"
              @click.prevent="handleRename(close)"
            >
              <svg
                class="mr-[7px] -mt-[2px]"
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 9.5L8.5 2L11.5 5L4 12.5H1V9.5Z"
                  stroke="#5C6582"
                  stroke-width="1.5"
                />
              </svg>
              Rename
            </button>
            <button
              class="flex items-center rounded-b-lg border-t-[1px] border-solid border-[#E8ECF4] bg-white px-4 py-2 text-sm capitalize text-red-500 hover:bg-gray-50"
              @click.prevent="handleRemove(close)"
            >
              <svg
                width="12"
                height="12"
                class="mr-2 -mt-[2px]"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.2002 2.70039H10.8002M4.8002 8.70039V5.10039M7.20019 8.70039V5.10039M8.40019 11.1004H3.6002C2.93745 11.1004 2.4002 10.5631 2.4002 9.90039V3.30039C2.4002 2.96902 2.66882 2.70039 3.0002 2.70039H9.00019C9.33157 2.70039 9.60019 2.96902 9.60019 3.30039V9.90039C9.60019 10.5631 9.06294 11.1004 8.40019 11.1004ZM4.8002 2.70039H7.20019C7.53157 2.70039 7.80019 2.43176 7.80019 2.10039V1.50039C7.80019 1.16902 7.53157 0.900391 7.20019 0.900391H4.8002C4.46882 0.900391 4.2002 1.16902 4.2002 1.50039V2.10039C4.2002 2.43176 4.46882 2.70039 4.8002 2.70039Z"
                  stroke="#FB4F57"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              Delete
            </button>
          </div>
        </template>
      </Popper>
    </div>
  </UiRouterLink>
</template>

<style scoped>
  .learner-menu {
    box-shadow: 0 4px 15px 5px rgba(0, 0, 0, 0.1);
  }
</style>

import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import Coach from './coach';
import CoachingRequest from './coaching_request';

export default class Bid extends Model {
  static entity = 'bid';

  @Num(null) declare id: number;
  @Str('') declare created: string;
  @BelongsTo(() => Coach, 'coach_id') declare coach: Coach;
  @Num(null) declare coach_id: number;
  @BelongsTo(() => CoachingRequest, 'request_id')
  declare request: CoachingRequest;
  @Num(null) declare request_id: number;

  static api() {
    return {
      async remove(id: number) {
        await axios.delete(`/bid/${id}`);

        const repo = useRepo(Bid);
        repo.destroy(id);
      },
    };
  }
}

import { computed } from 'vue';

import { useAgoraStore } from '@/stores';

export const useAgora = () => {
  const store = useAgoraStore();

  const cameraEncoderConfig = computed(() => store.encoderConfig.camera);

  return {
    cameraEncoderConfig,
  };
};

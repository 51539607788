import axios from 'axios';
import { Model } from 'pinia-orm';
import { Attr, Num, Str } from 'pinia-orm/dist/decorators';

interface IRecommendationLearner {
  id: number;
}

interface ITrainingRecommendation {
  group_learner_id: number;
  quarter_key: number;
  recommendation_type: string;
  skill_id: number;
}

interface ITrainingQuarter {
  end: string;
  key: number;
  quarter: number;
  start: string;
  year: number;
}

export default class TrainingRecommendation extends Model {
  static entity = 'training_recommendation';

  @Num(null) declare group_id: number;
  @Attr() declare group_learners: IRecommendationLearner[];
  @Attr() declare live_training_recommendations: ITrainingRecommendation[];
  @Num(0) declare maximum_number_of_trainings: number;
  @Str('') declare number_of_quarters: number;
  @Num(0) declare number_of_trainings: number;
  @Attr() declare quarters: ITrainingQuarter[];
  @Num(0) declare starting_quarter_key: number;

  static api() {
    return {
      async getRecommendation(request: any) {
        const { data } = await axios.post(`/training-recommendation/`, request);

        return data;
      },
    };
  }
}

<script setup lang="ts">
  import { computed, onBeforeMount, ref, watch } from 'vue';
  import { isNil } from 'lodash';

  import { type GroupLearner, Skill } from '@orm-lib';
  import { IconDownChevron, UiDropdown, UiListItem } from '@ui-lib';
  import AppCheckboxGroup from '@/components/AppCheckboxGroup.vue';

  const props = defineProps<{
    learner: GroupLearner;
    expanded: boolean;
    isDesktop: boolean;
    recommendedSkillId?: number;
    skillList: Skill[];
  }>();

  const isExpanded = ref(props.expanded);
  const localTier = ref('focused');
  const emit = defineEmits(['select-tier', 'select-skill', 'toggle-expand']);

  const selectedSkillId = ref();
  const isDropdownDisabled = computed(() => {
    return localTier.value !== 'focused';
  });

  const currentSubscription = computed(() => {
    return props.learner.learner.training_subscriptions?.find(
      (subscription) => subscription.status === 'Present'
    );
  });

  const skillOptions = computed(() => {
    let recommendedSkillIndex = null;
    const formattedSkills = props.skillList?.map((skill, index) => {
      if (skill.id === props.recommendedSkillId) {
        recommendedSkillIndex = index;
      }

      return {
        id: skill.id as null | number,
        text: skill.name,
        tag:
          skill.id && skill.id === props.recommendedSkillId
            ? 'Recommended'
            : null,
      };
    });

    const manualOptions = [
      {
        id: null as null | number,
        text: 'Let the Learner Decide',
        tag: null as null | string,
      },
    ];

    const sortedSkills = formattedSkills.sort((a, b) => {
      if (a.text.toLowerCase() < b.text.toLowerCase()) {
        return -1;
      }
      if (a.text.toLowerCase() > b.text.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const fullSkillList = sortedSkills.concat(manualOptions);

    if (!isNil(recommendedSkillIndex)) {
      return fullSkillList.sort((a, b) => {
        return a.id == props.recommendedSkillId
          ? -1
          : b.id == props.recommendedSkillId
          ? 1
          : 0;
      });
    }

    return fullSkillList;
  });

  const selectedSkill = computed(() => {
    const selectedSkillOption = skillOptions.value.find(
      (skill) => skill.id === selectedSkillId.value
    );

    return props.skillList.find(
      (skill) => skill.id === selectedSkillOption?.id
    );
  });

  const selectedSkillName = computed(() => {
    if (isDropdownDisabled.value) {
      return 'N / A';
    }

    return selectedSkill.value?.name ?? 'Let the Learner Decide';
  });

  const selectedSkillDescription = computed(() => {
    if (localTier.value === 'focused' && !selectedSkill.value?.description) {
      return 'Let the learner decide their own skill focus for this training.';
    }

    if (localTier.value === 'focused') {
      return selectedSkill.value?.description;
    }

    return localTier.value === 'basic'
      ? 'The basic subscription tier allows this learner to have free access to StageBot, our skill specific AI meeting review bot. It will be listed to a single meeting review every 90 days. We recommend areas of skill focus based on the goals this learner is associated with and deliver feedback after the meeting with specific action items and video clips of the meeting highlighting their behavior.'
      : 'The lite subscription tier allows this learner to have unlimited access to StageBot, our skill specific AI meeting review bot. We recommend areas of skill focus based on the goals this learner is associated with and deliver feedback after the meeting with specific action items and video clips of the meeting highlighting their behavior.';
  });

  const tierOptions = computed(() => {
    return [
      {
        id: 'focused',
        title: 'Focused',
        subtitle:
          'Our most effective training that includes live session with a trainer and unlimited access to AI meeting review.',
        disabled:
          !currentSubscription.value?.available_upgrades?.includes('Focused'),
      },
      {
        id: 'lite',
        title:
          currentSubscription.value?.tier === 'Lite'
            ? 'Lite (Current)'
            : 'Lite',
        subtitle: 'Unlimited access to skill-focused AI meeting review.',
        disabled:
          !currentSubscription.value?.available_upgrades?.includes('Lite'),
      },
      {
        id: 'basic',
        title:
          currentSubscription.value?.tier === 'Basic'
            ? 'Basic (Current)'
            : 'Lite (Current)',
        subtitle: 'Free limited access to AI meeting review.',
        disabled:
          !currentSubscription.value?.available_upgrades?.includes('Basic'),
      },
    ];
  });

  const slotPosition = computed(() => {
    if (localTier.value === 'focused') {
      return 1;
    }

    if (localTier.value === 'lite') {
      return 2;
    }

    return 3;
  });

  function handleTierSelect(_selection: MouseEvent, id: string) {
    localTier.value = id;
    if (id !== 'focused') {
      selectedSkillId.value = null;
    } else {
      selectedSkillId.value = skillOptions.value?.[0]?.id;
    }
    emit('select-tier', id);
    emit('select-skill', selectedSkillId.value);
  }

  function selectSkill(skillId: number) {
    selectedSkillId.value = skillId ?? null;
    emit('select-skill', skillId);
  }

  function toggleExpand() {
    isExpanded.value = !isExpanded.value;
    emit('toggle-expand');
  }

  onBeforeMount(() => {
    if (currentSubscription.value?.available_upgrades?.includes('Focused')) {
      selectedSkillId.value = skillOptions.value?.[0]?.id;
      emit('select-tier', 'focused');
      emit('select-skill', selectedSkillId.value);
      return;
    } else {
      selectedSkillId.value = null;
      emit('select-skill', null);
    }

    if (currentSubscription.value?.available_upgrades?.includes('Lite')) {
      emit('select-tier', 'lite');
      return;
    }

    emit('select-tier', 'basic');
  });

  watch(
    () => props.expanded,
    () => {
      isExpanded.value = props.expanded;
    }
  );

  watch(
    () => props.recommendedSkillId,
    () => {
      if (props.recommendedSkillId) {
        selectedSkillId.value = props.recommendedSkillId;

        emit('select-skill', selectedSkillId.value);
      }
    }
  );
</script>

<template>
  <div
    class="flex w-full flex-col border-b-[1px] border-solid border-[#E8ECF4] last:border-b-0 [&>button]:first:rounded-t-[10px]"
  >
    <button
      :class="`flex cursor-pointer items-center gap-2 py-3 px-7  ${
        isExpanded ? ' bg-[#5E5CFF1A]' : ' '
      }`"
      @click.prevent="toggleExpand"
    >
      <div
        class="flex w-full flex-col items-start gap-x-4 md:flex-row md:items-start md:justify-between"
      >
        <h4
          class="text-header-primary whitespace-nowrap text-left text-base font-semibold sm:text-lg"
        >
          {{ learner.learner.full_name }}
        </h4>
        <span
          :class="`block text-left text-sm md:text-base ${
            isExpanded ? 'text-brand-primary' : 'text-body-secondary'
          }`"
        >
          Upgrade
          <span class="capitalize">
            {{ currentSubscription?.tier ?? ' Basic' }}
          </span>
          to
          <span class="capitalize">
            {{ localTier }}{{ localTier === 'focused' ? ':' : '' }}
          </span>
          {{ localTier === 'focused' ? ` ${selectedSkillName}` : '' }}
        </span>
      </div>
      <div class="ml-auto">
        <IconDownChevron
          :class="`ml-3 transition duration-100 ease-in-out ${
            isExpanded && 'rotate-180'
          }`"
        />
      </div>
    </button>
    <template v-if="isExpanded">
      <div
        class="grid grid-cols-12 gap-4 border-t-[1px] border-solid border-[#E8ECF4] p-6 pb-16"
      >
        <div class="col-span-12 md:col-span-6">
          <AppCheckboxGroup
            v-model="localTier"
            :is-multiple="false"
            class="!mb-0 [&>div.radio]:!my-0 [&>div.radio]:!mb-4 [&>div.radio]:!rounded-[10px] [&>div.radio]:!border-[1px] last:[&>div.radio]:!mb-0 [&>div.radio>input]:!self-center"
            variant="bordered"
            :options="tierOptions"
            :slot-position="slotPosition"
            @input="handleTierSelect"
          >
            <div
              v-if="!isDesktop"
              class="mb-4 flex w-full flex-col rounded-[10px] border-[1px] border-solid border-[#E8ECF4] py-3 px-[18px]"
            >
              <span class="mb-1 text-sm font-semibold sm:mb-2 sm:text-base">
                Skill Focus
              </span>
              <UiDropdown
                :options="skillOptions"
                :disabled="isDropdownDisabled"
                :text="selectedSkillName"
                variant="rich"
                @select="(selection) => selectSkill(selection, learner.id)"
              >
                <template #trigger>
                  <button>
                    <UiListItem
                      is-action
                      :text="selectedSkillName"
                      :tag="
                        selectedSkillId &&
                        selectedSkillId === recommendedSkillId
                          ? 'Recommended'
                          : ''
                      "
                    />
                  </button>
                </template>
              </UiDropdown>
              <span class="mb-1 text-sm font-semibold sm:mb-2 sm:text-base">
                Description
              </span>
              <p class="text-body-secondary text-sm">
                {{ selectedSkillDescription }}
              </p>
            </div>
          </AppCheckboxGroup>
        </div>
        <div v-if="isDesktop" class="col-span-12 md:col-span-6">
          <div
            class="flex w-full flex-col rounded-[10px] border-[1px] border-solid border-[#E8ECF4] py-3 px-[18px]"
          >
            <span class="mb-1 text-sm font-semibold sm:mb-2 sm:text-base">
              Skill Focus
            </span>
            <UiDropdown
              :options="skillOptions"
              :disabled="isDropdownDisabled"
              :text="selectedSkillName"
              variant="rich"
              @select="(selection) => selectSkill(selection, learner.id)"
            >
              <template #trigger>
                <button>
                  <UiListItem
                    is-action
                    :text="selectedSkillName"
                    :tag="
                      selectedSkillId === recommendedSkillId
                        ? 'Recommended'
                        : ''
                    "
                  />
                </button>
              </template>
            </UiDropdown>
            <span class="mb-1 text-sm font-semibold sm:mb-2 sm:text-base">
              Description
            </span>
            <p class="text-body-secondary text-sm">
              {{ selectedSkillDescription }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
  :deep(.dropdown) {
    @apply mb-5 w-full;
  }

  :deep(.dropdown .absolute) {
    @apply rounded-lg;
  }

  :deep(.dropdown li:last-child) {
    @apply relative mt-[17px];
  }

  :deep(.dropdown li:last-child::before) {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    top: -9px;
    left: 0;
    @apply bg-gray-200;
  }

  :deep(input[disabled]) {
    position: relative;
  }

  :deep(input[disabled]:after) {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    @apply bg-gray-200;
  }

  :deep(.dropdown button div span.text-header-primary),
  :deep(.dropdown li div span.text-header-primary) {
    @apply !font-normal;
  }

  :deep(.dropdown li) {
    @apply !py-[7px] !px-2;
  }

  :deep(.dropdown button) {
    @apply !py-2.5 !px-4;
  }

  :deep(.dropdown button div.ml-4) {
    @apply !ml-2;
  }

  :deep(.dropdown button div.bg-opacity-10) {
    @apply !-my-1;
  }

  :deep(.dropdown li div.bg-opacity-10) {
    @apply !-my-1 !mr-0;
  }
</style>

<script lang="ts" setup>
  import { computed, useAttrs } from 'vue';
  import type { RouteLocationRaw } from 'vue-router';

  interface IProps {
    to?: RouteLocationRaw;
    variant?: 'primary' | 'brand';
  }
  const props = defineProps<IProps>();

  const attrs = useAttrs();

  const linkClass = computed(() => {
    const baseClass = 'cursor-pointer hover:underline';

    if (props.variant === 'brand') {
      return baseClass + ' text-brand-primary text-sm [&>span]:text-sm';
    }

    return baseClass;
  });
</script>

<template>
  <RouterLink v-if="!!to" :to="to" :class="linkClass" v-bind="attrs">
    <slot></slot>
  </RouterLink>
  <a v-else class="cursor-pointer"><slot></slot></a>
</template>

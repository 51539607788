import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Num } from 'pinia-orm/dist/decorators';

export default class RecommendedSkill extends Model {
  static entity = 'recommended-skill';

  @Num(null) declare id: number;
  @Num(null) declare priority: number;
  @Num(null) declare skill_id: number;

  static api() {
    return {
      async list() {
        const { data } = await axios.get(`/recommended-skill`);
        const repo = useRepo(RecommendedSkill);
        repo.flush();
        repo.save(
          data.map((datum, index) => ({
            ...datum,
            id: index,
          }))
        );
      },
    };
  }
}

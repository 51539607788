<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { DateTime } from 'luxon';
  import { useRepo } from 'pinia-orm';

  import { Action } from '@orm-lib';
  import { useMedia } from '@ui-lib';
  import AppTaskCard from '@/components/AppTaskCard.vue';
  import EmptyCard from '@/components/EmptyCard.vue';
  import HomeTrainingCard from '@/components/HomeTrainingCard.vue';
  import { useApp, useHome } from '@/composables';

  const { isLearner, isCoach } = useApp();

  interface IProps {
    loaded: boolean;
  }
  const props = withDefaults(defineProps<IProps>(), {
    loaded: false,
  });

  const newestFirst = ref(true);
  const dashboard = ref();
  const md = useMedia('md');

  const { trainings, getLearnerTrainingAction } = useHome();
  const { member } = useApp();

  const userActions = computed(() => {
    return useRepo(Action).withAllRecursive().get();
  });

  const formattedActions = computed(() => {
    const newActions = userActions.value.slice();

    if (newestFirst.value) {
      return newActions.sort(
        (a, b) => DateTime.fromISO(b.created) - DateTime.fromISO(a.created)
      );
    }

    return newActions.sort(
      (a, b) => DateTime.fromISO(a.created) - DateTime.fromISO(b.created)
    );
  });

  const formattedTrainings = computed(() => {
    return trainings.value
      .map((training) => getLearnerTrainingAction(training, true))
      .filter((training) => !!training?.key);
  });

  const showLeftColumn = computed(() => {
    return formattedTrainings.value?.length || member.value?.learner_group;
  });
</script>

<template>
  <div ref="dashboard" class="w-full">
    <h1 class="text-header-3 mb-3 self-start font-bold">Dashboard</h1>
    <template v-if="loaded">
      <div :class="`flex grid-cols-12 flex-col gap-[46px] xl:grid`">
        <div v-if="showLeftColumn" class="task-column col-span-5">
          <h2 class="column-heading mb-2">Focused Training</h2>
          <div v-if="formattedTrainings?.length" class="flex flex-col gap-4">
            <HomeTrainingCard
              v-for="training in formattedTrainings"
              :key="training.key"
              class="max-w-[470px]"
              :current-session-id="training.training?.current_session_id"
              :title="training.training?.skill?.name ?? 'Any Skill'"
              :coach="training.training?.coach?.user?.full_name"
              :entity="training.training?.entity"
              :subtitle="training.subtitle"
              :created="training.training?.created"
              :training-id="training.training?.id"
              :skill-id="training.training?.skill?.id"
              :can-reschedule="
                training.training?.is_learner_reschedule_allowed ?? false
              "
              :started="training.training?.started"
              :scheduled="training.training?.currentSession?.scheduled"
              :coach-match-completed="training.training?.coach_match_completed"
            />
          </div>
          <EmptyCard
            v-else
            heading="You don't have a focused training assignment."
            message="When one is assigned to you it will appear here."
          />
        </div>
        <div
          :class="`task-column ${
            showLeftColumn ? 'col-span-7' : 'col-span-12'
          }`"
        >
          <div class="mb-2 flex justify-between">
            <h2 class="column-heading flex">
              Tasks
              <span
                class="ml-4 block self-center rounded-full bg-[#FB4F57] px-2 text-xs font-normal text-white !no-underline hover:!no-underline"
              >
                {{ formattedActions.length }}
              </span>
            </h2>
            <div v-if="formattedActions.length > 1" class="flex items-center">
              <button
                :class="`text-sm ${
                  newestFirst
                    ? ' text-brand-primary font-bold'
                    : ' text-body-light'
                }`"
                @click="newestFirst = true"
              >
                Newest
              </button>
              <span class="text-body-light mx-2 text-sm">|</span>
              <button
                :class="`text-sm ${
                  !newestFirst
                    ? ' text-brand-primary font-bold'
                    : ' text-body-light'
                }`"
                @click="newestFirst = false"
              >
                Oldest
              </button>
            </div>
          </div>
          <div
            v-if="formattedActions.length"
            class="flex flex-col rounded-lg border-[1px] border-solid border-[#E8ECF4] bg-white p-2 sm:p-4"
          >
            <template
              v-for="(action, index) in formattedActions"
              :key="action.id"
            >
              <AppTaskCard
                :assigned="action.assigned"
                :title="action.title"
                :subtitle="action.subtitle"
                :reason="action.reason"
                :status="action.status"
                :status-type="action.status_type"
                :group-id="action.group_id"
                :group-learner-id="action.group_learner_id"
                :group-goal-id="action.group_goal_id"
                :assignment-id="action.assignment_id"
                :feedback-request-id="action.feedback_request_id"
                :owned-group-id="member?.owned_group?.id"
              />
              <div
                v-if="index !== formattedActions.length - 1"
                class="h-[1px] w-full bg-[#E8ECF4]"
              ></div>
            </template>
          </div>
          <EmptyCard
            v-else
            heading="You're all caught up!"
            message="When you receive a new task it will appear here."
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
  .task-column {
    @apply flex max-w-full flex-col;
  }
</style>

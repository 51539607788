import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { type Collection, type Item, useRepo } from 'pinia-orm';

import { Assignment, CoachingRequest, Engagement, Feedback } from '@orm-lib';
import { useApp, useFeedback } from '@/composables';
import type { IBaseTask, IBaseTaskColumn } from '@/types';

export const useHome = () => {
  const { isAdmin, isBillable, isLearner, isCoach, member, user } = useApp();
  const { formatAlert, formatLink } = useFeedback();

  const requests = computed(
    (): Collection<Feedback> | Collection<CoachingRequest> => {
      if (!isCoach.value) {
        return (
          useRepo(Feedback)
            .orderBy('collaborator_id', 'desc')
            .orderBy('created', 'desc')
            .get() ?? []
        );
      }

      return (
        useRepo(CoachingRequest)
          .with('skill')
          .with('bids')
          .orderBy('started')
          .get() ?? []
      );
    }
  );

  const trainings = computed(() => {
    let result: Item<Engagement | Assignment>[] = [];

    if (isLearner.value) {
      member.value?.inProgressEngagements
        .orderBy('created', 'desc')
        .get()
        .forEach((engagement: Engagement) => {
          result.push(engagement);
        });
      member.value?.assignments
        .withAllRecursive(2)
        .orderBy('created', 'desc')
        .get()
        .forEach((assignment: Item<Assignment>) => {
          result.push(assignment);
        });
    } else if (isCoach.value) {
      result = useRepo(Engagement)
        .withAllRecursive()
        .orderBy('created', 'desc')
        .where('completed', null)
        .where('coach_id', member.value?.id)
        .get();
    }

    return result;
  });

  const taskColumns = computed(() => {
    const columns: IBaseTaskColumn[] = [];

    addHomeBaseActions(columns);
    addHomeRequestActions(columns);
    addHomeTrainingActions(columns);

    return columns;
  });

  function getLearnerTrainingAction(
    training: Item<Engagement> | Item<Assignment>,
    isLearnerDashboard = false
  ) {
    let title = '',
      body = '',
      alert = '',
      key = '',
      to = <RouteLocationRaw>{};

    if (
      (!isLearnerDashboard &&
        training?.entity === 'assignment' &&
        training.started === null) ||
      (isLearnerDashboard && training?.entity === 'assignment')
    ) {
      title = `Start Training`;
      body = `Begin your ${training.name} training.`;
      alert = DateTime.fromISO(String(training.created)).toRelative() ?? '';
      key = String(training.id) + '-' + training.entity;

      to = {
        name: 'Assignment',
        params: {
          assignmentId: training.id?.toString(),
          skillId: training.skill_id?.toString() ?? null,
        },
      };
    } else if (
      training?.entity === 'engagement' &&
      training.completed === null
    ) {
      title = 'Continue Training';
      body = `Resume your ${training.name} training.`;
      alert = DateTime.fromISO(String(training.created)).toRelative() ?? '';
      key = String(training.id) + '-' + training.entity;
      to = {
        name: 'Engagement',
        params: { engagementId: String(training.id) },
      };
    } else {
      return null;
    }

    return <IBaseTask>{
      title,
      body,
      alert,
      to,
      key,
      training,
    };
  }

  function addHomeBaseActions(columns: IBaseTaskColumn[]) {
    const adminTasks: IBaseTask[] = [];

    let emptyState: { title: string; body: string } | null = null;

    if (
      isLearner.value &&
      !member.value?.is_cohort_manager &&
      !member.value?.is_cohort_learner
    ) {
      emptyState = {
        title: `No Feedback Requests`,
        body: "You don't have any feedback requests. Once you do we will notify you and they will show up here.",
      };
    } else if (isLearner.value && member.value?.is_cohort_learner) {
      emptyState = {
        title: `You don't have any new trainings.`,
        body: 'Once you do we will notify you and they will show up here.',
      };
    }

    columns.push({
      title: 'Actions',
      tasks: adminTasks,
      emptyState,
    });

    if (isLearner.value) {
      /**
       * Add learner feedback requests.
       */
      if (requests.value.length === 1) {
        const request = requests.value[0];

        let body = '',
          to: RouteLocationRaw = {};

        if (request.collaborator_id !== null) {
          body =
            request.type === 'training.pre'
              ? `${request.learner_full_name} is starting a new training and has requested your help.`
              : `${request.learner_full_name} has completed a training and requested your help.`;
          to = formatLink(request);
        } else {
          body = `Let us know how your ${request.skill?.name} training went.`;
          to = {
            name: 'FeedbackDetail',
            params: {
              feedbackId: request.id,
            },
          };
        }

        adminTasks.push({
          title: 'Provide Feedback',
          body,
          to,
          alert: formatAlert(request),
        });
      } else if (requests.value.length > 1) {
        adminTasks.push({
          title: 'Provide Feedback',
          body: 'You have several pending requests for feedback.',
          to: { name: 'Feedback' },
        });
      }

      /**
       * Add learner trainings to base actions.
       */
      trainings.value?.forEach((training: Item<Engagement | Assignment>) => {
        const trainingAction = getLearnerTrainingAction(training);

        if (trainingAction) {
          adminTasks.push(trainingAction);
        }
      });
    }

    if (member.value?.learner_cohorts?.length > 0 && !member.value?.assessed) {
      const oldestCohortLearnerRecord = sortBy(
        member.value?.learner_cohorts,
        'created'
      )?.[0];

      adminTasks.push({
        title: 'Take Assessment',
        body: `Complete your assessment to personalize your trainings.`,
        alert: oldestCohortLearnerRecord?.created
          ? DateTime.fromISO(oldestCohortLearnerRecord.created).toRelative() ??
            ''
          : '',
        to: {
          name: 'ProfileAssessment',
        },
      });
    }
  }

  function addHomeRequestActions(columns: IBaseTaskColumn[]) {
    if (isCoach.value && requests.value?.length) {
      const requestTasks: IBaseTask[] = [];
      columns.push({
        title: 'Requests',
        tasks: requestTasks,
      });
      // @ts-ignore
      const newRequests = requests.value?.filter((request: CoachingRequest) => {
        return !request.bids?.some((bid) => bid.coach_id === member.value?.id);
      });

      newRequests.slice(0, 3).forEach((request: CoachingRequest) => {
        requestTasks.push({
          key:
            String(request.id) +
            '-' +
            request.skill?.name +
            '-' +
            request.entity,
          title: request.skill?.name,
          body: request.timezoneTitle,
          to: { name: 'Request', params: { requestId: String(request.id) } },
        });
      });

      if (newRequests.length > 3) {
        requestTasks.push({
          title: 'All Requests',
          body: 'See all of your trainer requests in one place.',
          to: {
            name: 'RequestList',
          },
        });
      }
    }
  }

  function addHomeTrainingActions(columns: IBaseTaskColumn[]) {
    if (isCoach?.value) {
      const trainingTasks: IBaseTask[] = [];

      columns.push({
        title: 'Trainings',
        tasks: trainingTasks,
        emptyState: {
          title: `You don't have any new trainings.`,
          body: 'Once you do we will notify you and they will show up here.',
        },
      });

      if (trainings.value?.length) {
        const trainingsShown = trainings.value.length > 4 ? 3 : 4;

        trainings.value.slice(0, trainingsShown).forEach((training) => {
          let title = '',
            to = <RouteLocationRaw>{};

          if (
            training?.entity === 'engagement' &&
            training.completed === null
          ) {
            title = training.skill?.name;
            to = {
              name: 'Engagement',
              params: { engagementId: String(training.id) },
            };
          }
          const body = `with ${training?.otherUser(user.value)?.full_name}.`;
          const alert =
            DateTime.fromISO(String(training?.created)).toRelative() ?? '';

          if (title) {
            const task = <IBaseTask>{
              key:
                String(training?.id) +
                '-' +
                training?.skill.name +
                '-' +
                training?.entity,
              title,
              body,
              alert,
              to,
            };

            trainingTasks.push(task);
          }
        });

        if (trainings.value.length > 4) {
          trainingTasks.push({
            title: 'All Trainings',
            body: 'See all of your training assignments in one place.',
            to: {
              name: 'TrainingList',
            },
          });
        }
      }
    }
  }

  return {
    getLearnerTrainingAction,
    taskColumns,
    trainings,
  };
};

<script lang="ts" setup>
  import { computed, reactive } from 'vue';

  import { Coach } from '@orm-lib';
  import { UiButton, UiDropdown, UiForm, UiFormGroup } from '@ui-lib';

  interface IProps {
    id: number;
    employmentType: number;
  }
  interface IEmit {
    (e: 'update', coachId: number): void;
  }

  const props = defineProps<IProps>();
  const emit = defineEmits<IEmit>();
  const form = reactive({
    coach: {
      employmentType: props.employmentType,
      employmentTypeList: [
        { text: 'Full Time Employee', value: 1 },
        { text: 'Independent Contractor', value: 2 },
      ],
    },
  });

  async function onCoachSubmit() {
    await Coach.api().detail(props.id, form.coach.employmentType);
    emit('update', props.id);
  }

  const selected = computed(() => {
    return form.coach.employmentTypeList.find(
      (option) => option.value === form.coach.employmentType
    )?.text;
  });

  function selectType(type) {
    form.coach.employmentType = type;
  }
</script>

<template>
  <UiForm
    subtitle="Update the details of this user's account."
    title="User Details"
    variant="inline"
    class="px-0 py-2"
    @submit="onCoachSubmit"
  >
    <UiFormGroup style="width: 100%" title="Employment Type" class="!mb-0">
      <div class="flex w-full">
        <UiDropdown
          v-model="form.coach.employmentType"
          class="w-full"
          :options="form.coach.employmentTypeList"
          :text="selected"
          @select="selectType"
        />
        <UiButton
          class="square ml-2 !rounded-[5px]"
          variant="icon"
          type="submit"
        >
          <img
            src="/assets/img/icon/save.png"
            alt="Add Note"
            class="h-[44px] min-w-[44px] p-[14px]"
          />
        </UiButton>
      </div>
    </UiFormGroup>
  </UiForm>
</template>

<script lang="ts" setup>
  import { reactive, ref } from 'vue';
  import { helpers, required } from '@vuelidate/validators';
  import { useRepo } from 'pinia-orm';

  import { Recording } from '@orm-lib';
  import {
    UiButton,
    UiForm,
    UiFormGroup,
    UiFormInput,
    UiModal,
    useValidatedForm,
  } from '@ui-lib';

  interface IEmits {
    (e: 'hide', value: boolean): void;
    (e: 'delete', value: number | null): void;
  }
  const emit = defineEmits<IEmits>();
  const form = reactive({
    save: {
      name: null as null | string,
    },
  });
  const validations = reactive({
    save: {
      name: {
        required: helpers.withMessage('Recording name required', required),
      },
    },
  });
  const recordingId = ref<null | number>(null);
  const modal = reactive({
    show: false,
  });

  const { serviceError, v$, validate } = useValidatedForm(
    form.save,
    validations.save,
    onRecordingSave
  );

  function onModalHidden() {
    modal.show = false;
    emit('hide', true);
  }

  function onRecordingDelete() {
    const recording = useRepo(Recording).find(recordingId.value);
    Recording.api().remove(recording);

    emit('delete', recordingId.value);

    recordingId.value = null;
    modal.show = false;
  }

  async function onRecordingSave() {
    const recording = useRepo(Recording).find(recordingId.value);
    recording.name = form.save.name ?? '';

    await Recording.api().update(recording);

    recordingId.value = null;
    modal.show = false;
  }

  function show(shownId: number) {
    recordingId.value = shownId;
    const recording = useRepo(Recording).find(recordingId.value);
    form.save.name = recording?.name ?? null;
    modal.show = true;
  }

  defineExpose({ show });
</script>

<template>
  <UiModal v-if="modal.show" @close="onModalHidden">
    <div class="modal-container">
      <UiForm
        title="Save Recording"
        subtitle=" Use this form to update the name of this recording."
        class="position-relative !mb-0 !pb-0"
        variant="inline"
        :error="serviceError"
        @submit="validate"
      >
        <div class="form-container w-full" style="box-shadow: none">
          <UiFormGroup class="form-group-input !mb-4">
            <UiFormInput
              v-model="form.save.name"
              type="text"
              :errors="v$.name.$errors"
              placeholder="Enter recording name"
              @input="(event) => (form.save.name = event.target.value)"
            />
          </UiFormGroup>
          <UiFormGroup class="form-group-submit !mb-4 w-full">
            <UiButton
              type="submit"
              class="text self-start !rounded-[5px]"
              @click="form.save.submitted = true"
            >
              Save
            </UiButton>
          </UiFormGroup>
          <div
            style="width: 100%; background-color: #d7d7d7; height: 1px"
          ></div>
        </div>
      </UiForm>
      <UiForm
        class="position-relative"
        title="Delete Recording"
        variant="inline"
        subtitle="Use this form to delete this recoding. This cannot be undone."
        @submit="onRecordingDelete"
      >
        <div class="form-container w-full" style="box-shadow: none">
          <UiFormGroup class="form-group-submit !mb-0 w-full">
            <UiButton type="submit" class="text self-start !rounded-[5px]">
              Delete
            </UiButton>
          </UiFormGroup>
        </div>
      </UiForm>
    </div>
  </UiModal>
</template>

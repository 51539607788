<script lang="ts" setup>
  import { computed, reactive, ref } from 'vue';
  import { isAxiosError } from 'axios';
  import { isNil } from 'lodash';
  import { DateTime } from 'luxon';

  import { Learner, MeetingRecording } from '@orm-lib';
  import {
    UiAlert,
    UiButton,
    UiDatePicker,
    UiDropdown,
    UiFormInput,
    UiModal,
    UiTimePicker,
  } from '@ui-lib';
  import { IconBotModal } from '@/components';

  const props = defineProps<{
    meetingId?: number;
    meetingRecordingAvailable: boolean;
  }>();
  const emit = defineEmits<{
    (e: 'hideSchedulingModal'): void;
  }>();

  const selectedPlatform = ref('teams');
  const form = reactive({
    link: '',
    date: '',
    time: '',
  });
  const validationError = reactive({
    link: '',
    date: '',
    time: '',
  });
  const error = ref();

  const platformOptions = computed(() => {
    return [
      { text: 'Microsoft Teams', value: 'teams' },
      { text: 'Google Meet', value: 'meet' },
      { text: 'Zoom', value: 'zoom' },
    ];
  });

  const selectedText = computed(() => {
    return platformOptions.value.find(
      (option) => option.value === selectedPlatform.value
    )?.text;
  });

  const isCancellation = computed(() => {
    return !isNil(props.meetingId);
  });

  const linkPlaceholder = computed(() => {
    if (selectedPlatform.value === 'teams') {
      return 'https://teams.live.com/meet/123456789';
    }

    if (selectedPlatform.value === 'zoom') {
      return 'https://us05web.zoom.us/123456789';
    }

    return 'https://meet.google.com/123-456-789';
  });

  const inputtedIso = computed(() => {
    return DateTime.fromFormat(
      `${form.date} ${form.time}`,
      'yyyy-MM-dd h:m:ss'
    ).toISO();
  });

  async function cancelMeeting() {
    await MeetingRecording.api().cancel(props.meetingId ?? 0);
    emit('hideSchedulingModal');
    await Learner.api().user();
  }

  async function scheduleMeeting() {
    const formValidates = validateMeetingDetails();

    if (!formValidates) {
      return;
    }

    await MeetingRecording.api().schedule(form.link, inputtedIso.value);
    await MeetingRecording.api().list();
    emit('hideSchedulingModal');
  }

  function closeModal(event: MouseEvent) {
    if (
      event.target?.tagName?.toLowerCase() === 'body' ||
      event.target.closest?.('.datepicker') ||
      event?.target?.className?.includes?.('datepicker')
    ) {
      return;
    }

    emit('hideSchedulingModal');
  }

  function validateMeetingDetails() {
    let formValidates = false;

    if (!form.link) {
      validationError.link = 'Meeting link required';
    } else if (!form.date) {
      validationError.date = 'Date required';
    } else if (!form.time) {
      validationError.time = 'Time required';
    } else {
      formValidates = true;
    }

    return formValidates;
  }

  async function handleSubmit() {
    error.value = '';

    try {
      if (isCancellation.value) {
        await cancelMeeting();
        await MeetingRecording.api().list();
      } else {
        await scheduleMeeting();
      }
    } catch (e) {
      error.value =
        'There was an error scheduling Stagebot. Please try again later.';

      if (isAxiosError(e)) {
        const data = e?.response?.data;
        error.value = Object.values(data)?.[0]?.[0];
      }
    }
  }
</script>

<template>
  <UiModal
    class="[&>div>div>div]:rounded-[20px]"
    :close-on-click-outside="false"
    @close="emit('hideSchedulingModal')"
  >
    <form
      v-click-outside="closeModal"
      class="flex flex-col p-6"
      @submit.prevent="handleSubmit"
    >
      <IconBotModal class="mb-2" />
      <h2 class="text-body-primary text-lg font-semibold sm:text-2xl">
        {{ isCancellation ? 'Cancel Recording' : 'Schedule Recording' }}
      </h2>
      <UiAlert v-if="!!error" class="mb-4 w-full" type="danger">
        {{ error }}
      </UiAlert>
      <span v-else class="text-body-secondary mb-6 text-sm">
        {{
          isCancellation
            ? 'Remove Stagebot from this scheduled meeting'
            : 'Let Stagebot provide feedback on work meetings'
        }}
      </span>
      <template v-if="!isCancellation">
        <div class="form-label mb-1">
          <span>Meeting Platform</span>
          <span class="text-red-500">*</span>
        </div>
        <UiDropdown
          class="mb-5"
          offset="0"
          :options="platformOptions"
          :text="selectedText"
          @select="(selection) => (selectedPlatform = selection)"
        />
        <span v-if="!validationError.link" class="form-label mb-1">
          Meeting URL
        </span>
        <span v-else class="form-label mb-1 uppercase !text-red-500">
          {{ validationError.link }}
        </span>
        <UiFormInput
          v-model="form.link"
          :class="`mb-2.5 ${
            validationError.link && ' [&>div>input]:!border-red-500'
          }`"
          :placeholder="linkPlaceholder"
          type="text"
          @input="validationError.link = ''"
        />
        <span
          v-if="!validationError.date && !validationError.time"
          class="form-label mb-1"
        >
          Meeting Date & Time
        </span>
        <span v-else class="form-label mb-1 uppercase !text-red-500">
          {{ validationError.date || validationError.time }}
        </span>
        <div class="mb-8 flex flex-wrap gap-2 sm:flex-nowrap sm:gap-4">
          <UiDatePicker
            v-model="form.date"
            :class="`!max-w-full basis-full sm:basis-1/2 ${
              validationError.date && ' [&>div>input]:!border-red-500'
            }`"
            @focus="validationError.date = ''"
            @input="(value) => (form.date = value)"
          />
          <UiTimePicker
            v-model="form.time"
            :class="`!max-w-full basis-full sm:basis-1/2 ${
              validationError.time && ' [&>div>div>div>input]:!border-red-500'
            }`"
            @focus="validationError.time = ''"
            @input="(value) => (form.time = value)"
          />
        </div>
      </template>
      <div class="mb-4 flex justify-end">
        <UiButton
          variant="app-home"
          class="mr-4"
          color="white"
          @click="closeModal"
        >
          {{ isCancellation ? 'Back' : 'Cancel' }}
        </UiButton>
        <UiButton variant="app-home" type="submit">
          {{ isCancellation ? 'Confirm' : 'Schedule' }}
        </UiButton>
      </div>
    </form>
  </UiModal>
</template>

<style lang="css">
  .form-label {
    @apply text-header-primary text-sm font-medium;
  }
</style>

<script lang="ts" setup>
  import { useSlots } from 'vue';

  interface IProps {
    title?: string;
    subtitle?: string;
  }

  const props = defineProps<IProps>();
  const slots = useSlots();
</script>

<template>
  <div class="flex w-full shrink flex-col overflow-hidden">
    <div
      v-if="title"
      class="list-title text-header-primary overflow-hidden text-ellipsis text-left text-base font-semibold"
    >
      {{ title }}
    </div>
    <template v-if="slots.subtitle">
      <slot name="subtitle"></slot>
    </template>
    <div
      v-else-if="subtitle"
      class="overflow-hidden text-ellipsis text-left text-sm opacity-50"
    >
      {{ subtitle }}
    </div>
  </div>
</template>

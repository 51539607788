<script lang="ts" setup>
  import { computed, onBeforeUnmount, onMounted, reactive, watch } from 'vue';
  import AgoraRTC from 'agora-rtc-sdk-ng';

  import { UiButton, UiDropdown, UiFormGroup, UiFormInput } from '@ui-lib';
  import { useAgora } from '@/composables';

  interface IProps {
    cta: string;
    show: boolean;
    subtitle?: string;
    title?: string;
  }

  const props = withDefaults(defineProps<IProps>(), {
    cta: 'Select',
    show: true,
    title: 'Select Your Device',
  });
  const emit = defineEmits<{
    (e: 'onCTA', cb: () => void): void;
  }>();

  const { cameraEncoderConfig } = useAgora();

  const initialForm = {
    select: {
      audioDeviceId: null,
      audioDeviceList: [],
      videoDeviceId: null,
      videoDeviceList: [],
    },
  };
  const form = reactive(initialForm);

  const initialMedia = {
    audioDeviceId: null,
    audioTrack: null,
    stream: null,
    videoDeviceId: null,
    videoTrack: null,
  };
  const media = reactive(initialMedia);

  onBeforeUnmount(() => {
    if (media.audioTrack !== null) media.audioTrack.close();
    if (media.videoTrack !== null) media.videoTrack.close();
  });

  onMounted(() => {
    resetForm();
  });

  const codec = computed(() => {
    return import.meta.env.VITE_WEBRTC_CODEC;
  });

  const disableCTA = computed(() => {
    return media.stream === null;
  });

  const showSubtitle = computed(() => {
    return props.subtitle !== null;
  });

  const selectedCameraText = computed(() => {
    return form.select.videoDeviceList.find(
      (device) => device.value === form.select.videoDeviceId
    )?.text;
  });

  const selectedMicText = computed(() => {
    return form.select.audioDeviceList.find(
      (device) => device.value === form.select.audioDeviceId
    )?.text;
  });

  watch(
    () => form.select.audioDeviceId,
    async () => {
      await setAudioTrack();
    }
  );

  watch(
    () => form.select.videoDeviceId,
    async () => {
      await setVideoTrack();
    }
  );

  watch(
    () => props.show,
    async () => {
      if (props.show) {
        await resetForm();
      }
    }
  );

  async function setAudioTrack() {
    if (form.select.audioDeviceId === media.audioDeviceId) return;
    media.audioDeviceId = form.select.audioDeviceId;
    media.audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
      encoderConfig: 'music_standard',
      microphoneId: media.audioDeviceId,
    });
    await setStream();
  }

  async function setVideoTrack() {
    if (form.select.videoDeviceId === media.videoDeviceId) return;
    media.videoDeviceId = form.select.videoDeviceId;
    media.videoTrack = await AgoraRTC.createCameraVideoTrack({
      cameraId: media.videoDeviceId,
      encoderConfig: cameraEncoderConfig.value.high,
      optimizationMode: 'motion',
    });
    await setStream();
  }

  async function setStream() {
    if (media.videoTrack === null || media.audioTrack === null) return;
    media.stream = new MediaStream([
      media.audioTrack.getMediaStreamTrack(),
      media.videoTrack.getMediaStreamTrack(),
    ]);
  }

  function formatDeviceName(deviceName) {
    const parts = deviceName.split(')');
    let result = parts[0];
    if (parts.length > 1) result += ')';

    return result;
  }

  function onCTAClick() {
    emit('onCTA', {
      audioTrack: media.audioTrack,
      stream: media.stream,
      videoTrack: media.videoTrack,
    });
  }

  function selectCamera(camera: string) {
    form.select.videoDeviceId = camera;
  }

  function selectMic(mic: string) {
    form.select.audioDeviceId = mic;
  }

  async function resetForm() {
    Object.assign(form, initialForm);
    Object.assign(media, initialMedia);

    const microphones = await AgoraRTC.getMicrophones();
    microphones.forEach((microphone) => {
      if (form.select.audioDeviceId === null)
        form.select.audioDeviceId = microphone.deviceId;

      form.select.audioDeviceList.push({
        value: microphone.deviceId,
        text: formatDeviceName(microphone.label),
      });
    });

    const cameras = await AgoraRTC.getCameras();
    cameras.forEach((camera) => {
      if (form.select.videoDeviceId === null)
        form.select.videoDeviceId = camera.deviceId;

      form.select.videoDeviceList.push({
        value: camera.deviceId,
        text: formatDeviceName(camera.label),
      });
    });
  }
</script>

<template>
  <div v-if="show" class="page-container-public-form" style="margin: auto">
    <div class="form-panel max-w-[420px] rounded-[20px] p-5 shadow-lg">
      <div class="form-container position-relative w-full">
        <!-- Picking -->
        <div class="form-title">{{ title }}</div>
        <div v-if="showSubtitle" class="form-subtitle">{{ subtitle }}</div>
        <div class="form-group-title">Camera</div>
        <UiFormGroup class="form-group-input !mb-6 w-full">
          <UiDropdown
            v-model="form.select.videoDeviceId"
            :options="form.select.videoDeviceList"
            :text="selectedCameraText"
            @select="selectCamera"
          ></UiDropdown>
          <video
            :srcObject.prop.camel="media.stream"
            playsinline
            autoplay
            muted
            class="device-picker-camera w-full"
          ></video>
        </UiFormGroup>
        <div class="form-group-title">MICROPHONE</div>
        <UiFormGroup class="form-group-input !mb-6">
          <UiDropdown
            v-model="form.select.audioDeviceId"
            :options="form.select.audioDeviceList"
            :text="selectedMicText"
            @select="selectMic"
          ></UiDropdown>
        </UiFormGroup>
        <UiFormGroup class="form-group-submit !mb-0">
          <UiButton
            type="submit"
            class="!mb-0 self-start !rounded-[5px]"
            :disabled="disableCTA"
            @click="onCTAClick"
          >
            {{ cta }}
          </UiButton>
        </UiFormGroup>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .loading {
    height: 48px;
    width: 48px;
    margin: 20px;
    animation: rotation 2s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  video.device-picker-camera {
    transform: rotateY(180deg);
    max-width: 375px;
    margin-top: 10px;
    border-radius: 4px;
  }
</style>

import './main.css';

import { createApp } from 'vue';

import { globalCookiesConfig } from "vue3-cookies";
import VueApexCharts from 'vue3-apexcharts';
import VueClipboard from 'vue3-clipboard';
import UUID from 'vue3-uuid';
import * as Sentry from '@sentry/vue';
import vClickOutside from 'click-outside-vue3';

import { initPosthog } from '@ui-lib';

import App from '@/App.vue';
import router from '@/router';
import setupInterceptors from '@/services/setupInterceptors';

import { pinia } from './stores';

// Start Sentry
if (import.meta.env.VITE_ENV !== 'local') {
  Sentry.init({
    dsn: 'https://db95fabec9af435ab7dade7d2d583975@o335302.ingest.sentry.io/5355334',
    environment: import.meta.env.VITE_ENV,
  });
}

setupInterceptors(router);

// Init Cookies
globalCookiesConfig({
    expireTimes: "180d",
    path: "/",
    domain: import.meta.env.VITE_COOKIE_DOMAIN,
    secure: import.meta.env.VITE_ENV !== 'local',
    sameSite: "Lax",
});

// Init Posthog
initPosthog(import.meta.env.VITE_POSTHOG_TOKEN)

const app = createApp(App)
  .use(pinia)
  .use(UUID)
  .use(VueApexCharts)
  .use(VueClipboard)
  .use(vClickOutside)
  .use(router);

app.mount('#app');

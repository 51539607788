<script lang="ts" setup>
  import { computed, nextTick, onMounted, ref, watch } from 'vue';
  import Popper from 'vue3-popper';
  import { useRepo } from 'pinia-orm';

  import { Action } from '@orm-lib';
  import {
    IconDownChevron,
    IconHamburgerMenu,
    IconHamburgerMenuActionable,
    UiAvatar,
    UiLogo,
    UiNavbar,
    UiRouterLink,
    useMedia,
  } from '@ui-lib';
  import AppMobileMenu from '@/components/AppMobileMenu.vue';
  import AppProfileMenu from '@/components/AppProfileMenu.vue';
  import { useApp } from '@/composables';

  const { hasUpdate, isCoach, user } = useApp();
  const profileButton = ref();
  const isPopperOpen = ref(false);

  const hasActions = computed(() => {
    if (isCoach.value) {
      return false;
    }

    return !!useRepo(Action).withAll().get().length;
  });

  const mobileMenuVisible = ref(false);
  const canUseHamburger = ref(true);
  const popperMenuWidth = ref();

  const md = useMedia('md');

  const lg = useMedia('lg');

  function setPopperWidth() {
    popperMenuWidth.value = `${profileButton.value?.clientWidth}px`;
  }

  function handleMenuClose() {
    mobileMenuVisible.value = false;

    setTimeout(() => {
      canUseHamburger.value = true;
    }, 200);
  }

  function handleHamburgerClick(event: PointerEvent) {
    if (canUseHamburger.value || lg.value) {
      mobileMenuVisible.value = true;
    }

    if (!lg.value) {
      canUseHamburger.value = false;
    }
  }

  onMounted(() => {
    nextTick(() => {
      setPopperWidth();
    });
  });

  watch(
    () => user.value,
    () => {
      nextTick(() => {
        setPopperWidth();
      });
    }
  );
</script>

<template>
  <UiNavbar
    class="fixed top-0 z-10 w-full bg-white !py-[18px] !px-4 lg:relative lg:bg-transparent [&>.container]:!max-w-full [&>div>div:last-child]:flex [&>div>button]:hidden"
  >
    <template #logo>
      <!--  Necessary to push profile button to right side  -->
      <UiRouterLink
        v-if="!lg"
        class="flex flex-row items-center self-stretch hover:no-underline"
        :to="{ name: 'Home' }"
        aria-label="Backstage Home"
      >
        <UiLogo
          class="[&>h1]:text-brand-primary [&>h1]:text-xl"
          size="md"
          heading-color="black"
          :variant="'primary'"
        />
      </UiRouterLink>
      <div v-else></div>
    </template>
    <template #right-side>
      <div v-if="lg" class="-my-2 -mx-8 lg:mr-4">
        <Popper
          offset-distance="8"
          @open:popper="isPopperOpen = true"
          @close:popper="isPopperOpen = false"
        >
          <button
            ref="profileButton"
            class="navigation-button flex items-center justify-end rounded-lg py-2 pl-6 pr-4 hover:bg-[#E6EBF1] hover:outline-none hover:outline focus:bg-[#E6EBF1]"
          >
            <h1 class="mr-3 text-left text-sm font-semibold text-[#242431]">
              {{ user?.full_name }}
            </h1>
            <UiAvatar
              :user="user"
              :has-update="hasUpdate"
              show-update
              class="-mt-1"
            />
            <IconDownChevron
              :class="`ml-3 transition duration-100 ease-in-out ${
                isPopperOpen && 'rotate-180'
              }`"
            />
          </button>
          <template #content>
            <AppProfileMenu ref="popper" :style="{ width: popperMenuWidth }" />
          </template>
        </Popper>
      </div>
      <button
        v-else
        id="mobile-hamburger-menu"
        class="navigation-button hover:bg-page-gray -my-2 -ml-[38px] -mr-8 flex items-center justify-end self-center rounded-lg p-2 hover:outline hover:outline-transparent"
        @click="handleHamburgerClick"
      >
        <IconHamburgerMenu
          v-if="!hasActions && !hasUpdate"
          class="ml-[1px] -mr-[1px] block h-[20px] w-[20px]"
        />
        <IconHamburgerMenuActionable v-else class="ml-[1px] block" />
      </button>
      <AppMobileMenu v-if="mobileMenuVisible && !lg" @close="handleMenuClose" />
    </template>
  </UiNavbar>
</template>

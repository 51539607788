import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Str } from 'pinia-orm/dist/decorators';

export default class Invoice extends Model {
  static entity = 'invoice';

  @Str(null) declare id: string;
  @Str(null) declare number: string;
  @Str(null) declare due_date: string;
  @Str(null) declare total: any;
  @Str(null) declare payment_url: string;
  @Str(null) declare status: string;

  static api() {
    return {
      async list() {
        const { data } = await axios.get(`/invoice/`);

        const repo = useRepo(Invoice);
        repo.flush();
        repo.save(
          data.map((datum: Invoice) => {
            return {
              ...datum,
              id: datum.number,
            };
          })
        );
      },
    };
  }
}

<script lang="ts" setup>
  import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { throttle } from 'lodash';

  import { UiRouterLink, useMedia } from '@ui-lib';
  import AppBreadcrumbs from '@/components/AppBreadcrumbs.vue';
  import AppNavigation from '@/components/AppNavigation.vue';
  import AppSidebar from '@/components/AppSidebar.vue';
  import { useApp } from '@/composables';
  import { useAppStore } from '@/stores';
  import type { TBreadcrumb } from '@/types/components';

  const appStore = useAppStore();

  interface IProps {
    breadcrumbs?: TBreadcrumb[];
    isRebrand?: boolean;
    titleAction?: TBreadcrumb;
    title?: string;
    variant?: 'public' | 'form-flow' | 'standard' | 'two-column';
  }

  const props = withDefaults(defineProps<IProps>(), {
    breadcrumbs: undefined,
    isRebrand: false,
    titleAction: undefined,
    title: '',
    variant: 'standard',
  });

  const route = useRoute();
  const pageContent = ref();
  const barVariant = ref();
  const profileMenuHidden = ref(true);
  const lg = useMedia('lg');
  const isSidebarHidden = ref(false);

  const { isSidebarCollapsed } = useApp();

  const sideMargin = computed(() => {
    if (!lg.value) {
      return ' ml-0';
    }

    if (isSidebarCollapsed.value) {
      return ' ml-[81px]';
    }

    return ' ml-[276px]';
  });

  const pageWidth = computed(() => {
    if (!lg.value) {
      return 'w-full';
    }

    if (isSidebarCollapsed.value) {
      return 'w-[calc(100%-81px)]';
    }

    return 'w-[calc(100%-276px)]';
  });

  const showBreadcrumbBar = computed(() => {
    return props.breadcrumbs?.length || props.title || props.titleAction;
  });

  function setBreadcrumbWidth() {
    if (props.variant !== 'two-column') {
      return 'max-w-[630px]';
    }

    barVariant.value =
      pageContent.value.clientWidth >= 800 ? 'w-full' : 'max-w-[630px]';
  }

  function toggleProfileMenu() {
    profileMenuHidden.value = !profileMenuHidden.value;
  }

  function toggleSidebar() {
    appStore.toggleSidebar();
  }

  function setScreenSizeLayout() {
    setBreadcrumbWidth();

    isSidebarHidden.value = !lg.value;
  }

  const handleResize = throttle(setScreenSizeLayout, 200);

  onMounted(() => {
    setScreenSizeLayout();

    window.addEventListener('resize', handleResize);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
  });
</script>

<template>
  <div class="h-full w-full overflow-x-hidden overflow-y-visible">
    <AppNavigation
      @toggle-sidebar="toggleSidebar"
      @toggle-profile-menu="toggleProfileMenu"
    />
    <AppSidebar
      v-if="lg"
      :collapsed="isSidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
    />
    <slot name="modal"></slot>
    <div
      :class="`page-container mt-[58px] min-h-[calc(100vh-58px)] overflow-y-visible px-4 py-[29px] sm:mt-[70px] sm:min-h-[calc(100vh-70px)] sm:px-[24px] sm:py-[48px] md:px-[36px] md:py-[68px] lg:mt-0 lg:min-h-[calc(100vh-78px)] lg:px-[48px] lg:py-4 ${sideMargin} ${pageWidth} transition-all lg:duration-300 lg:ease-in-out`"
    >
      <div
        :class="`flex flex-col ${
          isRebrand
            ? `mx-auto transition-all lg:duration-300 lg:ease-in-out ${
                isSidebarCollapsed ? 'max-w-[1360px]' : 'max-w-[1160px]'
              }`
            : 'max-w-[1280px]'
        }`"
      >
        <div
          v-if="showBreadcrumbBar"
          :class="`mb-4 flex flex-row ${variant} ${barVariant}`"
        >
          <AppBreadcrumbs
            v-if="breadcrumbs?.length"
            :breadcrumbs="breadcrumbs"
          />
          <div v-if="!!title" class="page-title">
            {{ title }}
          </div>
          <UiRouterLink
            v-if="!!titleAction"
            :to="titleAction[1]"
            class="page-title-action cta-link"
          >
            {{ titleAction[0] }}
          </UiRouterLink>
        </div>
        <div
          ref="pageContent"
          class="page-content-wrapper justify between flex flex-row flex-wrap gap-x-5"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<template>
  <svg
    width="64"
    height="40"
    viewBox="0 0 64 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="40" rx="4" fill="#E8ECF4" />
    <rect x="5" y="5" width="14" height="10" rx="2" fill="white" />
    <rect y="28" width="64" height="6" fill="#5C6582" fill-opacity="0.2" />
  </svg>
</template>

import { uuid } from 'vue3-uuid';
import axios, { type AxiosProgressEvent, isAxiosError } from 'axios';
import { defineStore } from 'pinia';
import { type Item, useRepo } from 'pinia-orm';
import { posthog } from 'posthog-js';
import ReconnectingWebSocket from 'reconnecting-websocket';

import {
  Action,
  Assignment,
  Coach,
  Employer,
  Engagement,
  EngagementRecording,
  FeatureFlag,
  Learner,
  Organization,
  Recording,
  RecordingNote,
  SessionRecording,
  Skill,
  SkillFocus,
  TrainingRequest,
  Upload,
  User,
} from '@orm-lib';
import router from '@/router';
import { useResetStore } from '@/services/resetPinia';

let userSocket: ReconnectingWebSocket | null = null;
let userSocketDisconnected = false;

export type TAppState = {
  currentUpload: any;
  navCollapsed: boolean;
  hasUpdate: boolean;
  member_id: number | null;
  member_type: string | null;
  token: string | null;
  uploadQueue: any[];
  user_id: number | null;
};

export const useAppStore = defineStore('app', {
  state: () =>
    <TAppState>{
      currentUpload: null,
      hasUpdate: false,
      navCollapsed: false,
      member_id: parseInt(localStorage.getItem('member_id') ?? '') || null,
      member_type: localStorage.getItem('member_type') || null,
      token: localStorage.getItem('token') || null,
      uploadQueue: [],
      user_id: parseInt(localStorage.getItem('user_id') ?? '') || null,
    },
  actions: {
    toggleSidebar() {
      this.navCollapsed = !this.navCollapsed;
    },
    async clearModel() {
      const resetStore = useResetStore();
      resetStore.all();

      if (this.isDesktop) {
        window.electron.ipcRenderer.send('electron-ready', false);
      }
    },
    async clearToken() {
      await this.removeTokenAndUserId();
      await this.ensureUserSocket();
    },
    async ensureUserSocket() {
      // Make sure the user is authenticated
      if (!this.isAuthenticated) {
        return;
      }

      if (this.isAuthenticated && userSocket === null) {
        userSocketDisconnected = false;

        const urlProvider = async () => {
          const ticketResponse = await axios.get('/ticket');
          return `${import.meta.env.VITE_WEBSOCKET_BASE_URL}/user/${
            this.user_id
          }?ticket=${ticketResponse.data.uuid}`;
        };

        userSocket = new ReconnectingWebSocket(urlProvider, undefined, {
          connectionTimeout: 20000,
        });
        userSocket.addEventListener('open', () => {
          if (userSocketDisconnected) {
            this.loadModel();
            userSocketDisconnected = false;
          }
        });

        userSocket.addEventListener('close', () => {
          userSocketDisconnected = true;
        });

        userSocket.addEventListener('message', (event) => {
          const message = JSON.parse(event.data);

          if (message.object === 'Upload') {
            Upload.handleModelMessage(message);
          } else if (message.object === 'Engagement') {
            Engagement.handleModelMessage(message);
          } else if (message.object === 'EngagementRecording') {
            EngagementRecording.handleModelMessage(message);
          } else if (message.object === 'Recording') {
            Recording.handleModelMessage(message);
          } else if (message.object === 'RecordingNote') {
            RecordingNote.handleModelMessage(message);
          } else if (message.object === 'SessionRecording') {
            SessionRecording.handleModelMessage(message);
          } else if (message.object === 'User') {
            User.handleModelMessage(message);
          } else if (message.object === 'Assignment') {
            Assignment.handleModelMessage(message);
          }
        });
      } else if (!this.isAuthenticated && userSocket !== null) {
        userSocket.close();
        userSocket = null;
        userSocketDisconnected = false;
      }
    },
    async loadDeepLink(deepLinkUrl: string) {
      const deepLinkPath = new URL(deepLinkUrl.toLowerCase().trim()).pathname;
      const deepLinkParts = deepLinkPath.split('/').filter((el) => {
        return el !== '';
      });

      const resolution = router.resolve(deepLinkPath);

      // @ts-expect-error
      if (resolution.route.redirectedFrom === undefined) {
        // @ts-expect-error
        await router.push(resolution.route).catch(() => {});
        return;
      }

      // Old Routes
      if (deepLinkParts.length >= 3 && deepLinkParts[0] === 'engagement') {
        const engagementId = Number(deepLinkParts[1]);
        let validEngagement = true;

        try {
          await Engagement.api().detail(engagementId);
        } catch {
          validEngagement = false;
        }

        if (validEngagement) {
          let route = null;

          if (deepLinkParts[2] === 'recording') {
            const recordingId = Number(deepLinkParts[3]);
            route = {
              name: 'LearnerRecording',
              params: { engagementId: engagementId, recordingId: recordingId },
            };
          } else if (deepLinkParts[2] === 'session') {
            route = { name: 'Session', params: { engagementId: engagementId } };
          }

          if (route != null) {
            await router.push(route).catch(() => {});
          }
        }
      }
    },
    removeMember() {
      this.member_id = null;
      this.member_type = null;

      localStorage.removeItem('member_id');
      localStorage.removeItem('member_type');
    },
    async loadModel() {
      // Make sure the user is authenticated
      if (!this.isAuthenticated) {
        return;
      }

      try {
        await Promise.allSettled([
          User.api().detail(),
          Learner.api().user(),
          Coach.api().user(),
          Skill.api().list(),
          SkillFocus.api().list(),
          Engagement.api().list(),
          Action.api().list(),
          FeatureFlag.api().list(),
        ]);
      } catch (e) {
        // Ignore error for now
      }

      // Get the user, coach and learner records for this user.

      // Clear the existing member if it doesn't have a corresponding record
      if (this.hasMember) {
        if (this.member_type === 'coach') {
          if (
            useRepo(Coach)
              .where('id', this.member_id)
              .where('user_id', this.user_id)
              .first()
          ) {
            this.removeMember();
          }
        } else if (this.member_type === 'learner') {
          if (
            useRepo(Learner)
              .where('id', this.member_id)
              .where('user_id', this.user_id)
              .first()
          ) {
            this.removeMember();
          }
        }
      }

      // Auto-select the member if it isn't already set
      if (!this.hasMember) {
        let member_id = null;
        let member_type = null;

        let member: Item<Coach> | Item<Learner> = useRepo(Coach)
          .where('user_id', this.user_id)
          .first();

        if (member !== null) {
          member_id = member.id;
          member_type = 'coach';
        } else {
          member = useRepo(Learner).where('user_id', this.user_id).first();
          if (member !== null) {
            member_id = member.id;
            member_type = 'learner';
          }
        }

        if (member !== null) {
          this.member_id = parseInt(String(member_id));
          this.member_type = member_type;

          localStorage.setItem('member_id', String(member_id));
          localStorage.setItem('member_type', String(member_type));
        }
      }

      if (this.isAuthenticated && this.hasMember) {
        if (this.isDesktop) {
          window.electron.ipcRenderer.send('electron-ready', true);
        }
      }

      // Learner administrator specific requests go here
      if (this.isAdmin && this.member_type === 'learner') {
        await TrainingRequest.api().adminList();
      }
      // Group owner specific request go here
      if (this.isGroupOwner) {
        await TrainingRequest.api().list(this.member.owned_group.id);
      }
      if (this.member_type === 'learner') {
        await Assignment.api().list();
      }
    },
    async login(user: { username: string; password: string }) {
      let response;

      try {
        response = await axios.post('/token', user);
      } catch (e) {
        await this.removeTokenAndUserId();
        await this.clearModel();
        throw e;
      }

      await this.setTokenAndLoadModel(
        response.data.token,
        response.data.user.id
      );
    },
    async setTokenAndLoadModel(token: string, userId: string) {
      try {
        if (this.isAuthenticated) {
          await this.logout();
        }

        await this.setTokenAndUserId({
          token: token,
          user_id: userId,
        });

        await this.ensureUserSocket();
        await this.reloadModel();

        const appStore = useAppStore();

        let email_campaign = null
        if (appStore.employer !== null) {
            email_campaign = appStore.employer.email_campaign
            posthog.group('employer', appStore.employer.id.toString(), {
                name: appStore.employer.name,
                email_campaign: appStore.employer.email_campaign
            });
        }

        if (appStore.user !== null) {
            posthog.identify(
                appStore.user.id.toString(),
                {
                    email: appStore.user.email,
                    email_campaign: email_campaign,
                    first_name: appStore.user.first_name,
                    last_name: appStore.user.last_name
                }
            )
        }
      } catch (e) {
        console.error(e);
      }
    },
    async logout() {
      if (this.token === null) {
        return;
      }

      try {
        await axios.delete('/token');
      } catch (e) {
        if (isAxiosError(e) && e.response?.status === 401) {
          await this.removeTokenAndUserId();
          this.removeMember();
          await this.clearModel();
        } else {
          throw e;
        }
      }

      await this.removeTokenAndUserId();
      this.removeMember();

      await this.ensureUserSocket();
      await this.clearModel();
    },
    setNextUpload() {
      if (this.currentUpload !== null || this.uploadQueue.length === 0) {
        return;
      }

      if (this.isDesktop) {
        window.electron.ipcRenderer.send(
          'upload-queue-length-changed',
          this.uploadQueue.length
        );
      }

      this.currentUpload = this.uploadQueue.shift();
    },
    async processUploadQueue() {
      if (this.uploadQueue.length === 0 || this.currentUpload !== null) {
        return;
      }

      this.setNextUpload();
      const formData = new FormData();
      formData.append('name', this.currentUpload.payload.name);
      formData.append('file', this.currentUpload.payload.file);

      await axios.post(
        `engagement/${this.currentUpload.payload.engagementId}/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            this.currentUpload.percentComplete = Math.round(
              (progressEvent.loaded /
                (progressEvent.total ?? progressEvent.loaded)) *
                100
            );
          },
        }
      );

      this.clearCurrentUpload();
      await this.processUploadQueue();
    },
    clearCurrentUpload() {
      this.currentUpload = null;

      if (this.isDesktop) {
        window.electron.ipcRenderer.send(
          'upload-queue-length-changed',
          this.uploadQueue.length
        );
      }
    },
    async queueUpload(payload: any) {
      const newUpload = {
        id: uuid.v4(),
        percentComplete: 0,
        payload: payload,
      };
      this.uploadQueue.push(newUpload);
      await this.processUploadQueue();
    },
    async reloadModel() {
      await this.clearModel();
      await this.loadModel();
    },
    async removeTokenAndUserId() {
        if (this.token !== null)
            posthog.reset();

        this.token = null;
        this.user_id = null;

        localStorage.removeItem('token');
        localStorage.removeItem('user_id');

        if (this.isDesktop) {
            window.electron.ipcRenderer.send('electron-ready', false);
        }
    },
    async setTokenAndUserId(payload: any) {
      this.token = payload.token;
      this.user_id = Number(payload.user_id);

      localStorage.setItem('token', payload.token);
      localStorage.setItem('user_id', payload.user_id);
    },
  },
  getters: {
    hasMember(state): boolean {
      return state.member_id !== null && state.member_type !== null;
    },
    isAuthenticated(state) {
      return state.token !== null && state.token !== undefined;
    },
    isAdmin() {
      // @ts-expect-error
      return this.member?.isAdmin ?? false;
    },
    isGroupOwner() {
      return !!this.member?.owned_group;
    },
    isCoach(state) {
      return state.member_type === 'coach';
    },
    isDesktop() {
      return import.meta.env.VITE_IS_DESKTOP === 'true';
    },
    isLearner(state) {
      return state.member_type === 'learner';
    },
    employer(): Item<Employer> {
      const employerRepo = useRepo(Employer);

      return employerRepo.withAllRecursive().first();
    },
    member(state): Item<Learner> | Item<Coach> {
      if (this.hasMember) {
        if (state.member_type === 'coach') {
          // @ts-ignore
          return useRepo(Coach).withAllRecursive().find(state.member_id);
        } else if (state.member_type === 'learner') {
          // @ts-ignore
          return useRepo(Learner).withAllRecursive().find(state.member_id);
        }
      }
      return null;
    },
    memberOf(state): Item<Organization> | Item<Employer> {
      if (this.hasMember) {
        if (state.member_type === 'coach') {
          return this.member?.organization;
        } else if (this.member_type === 'learner') {
          return this.member?.employer;
        }
      }
      return null;
    },
    user(state) {
      if (state.user_id === null) {
        return null;
      }

      return useRepo(User).find(state.user_id);
    },
    isBillable(): boolean {
      return this.employer?.billing_status === 'billable';
    },
  },
});

import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import Employer from './employer';
import Learner from './learner';

export default class EmployerAdmin extends Model {
  static entity = 'employer-admin';

  @Num(null) declare id: number;
  @Str('') declare created: string;
  @BelongsTo(() => Employer, 'employer_id')
  declare employer: Employer[];
  @Num(null) declare employer_id: number;
  @BelongsTo(() => Learner, 'learner_id')
  declare learner: Learner[];
  @Num(null) declare learner_id: number;
  @Num(null) declare unstarted_assignment_count: number;

  static api() {
    return {
      async add(learner_id: number) {
        const payload = {
          learner_id: learner_id,
        };
        const { data } = await axios.post(`/employer-admin`, payload);
        const repo = useRepo(EmployerAdmin);
        repo.save(data);
      },
      async invite(email: string) {
        const payload = {
          email: email,
        };
        const { data } = await axios.post(`/employer-admin/invite`, payload);
        const repo = useRepo(EmployerAdmin);
        repo.save(data);
      },
      async reinvite(id: number) {
        await axios.get(`/employer-admin/${id}/reinvite`);
      },
      async remove(id: number) {
        await axios.delete(`/employer-admin/${id}`);
        const repo = useRepo(EmployerAdmin);
        repo.destroy(id);
      },
    };
  }
}

<script lang="ts" setup>
  import { computed, onBeforeMount, reactive, ref } from 'vue';
  import { DateTime } from 'luxon';

  import { Skill } from '@orm-lib';
  import { UiDropdown, UiForm, UiFormGroup, UiFormNavigation } from '@ui-lib';

  interface IProps {
    maxSteps: number;
    skill: typeof Skill;
    skillId?: number;
    timezone?: string;
    skillSelectDisabled?: boolean;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<{
    (e: 'next', form: any): void;
    (e: 'prev'): void;
  }>();
  const error = ref('');

  const form = reactive({
    skill: 0 as number | null,
    submitted: false,
    timezone: '',
    timezoneOptions: [],
  });

  function next() {
    if (!form.skill) {
      error.value = 'Please select a skill';
      return;
    }

    const updatedForm = {
      timezone: form.timezone,
      skill_id: form.skill,
    };
    emit('next', updatedForm);
  }

  const skillOptions = computed(() => {
    return Skill.getAsOptions();
  });

  onBeforeMount(async () => {
    form.skill = props?.skillId ?? null;

    form.timezone = props.timezone ?? DateTime.local().toFormat('z');

    let timezones;
    try {
      const usEG = new Intl.Locale('en-US');
      timezones = usEG?.timeZones;
    } catch (e) {
      // Fallback if Inl.Locale timezones not supported
      timezones = (Intl as any).supportedValuesOf('timeZone');
    }

    // Add local timezone to list, if not in US list.
    if (!timezones.includes(DateTime.local().toFormat('z'))) {
      timezones.push(DateTime.local().toFormat('z'));
    }

    form.timezoneOptions = timezones.map((name) => {
      const shortName = DateTime.fromObject(
        { year: 2022 },
        { zone: name }
      ).toFormat('ZZZZ');

      return {
        value: name,
        text: `${name} (${shortName})`,
      };
    });
  });

  const selectedText = computed(() => {
    return (
      skillOptions.value.find((option) => option.value === form.skill)?.text ??
      'Select a skill'
    );
  });

  function setSkill(skill: number) {
    error.value = '';
    form.skill = skill;
  }

  function setTimezone(timezone: string) {
    form.timezone = timezone;
  }
</script>

<template>
  <UiForm
    show-prev
    prev-text="Cancel"
    title="Skill"
    variant="config"
    @submit="next"
    @prev="emit('prev')"
  >
    <template #header>
      <slot />
    </template>
    <div v-if="!error" class="form-group-title self-start">Skill</div>
    <div
      v-else
      class="mb-1 self-start text-sm font-semibold uppercase text-red-500"
    >
      Skill is required
    </div>
    <UiFormGroup class="form-group-input mb-6">
      <UiDropdown
        :text="selectedText"
        class="form-select"
        :options="skillOptions"
        :disabled="skillSelectDisabled"
        @select="setSkill"
      />
    </UiFormGroup>
    <div class="text-header-primary mb-1.5 self-start font-semibold">
      {{ skill?.tagline }}
    </div>
    <div class="text-body-secondary mb-6 self-start">
      {{ skill?.description }}
    </div>
    <div class="form-group-title self-start">Timezone</div>
    <UiFormGroup class="form-group-input !mb-4">
      <UiDropdown
        :text="form.timezone"
        class="form-select"
        :options="form.timezoneOptions"
        @select="setTimezone"
      />
    </UiFormGroup>
    <UiFormNavigation
      class="mb-0 mt-6"
      show-previous
      prev-text="Cancel"
      submit-text="Next Step"
      @prev="emit('prev')"
    >
      <span class="text-body-secondary">Step 1/{{ maxSteps }}</span>
    </UiFormNavigation>
  </UiForm>
</template>

<style scoped>
  .form-select {
    color: #808080;
    font-size: 15px;
  }
</style>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import {
    AngleLeft,
    AngleRight,
} from "@/components/icons";

const router = useRouter();

interface IProps {
    direction?: 'left' | 'right' | null;
    text?: string;
    to?: object | null;
}

const props = withDefaults(defineProps<IProps>(), {
    direction: null,
    text: 'Button',
    to: null
});

interface IEmits {
    (e: 'click'): void;
}

const emit = defineEmits<IEmits>();

const buttonClass = computed(() => {
    let result = ''

    // padding
    if (props.direction === null) {
        result = result + ' px-[16px]'
    } else if (props.direction === 'right') {
        result = result + ' pl-[16px] pr-[8px]'
    } else if (props.direction === 'left') {
        result = result + ' pl-[8px] pr-[16px]'
    }

    return result
})

const iconClass = computed(() => {
    return 'stroke-primary w-[24px] h-[24px] opacity-0 transition duration-[50ms]'
})

function handleClick() {
    if (props.to !== null) {
        router.push(props.to)
    } else {
        emit('click')
    }
}
</script>

<template>
    <button
        class="link-button"
        :class="buttonClass" @click="handleClick"
    >
        <div class="flex flex-row items-center gap-[2px]">
            <AngleLeft v-if="props.direction === 'left'" :class="iconClass" />
            {{ props.text }}
            <AngleRight v-if="props.direction === 'right'" :class="iconClass" />
        </div>
    </button>
</template>

<style scoped>
    .link-button {
        @apply box-border font-[400] rounded-[8px] transition duration-[50ms] text-darkGrey h-[44px] text-[14px]
    }

    .link-button:focus-visible {
        @apply outline-golden outline-dashed outline-offset-[2px];
    }

    .link-button:focus-visible,
    .link-button:hover {
        @apply text-primary
    }

    .link-button:focus-visible svg,
    .link-button:hover svg {
        @apply opacity-100
    }
</style>
import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Bool, HasOne, Num, Str } from 'pinia-orm/dist/decorators';
import slugify from 'slugify';

import SkillFocus from './skill_focus';

export default class Skill extends Model {
  static entity = 'skill';

  @Num(null) declare id: number;
  @Str(null) declare background: string;
  @Str('') declare confidence_question: string;
  @Num(0) declare focus_id: number;
  @HasOne(() => SkillFocus, 'id', 'focus_id') declare focus: SkillFocus | null;
  @Str('') declare description: string;
  @Str('') declare name: string;
  @Str('') declare tagline: string;
  @Bool(false) declare is_bot_contextualized: boolean;

  get slug() {
    return slugify(this.name, { lower: true });
  }

  get detailPath() {
    return `/skill/${this.id}/${this.slug}`;
  }

  get groupColor() {
    const colorMap = <Record<number, string>>{
      1: '#6D8DFF',
      2: '#907EFF',
      3: '#5DB2D6',
    };

    return colorMap[this.focus_id] ? colorMap[this.focus_id] : '#6D8DFF';
  }

  static api() {
    return {
      async list() {
        const { data } = await axios.get('/skill');
        const repo = useRepo(Skill);
        repo.save(data);
      },
    };
  }

  static getAsOptions(allowAny = false, text = 'Allow the learner to choose') {
    let skillOptions = <{ value: null | number; text: string }[]>[];

    useRepo(Skill)
      .orderBy('name')
      .get()
      .forEach((skill) => {
        skillOptions.push({
          value: skill.id,
          text: skill.name,
        });
      });

    if (allowAny) {
      skillOptions.push({ value: null, text: text });
    }

    return skillOptions;
  }
}

<script lang="ts" setup>
import { reactive, ref } from "vue";
import { useRouter } from 'vue-router';

import type { AxiosError } from "axios";

import { User } from "@orm-lib";

import type { ResetPasswordFormPayload } from '@/components/public/types';
import { ResetPasswordFormPayloadCreate } from '@/components/public/types';
import { FormBase, TextRow } from './'

type Nullable<T> = T | null;

const props = defineProps<{
    token: string;
    userId: number;
}>();

interface IEmits {
    (e: 'submit', value:ResetPasswordFormPayload): void;
}

const emit = defineEmits<IEmits>();

const router = useRouter();

const errorMessage = ref<string | null>(null)

const data = reactive(ResetPasswordFormPayloadCreate())

const confirmationInput = ref<typeof TextRow | null>(null)
const passwordInput = ref<typeof TextRow | null>(null)
const inputs = [
    confirmationInput,
    passwordInput
]

function handleConfirmationInputChange(inputValue: Nullable<string>) {
    if (inputValue !== null)
        data.confirmation = inputValue
}

function handleForgot() {
    router.push({
        name: 'LoginForgot',
    })
}

function handlePasswordInputChange(inputValue: Nullable<string>) {
    if (inputValue !== null)
        data.password = inputValue
}

async function handleSubmit() {
    let errorCount = 0;

    inputs.forEach((input) => {
        let error = input.value?.validate()
        if (error !== null) {
            errorCount += 1;
        }
    });

    if (errorCount === 0) {
        if (data.password !== data.confirmation) {
            errorCount = 1
            errorMessage.value = 'Your new password and password confirmation do not match.'
        }
    }

    if (errorCount !== 0)
        return

    try {
        await User.api().reset(props.userId, props.token, data.password);
        errorMessage.value = null
    } catch(e) {
        errorCount = 1
        const axiosError = e as AxiosError
        const errors = axiosError.response?.data as object
        errorMessage.value = Object.values(errors)[0][0]
    }

    if (errorCount === 0) {
         emit('submit', data)
    }
}
</script>

<template>
    <FormBase
        :error-message=errorMessage
        mode=reset-password
        title="Reset Password"
        subtitle="Create a new password for your Backstage account."
        @forgot=handleForgot
        @submit=handleSubmit
    >
        <TextRow
            id="reset-password-password"
            ref="passwordInput"
            :required="true"
            title="New Password"
            subtitle="Your new password must be at least 8 characters long and cannot contain common words including your first name, last name and email address."
            type="password"
            @change=handlePasswordInputChange
            @submit=handleSubmit
        />
        <TextRow
            id="reset-password-confirmation"
            ref="confirmationInput"
            :required="true"
            title="Password Confirmation"
            type="password"
            @change=handleConfirmationInputChange
            @submit=handleSubmit
        />
    </FormBase>
</template>
<script lang="ts" setup>
  import { computed, reactive, ref } from 'vue';
  import type { AssessmentQuestion } from '@orm-lib';
  import { isNil } from 'lodash';

  import { UiForm, UiFormNavigation } from '@ui-lib';
  import AppCheckboxGroup from '@/components/AppCheckboxGroup.vue';
  import type { IAssessmentShortForm, IOnboardingForm } from '@/types';

  const props = withDefaults(
    defineProps<{
      form: IOnboardingForm | IAssessmentShortForm;
      error?: string;
      isFinalStep?: boolean;
      question: AssessmentQuestion;
      flow?: 'onboarding' | 'assessment' | 'create' | 'goal';
    }>(),
    { flow: 'assessment' }
  );
  const emit = defineEmits<{
    (e: 'prev'): void;
    (e: 'next', form: Partial<IOnboardingForm>): void;
    (e: 'complete', form: Partial<IOnboardingForm>): void;
  }>();

  const resultProp = ref(
    props.flow === 'assessment' ||
      props.flow === 'create' ||
      props.flow === 'goal'
      ? 'assessment_result'
      : 'cohort_assessment_result'
  );

  const localForm = reactive(props.form);

  const error = ref();

  const initialChecked = localForm[resultProp.value]?.items
    ?.filter(
      (item) =>
        !!props.question.answers?.find((answer) => answer.id === item.answer_id)
    )
    ?.map((item) => item.answer_id);

  const isMultipleChoice = computed(
    () => props.question?.type === 'choice.multiple'
  );

  const localValue = ref(
    isMultipleChoice.value ? initialChecked : initialChecked?.[0] ?? null
  );

  function submit() {
    if (
      (Array.isArray(localValue?.value) && !localValue?.value?.length) ||
      isNil(localValue.value)
    ) {
      return (error.value = `Please ${
        isMultipleChoice.value
          ? 'select at least one option'
          : 'make a selection'
      }.`);
    }

    /**
     * Items checked.
     */
    const newItems = Array.isArray(localValue?.value)
      ? localValue.value
      : [localValue.value];

    /**
     * Removes items from form that were initially selected
     */
    const filteredPrevStateItems = localForm[resultProp.value]?.items
      ?.filter((item) => !initialChecked?.includes(item.answer_id))
      .map((item) => item.answer_id);

    /**
     * Formats items that were
     */
    const formattedItems = filteredPrevStateItems
      ?.concat(newItems)
      .map((item) => ({ answer_id: item }));
    if (props.isFinalStep && props.flow === 'goal') {
      emit('complete', {
        [resultProp.value]: {
          assessment_id: localForm[resultProp.value]?.assessment_id,
          items: formattedItems,
        },
      });
    } else {
      emit('next', {
        [resultProp.value]: {
          assessment_id: localForm[resultProp.value]?.assessment_id,
          items: formattedItems,
        },
      });
    }
  }

  function handleCheckItem(checked: boolean, value: number) {
    if (!isMultipleChoice.value && checked) {
      localValue.value = value;
      return;
    }

    if (!isMultipleChoice.value) {
      localValue.value = null;
      return;
    }

    if (checked && localValue.value?.length) {
      localValue.value?.push(value);
      return;
    }

    if (checked && !localValue.value?.length) {
      localValue.value = [value];
      return;
    }

    localValue.value = localValue.value.filter((val) => value !== val);
  }
</script>

<template>
  <UiForm
    :title="flow !== 'goal' ? `Cohort Assessment` : null"
    :error="props.error || error"
    :variant="flow === 'goal' ? 'app-config' : 'config'"
    @submit="submit"
  >
    <template v-if="flow !== 'goal'" #header>
      <slot />
    </template>
    <div class="form-question">
      <h3 class="text-base font-semibold text-[#242431] md:text-lg">
        {{ question?.title }}
      </h3>
      <span class="font-normal text-[#5C6582]">
        {{ isMultipleChoice ? 'Check all that apply.' : 'Check one.' }}
      </span>
    </div>
    <AppCheckboxGroup
      v-model="localValue"
      :is-multiple="isMultipleChoice"
      :options="question?.answers"
      @input="handleCheckItem"
    />
    <UiFormNavigation
      class="mb-0"
      show-previous
      prev-text="Go Back"
      :submit-text="
        isFinalStep && (flow === 'assessment' || flow === 'goal')
          ? 'Complete'
          : 'Next Step'
      "
      @prev="emit('prev')"
    >
      <template v-if="flow === 'goal'">
        <slot></slot>
      </template>
    </UiFormNavigation>
  </UiForm>
</template>

<style scoped>
  div.question {
    margin-bottom: 16px;
    font-size: 14px;
  }
</style>

<script lang="ts" setup>
  import { computed } from 'vue';
  import { DateTime } from 'luxon';

  import { Assignment, Engagement } from '@orm-lib';
  import AppPanel from '@/components/AppPanel.vue';
  import PanelAccordion from '@/components/PanelAccordion.vue';
  import PanelListItem from '@/components/PanelListItem.vue';
  import { useApp } from '@/composables';

  const { isCoach, isLearner, member, user } = useApp();

  const props = defineProps<{
    initial?: string;
  }>();

  const completed = computed(() => {
    const unsortedCompleted = member.value?.completedEngagements
      .orderBy('created', 'desc')
      .get()
      .slice();
    return unsortedCompleted?.sort(
      (a, b) => DateTime.fromISO(b.completed) - DateTime.fromISO(a.completed)
    );
  });

  const completedList = computed(() => {
    return completed.value?.map((assignment) => formatListItem(assignment));
  });

  const inProgress = computed(() => {
    const unsortedInProgress = member.value?.inProgressEngagements
      .orderBy('created', 'desc')
      .get()
      .slice();
    return unsortedInProgress?.sort(
      (a, b) => DateTime.fromISO(b.created) - DateTime.fromISO(a.created)
    );
  });

  const inProgressList = computed(() => {
    return inProgress.value?.map((assignment) => formatListItem(assignment));
  });

  const showCompleted = computed(() => {
    return completed.value?.length ?? 0 > 0;
  });

  const showInProgress = computed(() => {
    return inProgress.value?.length ?? 0 > 0;
  });

  const showNoTrainings = computed(() => {
    return !showInProgress.value && !showCompleted.value;
  });

  function formatListItem(assignment: Assignment) {
    return {
      id: assignment.id,
      title: formatTitle(assignment),
      subtitle: isCoach.value
        ? `with ${assignment?.otherUser(user.value)?.full_name}`
        : '',
      alert: formatAlert(assignment),
      tag: formatTag(assignment),
      to: formatLink(assignment),
      training: assignment,
      started: assignment.started,
    };
  }

  function isAssignment(assignment: Assignment) {
    return assignment.entity === 'assignment';
  }

  function isEngagement(assignment: Assignment) {
    return assignment.entity === 'engagement';
  }

  function formatAlert(assignment: Assignment) {
    return isAssignment(assignment)
      ? DateTime.fromISO(assignment.created).toRelative()
      : '';
  }

  function formatTag(assignment: Assignment) {
    if (isAssignment(assignment)) {
      return '';
    }

    return assignment.completed
      ? DateTime.fromISO(assignment.completed).toRelative() ?? ''
      : DateTime.fromISO(assignment.created).toRelative() ?? '';
  }

  function formatTitle(assignment: Assignment) {
    let title = 'Unknown Type';

    if (isAssignment(assignment)) {
      title = assignment?.name;
    } else if (isEngagement(assignment)) {
      title = assignment.skill?.name;
    }

    return title;
  }

  function formatLink(assignment: Assignment) {
    if (isAssignment(assignment)) {
      return [
        'Assignment',
        {
          name: 'Assignment',
          params: {
            assignmentId: assignment.id,
            skillId: assignment.skill?.id ?? null,
          },
        },
      ];
    }

    return ['Engagement', assignment.detailTo];
  }

  function startOpen(id: number) {
    if (!props.initial) {
      return false;
    }
    const splitString = props.initial.split(':');

    return splitString?.[1] === id.toString();
  }
</script>

<template>
  <div class="form-panel-column">
    <AppPanel
      :title="showNoTrainings ? 'No Trainings' : 'All Trainings'"
      :variant="showNoTrainings ? 'empty' : 'primary'"
      :subtitle="
        showNoTrainings
          ? 'You don\'t have any trainings. Once you do we will notify you and they will show up here.'
          : 'Access all of your trainings in the list below.'
      "
    >
      <div class="form-list full-width">
        <!-- In Progress -->
        <div v-if="showInProgress">
          <div class="page-section">In Progress</div>
          <div class="form-list flex-column">
            <PanelListItem
              v-for="engagement in inProgressList"
              :key="engagement.id"
              is-link
              class="dark"
              :title="engagement.title"
              :subtitle="engagement.subtitle"
              :alert="engagement.alert"
              :tag="engagement.tag"
              :to="engagement.to"
              :start-open="startOpen(engagement.id)"
            />
          </div>
        </div>
        <!-- Completed -->
        <div v-if="showCompleted">
          <div class="page-section">Completed</div>
          <div class="form-list flex-column">
            <PanelListItem
              v-for="engagement in completedList"
              :key="engagement.id"
              is-link
              class="dark"
              :title="engagement.title"
              :subtitle="engagement.subtitle"
              :alert="engagement.alert"
              :tag="engagement.tag"
              :to="engagement.to"
              :start-open="startOpen(engagement.id)"
            />
          </div>
        </div>
      </div>
    </AppPanel>
  </div>
</template>

<style scoped>
  .form-container-list {
    box-shadow: none;
  }
</style>

import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Num, Str } from 'pinia-orm/dist/decorators';

export default class User extends Model {
  static entity = 'user';

  @Num(null) declare id: number;
  @Str('') declare email: string;
  @Str('') declare first_name: string;
  @Str('') declare last_name: string;

  static api() {
    return {
      async detail() {
        const { data } = await axios.get('/user');
        useRepo(User).fresh(data);
      },
      async forgot(email: string) {
        const data = {
          email: email,
        };
        await axios.put(`/user/password/forgot`, data);
      },
      async profile(firstName: string, lastName: string) {
        const payload = {
          first_name: firstName,
          last_name: lastName,
        };
        await axios.put('/user', payload);
      },
      async reset(userId: number, token: string, password: string) {
        const data = {
          password: password,
          user_id: userId,
          token: token,
        };
        await axios.put(`/user/password/reset`, data);
      },
    };
  }

  static async handleModelMessage(message: {
    payload: User;
    method: 'create' | 'update' | 'delete';
  }) {
    if (message.method === 'create') {
      await useRepo(User).insert(message.payload);
    } else if (message.method === 'update') {
      await useRepo(User).insert(message.payload);
    } else if (message.method === 'delete') {
      useRepo(User).destroy(message.payload.id);
    }
  }

  get full_name() {
    let full_name = '';
    if (this.first_name !== null) {
      full_name += this.first_name;
    }
    if (this.last_name !== null) {
      if (full_name !== '') {
        full_name += ' ';
      }
      full_name += this.last_name;
    }

    return full_name;
  }

  get full_name_reverse() {
    let full_name_reverse = '';

    if (this.last_name !== null) {
      full_name_reverse += this.last_name;
    }
    if (this.first_name !== null) {
      if (full_name_reverse !== '') {
        full_name_reverse += ', ';
      }
      full_name_reverse += this.first_name;
    }

    return full_name_reverse;
  }

  get initials() {
    let initials = '';
    if (this.first_name !== null) {
      initials += this.first_name.substring(0, 1);
    }
    if (this.last_name !== null) {
      initials += this.last_name.substring(0, 1);
    }

    return initials.toUpperCase();
  }

  get short_name() {
    return `${this.first_name} ${this.last_name.charAt(0)}.`;
  }
}

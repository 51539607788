<script lang="ts" setup>
  import { computed } from 'vue';
  import type { User } from '@orm-lib';

  import { Avatar } from '../flowbite-vue';

  interface IProps {
    avatarStyle?: string;
    hasUpdate?: boolean;
    showUpdate?: boolean;
    initials?: string;
    user?: User;
  }
  const props = defineProps<IProps>();

  const containerClass = computed(() => {
    let className = 'avatar-container';

    if (props.hasUpdate && props.showUpdate) {
      className += ' avatar-update';
    }

    return className;
  });
</script>

<template>
  <div :class="containerClass">
    <Avatar
      :initials="initials || user?.initials || ''"
      class="avatar [&>div]:bg-brand-primary relative text-center [&>div>div]:text-xs [&>div>div]:font-semibold [&>div>div]:tracking-[0.05rem] [&>div>div]:text-white"
      :style="avatarStyle"
      rounded
    />
  </div>
</template>

<style scoped>
  @keyframes flash {
    0% {
      background-color: rgba(255, 164, 164, 0);
    }
    50% {
      background-color: rgba(255, 164, 164, 1);
    }
    100% {
      background-color: rgba(255, 164, 164, 0);
    }
  }

  :deep(.avatar) {
    @apply h-[38px] min-h-[38px] w-[38px] min-w-[38px] text-sm [&>div]:h-[38px] [&>div]:w-[38px];
  }

  .avatar-container {
    @apply relative flex h-[46px] min-h-[46px] w-[46px] min-w-[46px] items-center justify-center rounded-full;
  }

  .avatar-update::after {
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    background: #fb4f57;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    right: 4px;
  }
</style>

import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Attr, Bool, HasMany, Str } from 'pinia-orm/dist/decorators';

import GroupGoal from './group_goal';
import GroupLearner from './group_learner';
import GroupOwner from './group_owner';
import TrainingSubscription from './training_subscription';

interface IChildGroup {
  id: number;
  created: string;
  name: string;
  is_root: boolean;
}

export default class Group extends Model {
  static entity = 'group';

  @Attr(null) declare id: number;
  @Str('') declare created: string;
  @Str('') declare name: string;
  @Bool(false) declare is_root: boolean;
  @HasMany(() => GroupLearner, 'group_id')
  declare learners: GroupLearner[];
  @Attr(null) declare groups: IChildGroup[];
  @HasMany(() => GroupOwner, 'group_id')
  declare owners: GroupOwner[];
  @Attr(null) declare parent: IChildGroup;
  @HasMany(() => GroupGoal, 'group_id') declare goals: GroupGoal[];

  static api() {
    return {
      async list() {
        const { data } = await axios.get(`/group`);
        const repo = useRepo(Group);
        repo.save(data);
      },
      async directory(id: number) {
        const { data } = await axios.get(`/group/${id}/directory`);
        const repo = useRepo(Group);
        repo.save(data);
      },
      async manage(id: number) {
        const { data } = await axios.get(`/group/${id}/manage`);
        const repo = useRepo(Group);
        repo.flush();
        const learnerGroupRepo = useRepo(GroupLearner);
        learnerGroupRepo.flush();
        const learnerOwnerRepo = useRepo(GroupOwner);
        learnerOwnerRepo.flush();
        const trainingSubscriptionRepo = useRepo(TrainingSubscription);
        trainingSubscriptionRepo.flush();
        repo.save(data);
      },
      async add(id: number, name: string) {
        const { data } = await axios.post(`/group/${id}/directory`, {
          name: name,
        });
        const currentGroup = useRepo(Group).withAllRecursive().find(id);
        const learnerGroupRepo = useRepo(GroupLearner);
        learnerGroupRepo.flush();
        const learnerOwnerRepo = useRepo(GroupOwner);
        learnerOwnerRepo.flush();

        useRepo(Group).save({
          ...currentGroup,
          groups: [data, ...currentGroup?.groups],
        });
      },
      async addGroupLearner(groupId: number, learnerId: number) {
        const { data } = await axios.post(`/group-learner/add/`, {
          group_id: groupId,
          learner_id: learnerId,
        });
        const currentGroup = useRepo(Group).withAllRecursive().find(groupId);
        const learnerGroupRepo = useRepo(GroupLearner);
        learnerGroupRepo.flush();
        const learnerOwnerRepo = useRepo(GroupOwner);
        learnerOwnerRepo.flush();

        useRepo(Group).save({
          ...currentGroup,
          learners: [data, ...currentGroup?.learners],
        });
      },
      async inviteGroupLearner(groupId: number, learnerEmail: string) {
        const { data } = await axios.post(`/group-learner/invite/`, {
          group_id: groupId,
          email: learnerEmail,
        });
        const currentGroup = useRepo(Group).withAllRecursive().find(groupId);
        const learnerGroupRepo = useRepo(GroupLearner);
        learnerGroupRepo.flush();
        const learnerOwnerRepo = useRepo(GroupOwner);
        learnerOwnerRepo.flush();

        useRepo(Group).save({
          ...currentGroup,
          learners: [data, ...currentGroup?.learners],
        });
      },
      async removeGroupLearner(groupId: number, groupLearnerId: number) {
        await axios.delete(`/group-learner/${groupLearnerId}/`);
        const currentGroup = useRepo(Group).withAllRecursive().find(groupId);
        const learnerGroupRepo = useRepo(GroupLearner);
        learnerGroupRepo.flush();
        const learnerOwnerRepo = useRepo(GroupOwner);
        learnerOwnerRepo.flush();

        useRepo(Group).save({
          ...currentGroup,
          learners: currentGroup.learners.filter(
            (groupLearner) => groupLearner.id !== groupLearnerId
          ),
        });
      },
      async addGroupOwner(groupId: number, ownerId: number) {
        const { data } = await axios.post(`/group-owner/add/`, {
          group_id: groupId,
          learner_id: ownerId,
        });

        const currentGroup = useRepo(Group).withAllRecursive().find(groupId);
        const learnerGroupRepo = useRepo(GroupLearner);
        learnerGroupRepo.flush();
        const learnerOwnerRepo = useRepo(GroupOwner);
        learnerOwnerRepo.flush();

        useRepo(Group).save({
          ...currentGroup,
          owners: [data, ...currentGroup?.owners],
        });
      },
      async inviteGroupOwner(groupId: number, ownerEmail: string) {
        const { data } = await axios.post(`/group-owner/invite/`, {
          group_id: groupId,
          email: ownerEmail,
        });
        const currentGroup = useRepo(Group).withAllRecursive().find(groupId);

        const learnerGroupRepo = useRepo(GroupLearner);
        learnerGroupRepo.flush();
        const learnerOwnerRepo = useRepo(GroupOwner);
        learnerOwnerRepo.flush();

        useRepo(Group).save({
          ...currentGroup,
          owners: [data, ...currentGroup?.owners],
        });
      },
      async removeGroupOwner(groupId: number, groupLearnerId: number) {
        await axios.delete(`/group-owner/${groupLearnerId}/`);
        const currentGroup = useRepo(Group).withAllRecursive().find(groupId);
        const learnerGroupRepo = useRepo(GroupLearner);
        learnerGroupRepo.flush();
        const learnerOwnerRepo = useRepo(GroupOwner);
        learnerOwnerRepo.flush();

        useRepo(Group).save({
          ...currentGroup,
          owners: currentGroup.owners.filter(
            (groupLearner) => groupLearner.id !== groupLearnerId
          ),
        });
      },
      async deleteGroup(groupId: number, parentGroupId?: number) {
        await axios.delete(`/group/${groupId}/directory`);

        const currentGroup = parentGroupId
          ? useRepo(Group).withAllRecursive().find(parentGroupId)
          : useRepo(Group).withAllRecursive().where('is_root', true).get()?.[0];

        if (currentGroup) {
          const newGroups = currentGroup.groups.filter(
            (group: IChildGroup) => group.id !== groupId
          );
          useRepo(Group).save({ id: currentGroup.id, groups: newGroups });
        }
      },
      async renameGroup(groupId: number, name: string, parentGroupId?: number) {
        await axios.put(`/group/${groupId}/directory`, {
          name: name,
        });

        const currentGroup = parentGroupId
          ? useRepo(Group).withAllRecursive().find(parentGroupId)
          : useRepo(Group).withAllRecursive().where('is_root', true).get()?.[0];

        if (currentGroup) {
          const newGroups = currentGroup.groups.map((group: IChildGroup) => {
            if (group.id === groupId) {
              return {
                ...group,
                name: name,
              };
            }
            return group;
          });
          useRepo(Group).save({ id: currentGroup.id, groups: newGroups });
        }
      },
    };
  }
}

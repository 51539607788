<script lang="ts" setup>
    import { AngleRight } from "@/components/icons";

    const linkSections = [
        {
            title: 'Get Started',
            links: [
                {
                    title: 'Sign Up',
                    to: { name: 'SignUp' },
                },
                {
                    title: 'Log In',
                    to: { name: 'Login' },
                },
                {
                    title: 'Forgot Password',
                    to: { name: 'LoginForgot' },
                },
            ]
        },
    ]

    function handleLinkClick(handler: string) {
        if (handler === 'handleLogIn') {
            window.open(`${import.meta.env.VITE_APP_BASE_URL}/login`);
        } else if (handler === 'handleSignUp') {
            window.open(`${import.meta.env.VITE_APP_BASE_URL}/sign-up`);
        }
    }
</script>
<template>
    <div class="flex flex-row sm:justify-center bg-barelyGrey border-t-[1px] border-t-lightgrey">
        <div class="flex flex-col justify-between py-[32px] px-[24px] sm:px-[0] sm:py-[48px] md:py-[64px] lg:py-[80px] w-[494px]">
            <div class="flex flex-col text-grey text-[12px] gap-[16px] items-start pb-[24px]">
                <img src='/assets/img/brand/brandmark-primary.svg' class="h-[28px]" />
                &copy; 2024 Backstage
            </div>
            <div v-for="linkSection in linkSections" v-bind:key="linkSection.title" class="flex flex-col gap-[8px] md:gap-[16px]">
                <span class="text-blackish uppercase text-[12px]">{{ linkSection.title }}</span>
                <template v-for="link in linkSection.links" v-bind:key="link.title">
                    <RouterLink v-if="link.to !== null" :aria-label="`Navigate to ${ link.title }`" class="footer-link" :to="link.to" >
                        {{ link.title }}
                        <AngleRight class="stroke-primary visible opacity-0 transition duration-[200ms] h-[24px] w-[24px]" />
                    </RouterLink>
                    <button v-else class="footer-link" @click=handleLinkClick(link.handler)>
                        {{ link.title }}
                        <AngleRight class="stroke-primary visible opacity-0 transition ease-out duration-[200ms] h-[24px] w-[24px]" />
                    </button>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .footer-link {
        @apply flex flex-row items-center text-darkGrey transition duration-[50ms] font-[300] rounded-[1px] text-[14px] md:text-[16px];
    }

    .footer-link:focus-visible {
        @apply outline-golden outline-dashed outline-offset-[8px];
    }

    .footer-link:focus-visible,
    .footer-link:hover {
        @apply text-primary
    }

    .footer-link:focus-visible > svg,
    .footer-link:hover > svg {
        @apply opacity-100
    }
</style>
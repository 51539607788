<script lang="ts" setup>
  import { reactive, ref } from 'vue';

  import { UiForm, UiFormNavigation } from '@ui-lib';
  import FormEvaluation from '@/components/FormEvaluation.vue';

  interface IProps {
    error?: string;
    maxSteps: number;
    trainerEffectivenessRating?: number;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<{
    (e: 'next', form: any): void;
    (e: 'prev'): void;
  }>();

  const form = reactive({
    rating: <number | null>props.trainerEffectivenessRating,
  });
  const localError = ref('');

  function selectRating(rating) {
    form.rating = rating;
  }

  function next() {
    if (!form.rating) {
      localError.value =
        'Please leave a rating on the effectiveness of your match with your trainer before proceeding.';
      return;
    }

    const updatedForm = {
      trainer_effectiveness_rating: form.rating,
    };

    emit('next', updatedForm);
  }
</script>

<template>
  <UiForm
    variant="config"
    show-prev
    title="Collaborator Feedback"
    :error="error || localError"
    @submit="next"
    @prev="emit('prev')"
  >
    <FormEvaluation
      v-model="form.rating"
      min-text="Not effective"
      max-text="Highly effective"
      question="How would you rate the effectiveness of your match with your trainer?"
      @select="selectRating"
    />
    <UiFormNavigation class="mb-0 mt-8" show-previous @prev="emit('prev')">
      <span class="text-body-secondary">Step 2/{{ maxSteps }}</span>
    </UiFormNavigation>
  </UiForm>
</template>

<style scoped>
  .collaborator-intro {
    font-family: 'me360';
    text-align: left;
    font-size: 14px;
    color: #808080;
  }
</style>

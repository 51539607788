<script lang="ts" setup>
  import { computed } from 'vue';

  import { UiButton, UiFormGroup } from '@ui-lib';

  interface IProps {
    disabled?: boolean;
    prevText?: string;
    showPrevious?: boolean;
    submitText?: string;
    loading?: boolean;
  }
  const props = withDefaults(defineProps<IProps>(), {
    prevText: 'Previous',
    submitText: 'Next',
  });
  const emit = defineEmits<{ (e: 'prev'): void }>();
</script>

<template>
  <UiFormGroup
    class="w-full !flex-row flex-nowrap items-center justify-between"
  >
    <slot></slot>
    <UiButton
      v-if="showPrevious"
      variant="app-home"
      type="button"
      class="mr-4 ml-auto sm:mr-6"
      color="grey"
      @click="emit('prev')"
    >
      {{ prevText }}
    </UiButton>
    <UiButton
      :class="`!rounded-[5px] ${!showPrevious && 'ml-auto'}`"
      :disabled="disabled"
      :loading="loading"
      variant="app-home"
      type="submit"
    >
      {{ submitText }}
    </UiButton>
  </UiFormGroup>
</template>

<script lang="ts" setup>
  import {
    computed,
    nextTick,
    onBeforeMount,
    onBeforeUnmount,
    reactive,
    ref,
    watch,
  } from 'vue';
  import { isNil } from 'lodash';
  import { Duration } from 'luxon';

  import { Recording } from '@orm-lib';

  const props = defineProps<{
    recording: Recording;
    lg: boolean;
    start: number | null;
    end: number | null;
  }>();

  const isPlayerReady = ref(false);
  const isPlaying = ref(false);
  const playerControls = ref();
  const hiddenControlsTimeout = ref();
  const recordingPlayer = ref();
  const file = ref<null | string>(null);

  const recordingFeedback = reactive({
    isAddingNote: false,
  });
  const player = reactive({
    ready: false,
    position: 0,
    playing: false,
    wasPlayingNote: false,
    wasPlayingScrub: false,
    rate: 1.0,
  });

  const mobileControlsVisible = ref();

  const disablePlaybackControls = computed(() => {
    if (isPlayerReady.value === false) {
      return true;
    }

    return recordingFeedback.isAddingNote === true;
  });

  const currentFormattedTime = computed(() => {
    const time = props.start
      ? Math.floor(Math.abs(player.position - props.start))
      : player.position;

    return Duration.fromObject({ second: time }).toFormat('mm:ss');
  });

  const formattedEndTime = computed(() => {
    const max =
      !isNil(props.end) && !isNil(props.start)
        ? Math.ceil(props.end - props.start)
        : props.recording.duration;

    return Duration.fromObject({
      second: max,
    }).toFormat('mm:ss');
  });

  const rangeMax = computed(() => {
    const max = props.end ? props.end : props.recording.duration;

    return Math.floor((max * 100) / 100);
  });

  function onScrubEnd() {
    if (player.wasPlayingScrub === true) {
      recordingPlayer.value.play();
    }

    if (isPlaying.value) {
      hiddenControlsTimeout.value = setTimeout(() => {
        playerControls.value.style.visibility = 'hidden';
      }, 300);
    }
  }

  function onScrubStart() {
    player.wasPlayingScrub = player.playing;
    recordingPlayer.value.pause();

    clearTimeout(hiddenControlsTimeout.value);
  }

  function handleScrubTouchStart() {
    clearTimeout(hiddenControlsTimeout.value);
  }

  function onTogglePlayPause() {
    clearTimeout(hiddenControlsTimeout.value);

    if (isPlaying.value === false) {
      if (props.end && player.position >= props.end) {
        player.position = props.start ?? 0;
        recordingPlayer.value.position = props.start ?? 0;
      }

      recordingPlayer.value.play();
      hiddenControlsTimeout.value = setTimeout(() => {
        if (props.lg) {
          return;
        }

        if (isPlaying.value) {
          playerControls.value.style.opacity = 0;
          mobileControlsVisible.value = false;

          hiddenControlsTimeout.value = setTimeout(() => {
            playerControls.value.style.visibility = 'hidden';
          }, 300);
        }
      }, 2000);
    } else {
      recordingPlayer.value.pause();
    }
  }

  function onToggleRate() {
    if (player.rate === 1.0) {
      player.rate = 1.5;
    } else if (player.rate === 1.5) {
      player.rate = 2.0;
    } else if (player.rate === 2.0) {
      player.rate = 1.0;
    } else {
      player.rate = 1.0;
    }
  }

  function goBackSeconds() {
    player.position =
      player.position < 10 ? props.start ?? 0 : player.position - 10;
  }

  function goForwardSeconds() {
    const end = props.end ?? props.recording.duration;
    player.position = player.position + 10 > end ? end : player.position + 10;
  }

  function toggleFullScreen() {
    if (recordingPlayer.value.requestFullscreen)
      recordingPlayer.value.requestFullscreen();
    else if (recordingPlayer.value.webkitRequestFullscreen)
      recordingPlayer.value.webkitRequestFullscreen();
    else if (recordingPlayer.value.msRequestFullScreen)
      recordingPlayer.value.msRequestFullScreen();
    else if (recordingPlayer.value.webkitEnterFullScreen)
      recordingPlayer.value.webkitEnterFullScreen();
  }

  function handleMouseLeave() {
    if (isPlaying.value) {
      playerControls.value.style.opacity = 0;

      hiddenControlsTimeout.value = setTimeout(() => {
        playerControls.value.style.visibility = 'hidden';
      }, 300);
    }
  }

  function handleTouchStart() {
    clearTimeout(hiddenControlsTimeout.value);

    if (mobileControlsVisible.value) {
      playerControls.value.style.opacity = 0;
      mobileControlsVisible.value = false;
      hiddenControlsTimeout.value = setTimeout(() => {
        playerControls.value.style.visibility = 'hidden';
      }, 300);
    } else {
      playerControls.value.style.visibility = 'visible';
      playerControls.value.style.opacity = 1;
      mobileControlsVisible.value = true;
    }
  }

  function handleTouchEnd() {
    if (isPlaying.value) {
      hiddenControlsTimeout.value = setTimeout(() => {
        playerControls.value.style.opacity = 0;
        mobileControlsVisible.value = false;

        hiddenControlsTimeout.value = setTimeout(() => {
          playerControls.value.style.visibility = 'hidden';
        }, 300);
      }, 3000);
    }
  }

  function setFile(newFile: string) {
    file.value = newFile;
    player.position = props.start ?? 0;
  }

  function onPlaybackEnd() {
    mobileControlsVisible.value = true;
    playerControls.value.style.opacity = 1;
    playerControls.value.style.visibility = 'visible';
    clearTimeout(hiddenControlsTimeout.value);
  }

  function handleMouseEnter() {
    clearTimeout(hiddenControlsTimeout.value);
    playerControls.value.style.opacity = 1;
    playerControls.value.style.visibility = 'visible';
  }

  function handlePlay(event: { target: { currentTime: number } }) {
    isPlaying.value = true;

    if (props.end && event.target.currentTime >= props.end) {
      player.position = props.start ?? 0;
      recordingPlayer.value.position = props.start ?? 0;
      recordingPlayer.value.play();
    }
  }

  function handleTimeUpdate(event: { target: { currentTime: number } }) {
    if (props.end && event.target.currentTime > props.end) {
      recordingPlayer.value.pause();
      player.position = props.end;
      onPlaybackEnd();
      return;
    }

    player.position = event.target.currentTime;
  }

  onBeforeMount(async () => {
    setFile(await props.recording.getFile());
  });

  onBeforeUnmount(() => {
    clearTimeout(hiddenControlsTimeout.value);
  });

  watch(
    () => file.value,
    () => {
      nextTick(() => {
        recordingPlayer.value?.load();
      });
    }
  );

  watch(
    () => player.position,
    (position) => {
      if (Math.abs(position - recordingPlayer.value.currentTime) > 0.5) {
        recordingPlayer.value.currentTime = position;
      }
    }
  );

  watch(
    () => player.rate,
    (rate) => {
      recordingPlayer.value.playbackRate = rate;
    }
  );
</script>

<template>
  <div
    class="video-interface-main relative flex flex-col border-none"
    @touchstart.passive="handleTouchStart"
    @touchend.passive="handleTouchEnd"
    @mouseleave="handleMouseLeave"
    @mouseenter="handleMouseEnter"
  >
    <!-- Recording Media -->
    <div
      class="rounded-none border-solid border-[#E8ECF4] p-0 lg:rounded-lg lg:border-[1px]"
    >
      <video
        id="recordingViewerPlayer"
        ref="recordingPlayer"
        preload="auto"
        playsinline
        :src="file"
        class="video-stream-main aspect-video h-full w-full rounded-none bg-black lg:rounded-lg"
        @ended="onPlaybackEnd"
        @canplay="isPlayerReady = true"
        @pause="isPlaying = false"
        @play="handlePlay"
        @timeupdate="handleTimeUpdate"
      ></video>
    </div>
    <!-- Player Controls -->
    <div
      v-show="!disablePlaybackControls"
      ref="playerControls"
      class="player-mask absolute bottom-0 flex h-full min-w-full flex-col rounded-none pb-4 transition-all duration-300 ease-in-out lg:m-0 lg:rounded-lg lg:px-4 lg:pt-3"
    >
      <div
        v-if="!lg"
        class="relative flex h-full w-full flex-col items-center justify-center pt-4"
      >
        <div class="flex">
          <button
            class="icon absolute top-4 right-0 !ml-auto min-h-[30px] min-w-[30px] self-end !rounded-[5px] !p-0 text-white sm:right-4"
            style="margin: 0 5px 0 0; padding: 0"
            @click.stop="onToggleRate"
            @touchstart.passive.stop
          >
            <template v-if="player.rate === 1.0">1x</template>
            <template v-else-if="player.rate === 1.5">1.5x</template>
            <template v-else>2x</template>
          </button>
          <button @click.stop="goBackSeconds" @touchstart.passive.stop>
            <svg
              class="h-[18px] w-[20px] sm:h-[34px] sm:w-[32px]"
              width="32"
              height="34"
              viewBox="0 0 32 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.28187 8.97631C2.20251 11.5625 0.960449 14.8349 0.960449 18.3939C0.960449 26.7641 7.83064 33.5495 16.3054 33.5495C24.7803 33.5495 31.6504 26.7641 31.6504 18.3939C31.6504 10.0237 24.7803 3.23828 16.3054 3.23828C16.0914 3.23828 15.8783 3.24261 15.6664 3.25119V5.25307C15.8781 5.24325 16.0912 5.23828 16.3054 5.23828C23.6992 5.23828 29.6504 11.1516 29.6504 18.3939C29.6504 25.6361 23.6992 31.5495 16.3054 31.5495C8.91172 31.5495 2.96045 25.6361 2.96045 18.3939C2.96045 15.2657 4.07073 12.3855 5.92796 10.1227L4.28187 8.97631Z"
                fill="white"
              />
              <path
                d="M9.91309 4.50185L16.6265 0.126808L16.6265 8.87689L9.91309 4.50185Z"
                fill="white"
              />
            </svg>
          </button>
          <button
            class="mx-6 flex h-[40px] w-[40px] items-center justify-center sm:mx-12 sm:h-[70px] sm:w-[70px]"
            @click.stop="onTogglePlayPause"
            @touchstart.passive.stop
          >
            <svg
              v-if="!isPlaying"
              class="h-[40px] w-[40px] sm:h-[70px] sm:w-[70px]"
              width="68"
              height="69"
              viewBox="0 0 68 69"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M50.75 33.201C51.75 33.7783 51.75 35.2217 50.75 35.799L24.5 50.9545C23.5 51.5318 22.25 50.8101 22.25 49.6554L22.25 19.3446C22.25 18.1899 23.5 17.4682 24.5 18.0455L50.75 33.201Z"
                fill="white"
              />
            </svg>
            <img
              v-else
              alt="Pause"
              class="h-[20px] w-[20px] sm:h-[32px] sm:w-[32px]"
              src="/assets/img/icon/pause.png"
            />
          </button>
          <button @click.stop="goForwardSeconds" @touchstart.passive.stop>
            <svg
              class="h-[18px] w-[20px] sm:h-[34px] sm:w-[32px]"
              width="32"
              height="34"
              viewBox="0 0 32 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.2356 3.29022C16.8138 3.25582 16.3872 3.23828 15.9564 3.23828C7.48369 3.23828 0.615234 10.0237 0.615234 18.3939C0.615234 26.7641 7.48369 33.5495 15.9564 33.5495C24.429 33.5495 31.2975 26.7641 31.2975 18.3939C31.2975 14.8659 30.0772 11.6194 28.0309 9.0439L26.4098 10.2198C28.2189 12.4676 29.2975 15.3102 29.2975 18.3939C29.2975 25.6367 23.3475 31.5495 15.9564 31.5495C8.56525 31.5495 2.61523 25.6367 2.61523 18.3939C2.61523 11.1511 8.56525 5.23828 15.9564 5.23828C16.388 5.23828 16.8147 5.25845 17.2356 5.29787V3.29022Z"
                fill="white"
              />
              <path
                d="M22.3447 4.50184L15.633 0.126788L15.633 8.87689L22.3447 4.50184Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
        <div
          class="absolute bottom-2 left-4 flex items-center self-center text-xs font-thin text-white sm:bottom-4 sm:left-6 sm:text-sm"
        >
          <span class="mr-0.5">
            {{ currentFormattedTime }}
          </span>
          /
          <span class="ml-0.5 text-[#848484]">
            {{ formattedEndTime }}
          </span>
        </div>
        <button
          class="absolute right-3 bottom-2 sm:right-6"
          @click.stop="toggleFullScreen"
          @touchstart.passive.stop
        >
          <svg
            class="h-[30px] w-4 sm:h-[52px] sm:w-6"
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.91919 2.46323L0.264688 2.46323L0.264688 0.799601L6.91919 0.799601L6.91919 2.46323Z"
              fill="white"
            />
            <path
              d="M0.264688 7.4541L0.264688 0.799601L1.92831 0.799601L1.92831 7.4541L0.264688 7.4541Z"
              fill="white"
            />
            <path
              d="M10.2449 2.46323L16.8994 2.46323L16.8994 0.799602L10.2449 0.799601L10.2449 2.46323Z"
              fill="white"
            />
            <path
              d="M16.8994 7.4541L16.8994 0.799602L15.2357 0.799601L15.2357 7.4541L16.8994 7.4541Z"
              fill="white"
            />
            <path
              d="M10.2449 15.7721L16.8994 15.7721L16.8994 17.4358L10.2449 17.4358L10.2449 15.7721Z"
              fill="white"
            />
            <path
              d="M16.8994 10.7813L16.8994 17.4358L15.2357 17.4358L15.2357 10.7813L16.8994 10.7813Z"
              fill="white"
            />
            <path
              d="M1.92831 10.782L1.92831 17.4365L0.264689 17.4365L0.264688 10.782L1.92831 10.782Z"
              fill="white"
            />
            <path
              d="M6.91919 17.4365L0.264689 17.4365L0.264688 15.7729L6.91919 15.7729L6.91919 17.4365Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <div class="relative mt-auto flex w-full items-center">
        <input
          ref="scrubBar"
          v-model.number="player.position"
          :max="rangeMax"
          class="scrub-bar w-full"
          :min="start ?? 0"
          step="0.2"
          type="range"
          @mousedown="onScrubStart"
          @mouseup="onScrubEnd"
          @touchstart.stop="handleScrubTouchStart"
        />
      </div>
      <div v-if="lg" class="flex w-full">
        <button
          style="margin: 0 5px 0 0; padding: 0"
          class="icon flex min-h-[30px] min-w-[30px] items-center justify-center self-end !rounded-[5px] !p-0"
          @click.stop="onTogglePlayPause"
          @touchstart.passive.stop
        >
          <svg
            v-if="!isPlaying"
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.833 5.8879C12.7387 6.3527 12.7387 7.6473 11.833 8.1121L2.32073 12.9938C1.4889 13.4207 0.499999 12.8167 0.499999 11.8817L0.5 2.11829C0.5 1.18332 1.4889 0.579302 2.32073 1.0062L11.833 5.8879Z"
              fill="white"
            />
          </svg>
          <img
            v-else
            alt="Pause"
            class="h-3 !w-3"
            src="/assets/img/icon/pause.png"
          />
        </button>
        <button
          style="margin: 0 5px 0 0; padding: 0"
          class="icon flex min-h-[30px] min-w-[30px] items-center justify-center self-end !rounded-[5px] !p-0"
          @click.stop="goBackSeconds"
          @touchstart.passive.stop
        >
          <svg
            width="16"
            height="19"
            viewBox="0 0 16 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.7692 5.50001C0.723966 6.81615 0.0996094 8.4816 0.0996094 10.2929C0.0996094 14.5525 3.55275 18.0057 7.81241 18.0057C12.0721 18.0057 15.5252 14.5525 15.5252 10.2929C15.5252 6.03322 12.0721 2.58008 7.81241 2.58008C7.70482 2.58008 7.59775 2.58228 7.49123 2.58664V4.08824C7.5976 4.08282 7.70469 4.08008 7.81241 4.08008C11.2436 4.08008 14.0252 6.86164 14.0252 10.2929C14.0252 13.7241 11.2436 16.5057 7.81241 16.5057C4.38117 16.5057 1.59961 13.7241 1.59961 10.2929C1.59961 8.80344 2.12373 7.43641 2.99761 6.36618L1.7692 5.50001Z"
              fill="white"
            />
            <path
              d="M4.59863 3.22301L7.97298 0.996519L7.97298 5.44951L4.59863 3.22301Z"
              fill="white"
            />
          </svg>
        </button>
        <button
          style="margin: 0 5px 0 0; padding: 0"
          class="icon flex min-h-[30px] min-w-[30px] items-center justify-center self-end !rounded-[5px] !p-0"
          @click.stop="goForwardSeconds"
          @touchstart.passive.stop
        >
          <svg
            width="16"
            height="19"
            viewBox="0 0 16 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.44711 2.60654C8.23493 2.58901 8.02032 2.58008 7.80362 2.58008C3.54396 2.58008 0.0908203 6.03322 0.0908203 10.2929C0.0908203 14.5525 3.54396 18.0057 7.80362 18.0057C12.0633 18.0057 15.5164 14.5525 15.5164 10.2929C15.5164 8.49756 14.903 6.84551 13.8744 5.53488L12.6645 6.42325C13.5107 7.48481 14.0164 8.82978 14.0164 10.2929C14.0164 13.7241 11.2349 16.5057 7.80362 16.5057C4.37239 16.5057 1.59082 13.7241 1.59082 10.2929C1.59082 6.86164 4.37239 4.08008 7.80362 4.08008C8.0209 4.08008 8.23558 4.09123 8.44711 4.113V2.60654Z"
              fill="white"
            />
            <path
              d="M11.0183 3.22301L7.64396 0.996519L7.64396 5.44951L11.0183 3.22301Z"
              fill="white"
            />
          </svg>
        </button>
        <div
          class="ml-1 flex items-center self-center text-sm font-thin text-white"
        >
          <span class="mr-0.5">
            {{ currentFormattedTime }}
          </span>
          /
          <span class="ml-0.5">
            {{ formattedEndTime }}
          </span>
        </div>
        <button
          class="icon !ml-auto min-h-[30px] min-w-[30px] self-end !rounded-[5px] !p-0 text-white"
          style="margin: 0 5px 0 0; padding: 0"
          @click.stop="onToggleRate"
          @touchstart.passive.stop
        >
          <template v-if="player.rate === 1.0">1x</template>
          <template v-else-if="player.rate === 1.5">1.5x</template>
          <template v-else>2x</template>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    @apply accent-brand-primary mb-2 cursor-pointer;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    @apply bg-brand-primary h-[5px];
  }

  input[type='range']::-moz-range-track {
    -webkit-appearance: none;
    appearance: none;
    @apply bg-brand-primary h-[5px];
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    z-index: 5 !important;
    @apply -mt-[5.5px] h-[16px] w-[16px] bg-white;
  }

  input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    z-index: 5 !important;
    @apply -mt-[5.5px] h-[16px] w-[16px] bg-white;
  }

  .player-mask {
    background: rgba(0, 0, 0, 0.5) 100%;
  }

  @media screen and (min-width: 1025px) {
    .player-mask {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 60%,
        rgba(0, 0, 0, 0.9) 100%
      );
    }
  }

  div.session-stream-subtitle {
    @apply text-brand-primary font-normal;
  }

  div.video-interface-main {
    display: flex;
    max-height: calc(100% - 60px);
    height: 100%;
    text-align: left;
    position: relative;
  }

  div.video-scrub-note {
    z-index: 1;
    position: absolute;
    max-height: 25px;
    pointer-events: none;
    @apply border-brand-primary absolute border-r-[2px];
  }

  div.video-stream-label {
    font-size: 13px;
    text-transform: uppercase;
    color: #c0c0c0;
    line-height: 14px;
    padding: 5px 0 2px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-height: 21px;
  }

  video.video-stream-main,
  canvas.video-stream-main {
    max-height: 100%;
    width: 100%;
    object-fit: contain;
  }

  div.video-stream-mute-overlay {
    top: 4px;
    bottom: 4px;
    left: 4px;
    right: 4px;
    z-index: 10;
    position: absolute;
    background-color: #000000;
  }

  :deep(div.video-stream-overlay) {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 10px;
    top: 31px;
    z-index: 10;
    align-items: flex-end;
  }

  :deep(div.video-stream-overlay-label) {
    height: 40px;
    padding: 0 20px;
    border-radius: 4px;
    background-color: rgba(247, 247, 247, 0.75);
    line-height: 40px;
  }

  div.video-stream-text {
    font-size: 16px;
    color: grey;
  }

  div.video-stream-title {
    font-size: 20px;
  }

  :deep(img.picker-list-thumbnail) {
    max-width: 72px;
    max-height: 40px;
    border-radius: 4px;
    width: 100%;
  }

  div.video-interface-main {
    display: flex;
    max-height: calc(100% - 60px);
    height: 100%;
    text-align: left;
    position: relative;
  }

  :deep(div.video-stream-border) {
    display: flex;
    width: 100%;
    padding: 4px;
    border-radius: 4px;
    border: solid 1px #d9d9d9;
  }

  :deep(div.video-stream-label) {
    font-size: 13px;
    text-transform: uppercase;
    color: #c0c0c0;
    line-height: 14px;
    padding: 5px 0 2px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-height: 21px;
  }

  video.video-stream-main,
  canvas.video-stream-main {
    max-height: 100%;
    width: 100%;
    object-fit: contain;
  }

  div.video-stream-mute-overlay {
    top: 4px;
    bottom: 4px;
    left: 4px;
    right: 4px;
    z-index: 10;
    position: absolute;
    background-color: #000000;
  }

  div.video-stream-overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 10px;
    top: 31px;
    z-index: 10;
    align-items: flex-end;
  }

  div.video-stream-overlay-label {
    height: 40px;
    padding: 0 20px;
    border-radius: 4px;
    background-color: rgba(247, 247, 247, 0.75);
    line-height: 40px;
  }

  div.video-stream-text {
    font-size: 16px;
    color: grey;
  }

  div.video-stream-title {
    font-size: 20px;
  }

  :deep(.picker-button) {
    text-align: left !important;
    width: 100%;
    padding: 10px 20px !important;
    font-size: 16px;
    border-radius: 0 !important;
    border: none !important;
    @apply items-center justify-start  hover:opacity-50 [&>span]:justify-start [&>span]:text-left [&>span]:hover:!text-white;
  }

  :deep(.picker-button:hover),
  :deep(.picker-button:focus),
  :deep(.picker-button:active),
  :deep(.picker-button.active) {
    box-shadow: none !important;
    background-color: var(--purple-dark) !important;
  }

  :deep(div.picker-detail) {
    width: 400px;
    padding: 20px;
    height: 590px;
    max-height: 590px;
    overflow-y: auto;
    border-radius: 0 5px 5px 0;
  }

  :deep(div.picker-list) {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 590px;
    max-height: 590px;
    overflow-y: auto;
    border-radius: 5px 0 0 5px;
    padding-top: 10px;
    @apply bg-brand-primary;
  }

  :deep(div.picker-list::-webkit-scrollbar) {
    @apply bg-brand-secondary;
  }

  :deep(div.picker-list::-webkit-scrollbar-thumb) {
    @apply bg-brand-primary;
  }

  :deep(div.picker-list-empty) {
    padding: 10px 20px;
    @apply text-white;
  }

  :deep(div.picker-list-section) {
    margin: 10px 0 0 20px !important;
    @apply text-white opacity-70;
  }

  :deep(img.picker-list-thumbnail) {
    max-width: 72px;
    max-height: 40px;
    border-radius: 4px;
  }

  :deep(div.picker-list-title) {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 177px;
  }
</style>

<script lang="ts" setup>
  import { computed, reactive, ref } from 'vue';

  import {
    UiDropdown,
    UiForm,
    UiFormGroup,
    UiFormNavigation,
    UiFormTextarea,
  } from '@ui-lib';
  import AppCheckboxGroup from '@/components/AppCheckboxGroup.vue';
  import { Skill } from '@orm-lib';

  interface IProps {
    error?: string;
    maxSteps: number;
    additionalTrainingSkill?: number | null;
    receiveAdditionalTraining?: string;
    receiveAdditionalTrainingComments?: string;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<{
    (e: 'next', form: any): void;
    (e: 'prev'): void;
  }>();

  const form = reactive({
    additional_training: <string | null>props.receiveAdditionalTraining,
    additional_training_comments: <string | null>(
      props.receiveAdditionalTrainingComments
    ),
    additional_training_skill: <number | null>props.additionalTrainingSkill,
  });
  const formValidation = reactive({
    additional_training_comments: '',
    additional_training_skill: '',
  });
  const skillSelectDisabled = ref(form?.additional_training === 'no');

  function handleCheckItem(_event: any, value: string) {
    form.additional_training = value;
    formValidation.additional_training_comments = '';
    formValidation.additional_training_skill = '';

    skillSelectDisabled.value = form.additional_training === 'no';
  }

  function next() {
    const hasValidationError =
      form.additional_training === 'yes' &&
      (!form.additional_training_skill || !form.additional_training_comments);

    if (form.additional_training === 'yes' && !form.additional_training_skill) {
      formValidation.additional_training_skill =
        'Please select a skill focus for your additional training.';
    }

    if (
      form.additional_training === 'yes' &&
      !form.additional_training_comments
    ) {
      formValidation.additional_training_comments =
        'Please enter a comment for this training request.';
    }

    if (hasValidationError) {
      return;
    }

    const updatedForm = {
      additional_training: form.additional_training,
      additional_training_comments: form.additional_training_comments,
      additional_training_skill: form.additional_training_skill,
    };

    emit('next', updatedForm);
  }

  const skillOptions = computed(() => {
    return Skill.getAsOptions();
  });

  const selectedText = computed(() => {
    return (
      skillOptions.value.find(
        (option) => option.value === form.additional_training_skill
      )?.text ?? 'Select skill'
    );
  });

  function handleComment(event) {
    form.additional_training_comments = event.target.value;
    formValidation.additional_training_comments = '';
  }

  function setSkill(skill: number) {
    formValidation.additional_training_skill = '';
    form.additional_training_skill = skill;
  }

  const additionalTrainingOptions = computed(() => {
    return [
      {
        id: 'yes',
        title: "Yes, I'd like additional training",
      },
      {
        id: 'no',
        title: "No, I've had enough",
      },
    ];
  });
</script>

<template>
  <UiForm
    variant="config"
    show-prev
    :error="error"
    @submit="next"
    @prev="emit('prev')"
    title="Collaborator Feedback"
  >
    <div class="form-group-title self-start !normal-case">
      Would you like to receive additional training?
    </div>
    <AppCheckboxGroup
      class="!mb-4"
      :options="additionalTrainingOptions"
      :model-value="form.additional_training"
      :is-multiple="false"
      @input="handleCheckItem"
    />
    <UiFormGroup class="form-group-input mb-6">
      <div
        :class="`form-group-title ${
          formValidation.additional_training_skill
            ? '!uppercase text-red-500 '
            : '!normal-case '
        }`"
      >
        {{
          formValidation.additional_training_skill ||
          'What skill would you like to focus on?'
        }}
      </div>
      <UiDropdown
        :text="selectedText"
        class="form-select"
        :options="skillOptions"
        :disabled="skillSelectDisabled"
        @select="setSkill"
      />
    </UiFormGroup>
    <div
      :class="`mb-12 w-full ${
        formValidation.additional_training_comments
          ? '[&>label]:uppercase [&>label]:text-red-500'
          : ''
      }`"
    >
      <UiFormTextarea
        v-model="form.additional_training_comments"
        :label="
          formValidation.additional_training_comments ||
          `How would additional training benefit you?`
        "
        rows="3"
        style="resize: none"
        :disabled="skillSelectDisabled"
        @input="handleComment"
      />
    </div>
    <UiFormNavigation class="mb-0" show-previous @prev="emit('prev')">
      <span class="text-body-secondary">Step 3/{{ maxSteps }}</span>
    </UiFormNavigation>
  </UiForm>
</template>

<script lang="ts" setup>
import { computed, reactive } from 'vue';

type Nullable<T> = T | null;

interface IProps {
    disabled?: boolean,
    error?: string | null,
    id: string,
    title: string | null
    type?: 'text' | 'password'
}

const props = withDefaults(defineProps<IProps>(), {
    disabled: false,
    error: null,
    type: 'text'
});

interface IEmits {
    (e: 'blur', value:Nullable<string>): void;
    (e: 'change', value:Nullable<string>): void;
    (e: 'submit'): void;
}

const emit = defineEmits<IEmits>();

const data = reactive({
    inputValue: null as Nullable<string>
})

const inputClass = computed(() => {
    if (props.error === null)
        return 'valid'

    return 'invalid'
})

function handleBlur() {
    emit('blur', data.inputValue)
}

function handleKeyup(event:KeyboardEvent) {
    if (event.code === 'Enter') {
        emit('submit')
    } else {
        emit('change', data.inputValue)
    }
}

function clear() {
    data.inputValue = null
}

function setValue(inputValue:Nullable<string>) {
    data.inputValue = inputValue
}

defineExpose({ clear, setValue });
</script>

<template>
    <label :for=id hidden>{{ title }}</label>
    <input
        :id=id
        v-model=data.inputValue
        :type="type"
        class="font-[16px] h-[44px] w-full border-[1px] rounded-[8px] px-[16px]"
        :class=inputClass
        :disabled=disabled
        @blur=handleBlur
        @keyup=handleKeyup
    />
</template>

<style scoped>
input:disabled {
    @apply text-grey bg-barelyGrey
}

input.valid,
input.invalid {
    @apply font-[300];
    box-shadow: none;
}

input.valid {
    @apply border-lightGrey;
}

input.invalid {
    @apply border-lightGrey;
}

input.valid:focus-visible,
input.invalid:focus-visible {
    @apply outline-golden outline-dashed outline-offset-[2px] outline-[1px];
}
</style>
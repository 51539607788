<script lang="ts" setup>
  import { computed, reactive, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useRepo } from 'pinia-orm';

  import { GroupLearner, Skill, TrainingRequest } from '@orm-lib';
  import {
    UiForm,
    UiFormGroup,
    UiFormNavigation,
    UiFormTextarea,
  } from '@ui-lib';
  import type { IOnboardingSignupRequest } from '@/types';

  const router = useRouter();

  interface IProps {
    groupLearners: GroupLearner[];
    serviceError?: string;
    form: IOnboardingSignupRequest;
    recommendation: any;
    isAdmin: boolean;
    isMobile: boolean;
  }
  interface IEmits {
    (e: 'prev'): void;
    (e: 'next', request: IOnboardingSignupRequest): void;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<IEmits>();

  const localForm = reactive({ ...props.form });
  const localComments = ref('');

  const error = ref();

  const skillOptions = computed(() => {
    return useRepo(Skill)
      .withAllRecursive()
      .get()
      ?.map((skill) => {
        return { id: skill.id, text: skill.name };
      })
      .sort((a, b) => {
        if (a.text.toLowerCase() < b.text.toLowerCase()) {
          return -1;
        }
        if (a.text.toLowerCase() > b.text.toLowerCase()) {
          return 1;
        }
        return 0;
      });
  });

  const selectedLearners = computed(() => {
    return props.groupLearners
      .filter((groupLearner) =>
        localForm.group_learners?.some(
          (selectedLearner) => selectedLearner.id === groupLearner.id
        )
      )
      .map((learner) => {
        const formData = localForm.items?.find(
          (item) => item.group_learner_id === learner.id
        );
        const currentTier = learner.learner.training_subscriptions?.find(
          (subscription) => subscription.status === 'Present'
        );

        const selectedSkill = skillOptions.value.find(
          (skill) => skill.id === formData.skill_id
        );

        return {
          name: learner.learner.full_name,
          description: `Upgrade ${currentTier?.tier} to ${
            formData.subscription_tier
          }${
            formData.subscription_tier === 'Focused'
              ? `: ${
                  selectedSkill ? selectedSkill?.text : 'Let the Learner Decide'
                }`
              : ''
          }`,
        };
      });
  });

  function updateComments(e) {
    localComments.value = e.target.value;
  }

  async function submit() {
    const request = {
      group_id: localForm.group_id,
      items: localForm.items,
      comment: localComments.value || null,
    };

    try {
      await TrainingRequest.api().submitRequest(request);
      await router.push({
        name: 'GroupManagement',
        params: {
          groupId: localForm.group_id,
        },
      });
    } catch (e) {
      error.value =
        'Unable to submit request at this time. Please try again later.';
    }
  }
</script>

<template>
  <UiForm
    :error="serviceError || error"
    variant="app-config"
    class="!border-0 bg-transparent sm:!p-0"
    @submit="submit"
  >
    <div class="w-full min-w-full">
      <UiFormGroup class="!mb-0">
        <div
          class="mb-6 rounded-[10px] border-[1px] border-solid border-[#E8ECF4] bg-white sm:mb-10"
        >
          <h4
            class="text-header-primary mt-4 px-4 text-left text-base font-bold sm:px-6 sm:text-lg"
          >
            Upgrades
          </h4>
          <table class="w-full table-fixed">
            <tr
              v-for="learner in selectedLearners"
              :key="learner.id"
              class="flex w-full flex-col px-4 pb-2.5 first:pt-2 sm:px-6 md:flex-row"
            >
              <td
                class="text-header-primary w-[30%] whitespace-nowrap font-semibold"
              >
                {{ learner.name }}
              </td>
              <td class="text-body-secondary text-sm md:text-base">
                {{ learner.description }}
              </td>
            </tr>
          </table>
          <div class="mt-4 mb-2 px-4 sm:px-6">
            <h4
              class="text-header-primary mb-2 text-left text-base font-semibold sm:text-lg"
            >
              Comments
              <span class="text-xs font-normal lg:text-sm">(optional)</span>
            </h4>
            <UiFormTextarea
              ref="noteTextarea"
              v-model="localComments"
              placeholder="Enter a note..."
              class="feedback-note !max-h-[80px] !min-h-[80px]"
              @input="updateComments"
            />
          </div>
        </div>
      </UiFormGroup>
    </div>
    <UiFormNavigation
      class="mb-0 mt-[40px] lg:mt-[60px] pr-4 lg:pr-6"
      show-previous
      prev-text="Go Back"
      :submit-text="`${isAdmin ? 'Finalize' : 'Submit Request'}`"
      @prev="emit('prev')"
    >
      <slot></slot>
    </UiFormNavigation>
  </UiForm>
</template>

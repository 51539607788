<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
  >
    <line
      x1="1.5"
      y1="2.5"
      x2="18.5"
      y2="2.5"
      stroke="#242431"
      stroke-width="3"
      stroke-linecap="round"
    />
    <line
      x1="1.5"
      y1="18.5"
      x2="18.5"
      y2="18.5"
      stroke="#242431"
      stroke-width="3"
      stroke-linecap="round"
    />
    <line
      x1="6.5"
      y1="10.5"
      x2="18.5"
      y2="10.5"
      stroke="#242431"
      stroke-width="3"
      stroke-linecap="round"
    />
    <circle cx="19" cy="3" r="3.5" fill="#FB4F57" stroke="white" />
  </svg>
</template>

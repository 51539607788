<script lang="ts" setup>
  import { computed } from 'vue';

  import {
    Accordion,
    AccordionContent,
    AccordionHeader,
    AccordionPanel,
  } from '../flowbite-vue';

  interface IProps {
    header: string;
    initialOpen?: boolean;
    variant: 'primary' | 'flat' | 'nav' | 'reporting';
  }
  const props = withDefaults(defineProps<IProps>(), {
    initialOpen: false,
    variant: 'primary',
  });

  const backgroundClass = computed(() => {
    if (props.variant === 'flat') {
      return ' [&>button]:!bg-[#f3f5f8] [&>div]:!bg-[#f3f5f8]';
    }

    return ' [&>button]:!bg-white [&>div]:!bg-white';
  });

  const headerClass = computed(() => {
    const baseClass = '[&>button]:!border-none' + backgroundClass.value;

    if (props.variant === 'nav') {
      return (
        baseClass +
        ' [&>button]:!px-4 [&>button]:!font-normal [&>button]:!text-xl [&>button]:!text-gray-500 [&>button]:!pt-2 [&>button]:!pb-1 [&>button:focus]:!ring-0 !mb-0'
      );
    }

    if (props.variant === 'reporting') {
      return (
        baseClass +
        ' [&>button]:!rounded-lg [&>button]:!py-4 [&>button]:!text-sm [&>button]:!font-semibold [&>button]:!text-brand-primary [&>button]:border-solid [&>button]:!font-normal [&>button]:!text-xl [&>button]:!text-gray-500 [&>button:focus]:!ring-0 !mb-0'
      );
    }

    return (
      baseClass +
      ' [&>button]:!text-header-primary [&>button]:!px-6 [&>button]:!py-5 [&>button]:!text-base [&>button]:!ring-0 [&>button>svg]:!ml-4'
    );
  });

  const contentClass = computed(() => {
    const baseClass =
      backgroundClass.value +
      '[&>div]:!text-body-secondary [&>div]:!border-0 [&>div]:!text-sm';

    if (props.variant === 'nav') {
      return baseClass + ' [&>div]:!px-2 [&>div]:!pt-2 [&>div]:!pb-1';
    }

    if (props.variant === 'reporting') {
      return (
        baseClass + ' [&>div]:!bg-white [&>div]:!rounded-b-lg [&>div]:!py-0'
      );
    }

    return baseClass + ' [&>div]:!px-6 [&>div]:!pt-1';
  });
</script>

<template>
  <Accordion
    flush
    :header="header"
    :class="`w-full ${variant === 'nav' && '!mb-0'} `"
  >
    <!--Hax because flowbite is bad. Need a "first" hidden panel so that the "second"-->
    <!--panel does not open by default-->
    <AccordionPanel v-show="false" v-if="!initialOpen"></AccordionPanel>
    <AccordionPanel :class="`${variant} rounded-[10px]`">
      <AccordionHeader :class="headerClass">
        {{ header }}
      </AccordionHeader>
      <AccordionContent :class="contentClass">
        <slot />
      </AccordionContent>
    </AccordionPanel>
  </Accordion>
</template>

<style scoped>
  .primary {
    box-shadow: 0 4px 30px rgba(7, 12, 54, 0.1);
  }

  :deep(.reporting) {
    @apply !rounded-lg border-[1px] border-solid border-[#E8ECF4];
  }
</style>

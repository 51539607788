<script lang="ts" setup>
  import { reactive, ref } from 'vue';
  import { isAxiosError } from 'axios';

  import { UiAlert, UiButton, UiFormInput, UiModal } from '@ui-lib';

  interface IProps {
    handleConfirm: () => null;
    heading?: string;
    confirmation?: string;
  }

  const props = withDefaults(defineProps<IProps>(), {
    heading: 'Delete Confirmation',
    confirmation: 'Delete',
  });
  const emit = defineEmits<{
    (e: 'close'): void;
  }>();

  const form = reactive({
    confirm: '',
  });
  const validationError = reactive({
    confirm: '',
  });
  const error = ref();

  function handleClose() {
    emit('close');
  }

  async function handleSubmit() {
    error.value = '';

    if (form.confirm?.toUpperCase() !== props.confirmation.toUpperCase()) {
      return (validationError.confirm = `Please type "${props.confirmation.toUpperCase()}" to confirm this deletion.`);
    }

    try {
      await props.handleConfirm();
      handleClose();
    } catch (e) {
      error.value =
        'There was an error deleting this entry. Please try again later.';

      if (isAxiosError(e)) {
        const data = e?.response?.data;
        error.value = Object.values(data)?.[0]?.[0];
      }
    }
  }
</script>

<template>
  <UiModal
    class="[&>div>div>div]:rounded-[20px]"
    :close-on-click-outside="false"
    @close="emit('close')"
  >
    <form
      v-click-outside="handleClose"
      class="flex flex-col p-6"
      @submit.prevent="handleSubmit"
    >
      <h2 class="mb-2 text-lg font-semibold text-red-500 sm:text-2xl">
        {{ heading }}
      </h2>
      <UiAlert v-if="!!error" class="mb-4 w-full" type="danger">
        {{ error }}
      </UiAlert>
      <span v-if="!validationError.confirm" class="form-label mb-1">
        Type {{ confirmation }} to confirm
      </span>
      <span v-else class="form-label mb-1 uppercase !text-red-500">
        {{ validationError.confirm }}
      </span>
      <UiFormInput
        v-model="form.confirm"
        :class="`mb-4 ${
          validationError.confirm && ' [&>div>input]:!border-red-500'
        }`"
        :placeholder="confirmation.toUpperCase()"
        type="text"
        @input="validationError.confirm = ''"
      />
      <div class="mb-4 flex justify-end">
        <UiButton
          variant="app-home"
          class="mr-4"
          color="white"
          type="button"
          @click="handleClose"
        >
          Cancel
        </UiButton>
        <UiButton variant="app-home" type="submit" color="red">
          Confirm
        </UiButton>
      </div>
    </form>
  </UiModal>
</template>

<style lang="css">
  .form-label {
    @apply text-header-secondary text-base font-medium;
  }
</style>

<script lang="ts" setup>
  import { computed, onBeforeMount, reactive } from 'vue';
  import { DateTime } from 'luxon';
  import { useRepo } from 'pinia-orm';

  import { Skill } from '@orm-lib';
  import { UiForm, UiFormNavigation } from '@ui-lib';

  interface IProps {
    skillId?: number;
    timezone?: string;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<{
    (e: 'next', form: any): void;
    (e: 'prev'): void;
  }>();

  const form = reactive({
    skill: 0,
    skillOptions: [],
    submitted: false,
    timezone: '',
    timezoneOptions: [],
  });

  const skill = computed(() => {
    return useRepo(Skill).find(form.skill);
  });

  function getState(submitted, errors) {
    if (submitted && errors.length !== 0) return false;
    return null;
  }

  function next() {
    const updatedForm = {
      timezone: form.timezone,
      skill_id: form.skill,
    };
    emit('next', updatedForm);
  }

  onBeforeMount(async () => {
    form.skill = props.skillId;
    form.skillOptions = await Skill.getAsOptions();
    form.timezone = props.timezone ?? DateTime.local().toFormat('z');

    const timezones = (Intl as any).supportedValuesOf('timeZone');

    form.timezoneOptions = timezones.map((name) => {
      const shortName = DateTime.fromObject(
        { year: 2022 },
        { zone: name }
      ).toFormat('ZZZZ');

      return {
        value: name,
        text: `${name} (${shortName})`,
      };
    });
  });
</script>

<template>
  <UiForm variant="config" title="Collaborator Feedback" @submit="next">
    <template #header>
      <slot />
    </template>
    <div class="form-question"><em>What is collaborator feedback?</em></div>
    <div class="form-subtitle">
      This learner is about to start a new skill training with Backstage. As
      part of the process, we collect feedback about this learner's competence
      from their collaborators.
    </div>
    <div class="form-question"><em>Why am I being asked for feedback?</em></div>
    <div class="form-subtitle">
      This learner has indicated that you are an important collaborator with
      regard to the skill training in question.
      <br />
      <br />
      Rest assured that your feedback will
      <strong>never</strong>
      be exposed individually and
      <strong>only</strong>
      be used in aggregate.
    </div>
    <UiFormNavigation
      class="mb-0"
      show-previous
      prev-text="Cancel"
      @prev="emit('prev')"
    />
  </UiForm>
</template>

<script lang="ts" setup>
import { useSlots, computed } from "vue";

interface IProps {
    completed?: boolean,
    currentStep?: number,
    stepCount?: number,
}

const props = withDefaults(defineProps<IProps>(), {
    completed: false,
    currentStep: 1,
    stepCount: 1,
});

const slots = useSlots()

const progressBarStyle = computed(() => {
    if (show.value === false)
        return '';

    return 'width:' + Math.round((props.currentStep / props.stepCount) * 100) + '%'
})

const show = computed(() => {
    if (props.currentStep <= props.stepCount) {
        return !!slots.default;
    }
    return false
});
</script>

<template>
    <div v-if="show" class="flex flex-col gap-[8px] px-[24px] md:px-[32px]">
        <div class="text-[26px] md:text-[32px] text-blackish font-[700]">
            <slot></slot>
        </div>
        <div class="relative">
            <div class="absolute top-0 w-full h-[6px] bg-lightGrey rounded-[3px]"></div>
            <div class="absolute top-0 h-[6px] bg-golden rounded-[3px] transition-all duration-[200ms]" :style=progressBarStyle></div>
        </div>
        <div class="text-right text-[12px] font-[300] text-darkGrey uppercase">
            <span v-if="completed === false">Step {{ currentStep }} of {{ stepCount }}</span>
            <span v-else>Completed</span>
        </div>
    </div>
</template>
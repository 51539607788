import { type Ref, computed } from 'vue';

export const useBarGradient = (from: Ref<number>, to: Ref<number>) => {
  const barWidth = computed(() => {
    const ratingRange = Number(to.value) - Number(from.value);

    return (ratingRange / 9) * 100;
  });

  const barLeftPosition = computed(() => {
    return ((Number(from.value) - 1) / 9) * 100;
  });

  const barGradient = computed(() => {
    return `linear-gradient(to right, #BF81D3, #7587FE 50%, #5BBEFA)`;
  });

  const barStyles = computed(() => {
    return `width: ${barWidth.value}%; margin-left:${barLeftPosition.value}%; background-image: ${barGradient.value};`;
  });

  return {
    barWidth,
    barStyles,
  };
};

import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Num, Str } from 'pinia-orm/dist/decorators';

export default class Product extends Model {
  static entity = 'product';

  @Num(null) declare id: number;
  @Str('') declare name: string;
  @Num(null) declare price: number;

  static api() {
    return {
      async list() {
        const { data } = await axios.get('/product');
        const repo = useRepo(Product);
        repo.flush();
        repo.save(data);
      },
    };
  }
}

<template>
  <svg
    width="30"
    height="42"
    viewBox="0 0 30 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 41C2.34315 41 1 39.6569 1 38L0.999998 4C0.999998 2.34315 2.34314 1 4 1L16.2239 0.999999C17.0091 0.999999 17.7629 1.30781 18.3237 1.85736L23.2286 6.66421L28.0998 11.4379C28.6756 12.0022 29 12.7744 29 13.5806L29 38C29 39.6569 27.6569 41 26 41L4 41Z"
      fill="white"
      stroke="#DCE0E9"
      stroke-width="2"
    />
    <path d="M17.8574 0.75V12.75H29.286" stroke="#DCE0E9" stroke-width="2" />
  </svg>
</template>

<script lang="ts" setup>
import { reactive, ref } from "vue";

import type { UseCase, UseCasePayload } from '@/components/public/types';
import { UseCasePayloadCreate } from '@/components/public/types';
import { FormBase, ChoiceRow } from './'

type Nullable<T> = T | null;

defineOptions({ version: 1 })

interface IProps {
    currentStep?: number,
    stepCount?: number,
}

const props = withDefaults(defineProps<IProps>(), {
    currentStep: 1,
    stepCount: 1,
});

interface IEmits {
    (e: 'back'): void;
    (e: 'submit', value:UseCasePayload): void;
}

const emit = defineEmits<IEmits>();

const errorMessage = ref<string | null>(null)

const data = reactive(UseCasePayloadCreate())

function handleBack() {
    emit('back')
}

function  handleChange(inputValues: string[]) {
    data.type = inputValues[0] as UseCase
}

async function handleSubmit() {
    if (data.type === null) {
        errorMessage.value = 'Select your role to continue.'
    } else {
        errorMessage.value = null;
        emit('submit', data)
    }
}
</script>

<template>
    <FormBase
        @back=handleBack
        @submit=handleSubmit
        :current-step=currentStep
        :error-message=errorMessage
        mode=sign-up
        title="Your Setup"
        :step-count=stepCount
        subtitle="How do you intend to use Backstage? This can be changed later."
    >
        <div class="flex flex-col gap-[4px] md:gap-[8px]">
            <ChoiceRow
                name="use-case-type"
                subtitle="I want to use Backstage with people I manage directly."
                title="My Team"
                value="manager"
                @change=handleChange
            />
            <ChoiceRow
                name="use-case-type"
                subtitle="I want to give Backstage to the managers of the teams I am responsible for."
                title="Teams In My Department"
                value="leadership"
                @change=handleChange
            />
            <ChoiceRow
                name="use-case-type"
                subtitle="I want to use Backstage to support company-wide learning initiatives."
                title="Learning & Development"
                value="learning"
                @change=handleChange
            />
            <ChoiceRow
                name="use-case-type"
                subtitle="I just want to try Backstage out for myself."
                title="Just Me"
                value="individual"
                @change=handleChange
            />
        </div>
    </FormBase>
</template>
import axios from 'axios';
import { Model } from 'pinia-orm';
import { Attr, Bool, Num, Str } from 'pinia-orm/dist/decorators';

export default class TrainingSubscription extends Model {
  static entity = 'training-subscription';

  @Num(null) declare id: number;
  @Attr(null) declare available_downgrades: any[];
  @Attr(null) declare available_upgrades: any[];
  @Str(null) declare created: string;
  @Str(null) declare ends: string;
  @Str(null) declare expires: string;
  @Num(null) declare learner_id: number;
  @Bool(null) declare is_cancelable: boolean;
  @Num(null) declare skill_id: number;
  @Str(null) declare starts: string;
  @Str(null) declare status: 'Past' | 'Present' | 'Future';
  @Str(null) declare tier: 'Basic' | 'Lite' | 'Focused';
  @Num(null) declare training_request_item_id: number;
  @Str(null) declare meeting_recording_available: string;

  static api() {
    return {
      async downgradeSubscription(subscriptionId: number) {
        await axios.post(`/training-subscription/${subscriptionId}/downgrade`);
      },
      async cancelSubscription(subscriptionId: number) {
        await axios.post(`/training-subscription/${subscriptionId}/cancel`);
      },
    };
  }
}

<script lang="ts" setup>
  import { computed, reactive, ref } from 'vue';

  import { UiForm, UiFormNavigation } from '@ui-lib';
  import UserSearch from '@/components/forms/UserSearch.vue';
  import PanelListItem from '@/components/PanelListItem.vue';

  interface IProps {
    collaboratorEmails: string[];
    collaboratorIds: number[];
    collaboratorMinimum: number;
    error: string;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<{
    (e: 'next'): void;
    (e: 'prev'): void;
  }>();

  const form = reactive({
    rating: null as number | null,
    collaborators: [],
  });

  const localError = ref('');

  const collaboratorList = computed(() => {
    return form.collaborators.map((collaborator) => {
      return 'email' in collaborator ? collaborator.email : collaborator.name;
    });
  });

  function addCollaborator(result) {
    if ('email' in result.result && !result.result.in_use) {
      localError.value = '';
      form.collaborators.push(result.result);
    } else if ('name' in result.result && result.result.name) {
      localError.value = '';
      form.collaborators.push(result.result);
    }
  }

  function removeCollaborator(person) {
    const index = form.collaborators.findIndex(
      (collaborator) =>
        collaborator.name === person || collaborator.email === person
    );

    if (index > -1) {
      form.collaborators.splice(index, 1);
    }
  }

  function submit() {
    const emails = form.collaborators
      .filter((manager) => 'email' in manager)
      .map((collaborator) => collaborator.email);
    const ids = form.collaborators
      .filter((manager) => 'id' in manager)
      .map((collaborator) => collaborator.id);

    if (emails.length + ids.length < props.collaboratorMinimum) {
      return (localError.value = `Please invite ${props.collaboratorMinimum} or more collaborators to proceed with training.`);
    }

    const updatedForm = {
      collaborator_ids: ids,
      collaborator_emails: emails,
    };

    emit('next', updatedForm);
  }
</script>

<template>
  <UiForm
    :error="error || localError"
    show-prev
    title="Skill"
    submit-text="Start"
    variant="config"
    @submit="submit"
    @prev="emit('prev')"
  >
    <template #header>
      <slot />
    </template>
    <div class="form-question">What are collaborators?</div>
    <div class="form-body text-body-primary mb-6 text-sm opacity-50">
      Collaborators are the people you work with for whom mastery of this skill
      will matter most. We will ask each collaborator for
      <em><strong>anonymous</strong></em>
      feedback about your skill improvement. Their feedback will
      <em><strong>only</strong></em>
      be used in aggregate and will
      <em><strong>never</strong></em>
      be shared individually.
    </div>
    <UserSearch
      style="margin-bottom: 20px"
      member-type="learnerAssignmentStart"
      placeholder="Add a collaborator by name or email"
      @select-result="addCollaborator"
    />
    <template v-if="form.collaborators.length > 0">
      <div class="form-group-title self-start">Collaborators</div>
      <div class="form-list mb-2 w-full">
        <PanelListItem
          v-for="collaborator in collaboratorList"
          :key="collaborator.id"
          class="dark w-full"
          show-delete
          :title="collaborator"
          :value="collaborator"
          @delete="removeCollaborator(collaborator)"
        />
      </div>
    </template>
    <div class="disclaimer self-start">
      Provide at least {{ collaboratorMinimum }} collaborators to continue.
    </div>
    <UiFormNavigation
      class="mb-0 mt-6"
      show-previous
      prev-text="Cancel"
      @prev="emit('prev')"
    />
  </UiForm>
</template>

<style scoped>
  .disclaimer {
    text-align: start;
    font-size: 13px;
    color: #808080;
    margin-top: -10px;
  }
</style>

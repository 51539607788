<script lang="ts" setup>
  import { computed, ref } from 'vue';

  import { Skill } from '@orm-lib';
  import { UiButton, UiDropdown, UiForm, UiRouterLink } from '@ui-lib';

  interface IProps {
    allowAny?: boolean;
    disabled?: false;
    exclude?: any[] | null;
    relatedId: number;
    title?: string;
  }

  const props = withDefaults(defineProps<IProps>(), {
    allowAny: true,
    disabled: false,
    exclude: null,
    title: 'Trainings',
  });
  const emit = defineEmits(['help', 'select']);
  defineExpose(['setSkill', 'help', 'select']);

  const id = ref(false);

  const filteredOptions = computed(() => {
    return options.value?.filter((option) => {
      return !props.exclude?.includes(option.value);
    });
  });

  const selectedText = computed(() => {
    if (id.value) {
      return options.value.find((option) => option.value === id.value)?.text;
    }

    if (id.value === null) {
      return 'Allow the learner to choose';
    }

    return 'Select a skill';
  });

  const options = computed(() => {
    return Skill.getAsOptions(props.allowAny);
  });

  async function onHelpClick() {
    emit('help', props.relatedId);
  }

  async function onSelect() {
    emit('select', { relatedId: props.relatedId, id: id.value });
    id.value = false;
  }

  async function setSkill(skillId) {
    id.value = skillId;
  }
</script>

<template>
  <UiForm
    class="[&>h1]:form-group-title !mb-0 w-full justify-start p-0"
    :title="title"
    variant="inline"
    @submit="onSelect"
  >
    <div class="flex flex-row items-center justify-between">
      <UiRouterLink
        class="cta-link absolute right-0 mr-2 mb-2 self-end justify-self-end"
        @click="onHelpClick"
      >
        Help me decide
      </UiRouterLink>
    </div>
    <div class="flex w-full flex-row">
      <UiDropdown
        v-model="id"
        class="w-full"
        :text="selectedText"
        :options="filteredOptions"
        :disabled="disabled"
        variant="form"
        @select="setSkill"
      />
      <UiButton
        type="submit"
        class="icon !bg-brand-primary ml-2 h-[46px] w-[48px] self-end !rounded-lg !p-0"
        variant="icon"
        :disabled="id === false || disabled"
      >
        <img src="/assets/img/icon/save.png" class="h-[17px] w-4" />
      </UiButton>
    </div>
  </UiForm>
</template>

<script lang="ts" setup>
  import { computed, reactive } from 'vue';
  import axios from 'axios';

  import { Coach, Employer, OrganizationAdmin } from '@orm-lib';
  import { UiFormGroup, UiFormInput } from '@ui-lib';

  type TMemberType =
    | 'coach'
    | 'learnerAssignmentStart'
    | 'employerAdmin'
    | 'organizationAdmin'
    | 'employerOnboarding'
    | 'groupLearner'
    | 'groupOwner';

  const props = withDefaults(
    defineProps<{
      cohortId?: number;
      onboardingKey?: string;
      memberType: TMemberType;
      placeholder?: string;
    }>(),
    { placeholder: 'Add a person by name or email' }
  );
  const emit = defineEmits(['selectResult']);

  const form = reactive<{
    search: { focused: boolean; results: any[]; term: string | null };
  }>({
    search: {
      focused: false,
      results: [],
      term: null,
    },
  });
  const isSearchResultEmpty = computed(() => form.search.results.length === 0);
  const showResults = computed(() => {
    return !isSearchResultEmpty.value && form.search.focused === true;
  });

  const getSearchResultVariant = (result: any) => {
    if (result?.type === 'email' && result?.result.in_use === true)
      return 'danger';

    return null;
  };

  const isResultActionable = (result: any) => {
    return !(result.type === 'email' && result.result.in_use === true);
  };

  const onSearchDismiss = () => {
    return (form.search.focused = false);
  };

  const selectResult = (result: any) => {
    if (!isResultActionable(result)) return;

    form.search.results = [];
    form.search.term = null;
    emit('selectResult', result);
  };

  const onSearchResultSelect = (result: any) => {
    selectResult(result);
  };

  const onSearchSubmit = () => {
    if (showResults.value) {
      selectResult(form.search.results[0]);
    }
  };

  const onSearchTermFocus = () => {};

  const onSearchTermUpdate = async () => {
    form.search.focused = true;

    if (form.search.term === '' || form.search.term === null) {
      form.search.term = null;
      form.search.results = [];
      return;
    }

    try {
      if (props.memberType === 'cohortLearner') {
        const { data } = await axios.post('/cohort-learner/search', {
          search_term: form.search.term,
          cohort_id: props.cohortId ?? null,
        });

        form.search.results = data;
      } else if (props.memberType === 'cohortManager') {
        const { data } = await axios.post('/cohort-manager/search', {
          search_term: form.search.term,
          cohort_id: props.cohortId ?? null,
        });
        form.search.results = data;
      } else if (props.memberType === 'employerAdmin')
        form.search.results = await Employer.api().searchAdmin(
          form.search.term
        );
      else if (props.memberType === 'organizationAdmin')
        form.search.results = await OrganizationAdmin.api().search(
          form.search.term
        );
      else if (props.memberType === 'coach')
        form.search.results = await Coach.api().search(form.search.term);
      else if (props.memberType === 'employerOnboarding') {
        const { data } = await axios.post('/employer-onboarding/search', {
          onboarding_key: props.onboardingKey ?? '',
          search_term: form.search.term,
        });

        form.search.results = data;
      } else if (props.memberType === 'learnerAssignmentStart') {
        const { data } = await axios.post('/collaborator/search', {
          search_term: form.search.term,
        });

        form.search.results = data;
      } else if (props.memberType === 'groupLearner') {
        const { data } = await axios.post('/group-learner/search', {
          search_term: form.search.term,
        });

        form.search.results = data;
      } else if (props.memberType === 'groupOwner') {
        const { data } = await axios.post('/group-owner/search', {
          search_term: form.search.term,
        });

        form.search.results = data;
      }
    } catch (e) {
      form.search.results = [];
    }
  };
</script>

<template>
  <form class="w-full" @submit.prevent="onSearchSubmit">
    <UiFormGroup class="!mb-4 w-full">
      <div v-click-outside="onSearchDismiss" class="relative w-full">
        <UiFormInput
          v-model="form.search.term"
          class="w-full"
          :placeholder="placeholder"
          type="text"
          variant="config"
          @focus="onSearchTermFocus"
          @input="onSearchTermUpdate"
        />
        <ul v-if="showResults" class="absolute z-10 mt-1 w-full bg-white">
          <li
            v-for="result in form.search.results"
            :key="result.key"
            :class="`search-result-item ${getSearchResultVariant(result)}`"
            @click.prevent="onSearchResultSelect(result)"
          >
            <span v-if="result.type === 'coach'">
              Add
              <b>{{ result.result.name }}</b>
            </span>
            <span v-if="result.type === 'learner'">
              Add
              <b>{{ result.result.name }}</b>
            </span>
            <span
              v-if="result.type === 'email' && result.result.in_use === false"
            >
              Invite
              <b>{{ result.result.email }}</b>
            </span>
            <span
              v-if="result.type === 'email' && result.result.in_use === true"
            >
              <b>{{ result.result.email }}</b>
              is in use
            </span>
          </li>
        </ul>
      </div>
    </UiFormGroup>
  </form>
</template>

<style scoped>
  .search-result-item {
    border: 1px solid rgba(36, 36, 49, 0.3);
    @apply hover:bg-brand-primary text-body-primary cursor-pointer rounded p-[10px] hover:text-white;
  }

  .search-result-item.danger {
    @apply cursor-default border-red-700 bg-red-100 text-red-700;
  }
</style>

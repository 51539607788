<script lang="ts" setup>
import { Error, FormSubmit } from '@/components/public/forms'

interface IProps {
    currentStep?: number,
    errorMessage?: string | null,
    mode?: 'sign-up' | 'log-in' | 'forgot-password' | 'reset-password' | 'create-account' | null,
    subtitle: string | null,
    stepCount?: number
    title: string | null,
}

const props = withDefaults(defineProps<IProps>(), {
    currentStep: 1,
    errorMessage: null,
    mode: null,
    stepCount: 1,
});

interface IEmits {
    (e: 'back'): void;
    (e: 'forgot'): void;
    (e: 'logIn'): void;
    (e: 'submit'): void;
}

const emit = defineEmits<IEmits>();

function handleBack() {
    emit('back')
}

function handleForgot() {
    emit('forgot')
}

function handleLogIn() {
    emit('logIn')
}

function handleSubmit() {
    emit('submit')
}

</script>

<template>
    <div class="flex flex-col p-[24px] md:p-[32px] bg-white border-[1px] border-lightGrey rounded-[16px] gap-[16px] md:gap-[24px]">
        <div class="flex flex-col pb-[8px] border-b-[1px] border-lightGrey">
            <div class="text-blackish text-[22px] md:text-[26px] font-[600]">
                {{ title }}
            </div>
            <div class="text-darkGrey text-[16px] md:text-[18px] font-[300]">
                {{ subtitle }}
            </div>
        </div>
        <Error :message=errorMessage />
        <slot></slot>
        <FormSubmit
            v-if="mode !== null"
            :current-step=currentStep
            :mode=mode
            :step-count=stepCount
            @back=handleBack
            @forgot=handleForgot
            @log-in=handleLogIn
            @submit=handleSubmit
        />
    </div>
</template>
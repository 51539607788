import { Model } from 'pinia-orm';
import { Attr, Num, Str } from 'pinia-orm/dist/decorators';

interface IPaymentMethod {
  id: string;
  card_brand: string;
  created: string;
  employer_billing_id: number;
  expire_month: number;
  expire_year: number;
  last_4_digits: string;
}

export default class EmployerBilling extends Model {
  static entity = 'employer-billing';

  @Num(null) declare id: number;
  @Num(null) declare employer_id: number;
  @Str('') declare invoice_type: string;
  @Str('') declare created: string;
  @Num(null) declare days_to_pay: number;
  @Attr(null) declare payment_method: IPaymentMethod;
}

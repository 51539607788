<script lang="ts" setup>
import { reactive, ref } from "vue";

import axios, { AxiosError } from "axios";

import type { BasicInfoPayload } from '@/components/public/types';
import { BasicInfoPayloadCreate } from '@/components/public/types';
import { FormBase, TextRow } from './'

type Nullable<T> = T | null;

defineOptions({ version: 1 })

interface IProps {
    currentStep?: number,
    stepCount?: number,
}

const props = withDefaults(defineProps<IProps>(), {
    currentStep: 1,
    stepCount: 1,
});

interface IEmits {
    (e: 'back'): void;
    (e: 'submit', value:BasicInfoPayload): void;
}

const emit = defineEmits<IEmits>();

const errorMessage = ref<string | null>(null)

const data = reactive(BasicInfoPayloadCreate())

const companyName = ref<typeof TextRow | null>(null)
const workEmail = ref<typeof TextRow | null>(null)
const inputs = [
    companyName,
    workEmail
]

function handleBack() {
    emit('back')
}

function handleCompanyNameChange(inputValue: Nullable<string>) {
    data.company_name = inputValue
}

function handleWorkEmailChange(inputValue: Nullable<string>) {
    data.work_email = inputValue
}

async function handleSubmit() {
    let errorCount = 0;

    inputs.forEach((input) => {
        let error = input.value?.validate()
        if (error !== null) {
            errorCount += 1;
        }
    });

    if (errorCount !== 0)
        return

    try {
        await axios.post('/signup/basic-info', {
            company_name: data.company_name,
            work_email: data.work_email
        });
        errorMessage.value = null
    } catch(e) {
        errorCount = 1
        const axiosError = e as AxiosError
        const errors = axiosError.response?.data as object
        errorMessage.value = Object.values(errors)[0][0]
    }

    if (errorCount === 0) {
         emit('submit', data)
    }
}
</script>

<template>
    <FormBase
        @back=handleBack
        @submit=handleSubmit
        :current-step=currentStep
        :error-message=errorMessage
        mode=sign-up
        title="Basic Information"
        :step-count=stepCount
        subtitle="Let's start with the basics about you and your company."
    >
        <TextRow id="basic-info-company-name" ref="companyName" :required="true"  title="Company Name" type="text" @change=handleCompanyNameChange @submit=handleSubmit />
        <TextRow id="basic-info-work-email" ref="workEmail" :required="true" title="Work Email" type="text" @change=handleWorkEmailChange @submit=handleSubmit />
    </FormBase>
</template>
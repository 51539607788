import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Num, Str } from 'pinia-orm/dist/decorators';

export default class Quarter extends Model {
  static entity = 'quarter';

  @Num(null) declare id: number;
  @Str('') declare end: string;
  @Num(null) declare key: number;
  @Num(null) declare quarter: number;
  @Str('') declare start: string;
  @Num(null) declare year: number;

  static api() {
    return {
      async list() {
        const { data } = await axios.get(`/quarter/`);
        const repo = useRepo(Quarter);

        repo.save(
          data.map((datum) => {
            return {
              id: datum.key,
              ...datum,
            };
          })
        );
      },
      async detail(key: number) {
        const { data } = await axios.get(`/quarter/${key}`);
        const repo = useRepo(Quarter);

        repo.save({
          id: data.key,
          ...data,
        });
      },
    };
  }
}

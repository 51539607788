<script lang="ts" setup>
  import { useAttrs } from 'vue';

  interface IProps {
    href: string;
  }
  const props = defineProps<IProps>();
  const attrs = useAttrs();
</script>

<template>
  <a :href="href" class="hover:underline" v-bind="attrs"><slot /></a>
</template>

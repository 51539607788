type Nullable<T> = T | null;

// ACCEPT INVITE

export type AcceptInviteFormPayload = {
    first_name: string,
    last_name: string,
    confirmation: string,
    password: string,
}

export function AcceptInviteFormPayloadCreate(): AcceptInviteFormPayload {
    return {
        first_name: '',
        last_name: '',
        confirmation: '',
        password: '',
    }
}

// FORGOT PASSWORD

export type ForgotPasswordFormPayload = {
    email: string,
}

export function ForgotPasswordFormPayloadCreate(): ForgotPasswordFormPayload {
    return {
        email: '',
    }
}

// LOG IN

export type LogInFormPayload = {
    email: string,
    password: string
}

export function LogInFormPayloadCreate(): LogInFormPayload {
    return {
        email: '',
        password: ''
    }
}

// RESET PASSWORD

export type ResetPasswordFormPayload = {
    confirmation: string,
    password: string,
}

export function ResetPasswordFormPayloadCreate(): ResetPasswordFormPayload {
    return {
        confirmation: '',
        password: '',
    }
}

// SIGN UP

export type UseCase = 'manager' | 'leadership' | 'learning' | 'individual' | null

export type AssessmentQuestionPayload = {
    answer_ids: number[],
    question_id: number
}

export function AssessmentQuestionPayloadCreate(questionId:number): AssessmentQuestionPayload {
    return {
        answer_ids: [],
        question_id: questionId
    }
}

export type BasicInfoPayload = {
    company_name: Nullable<string>,
    work_email: Nullable<string>,
}

export function BasicInfoPayloadCreate():BasicInfoPayload {
    return {
        company_name: null,
        work_email: null
    }
}

export type GroupCreatePayload = {
    group_name: Nullable<string>,
    group_owners: Array<string>,
    group_learners: Array<string>,
}

export function GroupCreatePayloadCreate():GroupCreatePayload {
    return {
        group_name: null,
        group_owners: [],
        group_learners: []
    }
}

export type UseCasePayload = {
    type: UseCase,
}

export function UseCasePayloadCreate(): UseCasePayload {
    return {
        type: null,
    }
}

export type UseGoalsPayload = {
    add_goal: Nullable<boolean>,
}

export function UseGoalsPayloadCreate(): UseGoalsPayload {
    return {
        add_goal: null,
    }
}

export type SignUpPayload = {
    assessment_questions: { [key: number]: AssessmentQuestionPayload }
    basic_info: BasicInfoPayload,
    group_create: GroupCreatePayload,
    email_campaign: Nullable<string>,
    use_case: UseCasePayload,
    use_goals: UseGoalsPayload,

}

export function SignUpPayloadCreate():SignUpPayload {
    return {
        assessment_questions: {},
        basic_info: BasicInfoPayloadCreate(),
        group_create: GroupCreatePayloadCreate(),
        email_campaign: null,
        use_case: UseCasePayloadCreate(),
        use_goals: UseGoalsPayloadCreate(),
    }
}
<script lang="ts" setup>
  import { computed, nextTick, reactive, ref, watch } from 'vue';

  import { Recording, RecordingNote } from '@orm-lib';
  import { UiButton, UiFormTextarea } from '@ui-lib';
  import { useApp } from '@/composables';

  const props = defineProps<{
    allowEdit?: boolean;
    currentNote: object | null;
    playerId?: string;
    recording: object;
  }>();

  const emit = defineEmits<{
    (e: 'is-adding-note', bool: boolean): void;
  }>();

  const { isCoach } = useApp();

  const tab = ref('Notes');
  const noteTextarea = ref();
  const summaryTextarea = ref();
  const noteEditor = reactive({
    body: null,
    id: null,
    show: false,
  });
  const summaryEditor = reactive({
    show: false,
    summary: null,
  });

  const canEdit = computed(() => {
    return props.allowEdit && isCoach.value;
  });

  const disableSaveNote = computed(() => {
    return !(noteEditor.show && noteEditor.body?.length > 0);
  });

  const isAddingNote = computed(() => {
    return noteEditor.show && noteEditor.id === null;
  });

  const showAddNote = computed(() => {
    return tab.value === 'Notes' && canEdit.value;
  });

  const showDeleteNote = computed(() => {
    return noteEditor.show && noteEditor.id !== null;
  });

  const showEditSummary = computed(() => {
    return tab.value === 'Summary' && canEdit.value;
  });

  const showNoNotes = computed(() => {
    return props.recording.notesSorted.length === 0;
  });

  const showNoSummary = computed(() => {
    return props.recording.summary === null || props.recording.summary === '';
  });

  const showNoteEditor = computed(() => {
    return tab.value === 'Notes' && noteEditor.show;
  });

  const showNoteViewer = computed(() => {
    return tab.value === 'Notes' && !noteEditor.show;
  });

  const showSummaryEditor = computed(() => {
    return tab.value === 'Summary' && summaryEditor.show;
  });

  const showSummaryViewer = computed(() => {
    return tab.value === 'Summary' && !summaryEditor.show;
  });

  function isNoteCurrent(note) {
    return props.currentNote !== null && note.id === props.currentNote.id;
  }

  function onNoteAdd() {
    noteEditor.show = !noteEditor.show;

    if (noteEditor.show) {
      noteEditor.id = null;
      noteEditor.body = null;

      nextTick(() => {
        noteTextarea.value.$refs.textArea.focus();
      });
    }
  }

  function onNoteCancel() {
    noteEditor.show = false;
  }

  async function onNoteDelete() {
    await RecordingNote.api().remove(noteEditor.id);
    noteEditor.show = false;
  }

  function onNoteSave() {
    if (noteEditor.id === null) {
      RecordingNote.api().create(
        props.recording.id,
        noteEditor.body,
        document.getElementById(props.playerId).currentTime
      );
    } else {
      RecordingNote.api().update(
        noteEditor.id,
        noteEditor.body,
        document.getElementById(props.playerId).currentTime
      );
    }
    noteEditor.show = false;
  }

  function onNoteSelect(recordingNote) {
    if (canEdit.value === false) return;

    noteEditor.show = true;
    noteEditor.id = recordingNote.id;
    noteEditor.body = recordingNote.note.body;

    nextTick(() => {
      noteTextarea.value.$refs.textArea.focus();
    });
  }

  function onSummaryCancel() {
    summaryEditor.show = false;
  }

  function onSummaryEdit() {
    summaryEditor.summary = props.recording.summary;
    summaryEditor.show = true;
    nextTick(() => {
      summaryTextarea.value.$refs.textArea.focus();
    });
  }

  function onSummarySave() {
    // TODO: This needs to be fixed
    // eslint-disable-next-line vue/no-mutating-props
    props.recording.summary = summaryEditor.summary;
    Recording.api().update(props.recording);
    summaryEditor.show = false;
  }

  function onTabSelect(value) {
    if (value !== tab.value) {
      tab.value = value;
      noteEditor.show = false;
      summaryEditor.show = false;
    }
  }

  function updateNote(e) {
    noteEditor.body = e.target.value;
  }

  function updateSummary(e) {
    summaryEditor.summary = e.target.value;
  }

  watch(
    () => isAddingNote.value,
    () => {
      emit('is-adding-note', isAddingNote.value);
    }
  );
</script>

<template>
  <div class="flex h-full min-h-[160px]">
    <div class="feedback-navbar">
      <div class="page-section picker-list-section">Feedback</div>
      <div class="flex w-full">
        <UiButton
          variant="icon"
          :pressed="tab === 'Notes'"
          class="picker-button"
          @click="onTabSelect('Notes')"
        >
          Notes
        </UiButton>
        <UiButton
          v-if="showAddNote"
          class="square"
          variant="icon"
          :pressed="isAddingNote"
          @click="onNoteAdd"
        >
          <img
            src="/assets/img/icon/add.png"
            alt="Add Note"
            class="h-[44px] min-w-[44px] p-[14px]"
          />
        </UiButton>
      </div>
      <div class="flex w-full">
        <UiButton
          v-model:pressed="summaryEditor.show"
          variant="icon"
          :pressed="tab === 'Summary'"
          class="picker-button"
          @click="onTabSelect('Summary')"
        >
          Summary
        </UiButton>
        <UiButton
          v-if="showEditSummary"
          class="square"
          variant="icon"
          @click="onSummaryEdit"
        >
          <img
            class="h-[44px] min-w-[44px] p-[14px]"
            src="/assets/img/icon/edit.png"
            alt="Edit Summary"
          />
        </UiButton>
      </div>
    </div>
    <div class="feedback-content">
      <div v-if="showNoteViewer" class="feedback-section">
        <div
          v-for="recordingNote in recording.notesSorted"
          :ref="'recordingNote' + recordingNote.id"
          :key="'recordingNote' + recordingNote.id"
          :class="[
            'feedback-note',
            {
              'feedback-note-current': isNoteCurrent(recordingNote),
              'feedback-note-hover': canEdit,
            },
          ]"
          @click="onNoteSelect(recordingNote)"
        >
          <div class="feedback-note-timecode">
            {{ recordingNote.note.timecode }}
          </div>
          <div class="w-full">{{ recordingNote.note.body }}</div>
        </div>
        <div
          v-if="showNoNotes"
          class="flex h-full flex-col items-center justify-center"
        >
          <div style="color: #808080; font-size: 16px">
            This recording has no notes.
          </div>
        </div>
      </div>
      <div v-else-if="showNoteEditor" class="feedback-section">
        <div class="flex h-full w-full flex-col">
          <div class="h-full w-full" style="padding: 10px">
            <UiFormTextarea
              ref="noteTextarea"
              v-model="noteEditor.body"
              placeholder="Enter a note..."
              class="feedback-note !max-h-[80px] !min-h-[80px]"
              @input="updateNote"
            ></UiFormTextarea>
          </div>
          <div class="flex" style="padding: 0 10px 10px 10px">
            <UiButton
              :disabled="disableSaveNote"
              class="!rounded-[5px]"
              style="margin-right: 5px"
              @click="onNoteSave"
            >
              Save
            </UiButton>
            <UiButton
              v-if="showDeleteNote"
              class="!rounded-[5px]"
              style="margin-right: 5px"
              @click="onNoteDelete"
            >
              Delete
            </UiButton>
            <UiButton class="!rounded-[5px]" @click="onNoteCancel">
              Cancel
            </UiButton>
          </div>
        </div>
      </div>
      <div v-else-if="showSummaryViewer" class="feedback-section">
        <div v-if="!showNoSummary" class="feedback-summary">
          {{ recording.summary }}
        </div>
        <div
          v-if="showNoSummary"
          class="flex h-full flex-col items-center justify-center"
        >
          <div style="color: #808080; font-size: 16px">
            This recording has no summary.
          </div>
        </div>
      </div>
      <div v-else-if="showSummaryEditor" class="feedback-section">
        <div class="flex h-full w-full flex-col">
          <div class="h-full w-full" style="padding: 10px">
            <UiFormTextarea
              ref="summaryTextarea"
              v-model="summaryEditor.summary"
              placeholder="Enter a summary..."
              class="feedback-note !max-h-[80px] !min-h-[80px]"
              @input="updateSummary"
            ></UiFormTextarea>
          </div>
          <div class="flex" style="padding: 0 10px 10px 10px">
            <UiButton
              class="!rounded-[5px]"
              style="margin-right: 10px"
              @click="onSummarySave"
            >
              Save
            </UiButton>
            <UiButton class="!rounded-[5px]" @click="onSummaryCancel">
              Cancel
            </UiButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  :deep(.picker-button) {
    @apply min-h-[44px] w-full !px-5 text-sm font-normal [&>span]:!justify-start [&>span]:!text-left;
  }

  div.feedback-content {
    display: flex;
    flex-flow: column;
    background-color: white;
    border-radius: 0 4px 4px 0;
    font-size: 14px;
    flex: 1 1 100%;
    align-self: stretch;
    border: solid 1px #d9d9d9;
    border-left-width: 0;
  }

  div.picker-list-section {
    margin: 10px 0 0 20px !important;
    @apply text-xs text-white opacity-70;
  }

  div.feedback-navbar {
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 4px 0 0 4px;
    @apply bg-brand-primary flex w-[180px] flex-col;
  }

  textarea.feedback-note {
    resize: none;
    width: 100%;
    height: 100%;
    line-height: 30px;
  }

  div.feedback-note,
  div.feedback-note-current,
  div.feedback-summary {
    display: flex;
    font-size: 14px !important;
    padding: 10px 20px;
    white-space: pre-wrap;
  }

  div.feedback-note-current {
    background-color: #eaeaea !important;
  }

  div.feedback-note-hover:hover {
    background-color: #eaeaea !important;
    cursor: pointer;
  }

  div.feedback-note-timecode {
    flex-grow: 1;
    flex-shrink: 1;
    font-weight: bold;
    padding-right: 10px;
  }

  div.feedback-section {
    display: flex;
    flex-flow: column;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
</style>

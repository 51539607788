<script lang="ts" setup>
  import { computed } from 'vue';

  import IconBackstage from '../icons/IconBackstage.vue';

  interface IProps {
    color?: 'brand-primary' | 'white';
    headingColor?: 'brand-primary' | 'white' | 'black';
    size?: 'md' | 'lg';
    variant?: 'primary' | 'icon';
  }
  const props = withDefaults(defineProps<IProps>(), {
    color: 'brand-primary',
    size: 'lg',
    variant: 'primary',
  });

  const headingClass = computed(() => {
    const baseClass =
      'font-avenir text-[25px] font-extrabold leading-[34px] tracking-[0.065rem]';

    if (props.headingColor) {
      return baseClass + ` !text-${props.headingColor}`;
    }

    if (props.color === 'white') {
      return baseClass + ' text-white';
    }

    return baseClass;
  });
</script>

<template>
  <div class="flex flex-row items-center">
    <IconBackstage class="-mt-0.5 mr-3" :color="color" :size="size" />
    <h1 v-if="variant === 'primary'" :class="headingClass">BACKSTAGE</h1>
  </div>
</template>

<script lang="ts" setup>
  import { DateTime } from 'luxon';

  import { UiAccordion } from '@ui-lib';

  const props = defineProps<{
    headings: string[];
    data: {
      type: 'string' | 'date' | 'percent' | 'dollar';
      value: string;
      placeholder?: string;
    }[][];
    isMobile: boolean;
    variant: 'goal-progress' | 'skill-improvement' | 'focused-training';
  }>();

  function formatCell(cell) {
    if (cell.type === 'date' && cell.value) {
      return DateTime.fromISO(cell.value).toFormat('M/d/yyyy');
    }

    if (cell.type === 'string' || cell.type === 'data') {
      return cell.value ?? cell.placeholder;
    }

    if (cell.type === 'percent') {
      return `${cell.value}%`;
    }

    if (cell.type === 'dollar') {
      return `$${cell.value}`;
    }

    return cell.value;
  }

  function formatMobileHeading(row) {
    if (props.variant === 'goal-progress') {
      return `${row[0].value}: ${row[1].value}`;
    }

    if (props.variant === 'focused-training') {
      return `${row[1].value}: ${row[2].value}`;
    }

    return row[0].value;
  }
</script>

<template>
  <div
    class="w-full rounded-[10px] md:border-[1px] md:border-solid md:border-[#E8ECF4] md:bg-white"
  >
    <table class="flex w-full flex-col sm:h-[1px] md:table">
      <tr class="hidden w-full md:table-header-group">
        <th
          v-for="heading in headings"
          :key="heading ?? 'no-text'"
          class="text-brand-primary whitespace-nowrap py-3 px-3 text-left text-sm font-semibold uppercase leading-[18px] first:pl-5 last:pr-5"
        >
          {{ heading }}
        </th>
      </tr>
      <template v-if="data?.length">
        <template v-if="!isMobile">
          <tr
            v-for="row in data"
            :key="`${row[0]}-${row[1]}-${row[2]}-${row[3]}`"
            :class="`border-t-solid flex w-full items-center border-t-[1px] border-[#E8ECF4] px-4 py-3 sm:table-row-group sm:h-full`"
          >
            <td
              v-for="cell in row"
              :key="cell"
              :class="`text-header-primary py-3 px-3 text-sm first:pl-5 last:pr-5 sm:h-full sm:overflow-hidden`"
            >
              {{ formatCell(cell) }}
            </td>
          </tr>
        </template>
        <div v-else class="flex flex-col gap-2">
          <UiAccordion
            v-for="row in data"
            :header="formatMobileHeading(row)"
            variant="reporting"
            class="[&>button>span]:!text-brand-primary"
          >
            <div
              v-for="(cell, index) in row"
              :key="cell.value"
              class="text-header-primary -mx-5 flex flex-col border-b-[1px] border-solid border-[#E8ECF4] bg-white px-5 py-2 first:border-t-[1px] last:rounded-b-lg last:border-b-0"
            >
              <span class="font-semibold">
                {{ headings[index] }}
              </span>
              <span>{{ formatCell(cell) ?? 'N/A' }}</span>
            </div>
          </UiAccordion>
        </div>
      </template>
    </table>
  </div>
</template>

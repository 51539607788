import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import Skill from './skill';

export default class Assignment extends Model {
  static entity = 'assignment';

  @Num(null) declare id: number;
  @Str(null) declare created: string;
  @BelongsTo(() => Skill, 'skill_id') declare skill: Skill;
  @Num(null) declare skill_id: number;
  @Str(null) declare started: string;
  @Str(null) declare completed: string;
  @Str(null) declare coach_match_completed: string;
  @Num(null) declare training_subscription_id: number;

  static api() {
    return {
      async list() {
        const { data } = await axios.get('/assignment');
        const repo = useRepo(Assignment);
        repo.flush();
        repo.save(data);
      },
      async detail(id: number) {
        await axios.get(`/assignment/${id}`);
      },
      async start(id: number, data: any) {
        await axios.put(`/assignment/${id}`, data);

        useRepo(Assignment).destroy(id);
      },
    };
  }

  get entity() {
    return Assignment.entity;
  }

  get key() {
    return `assignment:${this.id}`;
  }

  get name() {
    if (this.skill_id === null) return 'Any Skill';
    if (this.skill === null) return '';

    return this.skill.name;
  }

  static async handleModelMessage(message: {
    payload: Assignment;
    method: 'create' | 'update' | 'delete';
  }) {
    if (message.method === 'create') {
      await useRepo(Assignment).save(message.payload);
    } else if (message.method === 'update') {
      await useRepo(Assignment).save(message.payload);
    } else if (message.method === 'delete') {
      useRepo(Assignment).destroy(message.payload.id);
    }
  }
}

<script lang="ts" setup>
  import { computed } from 'vue';

  import { UiDropdown, UiFormRadio } from '@ui-lib';
  import { TRAININGS_PER_YEAR_OPTIONS } from '@/constants';

  const props = defineProps<{
    cadence?: number;
    mode: 'mode' | 'automatic';
  }>();
  const emit = defineEmits<{
    (e: 'updateCadence', cadence: number): void;
    (e: 'updateMode', mode: 'manual' | 'automatic'): void;
  }>();

  const selectedOption = computed(() => {
    return TRAININGS_PER_YEAR_OPTIONS.find(
      (option) => option.value === props.cadence
    )?.text;
  });
</script>

<template>
  <div class="flex flex-col">
    <div class="option-row">
      <UiFormRadio
        id="automatic-option"
        :checked="mode === 'automatic'"
        class="form-radio"
        value="automatic"
        @change="emit('updateMode', 'automatic')"
      />
      <div class="option-content">
        <div class="option-title">Automatic</div>
        <div class="form-subtitle">
          Backstage works with both managers and learners of this cohort to
          select the most valuable skill for each training.
        </div>
        <div class="form-group-title">Cadence</div>
        <div class="form-subtitle" style="padding-bottom: 8px">
          Decide how often learners should be trained.
        </div>
        <UiDropdown
          class="w-full"
          :disabled="mode === 'manual'"
          :options="TRAININGS_PER_YEAR_OPTIONS"
          :text="selectedOption"
          variant="form"
          @select="(value) => emit('updateCadence', value)"
        />
      </div>
    </div>
    <div class="option-row" style="margin-top: 20px">
      <UiFormRadio
        id="manual-option"
        value="manual"
        :checked="mode === 'manual'"
        class="form-radio"
        @change="emit('updateMode', 'manual')"
      />
      <div class="option-content">
        <div class="option-title">Manual</div>
        <div class="form-subtitle">
          Managers can assign trainings at any time.
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .option-title {
    text-align: left;
  }

  .option-content {
    flex: 1;
  }
  .option-content .form-group-title {
    line-height: 1;
    @apply mt-4;
  }
  .option-content .form-subtitle {
    font-size: 13px;
    line-height: 1.25;
    @apply text-header-primary mb-1 text-sm opacity-30;
  }

  .option-row {
    display: flex;
  }
</style>

<script lang="ts" setup>
  import { computed, onBeforeMount, reactive, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import axios from 'axios';
  import { capitalize } from 'lodash-es';
  import { useRepo } from 'pinia-orm';

  import { GroupLearner, Skill } from '@orm-lib';
  import { UiForm, UiFormGroup, UiFormNavigation } from '@ui-lib';
  import TrainingRequestLearnerCard from '@/components/TrainingRequestLearnerCard.vue';
  import type { IOnboardingSignupRequest } from '@/types';

  const router = useRouter();

  interface IProps {
    groupLearners: GroupLearner[];
    serviceError?: string;
    form: IOnboardingSignupRequest;
    recommendation: any;
    isMobile: boolean;
    isDesktop: boolean;
  }
  interface IEmits {
    (e: 'prev'): void;
    (e: 'next', request: IOnboardingSignupRequest): void;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<IEmits>();
  const expandedIndex = ref(0);

  const localForm = reactive({ ...props.form });

  const error = ref();

  const learnerRecommendations = ref({});

  const skillList = computed(() => {
    return useRepo(Skill).withAllRecursive().get();
  });

  const selectedLearners = computed(() => {
    return props.groupLearners.filter((groupLearner) => {
      return localForm.group_learners?.some(
        (selectedLearner) => selectedLearner.id === groupLearner.id
      );
    });
  });

  function handleTierSelect(tier: string, learner: GroupLearner) {
    localForm.group_learners = localForm.group_learners.map((group_learner) => {
      if (group_learner.id === learner.id) {
        return {
          ...group_learner,
          subscription_tier: capitalize(tier),
        };
      }

      return group_learner;
    });
  }

  function handleSkillSelect(skillId: number, learner: GroupLearner) {
    localForm.group_learners = localForm.group_learners.map((group_learner) => {
      if (group_learner.id === learner.id) {
        return {
          ...group_learner,
          skill_id: skillId ?? null,
        };
      }

      return group_learner;
    });
  }

  async function submit() {
    const updatedForm = {
      group_id: localForm.group_id,
      items: localForm.group_learners.map((group_learner) => {
        const fullLearnerObject = props.groupLearners.find(
          (groupLearner) => groupLearner.id === group_learner.id
        );

        return {
          type:
            learnerRecommendations?.value?.[fullLearnerObject.learner.id] ===
            group_learner.skill_id
              ? 'Recommended'
              : 'Manual',
          group_learner_id: group_learner.id,
          subscription_tier: group_learner.subscription_tier,
          skill_id: group_learner.skill_id,
        };
      }),
    };

    emit('next', updatedForm);
  }

  onBeforeMount(async () => {
    const responses = await Promise.all(
      localForm.group_learners?.map((group_learner) => {
        const fullLearnerObject = props.groupLearners.find(
          (groupLearner) => groupLearner.id === group_learner.id
        );

        return axios.get(`/recommended-skill/${fullLearnerObject?.learner_id}`);
      })
    );
    const newRecommendation = {};
    responses.forEach(({ data }) => {
      data.forEach((datum) => {
        newRecommendation[datum.learner_id] = datum.skill_id;
      });
    });

    learnerRecommendations.value = newRecommendation;
  });
</script>

<template>
  <UiForm
    :error="serviceError || error"
    variant="app-config"
    class="!border-0 bg-transparent sm:!p-0"
    @submit="submit"
  >
    <div
      class="w-full min-w-full rounded-[10px] border-[1px] border-solid border-[#E8ECF4] bg-white"
    >
      <UiFormGroup class="!mb-0">
        <div>
          <TrainingRequestLearnerCard
            v-for="(learner, index) in selectedLearners"
            :key="learner.id"
            :learner="learner"
            :expanded="expandedIndex === index"
            :is-mobile="isMobile"
            :is-desktop="isDesktop"
            :recommended-skill-id="learnerRecommendations?.[learner.learner_id]"
            :skill-list="skillList"
            @select-tier="(tier) => handleTierSelect(tier, learner)"
            @select-skill="(skillId) => handleSkillSelect(skillId, learner)"
            @toggle-expand="expandedIndex = index"
          />
        </div>
      </UiFormGroup>
    </div>
    <UiFormNavigation
      class="mb-0 mt-[40px] lg:mt-[60px] pr-4 lg:pr-6"
      show-previous
      prev-text="Go Back"
      submit-text="Next Step"
      @prev="emit('prev')"
    >
      <slot></slot>
    </UiFormNavigation>
  </UiForm>
</template>

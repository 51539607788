<!-- Has to be its own component because vuelidate poops if it exists in both parent and child component -->
<script lang="ts" setup>
  import { computed, onMounted, reactive } from 'vue';
  import { helpers, required } from '@vuelidate/validators';
  import { useRepo } from 'pinia-orm';

  import { Employer } from '@orm-lib';
  import {
    UiButton,
    UiFormGroup,
    UiFormInput,
    useValidatedForm,
  } from '@ui-lib';
  import AppPanel from '@/components/AppPanel.vue';

  interface IProps {
    cohortId?: number;
  }

  const props = defineProps<IProps>();

  const form = reactive({
    name: '',
  });
  const validations = reactive({
    name: {
      required: helpers.withMessage('New name required', required),
    },
  });

  const { isSubmitting, serviceError, validate, v$ } = useValidatedForm(
    form,
    validations,
    saveName
  );

  const employer = computed(() => useRepo(Employer).withAllRecursive().first());

  async function saveName(cb?: (arg: string) => void) {
    await Employer.api().saveName(form.name);
    cb?.('Details updated');
  }

  onMounted(() => {
    form.name = employer.value?.name ?? '';
  });
</script>

<template>
  <AppPanel
    subtitle="Update your company's basic info."
    title="Details"
    :error="serviceError"
    @submit="validate"
  >
    <UiFormGroup class="form-group-input !mb-0">
      <div class="flex">
        <UiFormInput
          v-model="form.name"
          type="text"
          class="w-full"
          label="Company Name"
          placeholder="Company Name"
          :errors="v$.name.$errors"
        />
        <UiButton
          :loading="isSubmitting"
          type="submit"
          class="icon !bg-brand-primary ml-2 h-[46px] w-[48px] self-end !rounded-lg !p-0"
          variant="icon"
        >
          <img
            alt="Save updated info"
            src="/assets/img/icon/save.png"
            class="h-[17px] w-[17px]"
          />
        </UiButton>
      </div>
    </UiFormGroup>
  </AppPanel>
</template>

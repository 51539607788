import { Model } from 'pinia-orm';
import { Num, Str } from 'pinia-orm/dist/decorators';

export default class GroupGoalReport extends Model {
  static entity = 'group-goal-report';

  @Num(null) declare id: number;
  @Str('') declare as_of: string;
  @Str(null) declare created: string;
  @Num(null) declare group_goal_id: number;
  @Num(null) declare percent_complete: number;
}

import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { HasMany, Num, Str } from 'pinia-orm/dist/decorators';

import EngagementRecording from './engagement_recording';
import Note from './note';
import RecordingNote from './recording_note';
import SessionRecording from './session_recording';

export default class Recording extends Model {
  static entity = 'recording';

  @Num(null) declare id: number;
  @Str(null) declare context: string;
  @Str(null) declare created: string;
  @Num(null) declare duration: number;
  @Str(null) declare name: string;
  @HasMany(() => RecordingNote, 'recording_id')
  declare notes: RecordingNote[];
  @Str(null) declare summary: string;
  @Str(null) declare thumbnail: string;
  @Str(null) declare type: string;

  static api() {
    return {
      async update(recording: any) {
        const payload = {
          duration: recording.duration,
          name: recording.name,
          summary: recording.summary,
          type: recording.type,
        };
        const { data } = await axios.put(`/recording/${recording.id}`, payload);

        return data;
      },
      async remove(recording: any) {
        const engagementRepo = useRepo(EngagementRecording);

        const er = engagementRepo.where('recording_id', recording.id).first();

        if (er !== null) {
          engagementRepo.destroy(er.id);
        } else {
          const sessionRecording = useRepo(SessionRecording);

          const sr = sessionRecording
            .where('recording_id', recording.id)
            .first();

          if (sr !== null) {
            sessionRecording.destroy(sr.id);
          }
        }

        return useRepo(Recording).destroy(`/recording/${recording.id}`);
      },
    };
  }

  static async handleModelMessage(message: {
    payload: Recording;
    method: 'create' | 'update' | 'delete';
  }) {
    if (message.method === 'update') {
      await useRepo(Recording).save(message.payload);
    }
  }

  async getFile() {
    // This exists because the file URL has a time sensitive signature, so it needs to be separately gettable
    const response = await axios.get(`recording/${this.id}`);
    return response.data.file;
  }

  get notesSorted() {
    return useRepo(RecordingNote)
      .withAllRecursive()
      .where('recording_id', this.id)
      .orderBy(function (recordingNote) {
        return useRepo(Note).find(recordingNote.note_id)?.position;
      })
      .get();
  }

  get hasFeedback() {
    if (this.summary !== null && this.summary !== '') {
      return true;
    }

    return this.notes.length > 0;
  }

  scrubNote(position: number) {
    return useRepo(RecordingNote)
      .withAllRecursive()
      .where('recording_id', this.id)
      .where(function (recordingNote) {
        return (
          (useRepo(Note).find(recordingNote.note_id)?.position ?? 0) <= position
        );
      })
      .where(function (recordingNote) {
        return (
          (useRepo(Note).find(recordingNote.note_id)?.position ?? 0) >=
          position - 10
        );
      })
      .orderBy(function (recordingNote) {
        return useRepo(Note).find(recordingNote.note_id)?.position;
      }, 'desc')
      .first();
  }

  nextNote(position: number) {
    return useRepo(RecordingNote)
      .withAllRecursive()
      .where('recording_id', this.id)
      .where(function (recordingNote) {
        return (
          (useRepo(Note).find(recordingNote.note_id)?.position ?? 0) > position
        );
      })
      .orderBy(function (recordingNote) {
        return useRepo(Note).find(recordingNote.note_id)?.position;
      }, 'asc')
      .first();
  }

  previousNote(position: number) {
    return useRepo(RecordingNote)
      .withAllRecursive()
      .where('recording_id', this.id)
      .where(function (recordingNote) {
        return (
          (useRepo(Note).find(recordingNote.note_id)?.position ?? 0) < position
        );
      })
      .orderBy(function (recordingNote) {
        return useRepo(Note).find(recordingNote.note_id)?.position;
      }, 'desc')
      .first();
  }
}

<template>
  <svg
    width="38"
    height="32"
    viewBox="0 0 38 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0236 3.38801C16.4018 -0.142704 21.5982 -0.142709 23.9764 3.388L36.9495 22.648C39.6339 26.6334 36.7782 32 31.9731 32H6.02692C1.22178 32 -1.6339 26.6334 1.05054 22.648L14.0236 3.38801Z"
      fill="#5E5CFF"
      fill-opacity="0.1"
    />
    <rect
      x="7"
      y="11"
      width="24"
      height="17"
      rx="4"
      fill="white"
      stroke="#5E5CFF"
      stroke-width="2"
    />
    <line
      x1="19"
      y1="8"
      x2="19"
      y2="10.8008"
      stroke="#5E5CFF"
      stroke-width="2"
    />
    <circle
      cx="19"
      cy="6"
      r="2.25"
      fill="white"
      stroke="#5E5CFF"
      stroke-width="1.5"
    />
    <circle
      cx="12.6123"
      cy="18.085"
      r="1.75"
      fill="white"
      stroke="#5E5CFF"
      stroke-width="1.5"
    />
    <circle
      cx="25.8457"
      cy="18.085"
      r="1.75"
      fill="white"
      stroke="#5E5CFF"
      stroke-width="1.5"
    />
    <path
      d="M16.2285 21.6934C16.2285 21.6934 16.7375 23.2203 19.2825 23.2203C21.8275 23.2203 22.3365 21.6934 22.3365 21.6934"
      stroke="#5E5CFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.625 19C4.625 17.9046 5.36654 16.9825 6.375 16.7081V22.2919C5.36654 22.0175 4.625 21.0954 4.625 20V19Z"
      fill="white"
      stroke="#5E5CFF"
      stroke-width="1.25"
    />
    <path
      d="M31.625 16.7081C32.6335 16.9825 33.375 17.9046 33.375 19V20C33.375 21.0954 32.6335 22.0175 31.625 22.2919V16.7081Z"
      fill="white"
      stroke="#5E5CFF"
      stroke-width="1.25"
    />
  </svg>
</template>

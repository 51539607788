import axios from 'axios';
import { DateTime } from 'luxon';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Bool, HasMany, Num, Str } from 'pinia-orm/dist/decorators';

import Bid from './bid';
import type Coach from './coach';
import Skill from './skill';

export default class CoachingRequest extends Model {
  static entity = 'coaching_request';

  @Num(null) declare id: number;
  @HasMany(() => Bid, 'request_id') declare bids: Bid[];
  @Num(0) declare coach_id: number;
  @Str('') declare created: string;
  @Num(0) declare engagement_id: number;
  @Num(0) declare organization_id: number;
  @BelongsTo(() => Skill, 'skill_id') declare skill: Skill;
  @Num(0) declare skill_id: number;
  @Str('') declare started: string;
  @Str('') declare timezone: string;
  @Bool(false) declare won: boolean;

  static api() {
    return {
      async bid(id: number) {
        const { data } = await axios.post(`/coaching-request/${id}/bid`, {});

        const repo = useRepo(CoachingRequest);
        repo.save(data);
      },
      async list() {
        const { data } = await axios.get('/coaching-request');

        const repo = useRepo(CoachingRequest);
        repo.flush();
        repo.save(data);
      },
      async detail(id: number) {
        const { data } = await axios.get(`/coaching-request/${id}`);

        const repo = useRepo(CoachingRequest);
        repo.save(data);
      },
    };
  }

  get timezoneTitle() {
    return `${this.timezone} (GMT ${DateTime.fromObject(
      {
        year: 2023,
      },
      { zone: this.timezone }
    ).toFormat('ZZ')})`;
  }

  canBuyout(coach?: Coach) {
    return (
      (this.organization_id === null ||
        this.organization_id === coach?.organization_id) &&
      coach?.specialties.includes(this.skill_id)
    );
  }

  getCoachBid(coach: Coach) {
    return useRepo(Bid)
      .where('request_id', this.id)
      .where('coach_id', coach.id)
      .first();
  }

  hasBid(coach?: Coach) {
    return !!useRepo(Bid)
      .where('request_id', this.id)
      .where('coach_id', coach?.id)
      .first();
  }
}

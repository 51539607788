<script lang="ts" setup>
import { reactive, ref } from "vue";

import type { UseGoalsPayload } from '@/components/public/types';
import { UseGoalsPayloadCreate } from '@/components/public/types';
import { FormBase, ChoiceRow } from './'


defineOptions({ version: 1 })

interface IProps {
    currentStep?: number,
    stepCount?: number,
}

const props = withDefaults(defineProps<IProps>(), {
    currentStep: 1,
    stepCount: 1,
});

interface IEmits {
    (e: 'back'): void;
    (e: 'submit', value:UseGoalsPayload): void;
}

const emit = defineEmits<IEmits>();

const errorMessage = ref<string | null>(null)

const data = reactive(UseGoalsPayloadCreate())

function handleBack() {
    emit('back')
}

function  handleChange(inputValues:string[]) {
    if (inputValues[0] === '1')
        data.add_goal = true
    else
        data.add_goal = false
}

async function handleSubmit() {
    if (data.add_goal === null) {
        errorMessage.value = 'Decide if you want training recommendations to continue.'
    } else {
        errorMessage.value = null;
        emit('submit', data)
    }
}
</script>

<template>
    <FormBase
        @back=handleBack
        @submit=handleSubmit
        :current-step=currentStep
        :error-message=errorMessage
        mode=sign-up
        title="Training Recommendations"
        :step-count=stepCount
        subtitle="Do you want Backstage to make training recommendations? This can be changed later."
    >
        <div class="flex flex-col gap-[8px]">
            <ChoiceRow
                name="use-goals-add-goal"
                subtitle="I want Backstage to use my goals to create training recommendations."
                title="Yes Please"
                value="1"
                @change=handleChange
            />
            <ChoiceRow
                name="use-goals-add-goal"
                subtitle="I don't need training recommendations right now."
                title="No Thanks"
                value="0"
                @change=handleChange
            />
        </div>
    </FormBase>
</template>
<script lang="ts" setup>
  import { computed, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { isEqual } from 'lodash';
  import { DateTime } from 'luxon';

  import { GroupLearner } from '@orm-lib';
  import { IconDownChevron, UiButton, UiProgress } from '@ui-lib';
  import AppCheckboxGroup from '@/components/AppCheckboxGroup.vue';

  interface IBaseTask {
    name: string;
    groupGoalId: number;
    description: string;
    percentComplete: number;
    goalLearners: GroupLearner[];
    groupLearners: GroupLearner[];
    asOf?: string;
  }

  const props = defineProps<IBaseTask>();
  const emit = defineEmits<{
    (e: 'delete'): void;
    (e: 'update-participants', newLearners: []): void;
    (e: 'update-progress', newLearners: []): void;
  }>();
  const router = useRouter();
  const initialChecked = ref(
    props.goalLearners?.map((learner) => learner.group_learner_id)
  );
  const localValue = ref(initialChecked.value.slice());
  const isExpanded = ref(false);

  const groupLearnerOptions = computed(() => {
    return props.groupLearners.map((learner) => {
      return {
        id: learner.id,
        title: learner?.learner.full_name,
        subtitle: learner?.learner.email,
      };
    });
  });

  const disabled = computed(() => {
    return isEqual(
      localValue.value.slice().sort((a, b) => b - a),
      initialChecked.value.slice().sort((a, b) => b - a)
    );
  });

  function toggleExpand() {
    isExpanded.value = !isExpanded.value;
  }

  function handleCheckboxInput(checked: boolean, groupLearnerId: number) {
    if (checked) {
      localValue.value.push(groupLearnerId);
    } else {
      const learnerIndex = localValue.value.indexOf(groupLearnerId);
      localValue.value.splice(learnerIndex, 1);
    }
  }

  watch(
    () => props.goalLearners,
    (oldVal, newVal) => {
      initialChecked.value = props.goalLearners?.map(
        (learner) => learner.group_learner_id
      );
    }
  );
</script>

<template>
  <div
    class="flex w-full flex-col justify-between rounded-lg border-[1px] border-solid border-[#E8ECF4] bg-white p-4 sm:px-8 sm:py-6"
  >
    <button
      class="-mx-8 -my-8 flex min-w-full cursor-pointer flex-col items-start justify-between py-8 px-8"
      @click="toggleExpand"
    >
      <div class="mb-0.5 flex w-full items-start justify-between">
        <h3
          :class="`text-brand-primary text-left text-base font-semibold sm:text-lg ${
            !isExpanded ? 'line-clamp-2' : ''
          }`"
        >
          {{ name }}
        </h3>
        <IconDownChevron
          :class="`ml-3 mt-2 shrink-0 transition duration-100 ease-in-out ${
            isExpanded && 'rotate-180'
          }`"
        />
      </div>
      <p
        v-if="isExpanded"
        class="text-body-secondary mt-2 whitespace-pre-wrap text-left text-base"
        v-text="description"
      />
      <div class="flex w-full flex-col">
        <h3
          v-if="isExpanded"
          class="text-header-primary mt-4 self-start font-semibold"
        >
          Progress
        </h3>
        <div class="mt-1 flex w-full grow items-center justify-between">
          <span
            class="mr-3 block whitespace-nowrap text-base font-medium text-[#5C6582]"
          >
            {{ percentComplete }}% Complete
          </span>
          <span v-if="asOf" class="whitespace-nowrap text-base text-[#5C6582]">
            As of {{ DateTime.fromISO(asOf).toFormat('MMM d, yyyy') }}
          </span>
        </div>
        <UiProgress
          class="progress mt-1.5 mb-2 w-full rounded-sm [&>div>div]:bg-clip-padding"
          size="sm"
          :progress="percentComplete"
        />
        <div v-if="isExpanded" class="mt-4 flex items-center justify-end">
          <UiButton
            variant="app-home"
            type="button"
            @click.stop="emit('update-progress', localValue)"
          >
            Update
          </UiButton>
        </div>
      </div>
    </button>
    <template v-if="isExpanded">
      <h3 class="text-header-primary mt-2 self-start font-semibold">
        Participants
      </h3>
      <div class="flex items-center justify-between px-4 pt-3 sm:-mx-8 sm:px-8">
        <AppCheckboxGroup
          v-if="groupLearnerOptions.length !== 0"
          class="mb-10"
          :options="groupLearnerOptions"
          :model-value="localValue"
          is-multiple
          variant="bordered"
          @input="handleCheckboxInput"
        />
        <div v-else class="pb-[16px]">
            <p class="text-body-secondary">
                Once a learner has been invited to this group and created an account, you'll be able to manage their participation in this goal here.
            </p>
        </div>
      </div>
      <div v-if="groupLearnerOptions.length !== 0" class="-mt-2 flex items-center justify-end">
        <UiButton
          variant="app-home"
          type="button"
          :disabled="disabled"
          @click="emit('update-participants', localValue)"
        >
          Update
        </UiButton>
      </div>
      <h3 class="mt-2 self-start font-semibold text-red-500">Delete</h3>
      <div class="flex items-center justify-between px-4 pt-3 sm:-mx-8 sm:px-8">
        <p class="text-body-secondary">
          Only delete goals if they were made in error. If this goal is
          legitimate but no longer active, update its progress above and mark it
          as
          <strong class="font-semibold">Ended</strong>
          instead.
        </p>
      </div>
      <div class="mt-3 flex items-center justify-end">
        <UiButton
          class="!border-red-500 [&>*]:!text-red-500"
          variant="app-home"
          type="button"
          color="white"
          @click="emit('delete', localValue)"
        >
          Delete
        </UiButton>
      </div>
    </template>
  </div>
</template>

<style scoped>
  :deep(.progress) > div > div {
    background: linear-gradient(
      270deg,
      #5bbefa 0%,
      #7587fe 47.84%,
      #bf81d3 100%
    );
  }
</style>

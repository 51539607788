<script lang="ts" setup>
  import { ref, watch } from 'vue';

  import { UiButton, UiFormGroup } from '@ui-lib';

  interface IProps {
    question?: string;
    modelValue?: number;
    variant?: 'history' | 'standard';
    minText?: string;
    maxText?: string;
  }
  const props = withDefaults(defineProps<IProps>(), {
    minText: 'Not competent',
    maxText: 'Highly competent',
    variant: 'standard',
  });
  const emit = defineEmits<{
    (e: 'select', rating: number): void;
  }>();

  const ratings = Object.freeze([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const selection = ref(props.modelValue);

  function buttonVariant(value: number) {
    const baseClass = '';
    return selection.value === value
      ? baseClass +
          ' bg-brand-primary text-white border-none disabled:opacity-80'
      : baseClass +
          ' border-[#E8ECF4] text-header-primary disabled:text-gray-400 disabled:border-[#E8ECF4]';
  }

  watch(
    () => props.modelValue,
    () => {
      selection.value = props.modelValue;
    }
  );
</script>

<template>
  <div class="rating w-full">
    <div v-if="variant === 'standard'" class="form-question mb-6">
      <em>{{ question }}</em>
    </div>
    <UiFormGroup :class="`rating-form-group ${variant} !mb-4`">
      <div class="flex w-full flex-wrap gap-x-[14px] sm:gap-x-6 md:gap-[14px]">
        <div class="rating-row">
          <button
            v-for="rating in ratings.slice(0, 5)"
            :key="rating"
            :class="`text rating-button ${buttonVariant(rating)}`"
            :disabled="variant === 'history'"
            type="button"
            @click.prevent="emit('select', rating)"
          >
            {{ rating }}
          </button>
        </div>
        <div class="rating-row">
          <button
            v-for="rating in ratings.slice(5, 10)"
            :key="rating"
            :class="`text rating-button ${buttonVariant(rating)}`"
            :disabled="variant === 'history'"
            type="button"
            @click.prevent="emit('select', rating)"
          >
            {{ rating }}
          </button>
        </div>
      </div>
      <div
        class="mb-[5px] flex justify-between text-end text-base text-[#5C6582]"
      >
        <span>1 - {{ minText }}</span>
        <span>10 - {{ maxText }}</span>
      </div>
    </UiFormGroup>
  </div>
</template>

<style scoped>
  .rating-row {
    flex: 1 0 calc(50% - 7px);
    @apply mb-[10px] flex w-full justify-around gap-x-[14px] !rounded-[5px] sm:gap-x-6 md:gap-[14px];
  }

  .rating-button {
    flex: 1 1 20%;
    @apply min-h-[44px] min-w-[44px] rounded border-[1px] border-solid font-bold;
  }
</style>

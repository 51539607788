<script lang="ts" setup>
  import { computed, reactive, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { isNil } from 'lodash';
  import { DateTime } from 'luxon';

  import { Quarter } from '@orm-lib';
  import {
    UiDatePicker,
    UiForm,
    UiFormCheckbox,
    UiFormGroup,
    UiFormNavigation,
    UiProgress,
    useValidatedForm,
  } from '@ui-lib';
  import type { IOnboardingSignupRequest } from '@/types';

  const router = useRouter();

  interface IProps {
    serviceError?: string;
    form: IOnboardingSignupRequest;
    lastUpdate?: string;
    goalProgress?: number;
    quarter: Quarter;
  }
  interface IEmits {
    (e: 'complete', request: any): void;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<IEmits>();

  const localForm = reactive({ ...props.form });
  const userChecked = ref(false);
  const endCheckDisabled = ref(false);

  localForm.as_of =
    props.lastUpdate &&
    DateTime.fromISO(props.lastUpdate).toJSDate() >= new Date()
      ? DateTime.fromISO(props.lastUpdate)
          .plus({ day: 1 })
          .toFormat('yyyy-MM-dd')
      : DateTime.now().toFormat('yyyy-MM-dd');

  localForm.percent_complete = props.goalProgress;

  if (isNil(localForm.ended)) {
    localForm.ended = false;
  }

  const validations = {};
  const { isSubmitting, validate, v$ } = useValidatedForm(
    localForm,
    validations,
    submit
  );

  function handleInput(event: InputEvent) {
    const newValue = event.target?.value ?? 0;

    if (endCheckDisabled.value && newValue !== 100) {
      localForm.ended = userChecked.value;
      endCheckDisabled.value = false;
    }

    if (newValue < props.goalProgress) {
      localForm.percent_complete = props.goalProgress;
    }

    if (newValue === '100') {
      localForm.ended = true;
      endCheckDisabled.value = true;
    }
  }

  function handleEndedCheck() {
    localForm.ended = !localForm.ended;
    userChecked.value = localForm.ended;
  }

  function handleDateSelect(value) {
    localForm.as_of = value;
  }

  async function submit() {
    emit('complete', localForm);
  }

  const datepickerMinDate = computed(() => {
    return props.lastUpdate
      ? DateTime.fromISO(props.lastUpdate).plus({ day: 1 }).toJSDate()
      : undefined;
  });

  watch(
    () => props.lastUpdate,
    () => {
      localForm.as_of =
        props.lastUpdate &&
        DateTime.fromISO(props.lastUpdate).toJSDate() >= new Date()
          ? DateTime.fromISO(props.lastUpdate)
              .plus({ day: 1 })
              .toFormat('yyyy-MM-dd')
          : DateTime.now().toFormat('yyyy-MM-dd');
    }
  );
</script>

<template>
  <UiForm :error="serviceError" variant="app-config" @submit="validate">
    <UiFormGroup class="md:!mb-18 !mb-6 sm:!mb-12">
      <h3 class="text-brand-primary mb-2 text-base font-semibold md:text-lg">
        {{ form.name }}
      </h3>
      <p class="mb-4 text-sm text-[#5C6582] sm:text-base">
        {{ form.description }}
      </p>
      <div class="mb02 mt-1 flex w-full grow items-center justify-between">
        <span
          class="mr-3 block whitespace-nowrap text-base font-semibold text-[#5C6582]"
        >
          {{ goalProgress }}% Complete
        </span>
        <span
          v-if="lastUpdate"
          class="whitespace-nowrap text-base text-[#5C6582]"
        >
          As of {{ DateTime.fromISO(lastUpdate).toFormat('MMM d, yyyy') }}
        </span>
      </div>
      <UiProgress
        class="progress mt-1.5 mb-4 w-full rounded-sm [&>div>div]:bg-clip-padding"
        size="sm"
        :progress="goalProgress"
      />
      <label
        class="text-header-primary mb-1 mt-3 block self-start text-base font-semibold"
        for="progress"
      >
        Percent Complete
      </label>
      <span
        class="mr-3 mb-3 block whitespace-nowrap text-base font-semibold text-[#5C6582]"
      >
        {{ localForm.percent_complete }}% complete
      </span>
      <div class="mb-3 flex">
        <input
          id="progress"
          ref="scrubBar"
          v-model.number="localForm.percent_complete"
          type="range"
          min="0"
          max="100"
          step="1"
          class="scrub-bar w-full"
          @input="handleInput"
        />
      </div>
      <label
        class="text-header-primary mb-1 mt-3 block self-start text-base font-semibold"
        for="progress-bar"
      >
        As Of
      </label>
      <UiDatePicker
        v-model="localForm.as_of"
        class="!max-w-full"
        :min-date="datepickerMinDate"
        hide-placeholder
        @input="handleDateSelect"
      />
      <label
        class="text-header-primary mt-5 block self-start text-base font-semibold"
        for="progress"
      >
        Ended
      </label>
      <UiFormCheckbox
        id="ended"
        v-model="localForm.ended"
        class="w-full"
        :disabled="endCheckDisabled"
        @input="handleEndedCheck"
      >
        <span class="-mt-[2px] block text-sm text-[#5C6582]">
          This is the final update for this goal. After this update it should no
          longer be tracked.
        </span>
      </UiFormCheckbox>
    </UiFormGroup>
    <UiFormNavigation
      class="mb-0 -mt-8"
      show-previous
      prev-text="Cancel"
      submit-text="Complete"
      :loading="isSubmitting"
      @prev="
        router.push({
          name: 'GroupManagement',
          params: {
            groupId: form.group_id,
          },
        })
      "
    >
      <slot></slot>
    </UiFormNavigation>
  </UiForm>
</template>

<style scoped>
  .scrub-bar {
    @apply accent-brand-primary;
  }
</style>

import { Model } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import GroupLearner from './group_learner';

export default class TrainingRequest extends Model {
  static entity = 'training-request';

  @Num(null) declare id: number;
  @Str(null) declare created: string;
  @BelongsTo(() => GroupLearner, 'group_learner_id')
  declare group_learner: GroupLearner;
  @Num(null) declare group_learner_id: number;
  @Num(null) declare skill_id: number;
  @Num(null) declare training_request_id: number;
  @Str(null) declare type: 'Manual' | 'Automatic';
}

import axios from 'axios';
import { DateTime } from 'luxon';
import { Model, useRepo } from 'pinia-orm';
import { Num, Str, Uid } from 'pinia-orm/dist/decorators';

import Assignment from './assignment';
import Feedback from './feedback';
import Learner from './learner';

export type TActionReason =
  | 'AutomaticCohortLearnerWithoutAssessment'
  | 'AutomaticCohortWithoutAssessment'
  | 'CohortWithoutLearners'
  | 'EmployerWithoutCohort'
  | 'IncompleteCollaboratorFeedbackRequest'
  | 'IncompleteLearnerFeedbackRequest'
  | 'UnstartedAssignment'
  | 'GroupWithoutGoal'
  | 'GroupLearnerWithoutGoal'
  | 'EmployerWithoutGroup'
  | 'EmployerPastDue'
  | 'GroupWithoutLearner'
  | 'GroupWithTrainingRequest'
  | 'GroupWithoutOwner'
  | 'GoalWithoutReport'
  | 'EmployerWithTrainingRequest';

export default class Action extends Model {
  static entity = 'action';

  @Uid() declare id: string;
  @Num(null) declare assignment_id: number;
  @Str(null) declare created: string;
  @Num(null) declare cohort_id: number;
  @Num(null) declare feedback_request_id: number;
  @Num(null) declare learner_id: number;
  @Num(null) declare group_id: number;
  @Num(null) declare group_goal_id: number;
  @Num(null) declare group_learner_id: number;
  @Str('') declare reason: TActionReason;
  @Str('') declare status: string;
  @Str('') declare status_type: 'warning' | 'error';
  @Str('') declare subtitle: string;
  @Str('') declare title: string;

  static api() {
    return {
      async list() {
        const { data } = await axios.get('/action/');
        const repo = useRepo(Action);
        repo.flush();
        repo.save(data);
      },
    };
  }

  get assignment() {
    return useRepo(Assignment).find(this.assignment_id);
  }

  get feedback() {
    return useRepo(Feedback).find(this.feedback_request_id);
  }

  get learner() {
    return useRepo(Learner).find(this.learner_id);
  }

  get assigned() {
    switch (this.reason) {
      case 'AutomaticCohortLearnerWithoutAssessment':
        return this.learner?.created;
      case 'AutomaticCohortWithoutAssessment':
        return this.cohort?.created;
      case 'CohortWithoutLearners':
        return this.cohort?.created;
      case 'EmployerWithoutCohort':
        return null;
      case 'IncompleteCollaboratorFeedbackRequest':
        return this.feedback?.created;
      case 'IncompleteLearnerFeedbackRequest':
        return this.feedback?.created;
      case 'GroupWithTrainingRequest':
        return this.created;
      case 'UnstartedAssignment':
        return this.assignment?.created;
      case 'EmployerWithoutGroup':
        return DateTime.now().toISO();
      case 'EmployerPastDue':
        return DateTime.now().toISO();
      case 'GroupWithoutLearner':
        return DateTime.now().toISO();
      case 'GroupWithoutOwner':
        return DateTime.now().toISO();
      default:
        return null;
    }
  }
}

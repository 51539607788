<script lang="ts" setup>
import { computed } from "vue";

import { Button, LinkButton } from "@/components/public/buttons";

interface IProps {
    currentStep?: number,
    mode: 'sign-up' | 'log-in' | 'forgot-password' | 'reset-password' | 'create-account'
    stepCount?: number,
}

const props = withDefaults(defineProps<IProps>(), {
    currentStep: 1,
    stepCount: 1,
});

interface IEmits {
    (e: 'back'): void;
    (e: 'forgot'): void;
    (e: 'logIn'): void;
    (e: 'submit'): void;
}

const emit = defineEmits<IEmits>();

const showBackLink = computed(() => {
    if (props.mode === 'sign-up') {
        if (props.currentStep > 1) {
            return true;
        }
    }
    return false;
});

const showCompleteButton = computed(() => {
    if (props.mode === 'sign-up') {
        if (props.currentStep === props.stepCount) {
            return true;
        }
    }
    return false;
});

const showCreateAccountButton = computed(() => {
    return props.mode === 'create-account'
});

const showForgotPasswordLink = computed(() => {
    return props.mode === 'log-in'
});

const showLogInButton = computed(() => {
    return props.mode === 'log-in'
});

const showLogInLink = computed(() => {
    if (props.mode === 'forgot-password') {
        if (props.currentStep === 1) {
            return true;
        }
    }
    return false;
});

const showNextButton = computed(() => {
    if (props.mode === 'sign-up') {
        if (props.currentStep < props.stepCount) {
            return true;
        }
    }
    return false;
});

const showResetButton = computed(() => {
    return props.mode === 'reset-password'
});

const showSendButton = computed(() => {
    return props.mode === 'forgot-password'
});

function handleBack() {
    emit('back')
}

function handleForgot() {
    emit('forgot')
}

function handleLogIn() {
    emit('logIn')
}

function handleSubmit() {
    emit('submit')
}
</script>

<template>
    <div class="flex flex-row justify-between pt-[24px]">
        <div class="flex flex-row">

        </div>
        <div class="flex flex-row gap-[16px]">
            <LinkButton v-if=showBackLink @click=handleBack direction=left text="Back" />
            <Button v-if=showNextButton @click=handleSubmit icon=angle-right text="Next" />
            <Button v-if=showCompleteButton @click=handleSubmit icon=sparkle text="Complete" />

            <LinkButton v-if=showForgotPasswordLink @click=handleForgot text="Forgot Password" />
            <Button v-if=showLogInButton @click=handleSubmit text="Log In" />

            <LinkButton v-if=showLogInLink @click=handleLogIn text="Log In" />
            <Button v-if=showSendButton @click=handleSubmit text="Send" />

            <Button v-if=showResetButton @click=handleSubmit text="Reset" />

            <Button v-if=showCreateAccountButton @click=handleSubmit text="Create Account" icon=sparkle />
        </div>
    </div>
</template>
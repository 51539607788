<script lang="ts" setup>
  import { computed, useSlots, watch } from 'vue';

  import { UiFormCheckbox, UiFormGroup, UiFormRadio } from '@ui-lib';

  interface ICheckboxOption {
    id: string | number;
    title: string;
    subtitle: string;
    tag?: string;
    tagColor?: string;
    disabled?: boolean;
  }
  interface Props {
    options: ICheckboxOption[];
    isMultiple: boolean;
    modelValue: number | number[] | null | string;
    variant?: 'primary' | 'bordered';
    slotPosition?: number;
  }
  interface Emits {
    (e: 'input', value: boolean, id: string | number): void;
  }

  const props = defineProps<Props>();
  const emit = defineEmits<Emits>();
  const slots = useSlots();
  const checked = Array.isArray(props.modelValue)
    ? props.options
        .map((option) => option.id)
        .filter((id) => !props.modelValue.includes(id))
    : props.modelValue;

  const component = computed(() => {
    return props.isMultiple ? UiFormCheckbox : UiFormRadio;
  });

  function handleInput(value: boolean, id: string | number) {
    emit('input', value, id);
  }

  function isInitialChecked(value: number) {
    if (Array.isArray(props.modelValue)) {
      return props.modelValue.includes(value);
    }

    return props.modelValue === value;
  }
</script>

<template>
  <UiFormGroup>
    <template v-if="slots.default">
      <component
        :is="component"
        v-for="option in options.slice(0, slotPosition ?? 1)"
        :id="option.id"
        :key="option.id"
        :class="variant"
        :disabled="option.disabled"
        :model-value="isInitialChecked(option.id)"
        @input="(value) => handleInput(value, option.id)"
      >
        <div
          class="option-content flex w-full flex-col items-start justify-between sm:flex-row sm:items-center"
        >
          <div class="flex flex-col">
            <div
              class="option-title mb-1 text-left text-sm font-semibold text-[#242431] sm:text-base"
            >
              {{ option.title }}
            </div>
            <div
              class="form-subtitle !text-body-secondary text-sm !opacity-100"
            >
              {{ option.subtitle }}
            </div>
          </div>
          <div
            v-if="option.tag"
            :class="`flex items-center whitespace-nowrap rounded-lg bg-opacity-10 px-2 py-1 text-xs  sm:ml-auto sm:px-3 sm:text-sm ${
              option.tagColor === 'yellow'
                ? ' bg-[#FFBE021A] text-[#FFBE02]'
                : ' bg-[#FB4F571A] text-[#FB4F57]'
            }`"
          >
            {{ option.tag }}
          </div>
        </div>
      </component>
      <slot></slot>
    </template>
    <component
      :is="component"
      v-for="option in slots.default
        ? options.slice(slotPosition ?? 1)
        : options"
      :id="option.id"
      :key="option.id"
      :class="variant"
      :disabled="option.disabled"
      :model-value="isInitialChecked(option.id)"
      @input="(value) => handleInput(value, option.id)"
    >
      <div
        class="option-content flex w-full flex-col items-start justify-between sm:flex-row sm:items-center"
      >
        <div class="flex flex-col">
          <div
            class="option-title mb-1 text-left text-sm font-semibold text-[#242431] sm:text-base"
          >
            {{ option.title }}
          </div>
          <div class="form-subtitle !text-body-secondary text-sm !opacity-100">
            {{ option.subtitle }}
          </div>
        </div>
        <div
          v-if="option.tag"
          :class="`flex items-center whitespace-nowrap rounded-lg bg-opacity-10 px-2 py-1 text-xs  sm:ml-auto sm:px-3 sm:text-sm ${
            option.tagColor === 'yellow'
              ? ' bg-[#FFBE021A] text-[#FFBE02]'
              : ' bg-[#FB4F571A] text-[#FB4F57]'
          }`"
        >
          {{ option.tag }}
        </div>
      </div>
    </component>
  </UiFormGroup>
</template>

<style scoped>
  :deep(.checkbox .option-title) {
    text-align: left;
    @apply -mt-1;
  }

  :deep(.checkbox label) {
    @apply w-full;
  }

  :deep(.radio) {
    @apply mb-2;
  }

  :deep(.radio:first-child) {
    @apply mt-2;
  }

  .option-content {
    flex: 1;
  }

  .bordered {
    @apply !items-center border-x-[1px] border-t-[1px] border-solid border-[#E8ECF4] px-4 pt-4 pb-2.5 first:rounded-t-md last:rounded-b-md last:border-b-[1px];
  }

  :deep(.bordered input) {
    @apply mb-2 mr-2.5;
  }

  .option-content .form-group-title {
    line-height: 1;
    @apply mt-4;
  }
  .option-content .form-subtitle {
    font-size: 13px;
    line-height: 1.25;
    @apply text-header-primary mb-1 text-sm opacity-30;
  }

  .option-row {
    display: flex;
  }
</style>

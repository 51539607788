import { ref } from 'vue';
import axios from 'axios';
import { DateTime } from 'luxon';

export const useReporting = (
  apiUrl: string,
  postLoad: () => void,
  tableHeadings: string[],
  filterProps: { startProp: string; endProp: string },
  reportName: string
) => {
  const data = ref([]);
  const startDate = ref();
  const chartData = ref([]);
  const endDate = ref();
  const groupFilters = ref([]);
  const selectedGroups = ref([]);

  function formatDatetimeForRequest(date: string, isEnd: boolean) {
    if (!date) {
      return null;
    }
    return isEnd
      ? DateTime.fromISO(date)
          .plus({ day: 1 })
          .minus({ millisecond: 1 })
          .toISO()
      : DateTime.fromISO(date).toISO();
  }

  function handleGroupFilter(groupIds: number[]) {
    selectedGroups.value = groupIds;

    loadPageData({
      group_ids: groupIds,
      [filterProps.startProp]: formatDatetimeForRequest(startDate.value, false),
      [filterProps.endProp]: formatDatetimeForRequest(endDate.value, true),
    });
  }

  function handleStartDateSelect(date) {
    startDate.value = date;

    if (!date && !endDate.value) {
      loadPageData({
        group_ids: selectedGroups.value,
      });
      return;
    }

    loadPageData({
      group_ids: selectedGroups.value,
      [filterProps.startProp]: formatDatetimeForRequest(startDate.value, false),
      [filterProps.endProp]: formatDatetimeForRequest(endDate.value, true),
    });
  }

  function handleEndDateSelect(date) {
    endDate.value = date;
    if (!date && !startDate.value) {
      loadPageData();
      return;
    }

    loadPageData({
      [filterProps.startProp]: formatDatetimeForRequest(startDate.value, false),
      [filterProps.endProp]: formatDatetimeForRequest(endDate.value, true),
    });
  }

  function clearGroupFilter() {
    selectedGroups.value = [];

    loadPageData({
      [filterProps.startProp]: formatDatetimeForRequest(startDate.value, false),
      [filterProps.endProp]: formatDatetimeForRequest(endDate.value, true),
    });
  }

  function handleExport() {
    let csvContent = '';
    csvContent += tableHeadings + '\r\n';

    data.value.forEach((datum) => {
      const rowArray = datum.map((cell) => {
        if (cell.type === 'date') {
          return cell.value
            ? DateTime.fromISO(cell.value).toFormat('M/d/yyyy')
            : '';
        }

        return cell.value;
      });
      csvContent += rowArray + '\r\n';
    });

    let url = window.URL.createObjectURL(
      new Blob([csvContent], { type: 'application/json' })
    );

    var a = document.createElement('A');
    a.href = url;
    a.download = reportName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async function loadFilterData() {
    const { data: loadedFilterData } = await axios.get('report-filter/group');

    function getParent(parentId: number) {
      return loadedFilterData.find((datum) => datum.id === parentId);
    }

    function getRootParent(parentId: number) {
      const foundParent = getParent(parentId);

      if (!foundParent.parent_id) {
        return foundParent;
      }
      return getRootParent(foundParent.parent_id);
    }

    function recursiveCompare(optionA, optionB) {
      if (
        (!optionA.parent_id && !optionB.parent_id) ||
        optionA.parent_id === optionB.parent_id
      ) {
        return optionA.name.localeCompare(optionB.name);
      }

      if (optionA.parent_id === optionB.id) {
        return 1;
      }

      if (optionB.parent_id === optionA.id) {
        return -1;
      }

      if (!optionA.parent_id && optionB.parent_id) {
        return optionA.name.localeCompare(
          getRootParent(optionB.parent_id).name
        );
      }

      if (optionA.parent_id && !optionB.parent_id) {
        return getRootParent(optionA.parent_id).name.localeCompare(
          optionB.name
        );
      }

      if (
        getRootParent(optionA.parent_id).id ===
        getRootParent(optionB.parent_id).id
      ) {
        const comparableItemA =
          getRootParent(optionA.parent_id).id === optionA.parent_id
            ? optionA
            : getParent(optionA.parent_id);
        const comparableItemB =
          getRootParent(optionB.parent_id).id === optionB.parent_id
            ? optionB
            : getParent(optionB.parent_id);

        return recursiveCompare(comparableItemA, comparableItemB);
      }

      return getRootParent(optionA.parent_id).name.localeCompare(
        getRootParent(optionB.parent_id).name
      );
    }

    const sortedData = loadedFilterData.slice().sort((a, b) => {
      return recursiveCompare(a, b);
    });

    groupFilters.value = sortedData.reduce((acc, datum, index) => {
      let depth = 0;

      let isFirstChild = true;

      if (sortedData[index - 1] && datum.parent_id) {
        isFirstChild = sortedData[index - 1].id === datum.parent_id;
      }

      if (datum.parent_id) {
        const parent =
          acc.find((item) => item.id === datum.parent_id)?.depth ?? 0;

        depth = parent + 1;
      }

      acc.push({
        id: datum.id,
        name: datum.name,
        parent_id: datum.parent_id,
        depth: depth,
        is_first_child: isFirstChild,
      });
      return acc;
    }, []);
  }

  async function loadPageData(payload?: any) {
    const { data: loadedData } = payload
      ? await axios.post(apiUrl, payload)
      : await axios.post(apiUrl);

    postLoad(loadedData);
  }

  return {
    chartData,
    data,
    groupFilters,
    selectedGroups,
    startDate,
    endDate,
    handleExport,
    handleGroupFilter,
    handleEndDateSelect,
    handleStartDateSelect,
    clearGroupFilter,
    loadPageData,
    loadFilterData,
  };
};

import axios from 'axios';
import { DateTime } from 'luxon';
import { Model, useRepo } from 'pinia-orm';
import { Attr, HasMany, Num, Str } from 'pinia-orm/dist/decorators';

import GroupGoalReport from './group_goal_report';

interface IGoal {
  id: number;
  created: string;
  description: string;
  name: string;
}

interface IAssessmentAnswer {
  answer_id: number;
}

interface IGoalLearner {
  id: number;
  created: string;
  group_learner_id: number;
  group_goal_id: number;
}

interface IGoalPayload {
  group_id: number;
  assessment_result: {
    assessment_id: number;
    items: IAssessmentAnswer[];
  };
  name: string;
  description: string;
  percent_complete: number;
  group_learners: IGoalLearner[];
}

export default class GroupGoal extends Model {
  static entity = 'group-goal';

  @Num(null) declare id: number;
  @Str('') declare created: string;
  @Num(null) declare goal_id: number;
  @Num(null) declare group_id: number;
  @Num(null) declare percent_complete: number;
  @Attr(null) declare goal: IGoal;
  @Attr(null) declare group_learners: IGoalLearner[];
  @HasMany(() => GroupGoalReport, 'group_goal_id')
  declare reports: GroupGoalReport[];

  static api() {
    return {
      async create(payload: IGoalPayload) {
        await axios.post('/group-goal', payload);
      },
      async delete(groupGoalId: number) {
        await axios.delete(`/group-goal/${groupGoalId}`);

        useRepo(GroupGoal).destroy(groupGoalId);
      },
      async report(payload: {
        group_goal_id: number;
        percent_complete: number;
        as_of: string;
        ended: boolean;
      }) {
        await axios.post(`/group-goal-report/`, payload);
      },
    };
  }

  get latestReport() {
    const sortedReports = this.reports
      ?.slice()
      .sort(
        (a, b) => DateTime.fromISO(b.created) - DateTime.fromISO(a.created)
      );

    return sortedReports[0];
  }

  get latestReportDate() {
    return this.latestReport?.as_of || this.created;
  }
}

<script lang="ts" setup>
  import { computed, ref, watch } from 'vue';

  import RecordingFeedback from '@/components/RecordingFeedback.vue';

  const props = defineProps<{
    connectionData: object;
  }>();

  const position = ref(0);

  const currentNote = computed(() => {
    if (recording.value === null) return null;
    return recording.value.scrubNote(position.value);
  });

  const name = computed(() => {
    if (recording.value === null) return '';
    return recording.value.name;
  });

  const recording = computed(() => {
    return props.connectionData.recording;
  });

  const reviewStreamContainerClass = computed(() => {
    let className = 'session-stream-review-video-feedback';
    if (recording.value === null) className = '';
    else if (recording.value.type === 'Audio')
      className = 'session-stream-review-audio';
    else if (!recording.value.hasFeedback)
      className = 'session-stream-review-video';

    return className;
  });

  const showFeedback = computed(() => {
    if (recording.value === null) return false;
    return recording.value.hasFeedback;
  });

  const showControls = computed(() => {
    return recording.value !== null;
  });

  const heightStyle = computed(() => {
    if (!showFeedback.value) {
      return 'max-height: calc(100vh - 155px)';
    }

    return 'max-height: calc(100vh - 415px)';
  });

  function getScrubNoteWidthStyle(newPosition: number) {
    const percentage = (newPosition / recording.value.duration) * 100;
    let offsetPixels = ((50.0 - percentage) / 100.0) * 16;
    return `calc(${Math.round(percentage)}% + ${Math.round(offsetPixels)}px )`;
  }

  function setPosition(newPosition: number) {
    position.value = newPosition;
  }

  watch(
    () => props.connectionData.stream,
    (state) => {
      if (state === null) {
        position.value = 0;
      }
    }
  );

  defineExpose({ setPosition });
</script>

<template>
  <div
    class="relative flex h-full w-full flex-col"
    style="max-height: calc(100% - 60px)"
  >
    <div class="video-stream-label">{{ name }}</div>

    <div
      :class="['video-stream-border', reviewStreamContainerClass]"
      :style="heightStyle"
    >
      <video
        :srcObject.prop.camel="connectionData.stream"
        autoplay
        playsinline
        class="video-stream-main mx-auto"
      ></video>
    </div>

    <div style="display: flex; height: 50px; padding-top: 10px; flex-basis: 0">
      <div
        v-if="showControls"
        style="
          width: 100%;
          height: 40px;
          position: relative;
          display: flex;
          align-items: center;
        "
      >
        <div
          v-for="recordingNote in recording.notesSorted"
          :key="'scrubNote' + recordingNote.id"
          class="video-scrub-note"
          :style="{
            width: getScrubNoteWidthStyle(recordingNote.note.position),
          }"
        ></div>

        <input
          ref="scrubBar"
          v-model.number="position"
          type="range"
          min="0"
          :max="recording.duration"
          step="0.2"
          class="scrub-bar w-full"
          disabled
        />
      </div>
    </div>

    <div
      v-if="showFeedback"
      class="flex h-full min-h-[160px] w-full flex-col pt-[10px]"
    >
      <RecordingFeedback
        :recording="recording"
        player-id="recordingViewerPlayer"
        :current-note="currentNote"
      />
    </div>
  </div>
</template>

<style scoped>
  .scrub-bar:disabled::-webkit-slider-runnable-track {
    @apply accent-brand-primary;
  }
  .scrub-bar:disabled::-webkit-slider-thumb {
    z-index: 5 !important;
    position: relative;
  }
</style>

import type { RouteLocationRaw } from 'vue-router';
import type { CoachingRequest, Feedback } from '@orm-lib';
import { DateTime } from 'luxon';
import type { Item } from 'pinia-orm';

export const useFeedback = () => {
  function formatTitle(request: Item<Feedback> | Item<CoachingRequest>) {
    return request?.collaborator_id !== null
      ? request?.learner_full_name
      : request.skill.name;
  }

  function formatAlert(request: Item<Feedback> | Item<CoachingRequest>) {
    return DateTime.fromISO(request?.created).toRelative();
  }

  function formatLink(
    request: Item<Feedback> | Item<CoachingRequest>
  ): RouteLocationRaw {
    return { name: 'FeedbackDetail', params: { feedbackId: request?.id } };
  }

  return {
    formatTitle,
    formatAlert,
    formatLink,
  };
};

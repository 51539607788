<script lang="ts" setup>
import { computed, ref, reactive } from "vue";

import axios from "axios";

import { RowBase, TextInput } from "@/components/public/forms";
import { Trash } from "@/components";
import {add} from "lodash-es";

type Nullable<T> = T | null;

interface IProps {
    id: string,
    required?: boolean,
    selfEmail?: Nullable<string>
    subtitle?: string | null,
    title: string | null,
}

const props = withDefaults(defineProps<IProps>(), {
    required: false,
    selfEmail: null,
    subtitle: null,
});

interface IEmits {
    (e: 'change', value:Array<string>): void;
}

const emit = defineEmits<IEmits>();

const data = reactive({
    inputValue: new Array<string>,
})

const state = reactive({
    blurred: false
})

const textInput = ref()

type TypeAheadResult = {
    email: string,
    in_use: boolean,
} | null

const typeAhead = ref(null as TypeAheadResult)

const error = computed(() => {
    if (state.blurred === false) {
        return null
    }

    if (props.required === true) {
        if (data.inputValue.length === 0) {
            return `${ props.title } is required`
        }
    }

    return null
})

const disableAddSelf = computed(() => {
    if (props.selfEmail !== null && data.inputValue.includes(props.selfEmail) === true) {
        return true
    }
    return false
})

const showAddSelf = computed(() => {
    if (props.selfEmail !== null)
        return true

    return false
})

const showError = computed(() => {
    if (typeAhead.value === null)
        return false

    if (typeAhead.value.in_use === false)
        return false

    return true
})

const showFound = computed(() => {
    if (typeAhead.value === null)
        return false

    if (typeAhead.value.in_use === true)
        return false

    return true
})

function addEmail(email:string) {
    data.inputValue.push(email)
    emit('change', data.inputValue)
}

function handleAdd() {
    if (typeAhead.value !== null) {
        addEmail(typeAhead.value.email);
        typeAhead.value = null
        textInput.value?.clear()
    }
}

function handleAddSelf() {
    if (props.selfEmail !== null) {
        addEmail(props.selfEmail)
    }
}

async function handleChange(inputValue: Nullable<string>) {
    if (inputValue === null || inputValue?.includes('@') === false) {
        typeAhead.value = null
        return
    }

    const response = await axios.post('/signup/search', {
        search_term: inputValue,
    });

    if (response.data.length > 0) {
        typeAhead.value = response.data[0].result;
    } else {
        typeAhead.value = null
    }
}

function handleDelete(email:string) {
    data.inputValue = data.inputValue?.filter(item => item !== email)
}

function resetValidation() {
    state.blurred = false
}

function validate() {
    state.blurred = true
    return error.value
}

defineExpose({ resetValidation, validate });
</script>

<template>
    <RowBase :error=error :title=title :subtitle=subtitle>
        <div class="flex flex-col">
            <TextInput :id=id ref="textInput" :error=error :title=title @change=handleChange />
            <div class="relative flex flex-col">
                <button v-if=showFound class="email-list-typeahead" state=found @click=handleAdd>
                    <b>Invite</b> {{ typeAhead?.email }}
                </button>
                <button v-else-if=showError class="email-list-typeahead" state=error disabled>
                    {{ typeAhead?.email }} is already in use.
                </button>
            </div>
        </div>
        <div v-for="email in data.inputValue" v-bind:key="email" class="flex flex-row gap-[8px]">
            <div class="flex flex-row grow text-darkGrey text-[14px] md:text-[16px] font-[300] bg-barelyGrey h-[44px] border-lightGrey border-[1px] rounded-[8px] items-center px-[16px]">
                {{ email }}
            </div>
            <button class="email-list-delete" @click="handleDelete(email)">
                <Trash class="stroke-error h-[22px] md:h-[24px] w-[22px] md:w-[24px]" />
            </button>
        </div>
        <div v-show=showAddSelf>
            <button :disabled=disableAddSelf class="email-list-self-add" @click=handleAddSelf>Add me to this list</button>
        </div>
    </RowBase>
</template>

<style scoped>
.email-list-typeahead {
    @apply absolute z-10 w-full mt-[8px] border-[1px] rounded-[8px] h-[44px] items-center font-[300] px-[16px] text-left text-[14px] md:text-[16px];
}

.email-list-typeahead[state=found] {
    @apply border-lightGrey bg-white text-blackish
}

.email-list-typeahead[state=found]:hover,
.email-list-typeahead[state=found]:focus-visible {
    @apply border-lightGrey bg-barelyGrey text-primary
}

.email-list-typeahead[state=found]:focus-visible {
    @apply outline-golden outline-dashed outline-offset-[2px] outline-[1px];
}

.email-list-typeahead[state=error] {
    @apply border-lightGrey bg-white text-error
}

.email-list-delete {
    @apply flex flex-col bg-white border-error border-[1px] w-[44px] h-[44px] rounded-[8px] justify-center items-center;
}

.email-list-delete:focus-visible {
    @apply outline-golden outline-dashed outline-offset-[2px] outline-[1px];
}

.email-list-delete:hover {
    @apply bg-error
}

.email-list-delete:hover svg {
    @apply stroke-white
}

.email-list-self-add {
    @apply h-[44px] text-primary px-[8px] font-[300] rounded-[8px] text-[14px] md:text-[16px]
}

.email-list-self-add:disabled {
    @apply text-grey
}

.email-list-self-add:focus-visible {
    @apply outline-golden outline-dashed outline-offset-[2px] outline-[1px];
}
</style>
import { reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import axios, { isAxiosError } from 'axios';
import { isNil } from 'lodash';

export const useForm = (
  initialState: unknown,
  submitHandler?: (arg?: unknown) => Promise<void>
) => {
  if (typeof initialState !== 'object' || initialState === null) {
    throw new Error('Invalid initial form state provided to useForm');
  }

  const form = reactive(initialState);
  const error = ref('');

  function handleError(e, defaultError?: string) {
    error.value =
      defaultError ||
      'Unable to submit form. Please try again or contact support.';

    if (isAxiosError(e)) {
      const data = e?.response?.data;
      error.value = Object.values(data)?.[0]?.[0];
    }
  }

  async function submit() {
    if (!submitHandler) {
      throw new Error('No submit handler provided for useForm');
    }

    try {
      await submitHandler();
    } catch (e) {
      handleError(e);
    }
  }

  return {
    error,
    form,
    handleError,
    submit,
  };
};

export const useFormFlow = (form: object, submitHandler?: (cb?) => void) => {
  const router = useRouter();

  const currentIndex = ref<number>(0);
  const error = ref('');

  function updateForm(updatedFields: object) {
    if (!isNil(updatedFields)) {
      Object.entries(updatedFields).forEach(
        ([key, value]: [string, unknown]) => {
          form[key] = value;
        }
      );
    }
  }

  function prev() {
    if (currentIndex.value === 0) {
      return router.back();
    }

    currentIndex.value--;
  }

  function next(updatedFields: object) {
    updateForm(updatedFields);

    currentIndex.value++;
  }

  function submit() {
    if (!submitHandler) {
      throw new Error('No submit handler provided for useFormFlow');
    }

    try {
      error.value = '';
      submitHandler();
    } catch (e) {
      error.value =
        'Unable to submit assessment. Please try again or contact support.';
      if (axios.isAxiosError(e)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        error.value = e?.response?.data?.non_field_errors?.[0];
      }
    }
  }

  watch(currentIndex, () => {
    error.value = '';
  });

  return {
    currentIndex,
    error,
    submit,
    next,
    prev,
    updateForm,
  };
};

<script lang="ts" setup>
  import { computed, reactive, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { helpers, required } from '@vuelidate/validators';

  import { GroupLearner } from '@orm-lib';
  import {
    UiForm,
    UiFormCheckbox,
    UiFormGroup,
    UiFormNavigation,
    useMedia,
    useValidatedForm,
  } from '@ui-lib';
  import EmptyCard from '@/components/EmptyCard.vue';
  import { AppCheckboxGroup } from '@/components/index';
  import type { IOnboardingSignupRequest } from '@/types';

  const router = useRouter();

  interface IProps {
    groupLearners: GroupLearner[];
    serviceError?: string;
    form: IOnboardingSignupRequest;
  }
  interface IEmits {
    (e: 'prev'): void;
    (e: 'next', request: IOnboardingSignupRequest): void;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<IEmits>();

  const localForm = reactive({ ...props.form });
  const initialChecked = localForm.group_learners.map((learner) => learner.id);
  const initialAllChecked =
    localForm.group_learners.length === props.groupLearners?.length;
  const localValue = ref(initialChecked);
  const allChecked = ref(initialAllChecked);
  const sm = useMedia('sm');

  const validations = {
    description: {
      required: helpers.withMessage('Description is required', required),
    },
    name: {
      required: helpers.withMessage('Name is required', required),
    },
  };
  const { isSubmitting, validate, v$ } = useValidatedForm(
    localForm,
    validations,
    submit
  );

  const learnerOptions = computed(() => {
    return (
      props.groupLearners?.map((learner) => {
        return {
          id: learner.id,
          title: learner.learner.full_name,
          subtitle: learner.learner.email,
        };
      }) ?? []
    );
  });

  const allText = computed(() => {
    if (allChecked.value) {
      return 'All Learners Selected';
    }

    return `${localValue.value.length} Learner${
      localValue.value.length === 1 ? '' : 's'
    } Selected`;
  });

  async function submit() {
    const updatedForm = {
      ...localForm,
      group_learners: localValue.value.map((val) => {
        return {
          id: val,
        };
      }),
    };

    emit('next', updatedForm);
  }

  function handleCheckItem(checked: boolean, value: number) {
    if (checked && localValue.value?.length) {
      localValue.value?.push(value);

      if (localValue.value.length === props.groupLearners.length) {
        allChecked.value = true;
      } else {
        allChecked.value = false;
      }
      return;
    }

    if (checked && !localValue.value?.length) {
      localValue.value = [value];
      if (localValue.value.length === props.groupLearners.length) {
        allChecked.value = true;
      } else {
        allChecked.value = false;
      }
      return;
    }

    localValue.value = localValue.value.filter((val) => value !== val);
    allChecked.value = false;
  }

  function handleAllCheck(checked: boolean) {
    if (checked) {
      localValue.value = props.groupLearners.map((learner) => learner.id);
      allChecked.value = true;
    } else {
      localValue.value = [];
      allChecked.value = false;
    }
  }
</script>

<template>
  <UiForm :error="serviceError" variant="app-config" @submit="validate">
    <UiFormGroup class="md:!mb-18 !mb-6 sm:!mb-12">
      <template v-if="learnerOptions.length">
        <UiFormCheckbox
          id="all"
          v-model="allChecked"
          :class="
            sm ? '[&>input]:mb-4' : 'ml-4 [&>input]:mb-3 [&>input]:mr-2.5'
          "
          @input="handleAllCheck"
        >
          <div class="option-content">
            <div
              class="option-title -mt-1 text-left text-base font-semibold text-[#242431]"
            >
              {{ allText }}
            </div>
          </div>
        </UiFormCheckbox>
        <AppCheckboxGroup
          v-model="localValue"
          is-multiple
          class="!mb-0"
          :variant="sm ? 'primary' : 'bordered'"
          :options="learnerOptions"
          @input="handleCheckItem"
        />
      </template>
      <EmptyCard
        v-else
        heading="There are no learners in this group."
        message="You can create this goal without learners and associate them once they have been added to your group."
      ></EmptyCard>
    </UiFormGroup>
    <UiFormNavigation
      class="mb-0"
      show-previous
      prev-text="Go Back"
      submit-text="Next Step"
      :loading="isSubmitting"
      @prev="emit('prev')"
    >
      <slot></slot>
    </UiFormNavigation>
  </UiForm>
</template>

import { computed, ref } from 'vue';
import { DateTime } from 'luxon';
import { useRepo } from 'pinia-orm';

import { Engagement, Session } from '@orm-lib';

export const useCalendly = (engagementId: number, isCoach: boolean) => {
  const baseLink = ref('');
  const showCalendly = ref(false);

  const engagement = computed(() => {
    return useRepo(Engagement).withAll().whereId(engagementId).first();
  });

  const currentSession = computed(() => {
    return useRepo(Session)
      .withAllRecursive()
      .whereId(engagement.value?.current_session_id ?? 0)
      .first();
  });

  const scheduledDate = computed(() => {
    if (!currentSession.value?.scheduled) {
      return null;
    }

    return DateTime.fromISO(currentSession.value.scheduled).toFormat(
      'cccc, MMM dd'
    );
  });

  const scheduledTime = computed(() => {
    if (!currentSession.value?.scheduled) {
      return null;
    }

    return `${DateTime.fromISO(currentSession.value.scheduled).toFormat(
      'h:mma'
    )}-${DateTime.fromISO(currentSession.value.scheduled)
      .plus({ hours: 1 })
      .toFormat('h:mma')} \u2022 ${engagement.value?.name}`;
  });

  async function loadCalendlyLink() {
    if (!currentSession.value?.scheduled) {
      baseLink.value = await Engagement.api().schedulerLink(
        engagement.value?.id ?? 0
      );
    } else {
      baseLink.value = currentSession.value?.cancel_url ?? '';
    }
  }

  return {
    baseLink,
    currentSession,
    engagement,
    scheduledDate,
    scheduledTime,
    showCalendly,
    loadCalendlyLink,
  };
};

import { Model, useRepo } from 'pinia-orm';
import { Num, Str } from 'pinia-orm/dist/decorators';

export default class Upload extends Model {
  static entity = 'upload';

  @Num(null) declare id: number;
  @Num(null) declare engagement_id: number;
  @Str('') declare name: string;
  @Str('') declare status: string;

  static async handleModelMessage(message: {
    payload: Upload;
    method: 'create' | 'update' | 'delete';
  }) {
    if (message.method === 'create') {
      useRepo(Upload).save(message.payload);
    } else if (message.method === 'update') {
      useRepo(Upload).save(message.payload);
    } else if (message.method === 'delete') {
      useRepo(Upload).destroy(message.payload.id);
    }
  }
}

import { ref } from 'vue';

export const usePillFilter = () => {
  const selectedFilters = ref<string[]>([]);

  function resetFilters() {
    selectedFilters.value = [];
  }

  function toggleFilter(option: string) {
    const selectedFilterIndex = selectedFilters.value.indexOf(option);

    if (selectedFilterIndex < 0) {
      selectedFilters.value.push(option);
    } else {
      selectedFilters.value.splice(selectedFilterIndex, 1);
    }
  }

  return {
    selectedFilters,
    resetFilters,
    toggleFilter,
  };
};

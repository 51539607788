<script lang="ts" setup>
import {
    computed,
    onBeforeUnmount,
    ref
} from 'vue';

import { PublicFooter } from "@/components/index";

interface IProps {
    variant?: 'primary' | 'gradient';
}

const props = withDefaults(defineProps<IProps>(), {
    enableNavigation: true,
    variant: 'primary',
});

const isScrolled = ref(false);

function handleScroll() {
    isScrolled.value = window.scrollY > 1;
}

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
});

</script>

<template>
    <div class="flex w-full flex-col">
        <div class="bg-barelyGrey h-[60px] md:h-[80px] fixed w-full top-0 z-10 flex justify-center items-center border-b-[1px] transition-colors ease-out duration-[200ms]" :class="{'bg-white' : isScrolled, 'border-b-lightGrey' : isScrolled, 'border-b-transparent' : !isScrolled }">
            <img src="/assets/img/brand/brandmark-primary.svg" class="h-[28px]" />
        </div>
        <div class="font-sans flex flex-col pt-[96px] gap-[96px] mt-[60px] md:mt-[80px] bg-barelyGrey min-h-[calc(100vh-60px)] md:min-h-[calc(100vh-80px)]">
            <div class="flex-1">
                <slot></slot>
            </div>
            <div class="flex-none">
                <PublicFooter />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { reactive, ref } from "vue";
import { useRouter } from 'vue-router';

import type { AxiosError } from "axios";

import { useAppStore } from '@/stores';
import type { LogInFormPayload } from '@/components/public/types';
import { LogInFormPayloadCreate } from '@/components/public/types';
import { FormBase, TextRow } from './'

type Nullable<T> = T | null;

interface IEmits {
    (e: 'submit', value:LogInFormPayload): void;
}

const emit = defineEmits<IEmits>();

const router = useRouter();

const store = useAppStore();

const errorMessage = ref<string | null>(null)

const data = reactive(LogInFormPayloadCreate())

const emailInput = ref<typeof TextRow | null>(null)
const passwordInput = ref<typeof TextRow | null>(null)
const inputs = [
    emailInput,
    passwordInput
]

function handleEmailInputChange(inputValue: Nullable<string>) {
    if (inputValue !== null)
        data.email = inputValue
}

function handleForgot() {
    router.push({
        name: 'LoginForgot',
    })
}

function handlePasswordInputChange(inputValue: Nullable<string>) {
    if (inputValue !== null)
        data.password = inputValue
}

async function handleSubmit() {
    let errorCount = 0;

    inputs.forEach((input) => {
        let error = input.value?.validate()
        if (error !== null) {
            errorCount += 1;
        }
    });

    if (errorCount !== 0)
        return

    try {
        await store.login({
            username: data.email,
            password: data.password,
        });
        errorMessage.value = null
    } catch(e) {
        errorCount = 1
        const axiosError = e as AxiosError
        const errors = axiosError.response?.data as object
        errorMessage.value = Object.values(errors)[0][0]
    }

    if (errorCount === 0) {
         emit('submit', data)
    }
}
</script>

<template>
    <FormBase
        :error-message=errorMessage
        mode=log-in
        title="Log In"
        subtitle="Enter your credentials to log in to Backstage."
        @forgot=handleForgot
        @submit=handleSubmit
    >
        <TextRow id="log-in-email" ref="emailInput" :required="true"  title="Work Email" type="text" @change=handleEmailInputChange @submit=handleSubmit />
        <TextRow id="log-in-password" ref="passwordInput" :required="true" title="Password" type="password" @change=handlePasswordInputChange @submit=handleSubmit />
    </FormBase>
</template>
<script lang="ts" setup>
  import { ref } from 'vue';

  const message = ref('Success');
  const success = ref(false);
  const timeout = ref<ReturnType<typeof setTimeout> | null>(null);

  const show = (customMessage) => {
    message.value = customMessage;

    if (timeout.value !== null) {
      clearTimeout(timeout.value);
      timeout.value = null;
    }

    success.value = true;
    timeout.value = setTimeout(() => {
      success.value = false;
    }, 2500);
  };

  defineExpose({ show });
</script>

<template>
  <transition name="fade">
    <div v-if="success" class="form-success">
      {{ message }}
    </div>
  </transition>
</template>

<style scoped>
  div.form-success {
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    padding: 10px 15px;
    border-radius: 0 10px 0 0;
    @apply bg-[#ffc7cd] font-semibold text-[#e44c5c];
  }
</style>

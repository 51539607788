<script lang="ts" setup>
  import { computed } from 'vue';
  import { type RouteLocationRaw, useRoute, useRouter } from 'vue-router';
  import { useRepo } from 'pinia-orm';

  import { type TActionReason, Assignment } from '@orm-lib';
  import { UiRouterLink } from '@ui-lib';

  interface IBaseTask {
    assigned?: string | null;
    title: string;
    subtitle: string;
    reason: TActionReason;
    groupId?: number | null;
    groupGoalId?: number | null;
    groupLearnerId?: number | null;
    assignmentId: number | null;
    feedbackRequestId: number | null;
    status: string;
    statusType: 'warning' | 'error';
    ownedGroupId?: number;
  }

  const props = defineProps<IBaseTask>();
  const router = useRouter();

  const assignment = computed(() => {
    return useRepo(Assignment).find(props.assignmentId ?? 0);
  });

  const route = useRoute();

  const to = computed(() => {
    const lib: Record<TActionReason, RouteLocationRaw> = {
      EmployerWithoutGroup: {
        name: 'Administration',
        query: route.query.tab ? {} : { tab: 'directory' },
      },
      EmployerPastDue: {
        name: 'Billing',
        query: route.query.tab ? {} : { tab: 'history' },
      },
      GroupWithoutLearner: {
        name: 'AdministrationGroup',
        params: {
          groupId: props.groupId,
        },
        query: route.query.tab ? {} : { tab: 'directory' },
      },
      GroupWithTrainingRequest: {
        name: 'GroupManagement',
        params: {
          groupId: props.groupId,
        },
        query: { tab: 'requests' },
      },
      EmployerWithTrainingRequest: {
        name: 'Administration',
        query: { tab: 'requests' },
      },
      GroupWithoutOwner: {
        name: 'AdministrationGroup',
        params: {
          groupId: props.groupId,
        },
        query: route.query.tab ? {} : { tab: 'directory' },
      },
      GroupLearnerWithoutGoal: {
        name: 'GroupLearnerGoalManage',
        params: {
          groupLearnerId: props.groupLearnerId,
        },
      },
      GoalWithoutReport: {
        name: 'GroupGoalProgress',
        params: {
          groupGoalId: props.groupGoalId,
          groupId: props.ownedGroupId,
        },
      },
      GroupWithoutGoal: {
        name: 'GroupGoalCreate',
        params: {
          groupId: props.groupId,
        },
      },
      IncompleteCollaboratorFeedbackRequest: {
        name: 'FeedbackDetail',
        params: {
          feedbackId: props.feedbackRequestId,
        },
      },
      IncompleteLearnerFeedbackRequest: {
        name: 'FeedbackDetail',
        params: {
          feedbackId: props.feedbackRequestId,
        },
      },
      UnstartedAssignment: {
        name: 'Assignment',
        params: {
          assignmentId: props.assignmentId,
          skillId: assignment.value?.skill_id,
        },
      },
    };

    return lib[props.reason];
  });

  const dueStatusColor = computed(() => {
    if (props.statusType === 'error') {
      return 'bg-[#D6272F] text-[#D6272F] ';
    }

    if (props.statusType === 'warning') {
      return 'bg-[#AF6900] text-[#AF6900] ';
    }

    return 'bg-brand-primary text-brand-primary';
  });
</script>

<template>
  <UiRouterLink
    :to="to"
    class="border-page-gray-border [&>*>h3]:hover:text-brand-primary flex w-full flex-col border-solid bg-white p-2 first:rounded-t-lg last:rounded-b-lg hover:bg-[#F6F8FC] hover:no-underline sm:p-4 [&:not(:last-child)>div:last-child]:border-b-[1px]"
  >
    <div class="mb-[6px] flex items-start justify-between sm:mb-2">
      <h3
        class="text-header-primary overflow-hidden text-ellipsis whitespace-nowrap text-base font-semibold sm:text-lg"
      >
        {{ title }}
      </h3>
      <div
        :class="`${dueStatusColor} ml-2 flex items-center whitespace-nowrap rounded-lg bg-opacity-10 px-2 py-1 text-xs sm:px-3 sm:text-sm`"
      >
        {{ status }}
      </div>
    </div>
    <span
      class="text-body-secondary line-clamp-3 sm:line-clamp-2 text-sm sm:text-base"
    >
      {{ subtitle }}
    </span>
  </UiRouterLink>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import Popper from 'vue3-popper';

  import { UiAvatar } from '@ui-lib';

  const props = defineProps<{
    name: string | null;
    email: string;
    variant: 'learner' | 'owner';
  }>();
  const emit = defineEmits<{
    (e: 'delete'): void;
    (e: 'reinvite'): void;
  }>();
  const isPopperOpen = ref(false);
  const popperRef = ref();

  const initials = computed(() => {
    if (!props.name) {
      return '';
    }

    const name = props.name?.split(' ');

    return name[0]?.substring(0, 1) + name[1]?.substring(0, 1);
  });

  function handleRemove(close: () => null) {
    close();

    emit('delete');
  }

  function handleResendInvite(close: () => null) {
    close();

    emit('reinvite');
  }
</script>

<template>
  <div
    class="flex items-center border-x-[1px] border-t-[1px] border-solid border-[#E8ECF4] bg-white px-6 py-4 first:rounded-t-[10px] last:rounded-b-[10px] last:border-b-[1px]"
  >
    <UiAvatar :initials="initials" class="-mt-1 mr-2" />
    <div class="flex flex-col overflow-hidden">
      <h5
        class="text-header-primary overflow-hidden text-ellipsis whitespace-nowrap text-base font-semibold sm:text-lg"
      >
        {{ name }}
      </h5>
      <span
        class="text-body-secondary overflow-hidden text-ellipsis whitespace-nowrap text-sm"
      >
        {{ email }}
      </span>
    </div>
    <div class="ml-auto h-[30px]">
      <Popper
        offset-distance="4"
        placement="bottom-end"
        @open:popper="isPopperOpen = true"
        @close:popper="isPopperOpen = false"
      >
        <button
          ref="editButton"
          class="navigation-button ml-2 flex h-[30px] w-[30px] items-center justify-self-end rounded-lg hover:bg-[#E6EBF1] hover:outline-none hover:outline focus:bg-[#E6EBF1]"
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="29"
              height="29"
              rx="5.5"
              stroke="#E8ECF4"
            />
            <circle cx="15" cy="9" r="2" fill="#5C6582" />
            <circle cx="15" cy="15" r="2" fill="#5C6582" />
            <circle cx="15" cy="21" r="2" fill="#5C6582" />
          </svg>
        </button>
        <template #content="{ close }">
          <div ref="popperRef" class="learner-menu rounded-lg">
            <button
              v-if="!name"
              class="text-brand-primary flex w-full items-center rounded-t-lg border-b-[1px] border-solid border-[#E8ECF4] bg-white px-4 py-2 text-sm capitalize hover:bg-gray-50"
              @click="handleResendInvite(close)"
            >
              <svg
                class="mr-2 -ml-[1px] -mt-[2px]"
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 9V1H13V9H1Z" stroke="#5E5CFF" stroke-width="1.5" />
                <path
                  d="M1 1.5L7 5L13 1.5"
                  stroke="#5E5CFF"
                  stroke-width="1.5"
                />
              </svg>
              Resend Invite
            </button>
            <button
              :class="`flex items-center bg-white px-4 py-2 text-sm capitalize text-red-500 hover:bg-gray-50 ${
                name ? ' rounded-lg' : ' rounded-b-lg'
              }`"
              @click="handleRemove(close)"
            >
              <svg
                width="12"
                height="12"
                class="mr-2 -mt-[2px]"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.2002 2.70039H10.8002M4.8002 8.70039V5.10039M7.20019 8.70039V5.10039M8.40019 11.1004H3.6002C2.93745 11.1004 2.4002 10.5631 2.4002 9.90039V3.30039C2.4002 2.96902 2.66882 2.70039 3.0002 2.70039H9.00019C9.33157 2.70039 9.60019 2.96902 9.60019 3.30039V9.90039C9.60019 10.5631 9.06294 11.1004 8.40019 11.1004ZM4.8002 2.70039H7.20019C7.53157 2.70039 7.80019 2.43176 7.80019 2.10039V1.50039C7.80019 1.16902 7.53157 0.900391 7.20019 0.900391H4.8002C4.46882 0.900391 4.2002 1.16902 4.2002 1.50039V2.10039C4.2002 2.43176 4.46882 2.70039 4.8002 2.70039Z"
                  stroke="#FB4F57"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Remove {{ variant }}
            </button>
          </div>
        </template>
      </Popper>
    </div>
  </div>
</template>

<style scoped>
  .learner-menu {
    box-shadow: 0 4px 15px 5px rgba(0, 0, 0, 0.1);
  }
</style>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue';
  import axios from 'axios';

  import { UiButton, UiModal } from '@ui-lib';

  const props = defineProps<{
    paymentSecret: string;
    redirectUrl: string;
  }>();

  const emit = defineEmits<{
    (e: 'close'): void;
    (e: 'success'): void;
  }>();
  const stripe = ref();
  const stripeElements = ref();
  const paymentElement = ref();
  const addressElement = ref();
  const loaded = ref(false);
  const submitting = ref(false);

  async function addPaymentMethod() {
    submitting.value = true;
    try {
      const { error, setupIntent } = await stripe.value.confirmSetup({
        elements: stripeElements.value,
        confirmParams: {
          return_url: props.redirectUrl,
        },
        redirect: 'if_required',
      });

      if (error) {
        console.error(error);
        submitting.value = false;
        // handleError();
      } else if (setupIntent && setupIntent.status === 'succeeded') {
        await axios.put('/payment-method/setup-intent', {
          id: setupIntent.id,
        });
        emit('success');
      }
    } catch (error) {
      console.error(error);
      submitting.value = false;
      // handleError();
    }
  }

  onMounted(() => {
    stripe.value = Stripe(import.meta.env.VITE_STRIPE_KEY);
    stripeElements.value = stripe.value.elements({
      clientSecret: props.paymentSecret,
    });

    paymentElement.value = stripeElements.value.create('payment');
    paymentElement.value.mount('#stripe-payment-element');
    addressElement.value = stripeElements.value.create('address', {
      mode: 'billing',
    });
    addressElement.value.mount('#stripe-address-element');
    paymentElement.value.on('ready', () => {
      loaded.value = true;
    });
  });
</script>

<template>
  <UiModal @close="emit('close')">
    <div class="flex h-full min-h-[466px] w-full items-stretch bg-white p-8">
      <form class="flex w-full flex-col" @submit.prevent="addPaymentMethod">
        <h3 class="text-header-primary mb-4 text-lg font-bold md:text-2xl">
          Edit Payment Method
        </h3>
        <div id="stripe-address-element" class="mb-4"></div>
        <div id="stripe-payment-element" class="mb-8"></div>
        <div
          class="mt-auto flex flex-col-reverse justify-self-end sm:flex-row sm:justify-end"
        >
          <UiButton
            variant="app-home"
            color="white"
            class="sm:mr-4"
            @click="emit('close')"
          >
            Cancel
          </UiButton>
          <UiButton
            variant="app-home"
            class="mb-4 transition-all duration-500 sm:mb-0"
            type="submit"
            :disabled="!loaded || submitting"
            :loading="submitting"
          >
            Add Payment Method
          </UiButton>
        </div>
      </form>
    </div>
  </UiModal>
</template>

import axios from 'axios';
import { Model } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import Learner from './learner';

export default class GroupOwner extends Model {
  static entity = 'group-owner';

  @Num(null) declare id: number;
  @Num(null) declare learner_id: number;
  @Num(null) declare group_id: number;
  @BelongsTo(() => Learner, 'learner_id')
  declare learner: Learner;
  @Str(null) declare created: string;

  static api() {
    return {
      async invite(groupOwnerId: number) {
        await axios.get(`/group-owner/${groupOwnerId}/reinvite/`);
      },
    };
  }
}

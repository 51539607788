import { Model } from 'pinia-orm';
import { Attr, HasMany, Num, Str } from 'pinia-orm/dist/decorators';

import MeetingRecordingFeedbackItem from './meeting_recording_feedback_item';

export default class MeetingRecordingFeedback extends Model {
  static entity = 'meeting-recording-feedback';

  @Num(null) declare id: number;
  @Str(null) declare created: string;
  @HasMany(() => MeetingRecordingFeedbackItem, 'meeting_feedback_id')
  declare items: MeetingRecordingFeedbackItem;
  @Str(null) declare outcome: 'Succeeded' | 'Failed';
  @Str(null) declare outcome_date: string;
  @Attr() declare participants: any;
  @Str(null) declare summary: string;
  @Str(null) declare tagline: string;
}

<script lang="ts" setup>
import {
    computed,
    onBeforeUnmount,
    ref
} from 'vue';

import { throttle } from "lodash";

import { Footer } from '@/components/public/marginals';

interface IProps {
    isBlank?: boolean,
}

const props = withDefaults(defineProps<IProps>(), {
    isBlank: false,
});

const isScrolled = ref(false);

const showFooter = computed(() => {
    return import.meta.env.VITE_IS_DESKTOP === 'false'
})

function handleScroll() {
    isScrolled.value = window.scrollY > 1;
}

window.addEventListener('scroll', throttle(handleScroll, 200));

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
    <div v-if="isBlank === false" class="flex w-full flex-col">
        <div class="bg-barelyGrey h-[60px] md:h-[80px] fixed w-full top-0 z-10 flex justify-center items-center border-b-[1px] transition-colors ease-out duration-[200ms]" :class="{'border-b-lightGrey' : isScrolled, 'border-b-transparent' : !isScrolled }">
            <img src="/assets/img/brand/brandmark-primary.svg" class="h-[24px] md:h-[28px]" />
        </div>
        <div class="font-sans flex flex-col pt-[48px] md:pt-[96px] gap-[96px] mt-[60px] md:mt-[80px] bg-barelyGrey min-h-[calc(100vh-60px)] md:min-h-[calc(100vh-80px)]">
            <div class="flex-1 flex flex-col px-[8px]">
                <slot></slot>
            </div>
            <div v-if=showFooter class="flex-none">
                <Footer />
            </div>
        </div>
    </div>
    <div v-else class="flex w-full flex-col">
        <slot></slot>
    </div>
</template>
<script lang="ts" setup>
  import { computed } from 'vue';
  import { DateTime } from 'luxon';

  interface IProps {
    startsAt: string;
  }
  interface IEmits {
    (e: 'cancelRecording'): void;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<IEmits>();

  const day = computed(() => {
    return DateTime.fromISO(props.startsAt).toFormat('dd');
  });

  const month = computed(() => {
    return DateTime.fromISO(props.startsAt).toFormat('MMM');
  });

  const time = computed(() => {
    return DateTime.fromISO(props.startsAt).toFormat('h:mm a');
  });
</script>

<template>
  <div class="bordered-card flex gap-3 bg-white p-3">
    <div
      class="bordered-card flex flex-col items-center justify-center py-2 px-4"
    >
      <span class="leading-0 font-semibold uppercase">{{ month }}</span>
      <span class="leading-0 -mt-2 -mb-1 text-2xl font-semibold">
        {{ day }}
      </span>
    </div>
    <div class="flex flex-col items-start justify-center self-stretch">
      <span class="text-header-primary font-medium">
        Work meeting at {{ time }}
      </span>
      <button
        class="font-medium text-[#FB4F57]"
        @click="emit('cancelRecording')"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { reactive } from 'vue';
  import { helpers, required, sameAs } from '@vuelidate/validators';

  import {
    UiButton,
    UiForm,
    UiFormGroup,
    UiFormInput,
    useValidatedForm,
  } from '@ui-lib';

  interface IProps {
    canDelete: boolean;
  }

  const props = defineProps<IProps>();
  const emit = defineEmits<{
    (e: 'delete'): void;
  }>();

  const form = reactive({ delete: '' });
  const validations = reactive({
    delete: {
      required: helpers.withMessage('Type delete to proceed', required),
      sameAsDelete: helpers.withMessage(
        'Type delete to proceed',
        sameAs('delete')
      ),
    },
  });

  const { isSubmitting, serviceError, validate, v$ } = useValidatedForm(
    form,
    validations,
    deleteLearner
  );

  async function deleteLearner() {
    emit('delete');
  }

  const subtitle =
    'Type the word "delete" in the box below to delete this user. This will remove all of this user\'s access to trainings.';
</script>

<template>
  <UiForm
    v-if="canDelete"
    :error="serviceError"
    :subtitle="subtitle"
    success-message="Details updated"
    title="Delete User"
    variant="inline"
    class="!p-0"
    @submit="validate"
  >
    <UiFormGroup class="form-group-input !mb-8">
      <div class="flex w-full flex-row">
        <UiFormInput
          v-model="form.delete"
          label="Delete"
          placeholder="Delete user"
          type="text"
          :errors="v$.delete.$errors"
          class="w-full"
          variant="config"
        />
        <UiButton
          type="submit"
          class="icon ml-2 h-[46px] w-[48px] self-end !rounded-lg !p-0"
          :loading="isSubmitting"
          variant="icon-delete"
          @click="form.submitted = true"
        >
          <img src="/assets/img/icon/delete.png" class="h-[17px] w-4" />
        </UiButton>
      </div>
    </UiFormGroup>
  </UiForm>
  <div v-else>
    <div class="form-title">Delete User</div>
    <div class="form-subtitle">
      As an administrator, you are not allowed to delete your own account. Any
      other administrator can do it or you.
    </div>
  </div>
</template>

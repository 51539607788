<script setup lang="ts">
import {computed, nextTick, reactive, ref} from 'vue';
  import { useRouter } from 'vue-router';
  import axios from 'axios';
  import { isNil } from 'lodash';
  import { DateTime } from 'luxon';
  import { useRepo } from 'pinia-orm';

  import {
    Employer,
    GroupLearner,
    Skill,
    TrainingRequest,
    TrainingRequestItem,
  } from '@orm-lib';
  import {capturePosthogAction, UiButton, UiFormTextarea, usePage} from '@ui-lib';
  import PaymentMethodModal from '@/components/modals/PaymentMethodModal.vue';

  const props = defineProps<{
    isGroupOwner?: boolean;
    groupId: number;
    trainingRequest: TrainingRequest;
    groupLearners: GroupLearner[];
    employer: Employer;
    requestId?: number;
    form?: any;
  }>();
  const emit = defineEmits<{
    (e: 'prev'): void;
    (e: 'deny', comment: string): void;
    (e: 'approve', comment: string): void;
  }>();

  const loaders = props.isGroupOwner
    ? []
    : [getCostEstimate(), getPaymentClientSecret()];

  if (props.requestId) {
    loaders.push(TrainingRequest.api().list(props.groupId));
  }

  const { loaded } = usePage(loaders);
  const router = useRouter();

  const breakdown = reactive({
    credits_remaining_count: 0,
    credits_used_count: 0,
    employer_id: 0,
    focused_subscription_count: 0,
    focused_subscription_price: 0,
    focused_subscription_total: 0,
    lite_subscription_count: 0,
    lite_subscription_price: 0,
    lite_subscription_total: 0,
  });
  const paymentSecret = ref();
  const paymentMethodModalOpen = ref();
  const comments = ref('');
  const error = ref();
  const localForm = reactive({ ...props.form });

  const stripeRedirectUrl = computed(() => {
    return `${location.origin}/administration/${props.groupId}/request/${props.requestId}/review`;
  });

  const requestItems = computed(() => {
    return props.trainingRequest?.items ?? [];
  });

  const requestReviews = computed(() => {
    return props.trainingRequest?.reviews ?? [];
  });

  const hasPaymentMethod = computed(() => {
    return (
      !!props.employer?.billing?.payment_method ||
      props.employer.billing?.invoice_type === 'due'
    );
  });

  const isApproval = computed(() => {
    return !isNil(props.requestId);
  });

  function getOutcomeStyle(outcome: string) {
    if (outcome === 'Denied') {
      return '  bg-[#FB4F571A] text-[#FB4F57]';
    }

    if (outcome === 'Approved') {
      return ' text-[#00C47D] bg-[#00C47D]';
    }

    return ' bg-[#5E5CFF1A] text-[#5E5CFF]';
  }

  function getRequestFriendlyName(item: TrainingRequestItem) {
    if (item.subscription_tier === 'Focused') {
      const skillName = useRepo(Skill).find(item.skill_id)?.name;
      return `Focused: ${skillName}`;
    }

    return item.subscription_tier;
  }

  async function submitTraining() {
    const request = {
      group_id: localForm.group_id,
      items: localForm.items,
      comment: comments.value || null,
    };

    try {
      await TrainingRequest.api().submitRequest(request);
      await router.push({
        name: 'GroupManagement',
        params: {
          groupId: localForm.group_id,
        },
      });
    } catch (e) {
      error.value =
        'Unable to submit request at this time. Please try again later.';
    }
  }

    function finalizeTraining() {
        let capturePurchase = false;
        if (isApproval.value || props.isGroupOwner) {
            emit('approve', comments.value);
            capturePurchase = true;
        } else if (hasPaymentMethod.value) {
            submitTraining();
            capturePurchase = true;
        } else {
            paymentMethodModalOpen.value = true;
        }

        if (capturePurchase === true) {
            capturePosthogAction('purchase_training', 'start', 1);
            nextTick(() => {
                capturePosthogAction('purchase_training', 'complete', 1, {
                    training_request_id: props.trainingRequest.id,
                    focused_subscription_count: breakdown.focused_subscription_count,
                    focused_subscription_price: breakdown.focused_subscription_price,
                    focused_subscription_total: breakdown.focused_subscription_total,
                    lite_subscription_count: breakdown.lite_subscription_count,
                    lite_subscription_price: breakdown.lite_subscription_price,
                    lite_subscription_total: breakdown.lite_subscription_total,
                })
            })
        }
    }

  function handleCancel() {
    if (isApproval.value) {
      emit('deny', comments.value);
    } else {
      emit('prev');
    }
  }

  function handlePrivacyClick() {
      window.open(`${import.meta.env.VITE_WWW_BASE_URL}/legal/privacy`);
  }
  function handleServiceClick() {
      window.open(`${import.meta.env.VITE_WWW_BASE_URL}/legal/service`);
  }

  function handleTermsClick() {
      window.open(`${import.meta.env.VITE_WWW_BASE_URL}/legal/terms`);
  }

  async function handlePaymentSuccess() {
    await Employer.api().detail();
    paymentMethodModalOpen.value = false;
  }

  function getGroupLearnerName(item: any) {
    const learner = props.groupLearners?.find(
      (groupLearner) => groupLearner.id === item.group_learner_id
    );

    return learner?.learner?.full_name ?? '';
  }

  async function getCostEstimate() {
    if (props.requestId) {
      const { data } = await axios.post(`/cost-estimate/${props.requestId}`);
      Object.assign(breakdown, data);
    } else {
      const { data } = await axios.post('/cost-estimate', {
        items: props.trainingRequest.items,
      });
      Object.assign(breakdown, data);
    }
  }

  async function getPaymentClientSecret() {
    const { data: secret } = await axios.post('/payment-method/setup-intent');

    paymentSecret.value = secret;
  }
</script>

<template>
  <div>
    <PaymentMethodModal
      v-if="paymentMethodModalOpen"
      :payment-secret="paymentSecret"
      :redirect-url="stripeRedirectUrl"
      @close="paymentMethodModalOpen = false"
      @success="handlePaymentSuccess"
    />
    <div v-if="loaded" class="grid w-full grid-cols-12 gap-4">
      <div class="col-span-12 -mb-4 flex w-full flex-col xl:col-span-7">
        <div class="breakdown-panel">
          <h3 class="!border-b-0">Upgrades</h3>
          <div class="-mx-6 -mb-4 w-[calc(100%+48px)]">
            <table class="table w-full">
              <tr
                class="table-header-group w-full border-y-[1px] border-solid border-[#E8ECF4] bg-[#EFEFFF] bg-opacity-50"
              >
                <th class="breakdown-table-header pl-6">Name</th>
                <th
                  :class="`breakdown-table-header ${
                    isApproval ? 'pl-2 pr-6' : 'px-2'
                  }`"
                >
                  {{ isApproval ? 'Tier' : 'Current' }}
                </th>
                <th v-if="!isApproval" class="breakdown-table-header pr-6">
                  New
                </th>
              </tr>
              <tr
                v-for="item in requestItems"
                :key="item.id"
                class="border-b-[1px] border-solid border-[#E8ECF4] last:border-0"
              >
                <td class="breakdown-table-cell pl-6 font-bold">
                  {{ getGroupLearnerName(item) }}
                </td>
                <td
                  :class="`breakdown-table-cell ${
                    isApproval ? 'pl-2 pr-6' : 'px-2'
                  }`"
                >
                  {{ isApproval ? getRequestFriendlyName(item) : 'Basic' }}
                </td>
                <td v-if="!isApproval" class="breakdown-table-cell pr-6">
                  {{ getRequestFriendlyName(item) }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div v-if="isApproval" class="breakdown-panel">
          <h3>Activity</h3>
          <div v-for="review in requestReviews" :key="review.id" class="pt-4">
            <div class="flex items-start justify-between">
              <div>
                <h4 class="text-header-primary font-semibold">
                  {{
                    `${review?.created_by_learner?.user?.first_name} ${review?.created_by_learner?.user?.last_name}`
                  }}
                </h4>
                <span class="text-header-primary">
                  {{
                    DateTime.fromISO(review.created).toFormat('MMMM d, yyyy')
                  }}
                </span>
              </div>
              <span
                :class="`rounded-lg bg-opacity-10 px-2 py-1 text-sm ${getOutcomeStyle(
                  review.outcome
                )}`"
              >
                {{ review.outcome }}
              </span>
            </div>
            <p class="text-body-secondary mb-4 italic last:mb-0">
              {{ review.comment }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-span-12 flex w-full flex-col xl:col-span-5">
        <div class="breakdown-panel">
          <h3>{{ isGroupOwner ? 'Review' : 'Pricing' }}</h3>
          <div class="pt-4">
            <template v-if="!isGroupOwner">
              <div
                v-if="breakdown.focused_subscription_count"
                class="mb-2 border-b-[1px] border-solid border-[#E8ECF4] pb-4"
              >
                <h4 class="text-header-primary mb-2 text-lg font-semibold">
                  Focused Training
                </h4>
                <div class="breakdown-item">
                  <span>Number of Trainings</span>
                  <span class="font-semibold">
                    {{ breakdown.focused_subscription_count }}
                  </span>
                </div>
                <div class="breakdown-item">
                  <span>Credits Used</span>
                  <span class="font-semibold">
                    {{ breakdown.credits_used_count }}
                  </span>
                </div>
                <div class="breakdown-item">
                  <span>Credits Remaining</span>
                  <span class="font-semibold">
                    {{ breakdown.credits_remaining_count }}
                  </span>
                </div>
                <div class="breakdown-item">
                  <span>Unit Price</span>
                  <span class="font-semibold">
                    ${{ breakdown.focused_subscription_price }}
                  </span>
                </div>
                <div class="breakdown-item mt-2 !-mb-0">
                  <span class="text-xl font-bold">One-Time Cost</span>
                  <span class="text-xl font-bold">
                    ${{ breakdown.focused_subscription_total }}
                  </span>
                </div>
                <span class="text-body-primary mb-2 text-xs">
                  * This is a one-time cost that will be billed as each
                  individual training is started.
                </span>
              </div>
              <div
                v-if="breakdown.lite_subscription_count"
                class="mb-2 border-b-[1px] border-solid border-[#E8ECF4] pt-2"
              >
                <h4 class="text-header-primary mb-2 text-lg font-semibold">
                  Lite Subscriptions
                </h4>
                <div class="breakdown-item">
                  <span>Number of Subscriptions</span>
                  <span class="font-semibold">
                    {{ breakdown.lite_subscription_count }}
                  </span>
                </div>
                <div class="breakdown-item">
                  <span>Recurring price</span>
                  <span class="font-semibold">
                    <span>${{ breakdown.lite_subscription_price }}/</span>
                    <span class="text-sm">mo</span>
                  </span>
                </div>
                <div class="breakdown-item mt-2 !-mb-0">
                  <span class="text-xl font-bold">Monthly Cost</span>
                  <span class="text-xl font-bold">
                    <span>${{ breakdown.lite_subscription_total }}</span>
                    <span>/</span>
                    <span class="text-base">mo</span>
                  </span>
                </div>
                <span class="text-body-primary mb-2 text-xs">
                  * This is a recurring cost that will be billed each month.
                </span>
              </div>
            </template>
            <div :class="`${isGroupOwner ? '' : 'pt-2'}`">
              <template v-if="hasPaymentMethod || isGroupOwner">
                <h4
                  class="text-header-primary mb-2 text-left text-base font-semibold sm:text-lg"
                >
                  Comments
                </h4>
                <UiFormTextarea
                  v-model="comments"
                  class="feedback-note !max-h-[80px] !min-h-[80px]"
                  type="text"
                  placeholder="Enter a note..."
                  @input="(event) => (comments = event.target.value)"
                />
              </template>
              <div class="mt-4 flex w-full flex-wrap-reverse gap-4">
                <div v-if="isGroupOwner === false && isApproval === false && hasPaymentMethod === true" class="text-[14px] font-[300]">
                    By finalizing, you acknowledge that you understand and agree to the Backstage
                    <button class="agreement-link" @click=handleTermsClick>Terms of Use</button>,
                    <button class="agreement-link" @click=handlePrivacyClick>Privacy Policy</button>, and
                    <button class="agreement-link" @click=handleServiceClick>Service Agreement</button>
                </div>
                <UiButton
                  variant="app-home"
                  color="white"
                  :class="`min-w-[264px] shrink grow basis-[calc(50%-8px)] ${
                    isApproval &&
                    '!border-red-500 hover:!bg-red-300 hover:!bg-opacity-10 [&>*]:!text-red-500'
                  }`"
                  @click="handleCancel"
                >
                  {{ isApproval ? 'Deny' : 'Go Back' }}
                </UiButton>
                <UiButton
                  class="min-w-[264px] shrink grow basis-[calc(50%-8px)]"
                  variant="app-home"
                  :color="
                    hasPaymentMethod || isGroupOwner ? 'emerald' : 'primary'
                  "
                  @click="finalizeTraining"
                >
                  {{
                    hasPaymentMethod || isGroupOwner
                      ? isApproval
                        ? 'Approve'
                        : 'Finalize'
                      : 'Add Payment Method'
                  }}
                </UiButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .breakdown-panel {
    @apply mb-4 flex w-full flex-col rounded-[10px] border-[1px] border-solid bg-white px-6 py-4;
  }

  .breakdown-panel h3 {
    @apply text-brand-primary mt-1 border-b-[1px] border-solid pb-4 text-lg font-bold leading-[18px];
  }

  .breakdown-table-header {
    @apply text-brand-primary py-3 text-left text-sm font-semibold uppercase;
  }

  .breakdown-table-cell {
    @apply py-3;
  }
  .breakdown-item {
    @apply text-header-primary mb-2 flex w-full items-center justify-between;
  }
</style>

<style scoped>
.agreement-link {
    @apply text-primary px-0 rounded-[2px];
}

.agreement-link:hover {
    @apply underline cursor-pointer
}

.agreement-link:focus-visible {
    @apply outline-golden outline-dashed outline-offset-[2px];
}
</style>

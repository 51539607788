<script setup lang="ts">
  import { computed, ref } from 'vue';

  import { UiButton, UiDropdown, UiFormCheckbox } from '@ui-lib';

  const props = defineProps<{
    groupFilters: {
      id: number;
      name: string;
      parent_id: number;
      depth: 0;
      is_first_child: boolean;
    }[];
  }>();
  const emit = defineEmits<{
    (e: 'apply-filters', []): void;
    (e: 'clear-filters'): void;
  }>();
  const selectedGroups = ref([]);

  const selectedGroupsText = computed(() => {
    if (selectedGroups.value.length === 1) {
      return props.groupFilters.find(
        (filter) => filter.id === selectedGroups.value[0]
      ).name;
    }

    return selectedGroups.value.length
      ? `${selectedGroups.value.length} Groups`
      : 'All Groups';
  });

  const groupOptions = computed(() => {
    return props.groupFilters.map((filter) => {
      return {
        is_first_child: filter.is_first_child,
        depth: filter.depth,
        parent_id: filter.parent_id,
        id: filter.id,
        text: filter.name,
        value: filter.id,
      };
    });
  });

  function toggleOption(option: number) {
    const foundIndex = selectedGroups.value.indexOf(option);

    if (foundIndex > -1) {
      selectedGroups.value.splice(foundIndex, 1);
      return;
    }

    selectedGroups.value.push(option);
  }

  function applyFilters() {
    emit('apply-filters', selectedGroups.value);
  }

  function clearFilters() {
    selectedGroups.value = [];
    emit('clear-filters');
  }

  function showWeirdStyleLine(option, index: number) {
    if (!option.parent_id || option.depth <= 1) {
      return false;
    }

    function getParent(parentId: number) {
      return groupOptions.value.find((datum) => datum.id === parentId);
    }

    function getRootParent(parentId: number) {
      const foundParent = getParent(parentId);

      if (!foundParent.parent_id) {
        return foundParent;
      }
      return getRootParent(foundParent.parent_id);
    }

    return groupOptions.value.some(
      (groupOption, groupOptionIndex) =>
        groupOptionIndex > index &&
        groupOption.parent_id === getRootParent(option.parent_id).id
    );
  }
</script>

<template>
  <div class="relative w-full">
    <label class="text-body-secondary mb-1 block font-medium">Group</label>
    <div class="relative w-full">
      <svg
        class="absolute top-[14px] left-[18px] z-[2]"
        width="19"
        height="18"
        viewBox="0 0 21 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3333 14.6667V13.3334C18.3333 11.3697 16.7415 9.77783 14.7778 9.77783V9.77783"
          stroke="#5E5CFF"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M1 14.6667V14.6667C1 11.9667 3.18883 9.77783 5.88889 9.77783H8.55556C11.2556 9.77783 13.4444 11.9667 13.4444 14.6667V14.6667"
          stroke="#5E5CFF"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M13 0.888916V0.888916C15.8376 1.99243 15.8376 6.00762 13 7.11114V7.11114"
          stroke="#5E5CFF"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <circle
          cx="7.22223"
          cy="4"
          r="3.25"
          stroke="#5E5CFF"
          stroke-width="1.5"
        />
      </svg>
      <UiDropdown
        class="[div>*>button]:!max-h-[40px] [&>*>button>span]:!text-body-secondary w-full [&>div.absolute]:rounded-lg [&>div.absolute]:border-[1px] [&>div.absolute]:border-solid [&>div.absolute]:border-[#e8ecf4] [&>div.absolute]:shadow-none [&>*>button>span]:!pl-5 [&>*>button>span]:text-sm [&>*>button>span]:!font-normal"
        :options="groupOptions"
        :text="selectedGroupsText"
        variant="rich"
        @select="(selection) => emit('select-skill', selection)"
      >
        <div class="px-4 py-2 pt-6">
          <div class="overflow-x-hidden text-ellipsis whitespace-nowrap py-2">
            <UiFormCheckbox
              v-for="(option, index) in groupOptions"
              :id="option.id"
              :key="option.id"
              :class="`${
                selectedGroups.indexOf(option.id) > -1
                  ? '[&>label>span]:text-brand-primary'
                  : ''
              }`"
              :model-value="selectedGroups.indexOf(option.id) > -1"
              @click.stop
              @input="() => toggleOption(option.id, option.value)"
            >
              <div
                class="relative"
                @click="toggleOption(option.id, option.value)"
              >
                <svg
                  v-if="showWeirdStyleLine(option, index)"
                  class="absolute top-1.5 -mt-[50px]"
                  width="18"
                  height="50"
                  viewBox="0 0 18 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="1"
                    x2="1"
                    y1="0"
                    y2="50"
                    stroke="#CFCEFF"
                    stroke-width="1.5"
                  />
                </svg>
                <svg
                  v-if="option.parent_id && option.is_first_child"
                  class="absolute -mt-[30px]"
                  :style="{
                    left: `${24 * (option.depth - 1)}px`,
                  }"
                  width="18"
                  height="40"
                  viewBox="0 0 18 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1V30C1 34.4183 4.58172 38 9 38H17"
                    stroke="#CFCEFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
                <svg
                  v-else-if="option.parent_id"
                  class="absolute -mt-[50px]"
                  :style="{
                    left: `${24 * (option.depth - 1)}px`,
                  }"
                  width="18"
                  height="61"
                  viewBox="0 0 18 61"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1V52C1 56.4183 4.58172 60 9 60H17"
                    stroke="#CFCEFF"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
                <span
                  class="block text-ellipsis whitespace-nowrap"
                  :style="{
                    'padding-left': `${24 * option.depth}px`,
                  }"
                >
                  {{ option.text }}
                </span>
              </div>
            </UiFormCheckbox>
            <UiButton
              variant="app-home"
              class="mb-3 mt-2 w-full"
              @click="applyFilters"
            >
              Apply
            </UiButton>
            <div class="flex justify-between">
              <span class="text-body-secondary">
                Selected: {{ selectedGroups.length }}
              </span>
              <UiButton variant="link" @click="clearFilters">
                Clear filters
              </UiButton>
            </div>
          </div>
        </div>
      </UiDropdown>
    </div>
  </div>
</template>

<style scoped>
  :deep(.checkbox:first-child) {
    @apply !pt-0;
  }
  :deep(.checkbox) {
    @apply !mb-4 px-2;
  }

  :deep(.checkbox input) {
    @apply !border-brand-primary !mb-0 cursor-pointer !rounded-sm !border-[2px] bg-white;
  }
  :deep(.checkbox input:checked) {
    @apply bg-brand-primary;
  }
  :deep(.checkbox label) {
    @apply text-body-secondary max-w-[40px] cursor-pointer text-base font-medium !leading-none;
  }
</style>

<script lang="ts" setup>
  import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

  import { Engagement, Session, User } from '@orm-lib';
  import { UiModal } from '@ui-lib';
  import { useAppStore } from '@/stores';

  const props = defineProps<{
    baseLink: string;
    engagementId: number;
    isReschedule: boolean;
    sessionId: number;
    user: null | User;
  }>();

  const emit = defineEmits<{
    (e: 'hideCalendly'): void;
    (e: 'getScheduleLink'): void;
  }>();

  const calendly = ref();
  const appStore = useAppStore();

  const schedulerLink = computed(() => {
    return `${props.baseLink}?embed_domain=${encodeURIComponent(
      document.location.host
    )}&name=${props.user?.full_name}&email=${props.user?.email}`;
  });

  function isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  }

  function isScheduleEvent(e) {
    return e.data.event && e.data.event === 'calendly.event_scheduled';
  }

  async function submitInvitees(e) {
    const invitee = e.data.payload.invitee.uri;
    if (props.isReschedule) {
      await Session.api().reschedule(
        props.sessionId,
        props.engagementId,
        invitee
      );
    } else {
      await Session.api().schedule(props.engagementId, invitee);
    }
    await Engagement.api().detail(props.engagementId);
    emit('getScheduleLink');
  }

  function handleDomEvent(e) {
    if (isCalendlyEvent(e) && isScheduleEvent(e)) {
      submitInvitees(e);
    }
  }

  onMounted(() => {
    window.Calendly.initInlineWidget({
      url: schedulerLink.value,
      parentElement: document.getElementById('calendly-inline-widget'),
    });

    window.addEventListener('message', handleDomEvent);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('message', handleDomEvent);
  });
</script>

<template>
  <UiModal
    variant="full"
    class="[&>div>div>div]:!bg-transparent [&>div>div]:max-h-[100vh] [&>div>div]:p-0"
    @close="emit('hideCalendly')"
  >
    <div class="h-[100vh] min-h-[100vh] bg-white">
      <div
        id="calendly-inline-widget"
        data-auto-load="false"
        class="h-full"
      ></div>
    </div>
  </UiModal>
</template>

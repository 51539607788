<script lang="ts" setup>
  import { reactive } from 'vue';
  import { useRouter } from 'vue-router';
  import { helpers, required } from '@vuelidate/validators';
  import { DateTime } from 'luxon';

  import {
    UiDatePicker,
    UiForm,
    UiFormGroup,
    UiFormInput,
    UiFormNavigation,
    UiFormTextarea,
    useValidatedForm,
  } from '@ui-lib';
  import type { IOnboardingSignupRequest } from '@/types';

  const router = useRouter();

  interface IProps {
    serviceError?: string;
    form: IOnboardingSignupRequest;
  }
  interface IEmits {
    (e: 'next', request: any): void;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<IEmits>();

  const localForm = reactive({ ...props.form });
  localForm.as_of = DateTime.now().toFormat('yyyy-MM-dd');
  const validations = {
    description: {
      required: helpers.withMessage('Description is required', required),
    },
    name: {
      required: helpers.withMessage('Name is required', required),
    },
  };

  const { isSubmitting, validate, v$ } = useValidatedForm(
    localForm,
    validations,
    submit
  );

  function handleDateSelect(value) {
    localForm.as_of = value;
  }

  async function submit() {
    emit('next', localForm);
  }
</script>

<template>
  <UiForm :error="serviceError" variant="app-config" @submit="validate">
    <UiFormGroup class="md:!mb-18 !mb-6 sm:!mb-12">
      <div class="mb-2 flex flex-wrap items-end gap-2 sm:flex-nowrap sm:gap-4">
        <UiFormInput
          v-model="localForm.name"
          class="w-full !max-w-full basis-full [&>label]:!text-base [&>label]:!opacity-100"
          label="Name"
          placeholder="Briefly describe your goal..."
          type="text"
          :errors="v$.name.$errors"
        />
      </div>
      <UiFormTextarea
        v-model="localForm.description"
        class="[label]:text-base mt-2 w-full [&>label]:!text-[#242431] [&>label]:!opacity-100"
        label="Description"
        type="text"
        placeholder="Briefly describe your goal..."
        :errors="v$.description.$errors"
        @input="(event) => (localForm.description = event.target.value)"
      >
        <span class="text-body-secondary mb-2 block">
          This optional field will help give context to your goal later.
        </span>
      </UiFormTextarea>
      <label
        class="mt-3 mb-2 block self-start text-sm font-medium text-[#242431]"
      >
        Percent Complete
      </label>
      <span class="text-body-secondary mb-2 block">
        Most goals start at 0% complete, but if that's not the case you can
        indicate that here.
      </span>
      <span class="mb-2 block font-semibold text-[#5C6582]">
        {{ localForm.percent_complete }}% complete
      </span>
      <input
        ref="scrubBar"
        v-model.number="localForm.percent_complete"
        type="range"
        min="0"
        max="100"
        step="1"
        class="scrub-bar w-full"
      />
      <label
        class="text-header-primary mb-1 mt-5 block self-start text-base font-semibold"
        for="progress"
      >
        Starts
      </label>
      <span class="text-body-secondary mb-2 block">
        If a goal starts on a day other than today you can indicate that here.
      </span>
      <UiDatePicker
        v-model="localForm.as_of"
        class="!max-w-full"
        hide-placeholder
        @input="handleDateSelect"
      />
    </UiFormGroup>
    <UiFormNavigation
      class="mb-0"
      show-previous
      prev-text="Cancel"
      submit-text="Next Step"
      :loading="isSubmitting"
      @prev="
        router.push({
          name: 'GroupManagement',
          params: {
            groupId: localForm.group_id,
          },
        })
      "
    >
      <slot></slot>
    </UiFormNavigation>
  </UiForm>
</template>

<style scoped>
  .scrub-bar {
    @apply accent-brand-primary;
  }

  :deep(label) {
    @apply !text-header-primary !text-base !font-semibold;
  }
</style>

<script setup lang="ts">
  import '@vuepic/vue-datepicker/dist/main.css';

  import { computed, ref, toRefs } from 'vue';
  import { uuid } from 'vue3-uuid';
  import VueDatePicker from '@vuepic/vue-datepicker';

  import { useValidationErrorMessage } from '../composables/form';
  import IconClock from '../icons/IconClock.vue';

  interface VuelidateError {
    $message: string;
  }
  interface ITimeObject {
    hours: number;
    minutes: number;
    seconds: number;
  }
  const props = defineProps<{
    errors?: VuelidateError[] | null;
    label?: string;
    modelValue: string;
  }>();
  const emit = defineEmits<{
    (e: 'focus'): void;
    (e: 'input', time: string): void;
  }>();

  const timePicker = ref();
  const localTime = ref(reformatStringToObject(props.modelValue));
  const { label, errors } = toRefs(props);
  const timePickerId = 'timePicker' + uuid.v4();

  const timePickerClass = computed(() => {
    const baseClass = 'relative w-full max-w-sm';

    if (errorMessage.value) {
      return baseClass + errorClass.value;
    }

    return baseClass;
  });

  const { errorClass, errorMessage, inputLabel } = useValidationErrorMessage(
    label,
    errors
  );

  function handleSelect(value: ITimeObject) {
    emit('input', reformatObjectToString(value));
  }

  function reformatObjectToString(timeObj: ITimeObject) {
    if (!timeObj) {
      return '';
    }

    return `${timeObj.hours || '00'}:${timeObj.minutes || '00'}:${
      timeObj.seconds || '00'
    }`;
  }

  function reformatStringToObject(timeStr: string) {
    if (!timeStr) {
      return null;
    }

    const timeArray = timeStr.split(':');

    return {
      hours: timeArray[0] || 0,
      minutes: timeArray[1] || 0,
      seconds: timeArray[2] || 0,
    };
  }
</script>

<template>
  <div :class="timePickerClass">
    <label
      :for="timePickerId"
      class="text-body-primary mb-1 block self-start text-sm font-medium"
    >
      {{ inputLabel }}
    </label>
    <VueDatePicker
      ref="timePicker"
      v-model="localTime"
      time-picker
      :is-24="false"
      position="center"
      :offset="2"
      disable-time-range-validation
      placeholder="Select time"
      minutes-increment="5"
      minutes-grid-increment="5"
      :start-time="{ hours: 9, minutes: 0 }"
      @focus="emit('focus')"
      @update:model-value="handleSelect"
    >
      <template #input-icon>
        <IconClock class="ml-3" />
      </template>
    </VueDatePicker>
  </div>
</template>

<style scoped>
  :deep(.dp_menu),
  :deep(.dp__input) {
    @apply font-sans;
  }

  :deep(input) {
    @apply cursor-text border-[#e8ecf4];
  }

  :deep(input.dp__input_focus),
  :deep(input:focus) {
    @apply outline outline-[2px] outline-offset-[-1px] outline-[#3F83F7];
  }

  :deep(.dp__overlay) {
    @apply rounded-lg;
  }

  :deep(.dp_menu) {
    transform: translateY(-100%) !important;
  }

  :deep(.dp__input) {
    @apply text-header-primary rounded-lg bg-white py-[10px] pl-10 text-sm;
  }

  :deep(.dp__instance_calendar) {
    @apply max-h-[140px];
  }

  :deep(.dp__input::placeholder) {
    @apply text-header-primary opacity-70;
  }

  :deep(.dp__pm_am_button) {
    @apply bg-brand-primary;
  }

  :deep(.dp__arrow_top),
  :deep(.dp__arrow_bottom) {
    display: none;
  }

  :deep(.dp__selection_preview) {
    display: none;
  }

  :deep(.dp__action_buttons) {
    @apply z-10 flex w-full justify-between;
  }

  :deep(.dp__select) {
    @apply text-brand-primary;
  }

  :deep(.dp__overlay_container) {
    @apply pt-1;
  }
</style>

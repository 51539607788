<script lang="ts" setup>
import { reactive, ref } from "vue";
import { useRouter } from 'vue-router';

import type { AxiosError } from "axios";

import { User } from '@orm-lib';

import { useAppStore } from '@/stores';
import type { ForgotPasswordFormPayload } from '@/components/public/types';
import { ForgotPasswordFormPayloadCreate } from '@/components/public/types';
import { FormBase, TextRow } from './'

type Nullable<T> = T | null;

interface IEmits {
    (e: 'submit', value:ForgotPasswordFormPayload): void;
}

const emit = defineEmits<IEmits>();

const router = useRouter();

const store = useAppStore();

const errorMessage = ref<string | null>(null)

const data = reactive(ForgotPasswordFormPayloadCreate())

const emailInput = ref<typeof TextRow | null>(null)
const inputs = [
    emailInput
]

function handleEmailInputChange(inputValue: Nullable<string>) {
    if (inputValue !== null)
        data.email = inputValue
}

function handleLogIn() {
    router.push({
        name: 'Login',
    })
}

async function handleSubmit() {
    let errorCount = 0;

    inputs.forEach((input) => {
        let error = input.value?.validate()
        if (error !== null) {
            errorCount += 1;
        }
    });

    if (errorCount !== 0)
        return

    try {
        await User.api().forgot(data.email);
        errorMessage.value = null
    } catch(e) {
        errorCount = 1
        const axiosError = e as AxiosError
        const errors = axiosError.response?.data as object
        errorMessage.value = Object.values(errors)[0][0]
    }

    if (errorCount === 0) {
         emit('submit', data)
    }
}
</script>

<template>
    <FormBase
        :error-message=errorMessage
        mode=forgot-password
        title="Forgot Password"
        subtitle="Enter your work email to receive instructions about how to reset your Backstage password."
        @log-in=handleLogIn
        @submit=handleSubmit
    >
        <TextRow id="forgot-password-email" ref="emailInput" :required="true"  title="Work Email" type="text" @change=handleEmailInputChange @submit=handleSubmit />
    </FormBase>
</template>
<script lang="ts" setup>
  import { reactive } from 'vue';

  import { UiForm, UiFormNavigation, UiFormTextarea } from '@ui-lib';

  interface IProps {
    skillId?: number;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<{
    (e: 'next', form: any): void;
    (e: 'prev'): void;
  }>();

  const form = reactive({
    comments: '',
  });

  function next() {
    const updatedForm = {
      comments: form.comments,
    };

    emit('next', updatedForm);
  }
</script>

<template>
  <UiForm
    prev-text="Cancel"
    title="Training Feedback"
    variant="config"
    @submit="next"
    @prev="emit('prev')"
  >
    <div class="form-question" style="margin-bottom: 20px">
      Is there anything else you would like to say about your training
      experience?
    </div>
    <UiFormTextarea
      v-model="form.comments"
      rows="3"
      style="resize: none"
      @input="(event) => (form.comments = event.target.value)"
    />
    <UiFormNavigation
      class="mb-0 mt-6"
      show-previous
      prev-text="Previous"
      submit-text="Finish"
      @prev="emit('prev')"
    />
  </UiForm>
</template>

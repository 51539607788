import { Model } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import Learner from './learner';

export default class TrainingRequestReview extends Model {
  static entity = 'training-request-review';

  @Num(null) declare id: number;
  @Str(null) declare comment: string;
  @Str(null) declare created: string;
  @BelongsTo(() => Learner, 'learner_id')
  declare created_by_learner: Learner;
  @Str(null) declare outcome: string;
  @Num(null) declare training_request_id: number;
  @Str(null) declare type: 'GroupOwner' | 'Admin';
}

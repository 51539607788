import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Bool, Num } from 'pinia-orm/dist/decorators';

export default class FeatureFlag extends Model {
  static entity = 'feature-flag';

  @Num(0) declare id: number;
  @Bool(false) declare 'employer.cohort.enabled': boolean;

  static api() {
    return {
      async list() {
        const { data } = await axios.get('/feature-flag/');
        const repo = useRepo(FeatureFlag);
        repo.flush();

        repo.save({ ...data, id: 1 });
      },
    };
  }
}

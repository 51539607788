<script lang="ts" setup>
  import { ref, watch } from 'vue';

  import { Tab, Tabs } from '../flowbite-vue';

  interface IProps {
    modelValue: string | number;
    tabs: { name: string; title: string; count?: number }[];
    variant: 'pills' | 'underline';
  }
  interface IEmit {
    (e: 'changeTab', id?: string): void;
  }
  const props = withDefaults(defineProps<IProps>(), { variant: 'underline' });
  const emit = defineEmits<IEmit>();

  const localValue = ref(props.modelValue);

  function getActiveClass(tab: string) {
    const rootClass = props.variant === 'default' ? 'bg-red-500' : '';

    if (tab === localValue.value) {
      return rootClass + ' active';
    }

    return rootClass;
  }

  watch(
    () => props.modelValue,
    (newValue) => {
      localValue.value = newValue;
    }
  );
</script>

<template>
  <div :class="`ui-tabs ${variant}`">
    <Tabs
      v-model="localValue"
      :variant="variant"
      @update:model-value="(value) => emit('changeTab', value)"
    >
      <Tab
        v-for="tab in tabs"
        :key="tab.name"
        class="ui-tab relative"
        :name="tab.name"
        :title="tab.title"
        :class="getActiveClass(tab.name)"
      ></Tab>
    </Tabs>
  </div>
</template>

<style scoped>
  .ui-tabs.pills :deep(ul) {
    @apply justify-start;
  }

  .ui-tabs.pills :deep(li > div) {
    @apply mx-0 mb-4 border-b-0 px-6 py-2;
  }

  .ui-tabs.pills :deep(li > div.bg-blue-600) {
    @apply bg-brand-primary text-brand-primary bg-opacity-10;
  }

  .ui-tabs.pills :deep(li > div) {
    @apply !mb-2 sm:!mb-4;
  }

  :deep(ul) {
    @apply justify-center;
  }

  :deep(div.border-b) {
    @apply border-0;
  }

  :deep(ul li div) {
    @apply mx-4 mb-7 border-b-2 border-solid px-0 pb-0 text-lg;
  }

  :deep(ul li div.text-blue-600) {
    @apply border-b-[3px] border-[#4F56FF] !text-[#4F56FF];
  }
</style>

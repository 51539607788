<script lang="ts" setup>
import {computed, ref, watch} from 'vue';

type Nullable<T> = T | null;

interface IProps {
    name: string,
    subtitle?: string | null,
    title: string,
    type?: 'radio' | 'checkbox',
    value: string
}

const props = withDefaults(defineProps<IProps>(), {
    subtitle: null,
    type: 'radio',
});

interface IEmits {
    (e: 'change', value:string[]): void;
}

const emit = defineEmits<IEmits>();

const id = computed(() => {
    return `${ props.name }-${ props.value }` as string
})

const input = ref();

function handleClick() {
    input.value?.click()
}

function handleChange() {
    const values: string[] = []

    const htmlInputs = document.getElementsByName(props.name) as unknown as Array<HTMLInputElement>
    htmlInputs.forEach((input) => {
        if (input.checked === true)
            values.push(input.value)
    })

    emit('change', values);
}
</script>

<template>
    <button class="choice-row-button" @click=handleClick>
        <div class="flex flex-row p-[8px] gap-[4px] md:gap-[8px]">
            <div class="flex flex-col p-[4px]">
                <label :for=id hidden>{{ title }}</label>
                <input :id=id ref="input" :type="type" class="choice-row-input" :name="name" :value="value" @click.stop="null" @change=handleChange />
            </div>
            <div class="flex flex-col">
                <div class="choice-row-title">
                    {{ title }}
                </div>
                <div v-if="subtitle!==null" class="text-[14px] md:text-[16px] font-[300] text-darkGrey">
                    {{ subtitle }}
                </div>
            </div>
        </div>
    </button>
</template>

<style scoped>
    .choice-row-button {
        @apply border-[1px] bg-transparent border-transparent rounded-[8px] text-left transition duration-[200ms];
    }

    .choice-row-button:hover,
    .choice-row-button:focus-visible,
    .choice-row-button:has(input:checked) {
        @apply bg-barelyGrey border-lightGrey
    }

    .choice-row-button:focus-visible {
        @apply outline-golden outline-dashed outline-offset-[2px] outline-[1px];
    }

    .choice-row-button:hover .choice-row-title,
    .choice-row-button:focus-visible .choice-row-title,
    .choice-row-button:has(input:checked) .choice-row-title {
        @apply text-primary;
    }

    .choice-row-title {
        @apply text-[14px] md:text-[16px] font-[400] text-blackish transition duration-[200ms];
    }

    .choice-row-input {
        appearance: none;
        @apply border-darkGrey h-[16px] w-[16px] md:h-[18px] md:w-[18px] transition duration-[200ms];
    }

    .choice-row-input:checked {
        @apply border-primary text-primary;
    }

    .choice-row-input:focus {
        box-shadow: none;
        @apply outline-golden outline-dashed outline-offset-[2px] outline-[1px];
    }
</style>
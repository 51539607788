import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import {
  Attr,
  BelongsTo,
  HasMany,
  HasOne,
  Num,
  Str,
} from 'pinia-orm/dist/decorators';

import Assignment from './assignment';
import Employer from './employer';
import Engagement from './engagement';
import Group from './group';
import Invite from './invite';
import TrainingSubscription from './training_subscription';
import User from './user';

export default class Learner extends Model {
  static entity = 'learner';

  @Num(null) declare id: number;
  @Str(null) declare assessed: string;
  @Str(null) declare created: string;
  @BelongsTo(() => Employer, 'employer_id')
  declare employer: Employer[];
  @Num(null) declare employer_id: number;
  @HasOne(() => Invite, 'learner_id') declare invite: Invite;
  @BelongsTo(() => User, 'user_id')
  declare user: User;
  @Attr(() => Group) declare owned_group: Group;
  @Attr(() => Group) declare learner_group: Group;
  @HasMany(() => TrainingSubscription, 'learner_id')
  declare training_subscriptions: TrainingSubscription[];
  @Num(null)
  declare user_id: number;
  @Str(null) declare welcomed: string;

  static api() {
    return {
      async detail(id: number) {
        const { data } = await axios.get(`/learner/${id}`);
        useRepo(Learner).save(data);
      },
      async list() {
        const { data } = await axios.get('/learner/');
        const repo = useRepo(Learner);
        repo.save(data);
      },
      async update(id: number, isAdmin: boolean) {
        const data = {
          is_admin: isAdmin,
        };
        await axios.put(`/learner/${id}`, data);
      },
      async saveAssessment(id: number, assessmentQuestionAnswers: any[]) {
        await axios.put(`/learner/${id}/assess`, assessmentQuestionAnswers);
      },
      async user() {
        const { data } = await axios.get(`/user/learner/`);
        useRepo(Learner).save(data);
      },
      async remove(id: number) {
        await axios.delete(`/learner/${id}`);
        const repo = useRepo(Learner);
        repo.destroy(id);
      },
      async welcome() {
        const { data } = await axios.put(`/user/learner/welcome`);
        const repo = useRepo(Learner);
        repo.flush();
        repo.save(data);
      },
    };
  }

  get assignments() {
    return useRepo(Assignment).with('skill');
  }

  get completedEngagements() {
    return this.engagements.where(
      'completed',
      (value: string) => value !== null
    );
  }

  get email() {
    if (this.user_id !== null) return useRepo(User).find(this.user_id)?.email;
    return useRepo(Invite).where('learner_id', this.id).first()?.email;
  }

  get full_name() {
    if (this.user_id !== null) {
      return useRepo(User).find(this.user_id)?.full_name;
    }

    return null;
  }

  get engagements() {
    return useRepo(Engagement).withAllRecursive().where('learner_id', this.id);
  }

  get inProgressEngagements() {
    return this.engagements.where('completed', null);
  }

  get isAdmin() {
    return useRepo(Employer).find(this.employer_id)?.isAdmin(this);
  }

  static async getAsOptions() {
    await Learner.api().list();

    const learners = useRepo(Learner)
      .with('user')
      .orderBy((learner) => {
        return useRepo(User).find(learner.user_id)?.last_name;
      })
      .get();

    const options: { value: number; text: string }[] = [];

    learners.forEach((learner) =>
      options.push({ value: learner.id, text: learner.user.full_name })
    );

    return options;
  }
}

<script lang="ts" setup>
  import { ref, useSlots } from 'vue';
  import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';

  import { UiButton, UiRouterLink } from '@ui-lib';
  import AppTag from '@/components/AppTag.vue';
  import AppTitleGroup from '@/components/AppTitleGroup.vue';

  interface IProps {
    action?: string;
    alert?: string;
    disabled?: boolean;
    showDelete?: boolean;
    startOpen?: boolean;
    subtitle?: string;
    tag?: string;
    title?: string;
    variant?: 'group' | 'standard';
  }
  interface IEmit {
    (e: 'delete'): void;
    (e: 'action-click'): void;
  }

  const props = withDefaults(defineProps<IProps>(), {
    showDelete: false,
    startOpen: false,
    variant: 'standard',
  });
  const slots = useSlots();
  const emit = defineEmits<IEmit>();
  const visible = ref(props.startOpen && !props.disabled);

  const toggleAccordion = () => {
    if (slots.default && !props.disabled) {
      visible.value = !visible.value;
    }
  };
</script>

<template>
  <div
    :class="`form-panel-list-item max-w-full ${variant} ${
      !!slots.default && 'cursor-pointer'
    }`"
    style="text-align: left"
    @click.prevent="toggleAccordion"
  >
    <component
      :is="!!slots.default ? 'button' : 'div'"
      class="panel-accordion relative z-[2] flex w-full min-w-full max-w-full flex-row items-stretch justify-start gap-2 py-5"
      :disabled="disabled"
      :style="!slots.default || disabled ? 'opacity: 0.5' : ''"
    >
      <AppTitleGroup
        :title="title"
        :subtitle="subtitle"
        class="z-[2] min-w-0 shrink"
      >
        <template v-if="slots.subtitle" #subtitle>
          <slot name="subtitle"></slot>
        </template>
      </AppTitleGroup>
      <div class="ml-auto flex shrink-0 items-center">
        <div class="opacity-70">
          <AppTag v-if="alert || tag">
            {{ alert || tag }}
          </AppTag>
        </div>
        <UiRouterLink
          v-if="action"
          class="cta-link text-brand-primary mr-3 whitespace-nowrap"
          @click.stop="emit('action-click')"
        >
          {{ action }}
        </UiRouterLink>
        <UiButton
          v-if="showDelete"
          type="button"
          class="icon ml-2 mr-4 h-[40px] min-w-[40px] self-end !rounded-lg !bg-white !p-0"
          variant="icon-delete"
          @click.stop="emit('delete')"
        >
          <img src="/assets/img/icon/delete.png" class="h-[17px] w-4" />
        </UiButton>
        <template v-if="!!slots.default">
          <img
            v-if="visible"
            class="ml-2 h-3 w-3"
            src="/assets/img/icon/collapse-grey.png"
            alt="Collapse content arrow"
          />
          <img
            v-else
            class="ml-2 h-3 w-3"
            src="/assets/img/icon/expand-grey.png"
            alt="Expand content arrow"
          />
        </template>
      </div>
    </component>
    <div v-if="!!slots.default" class="flex w-full flex-col">
      <CollapseTransition>
        <div v-show="visible" class="form-panel-list" @click.stop="">
          <slot />
        </div>
      </CollapseTransition>
    </div>
  </div>
</template>

<style scoped>
  :deep(.form-panel-list) {
    @apply mx-[-20px]  px-[20px];
  }

  .form-panel-list .form-panel-list .panel-accordion,
  .form-panel-list .form-panel-list .form-panel-list {
    @apply bg-white;
  }
</style>

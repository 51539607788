import axios from 'axios';
import { Model } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import Learner from './learner';

export default class GroupLearner extends Model {
  static entity = 'group-learner';

  @Num(null) declare id: number;
  @Num(null) declare learner_id: number;
  @Num(null) declare group_id: number;
  @BelongsTo(() => Learner, 'learner_id')
  declare learner: Learner;
  @Str(null) declare created: string;

  static api() {
    return {
      async remove(groupLearnerId: number) {
        await axios.delete(`/group-goal-group-learner/${groupLearnerId}`);
      },
      async add(groupGoalId: number, groupLearnerId: number) {
        await axios.post(`/group-goal-group-learner`, {
          group_goal_id: groupGoalId,
          group_learner_id: groupLearnerId,
        });
      },
      async reinvite(groupLearnerId: number) {
        await axios.get(`/group-learner/${groupLearnerId}/reinvite/`);
      },
    };
  }
}

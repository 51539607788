import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Attr, BelongsTo, HasOne, Num, Str } from 'pinia-orm/dist/decorators';

import Engagement from './engagement';
import Invite from './invite';
import Organization from './organization';
import Scheduler from './scheduler';
import User from './user';

export default class Coach extends Model {
  static entity = 'coach';

  @Num(null) declare id: number;
  @Str(null) declare created: string;
  @Num(null) declare employment_type: number;
  @HasOne(() => Invite, 'coach_id') declare invite: Invite;
  @BelongsTo(() => Organization, 'organization_id')
  declare organization: Organization;
  @HasOne(() => Scheduler, 'coach_id') declare scheduler: Scheduler;
  @Num(null) declare organization_id: number;
  @Attr([]) declare specialties: number[];
  @BelongsTo(() => User, 'user_id') declare user: User;
  @Num(null) declare user_id: number;

  static api() {
    return {
      async detail(id: number, employmentType: string) {
        const data = {
          employment_type: employmentType,
        };
        await axios.put(`/coach/${id}`, data);
      },
      async invite(email: string) {
        const payload = {
          email: email,
        };
        const { data } = await axios.post(`/coach/invite`, payload);
        const repo = useRepo(Coach);

        repo.save(data);
      },
      async list() {
        const { data } = await axios.get('/coach');
        const repo = useRepo(Coach);
        repo.save(data);
      },
      async profile(specialties: string[]) {
        const data = {
          specialties: specialties,
        };
        await axios.put('/user/coach', data);
      },
      async search(searchTerm: string) {
        const data = {
          search_term: searchTerm,
        };
        const response = await axios.post(`/coach/search`, data);
        return response.data;
      },
      async user() {
        const { data } = await axios.get(`/user/coach`);
        useRepo(Coach).save(data);
      },
    };
  }

  get completedEngagements() {
    return this.engagements.where(
      'completed',
      (value: string | null) => value !== null
    );
  }

  get email() {
    if (this.user_id !== null)
      return useRepo(User).withAll().find(this.user_id)?.email;
    return useRepo(Invite).withAll().where('coach_id', this.id).first()?.email;
  }

  get engagements() {
    return useRepo(Engagement).withAll().where('coach_id', this.id);
  }

  get inProgressEngagements() {
    return this.engagements.where('completed', null);
  }

  get isAdmin() {
    return useRepo(Organization).find(this.organization_id)?.isAdmin(this);
  }
}

<script lang="ts" setup>
  import { reactive, ref } from 'vue';
  import { isAxiosError } from 'axios';

  import { Group } from '@orm-lib';
  import { UiAlert, UiButton, UiFormInput, UiModal } from '@ui-lib';

  const props = defineProps<{
    group: Group;
    parentGroupId?: number;
  }>();
  const emit = defineEmits<{
    (e: 'close'): void;
  }>();

  const form = reactive({
    newName: '',
  });
  const validationError = reactive({
    newName: '',
  });
  const error = ref();

  function handleClose() {
    emit('close');
  }

  async function handleSubmit() {
    error.value = '';

    try {
      await Group.api().renameGroup(
        props.group.id,
        form.newName,
        props.parentGroupId
      );
      emit('close');
    } catch (e) {
      console.error(e);
      error.value =
        'There was an error renaming this group. Please try again later.';

      if (isAxiosError(e)) {
        const data = e?.response?.data;
        error.value = Object.values(data)?.[0]?.[0];
      }
    }
  }
</script>

<template>
  <UiModal
    class="[&>div>div>div]:rounded-[20px]"
    :close-on-click-outside="false"
    @close="emit('close')"
  >
    <form
      v-click-outside="handleClose"
      class="flex flex-col p-6"
      @submit.prevent="handleSubmit"
    >
      <h2 class="text-brand-primary mb-2 text-lg font-semibold sm:text-2xl">
        Rename the "{{ group.name }}" group
      </h2>
      <UiAlert v-if="!!error" class="mb-4 w-full" type="danger">
        {{ error }}
      </UiAlert>
      <span v-if="!validationError.link" class="form-label mb-1">
        New group name
      </span>
      <span v-else class="form-label mb-1 uppercase !text-red-500">
        {{ validationError.link }}
      </span>
      <UiFormInput
        v-model="form.newName"
        :class="`mb-4 ${
          validationError.newName && ' [&>div>input]:!border-red-500'
        }`"
        placeholder="Group name"
        type="text"
        @input="validationError.link = ''"
      />
      <div class="mb-4 flex justify-end">
        <UiButton
          variant="app-home"
          class="mr-4"
          color="white"
          type="button"
          @click="handleClose"
        >
          Cancel
        </UiButton>
        <UiButton variant="app-home" type="submit">Confirm</UiButton>
      </div>
    </form>
  </UiModal>
</template>

<style lang="css">
  .form-label {
    @apply text-header-secondary text-base font-medium;
  }
</style>

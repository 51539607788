<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { isNil } from 'lodash';

  import { type Recording } from '@orm-lib';
  import BotRecordingPlayer from '@/components/BotRecordingPlayer.vue';

  const props = defineProps<{
    quote: string;
    explanation: string;
    recording: Recording;
    lg: boolean;
    start: number | null;
    end: number | null;
    type: 'positive' | 'negative';
  }>();
  const overFlowHidden = ref(true);
  const quoteBlock = ref();

  const isQuoteOverflown = computed(() => {
    return quoteBlock.value?.clientHeight < quoteBlock.value?.scrollHeight;
  });

  function toggleQuoteLength() {
    overFlowHidden.value = !overFlowHidden.value;
  }
</script>

<template>
  <div>
    <h4 class="text-header-primary mb-2 text-lg font-semibold sm:text-xl">
      {{
        type === 'positive' ? 'What You Did Well' : 'Where You Need Improvement'
      }}
    </h4>
    <p
      class="text-body-primary mb-4 whitespace-pre-wrap text-sm sm:text-base"
      v-html="explanation"
    ></p>
    <template v-if="!isNil(start) && !isNil(end)">
      <BotRecordingPlayer
        :recording="recording"
        :lg="lg"
        :start="start"
        :end="end"
      />
      <div class="relative flex flex-col pt-4">
        <p
          ref="quoteBlock"
          :class="`quote-block text-body-secondary relative whitespace-pre-wrap px-6 text-center text-sm italic sm:text-base ${
            overFlowHidden && 'line-clamp-3'
          }`"
        >
          <span v-html="quote"></span>
          <span class="hidedots"></span>
        </p>
        <button
          v-if="isQuoteOverflown"
          class="text-brand-primary inline px-4 font-medium"
          @click="toggleQuoteLength"
        >
          {{ overFlowHidden ? 'more' : 'less' }}
        </button>
      </div>
    </template>
  </div>
</template>

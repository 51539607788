<script lang="ts" setup>
  const props = defineProps<{
    id: string;
    data: any[];
  }>();

  const chartOptions = {
    chart: {
      id: props.id,
      toolbar: { show: false },
      width: 400,
      zoom: {
        enabled: false,
      },
    },
    markers: {
      size: 10,
    },
    xaxis: {
      tickAmount: 5,
      labels: {
        formatter: function (val: number) {
          return `${val.toFixed(1)}%`;
        },
        style: {
          fontSize: '12px',
          fontFamily: 'Poppins',
        },
      },
    },
    legend: { show: false },
    colors: ['#9F84E6', '#9185EF', '#7D87FA', '#6AA1FD', '#61B4FC', '#5CBEFB'],
    yaxis: {
      tickAmount: 5,
      labels: {
        formatter: function (val: number) {
          return `$${val.toFixed(0)}`;
        },
        style: {
          fontSize: '12px',
          fontFamily: 'Poppins',
        },
      },
    },
  };
</script>

<template>
  <div
    class="flex flex-col rounded-[10px] border-[1px] border-solid border-[#E8ECF4] bg-white px-4 py-3"
  >
    <h3
      class="text-brand-primary mb-4 w-full border-b-[1px] border-solid border-[#CED1DA] pb-2 text-sm font-semibold"
    >
      MONTHLY SPEND
    </h3>
    <apexchart
      type="scatter"
      width="100%"
      :series="data"
      :options="chartOptions"
    ></apexchart>
    <h3 class="text-brand-primary self-center text-sm font-semibold">
      MONTHLY PROGRESS
    </h3>
  </div>
</template>

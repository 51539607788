<script lang="ts" setup>
  import PanelListHeading from '@/components/PanelListHeading.vue';
  import PanelListItem from '@/components/PanelListItem.vue';
  import type { TBreadcrumb } from '@/types';

  // This should be imported from list item, when that is allowed.
  interface IListItemProps {
    action?: string;
    alert?: string;
    isLink?: boolean;
    showDelete?: boolean;
    subtitle?: string;
    tag?: string;
    title: string;
    to?: TBreadcrumb;
    value: number | string;
  }

  interface IProps {
    heading?: string;
    items?: IListItemProps[];
  }
  const props = defineProps<IProps>();

  const emit = defineEmits<{
    (e: 'delete', id: number | string): void;
    (e: 'action-click', item: IListItemProps): void;
  }>();
</script>

<template>
  <div class="form-list flex-column">
    <PanelListHeading v-if="heading" :heading="heading" />
    <PanelListItem
      v-for="item in items"
      :key="item.value"
      class="dark"
      :action="item.action"
      :alert="item.alert"
      :is-link="item.isLink"
      :show-delete="item.showDelete"
      :subtitle="item.subtitle"
      :tag="item.tag"
      :title="item.title"
      :to="item.to"
      :value="item.value"
      @action-click="emit('action-click', item)"
      @delete="emit('delete', item.value)"
    />
    <slot></slot>
  </div>
</template>

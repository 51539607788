import { onBeforeUnmount, ref, watchEffect } from 'vue';
import { throttle } from 'lodash';

type TScreen = 'sm' | 'md' | 'lg' | 'xl' | '2xl';
const screens: Record<TScreen, string> = {
  sm: '(min-width: 641px)',
  md: '(min-width: 769px)',
  lg: '(min-width: 1025px)',
  xl: '(min-width: 1281px)',
  '2xl': '(min-width: 1537px)',
};

export const useMedia = (screen: TScreen) => {
  const matches = ref(true);
  const query = screens[screen];
  const media = window.matchMedia(query);
  const throttledFunction = ref();

  function onChange() {
    matches.value = media.matches;
  }

  watchEffect((onInvalidate) => {
    if (media.matches !== matches.value) {
      matches.value = media.matches;
    }

    media.addEventListener('change', onChange);

    onInvalidate(() => {
      media.removeEventListener('change', onChange);
    });
  });
  throttledFunction.value = throttle(onChange, 200);
  window.addEventListener('resize', throttledFunction.value);

  onBeforeUnmount(() => {
    window.removeEventListener('resize', throttledFunction.value);
  });

  return matches;
};

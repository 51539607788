<script lang="ts" setup>
  import { computed } from 'vue';
  import { DateTime } from 'luxon';

  import { type Invoice } from '@orm-lib';
  import { UiLink } from '@ui-lib';

  const props = defineProps<{
    invoices: Invoice[];
    isMobile: boolean;
  }>();

  const headings = computed(() => {
    if (props.isMobile) {
      return ['Invoice', 'Status', ''];
    }

    return ['Invoice', 'Amount', 'Status', 'Due', ''];
  });

  function getStatusStyle(status: 'open' | 'paid', due_date: string) {
    if (status === 'paid') {
      return ' text-[#00C47D] bg-[#00C47D]';
    }

    if (DateTime.fromISO(due_date) < DateTime.now()) {
      return ' bg-[#FB4F571A] text-[#FB4F57]';
    }

    return ' bg-[#FFBE021A] text-[#AF6900]';
  }

  function formatStatusText(status: 'open' | 'paid', due_date: string) {
    if (status === 'paid') {
      return 'Paid';
    }

    if (DateTime.fromISO(due_date) < DateTime.now()) {
      return 'Past Due';
    }

    return 'Due';
  }
</script>

<template>
  <div
    class="w-full rounded-[10px] border-[1px] border-solid border-[#E8ECF4] bg-white"
  >
    <table class="table w-full sm:h-[1px]">
      <tr class="table-header-group w-full">
        <th
          v-for="heading in headings"
          :key="heading ?? 'no-text'"
          class="text-brand-primary whitespace-nowrap py-3 px-3 text-left text-sm font-semibold uppercase leading-[18px] first:pl-5"
        >
          {{ heading }}
        </th>
      </tr>
      <template v-if="isMobile">
        <tr
          v-for="invoice in invoices"
          :key="invoice.number"
          class="border-t-solid table-row-group w-full items-center border-t-[1px] border-[#E8ECF4] px-4 py-4 sm:h-full"
        >
          <td
            class="py-3 pr-1 pl-5 sm:h-full sm:overflow-hidden"
            style="vertical-align: top"
          >
            <div class="flex flex-col items-start justify-center">
              <span
                :class="`text-header-primary overflow-hidden text-ellipsis text-sm font-semibold leading-[18px] sm:leading-[21px] `"
              >
                {{ invoice.number }}
              </span>
              <span
                class="text-body-secondary mt-[1px] overflow-hidden text-ellipsis text-sm leading-[18px] xl:hidden"
              >
                ${{ invoice.total }}
              </span>
            </div>
          </td>
          <td class="px-1 py-3" style="vertical-align: top">
            <div class="flex flex-col items-start justify-center">
              <span
                :class="`inline-flex items-center overflow-hidden text-ellipsis whitespace-nowrap rounded-lg bg-opacity-10 px-[10px] py-1 text-sm leading-[18px] ${getStatusStyle(
                  invoice.status,
                  invoice.due_date
                )}`"
              >
                <svg
                  v-if="invoice.status === 'paid'"
                  class="mr-2"
                  width="11"
                  height="8"
                  viewBox="0 0 11 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 3.5L4 6.5L9.5 1"
                    stroke="#00C47D"
                    stroke-width="1.5"
                  />
                </svg>
                {{ formatStatusText(invoice.status, invoice.due_date) }}
              </span>
            </div>
          </td>
          <td class="py-3 pl-1 pr-5" style="vertical-align: top">
            <UiLink
              :href="invoice.payment_url"
              target="_blank"
              class="text-brand-primary text-sm font-semibold"
            >
              View
            </UiLink>
          </td>
        </tr>
      </template>
      <template v-else>
        <tr
          v-for="invoice in invoices"
          :key="invoice.number"
          class="border-t-solid flex w-full items-center border-t-[1px] border-[#E8ECF4] px-4 py-4 sm:table-row-group sm:h-full"
        >
          <td
            class="text-header-primary overflow-hidden px-5 py-4 text-sm md:text-base"
            style="vertical-align: top"
          >
            {{ invoice.number }}
          </td>
          <td
            class="text-header-primary overflow-hidden px-3 py-4 text-sm md:text-base"
            style="vertical-align: top"
          >
            ${{ invoice.total }}
          </td>
          <td
            class="text-header-primary overflow-hidden px-3 py-4 text-sm md:text-base"
            style="vertical-align: top"
          >
            <span
              :class="`inline-flex items-center rounded-lg bg-opacity-10 px-[10px] py-1 ${getStatusStyle(
                invoice.status,
                invoice.due_date
              )}`"
            >
              <svg
                v-if="invoice.status === 'paid'"
                class="mr-2"
                width="11"
                height="8"
                viewBox="0 0 11 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 3.5L4 6.5L9.5 1"
                  stroke="#00C47D"
                  stroke-width="1.5"
                />
              </svg>
              {{ formatStatusText(invoice.status, invoice.due_date) }}
            </span>
          </td>
          <td
            class="text-header-primary overflow-hidden px-3 py-4 text-sm md:text-base"
            style="vertical-align: top"
          >
            {{ DateTime.fromISO(invoice.due_date).toFormat('MMM d, yyyy') }}
          </td>
          <td
            class="text-header-primary overflow-hidden px-3 py-4 text-sm md:text-base"
            style="vertical-align: top"
          >
            <UiLink
              target="_blank"
              :href="invoice.payment_url"
              class="text-brand-primary font-semibold"
            >
              View
            </UiLink>
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<style scoped>
  .learner-menu {
    box-shadow: 0 4px 15px 5px rgba(0, 0, 0, 0.1);
  }
</style>

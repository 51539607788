import { computed } from 'vue';

import { useAppStore } from '@/stores';

export const useApp = () => {
  const store = useAppStore();

  const employer = computed(() => store.employer);
  const hasUpdate = computed(() => store.hasUpdate);
  const currentUpload = computed(() => store.currentUpload);
  const isAdmin = computed(() => store.isAdmin);
  const isBillable = computed(() => store.isBillable);
  const isSidebarCollapsed = computed(() => store.navCollapsed);
  const isCoach = computed(() => store.isCoach);
  const isDesktop = computed(() => store.isDesktop);
  const isLearner = computed(() => store.isLearner);
  const member = computed(() => store.member);
  const memberOf = computed(() => store.memberOf);
  const uploadQueue = computed(() => store.uploadQueue);
  const user = computed(() => store.user);

  return {
    currentUpload,
    uploadQueue,
    isSidebarCollapsed,
    employer,
    hasUpdate,
    isAdmin,
    isBillable,
    isCoach,
    isDesktop,
    isLearner,
    member,
    memberOf,
    user,
  };
};
